import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { ExamService } from './exam.service';
import { AttestationService } from './../pages/attestation/attestation.service';
import { PrescriptionService } from './prescription.service';
import { OrientationService } from '@app/modules/document/pages/orientation/orientation.service';
import { PdfService } from './pdf.service';
import { Injectable } from '@angular/core';
import { HealthProfessional } from '@app/shared/models';
import { environment } from '@env/environment';
import { md5 } from '@app/utils/md5';
import { InfusionService } from './infusion.service';

interface PdfSignParams {
  code: string;
  prescriber: HealthProfessional;
  patientId: string;
  token?: string;
  layout?: any;
  payload?: string;
  callbackUrl?: string;
  showSendDocumentOnCallback?: boolean;
  victaFeature?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private _model: any;
  private _patientForm: FormGroup;
  private _plusGApp: string;
  private _sharedKey: string;

  constructor(
    private prescriptionService: PrescriptionService,
    private attestationService: AttestationService,
    private examService: ExamService,
    private orientationService: OrientationService,
    private infusionService: InfusionService,
    private pdfService: PdfService,
    private http: HttpClient
  ) {}

  get model() {
    return this._model;
  }

  set model(model: any) {
    this._model = model;
  }

  get patientForm() {
    return this._patientForm;
  }

  set patientForm(patientForm: FormGroup) {
    this._patientForm = patientForm;
  }

  usePdfV1Endpoint() {
    this.pdfService.useV1Endpoint();
  }

  async getDocument(code: string, type?: string) {
    let document = null;
    if (code[0] === 'A' || type === 'attestation') {
      document = await this.attestationService.getAttestation(code).toPromise();
      document.type = 'Atestado';
    } else if (code[0] === 'E' || type === 'exam') {
      document = await this.examService.getExam(code).toPromise();
      document.type = 'Exame';
    } else if (code[0] === 'O' || type === 'orientation') {
      document = await this.orientationService.getOrientation(code).toPromise();
      document.type = 'Outros';
    } else if (code[0] === 'I' || type === 'infusion') {
      document = await this.infusionService.getInfusion(code).toPromise();
      document.type = 'Infusão';
    } else if (code[0] === 'P' || type === 'prescription' || !type) {
      document = await this.prescriptionService.getPrescriptionbyCode(code).toPromise();
      document.type = 'Prescrição';
    }

    return document;
  }

  archive(code: string) {
    return this.http.put(`${environment.apiRoot}documents/${code}/archive`, {});
  }

  unarchive(code: string) {
    return this.http.delete(`${environment.apiRoot}documents/${code}/archive`);
  }

  getTotals(tenantId: string) {
    return this.http.get<any>(`${environment.apiRoot}documents/totals`, { params: { tenantId } });
  }

  async generateAndOpenPdf(code: string, layout = {}) {
    const pdf = await this.pdfService.generateAndSavePdf({ code, draft: true, layout });
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = pdf.url;
    link.download = pdf.filename;
    link.click();
    link.remove();
  }

  async getUrlToSignDocument({
    code,
    prescriber,
    patientId,
    token,
    layout,
    payload,
    callbackUrl,
    showSendDocumentOnCallback = true,
    victaFeature
  }: PdfSignParams): Promise<string> {
    if (victaFeature) {
      this._plusGApp = `${environment.plusGApp}/institutodosoro`;
      this._sharedKey = environment.envPlusSecretKeyIS;
    } else {
      this._plusGApp = `${environment.plusGApp}/receitadigital`;
      this._sharedKey = environment.envPlusSecretKey;
    }

    try {
      const { filename, url: pdfUrl } = await this.pdfService.generateAndSavePdf({ code, layout });
      if (!pdfUrl) {
        throw Error('Erro ao gerar PDF');
      }

      return this.buildSignPdfPayload({
        filename,
        pdfUrl,
        prescriber,
        patientId,
        code,
        token,
        payload,
        callbackUrl,
        showSendDocumentOnCallback
      });
    } catch (err) {
      throw err;
    }
  }

  private buildSignPdfPayload({
    filename,
    pdfUrl,
    prescriber,
    patientId,
    code,
    token,
    payload: payloadB64,
    callbackUrl,
    showSendDocumentOnCallback
  }): string {
    const document = prescriber.regionalCouncilNumber;
    const route = token ? `recipe/new/${token}` : 'prescription/new';
    const payloadParam = payloadB64 ? `&payload=${payloadB64}` : '';

    let url = `${window.location.origin}/${route}`;
    let urlParams = `code=${code}&patientId=${patientId}${payloadParam}`;

    if (callbackUrl) {
      if (showSendDocumentOnCallback) {
        urlParams += `&callbackUrl=${callbackUrl}`;
      } else {
        url = callbackUrl;
      }
    }

    const payload = {
      callbackUrl: `${url}?${urlParams}&response=`,
      webhookUrl: `${environment.apiRoot}pdf/upload-signed?code=${code}`,
      ui: {
        username: prescriber.cpf
      },
      security: {
        allowAddNewDocument: false,
        allowDocumentType: false,
        payloadCallbackUrl: true
      },
      files: [
        {
          name: filename,
          src: pdfUrl,
          signatureSetting: {
            id: code,
            type: 'DOC-pdf',
            visibleSignatureField: 'Assinatura',
            visibleSignatureGeneratorDocument: `${document.label} ${document.number} ${document.uf}`,
            visibleSignatureGeneratorHeader: 'Assinado Digitalmente por:',
            visibleSignatureGeneratorFooter: 'Em conformidade com a ICP-Brasil MP2.200-2'
          }
        }
      ]
    };

    return this.computeHmacUrl(JSON.stringify(payload).replace(/\//g, '\\/'));
  }

  private computeHmacUrl(payloadJson: string): string {
    // Payload
    const payloadEncoded = btoa(payloadJson);
    console.log('payloadEncoded: ', payloadEncoded);

    // Compute HMAC
    const time = Math.round(new Date().getTime() / 1000);
    const rand = Math.floor(Math.random() * 9999);
    const nonce = `${time}${rand}`;
    const token = `${nonce}-${md5(`${nonce}${this._sharedKey}${md5(payloadEncoded)}`)}`;

    // URL
    return `${this._plusGApp}/${token}/embed?payload=${encodeURIComponent(payloadEncoded)}`;
  }
}
