<div class="favorite-document-modal" theme>
  <div
    class="modal-header"
    #modalHeader
    [ngStyle]="{
      'background-color': colorSetting?.primary,
      'border-color': colorSetting?.primary
    }"
  >
    <h4 class="modal-title pull-left text-white">Modelos de {{ documentType }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="body prescription">
    <nz-list nzBordered>
      <nz-list-item *ngFor="let item of documentsTemplate">
        <div>
          <div class="row header prescription align-items-center">
            <div class="col-1" (click)="activeChange(item)">
              <i nz-icon nzType="right" *ngIf="!item.active" nzTheme="outline"></i>
              <i nz-icon nzType="down" *ngIf="item.active" nzTheme="outline"></i>
            </div>

            <div class="col pt-2" (click)="activeChange(item)">
              <h6>{{ item.title }}</h6>
            </div>

            <div class="p-1">
              <button
                class="ok-btn btn-selecionar"
                nz-button
                (click)="onSelect(item)"
                [ngStyle]="{
                  'background-color': colorSetting?.secondary,
                  'border-color': colorSetting?.secondary
                }"
              >
                Adicionar
              </button>
            </div>

            <div class="p-0">
              <button *ngIf="!hideEdit" (click)="onEdit(item)" title="Editar" class="btn btn-edit">
                <span class="btn-inner--icon"><i class="fa fa-pencil-solid"></i></span>
              </button>
            </div>
            <div class="pl-0">
              <button (click)="onDelete(item)" title="Excluir" class="btn btn-trash">
                <span class="btn-inner--icon"><i class="fa fa-trash-solid position-inherit"></i></span>
              </button>
            </div>
          </div>

          <div *ngIf="item.active && documentType === typesOfDocuments[0]">
            <div *ngFor="let medicament of item.medicaments">
              <br />
              <p><span class="bold">Nome:</span> {{ medicament.name }}</p>
              <p><span class="bold">Princípio ativo:</span> {{ medicament.principle }}</p>
              <p><span class="bold">Descrição:</span> {{ medicament.description }}</p>
              <p><span class="bold">Quantidade:</span> {{ medicament.prescribedQuantity }}</p>
              <p><span class="bold">Posologia:</span> {{ medicament.dosage }}</p>
            </div>
          </div>
          <div *ngIf="item.active && documentType === typesOfDocuments[1]">
            <div *ngFor="let row of item.examList">
              Material: {{ row.material }}
              <div>
                Exames:
                <ul>
                  <p *ngFor="let exam of row.exams">{{ exam.name }}</p>
                </ul>
              </div>
              <div *ngIf="getText(item.justification)">
                <p class="mt-4 mb-0">Justificativa:</p>
                <ul>
                  <p *ngFor="let j of item.justification">{{ j }}</p>
                </ul>
              </div>
            </div>
          </div>
          <div *ngIf="item.active && documentType === typesOfDocuments[2]">
            <div>
              <br />
              <p>{{ getText(item.description) }}</p>
            </div>
          </div>
          <div *ngIf="item.active && documentType === typesOfDocuments[3]">
            <div>
              <br />
              <p>{{ getText(item.orientations) }}</p>
            </div>
          </div>
        </div>
      </nz-list-item>
    </nz-list>
    <br />
  </div>
</div>
