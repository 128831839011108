<nz-progress [nzPercent]="(100.0 / 3) * step" [nzShowInfo]="false" nzStrokeColor="#0b6054"></nz-progress>

<div class="register-body">
  <app-file-step *ngIf="step === 1" [(form)]="fileForm"></app-file-step>

  <app-subaccount-step
    *ngIf="step === 2"
    [(form)]="subaccountForm"
    [(profile)]="profile"
  ></app-subaccount-step>

  <app-subscription-step *ngIf="step === 3" [(form)]="subscriptionForm"></app-subscription-step>

  <div class="steps-btn">
    <button nz-button class="btn btn-primary" type="button" nzType="primary" (click)="changeStep(false)">
      <div>
        <i class="fas fa-arrow-left"></i>
        <span>VOLTAR</span>
      </div>
    </button>

    <button nz-button class="btn btn-primary" type="button" nzType="primary" (click)="changeStep(true)">
      <div>
        <span>{{ step < 3 ? 'PRÓXIMO' : 'CONCLUIR' }}</span>
        <i class="fas fa-arrow-right"></i>
      </div>
    </button>
  </div>
</div>
