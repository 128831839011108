<ng-template #tplContent>
  <div class="container send-document" theme>
    <h1 class="title" [ngStyle]="{ color: colorSetting?.primary }">
      Selecione o layout da prescrição
    </h1>

    <nz-radio-group [(ngModel)]="layout" (ngModelChange)="changeLayout()">
      <label nz-radio nzValue="simple">Receita Simples</label>
      <label nz-radio nzValue="antimicrobial">Receita de Antimicrobiano</label>
      <label nz-radio nzValue="special">Receita de Controle Especial</label>
      <label nz-checkbox [(ngModel)]="showPrescriberCpf" *ngIf="layout === 'special'">
        <span>
          Exibir CPF do prescritor<br />
          <span class="horm">(Obrigatório para prescrição de hormônios)</span>
        </span>
      </label>
    </nz-radio-group>

    <div *ngIf="showAddressForm" style="margin-top: 1rem; position: relative; width: 100%;">
      <form nz-form [formGroup]="form" theme>
        <app-address-data-form
          [form]="form"
          alertMsg="Para prosseguir, complete os dados obrigatórios de endereço do paciente, conforme RDC 344/98 (Anvisa)"
          [required]="true"
        ></app-address-data-form>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #tplFooter>
  <div
    class="d-flex align-items-end flex-column modal-footer"
    theme
    [ngStyle]="
      colorSetting && {
        'background-color': colorSetting.secondary,
        'border-color': colorSetting.secondary
      }
    "
  >
    <div class="row">
      <button
        (click)="close()"
        class="btn btn-black"
        [ngStyle]="colorSetting && { color: colorSetting.primary }"
      >
        Cancelar
      </button>
      <button
        (click)="submit()"
        class="btn btn-ok"
        [ngStyle]="
          colorSetting && {
            'background-color': colorSetting?.primary,
            'border-color': colorSetting?.primary
          }
        "
      >
        OK
      </button>
    </div>
  </div>
</ng-template>
