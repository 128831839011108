import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HealthProfessional, Patient } from '@app/shared/models';
import { User } from '@app/shared/models/decodedLoginToken';
import { environment } from '@env/environment';
import { CroppedEvent } from 'ngx-photo-editor';
import { Observable } from 'rxjs';

export const HEALTH_PROFESSIONAL_URL = 'health-professional';
export const NEW_PATIENT_URL = 'new-patient';
export const ADD_PATIENT_URL = 'add-patient';
export const REMOVE_PATIENT_URL = 'remove-patient';
export const USER_URL = 'user';

interface UploadResponse {
  message: string;
  url: string;
}
@Injectable({ providedIn: 'root' })
export class HealthProfessionalService {
  constructor(private http: HttpClient) {}

  save(healthProfessional: HealthProfessional): Observable<HealthProfessional> {
    return this.http.post<HealthProfessional>(
      `${environment.apiRoot}${HEALTH_PROFESSIONAL_URL}`,
      healthProfessional
    );
  }

  savePatient(healthProfessionalId: string, patient: Patient): Observable<Patient> {
    return this.http.post<Patient>(
      `${environment.apiRoot}${HEALTH_PROFESSIONAL_URL}/${healthProfessionalId}/${NEW_PATIENT_URL}`,
      patient
    );
  }

  addPatient(healthProfessionalId: string, patientId: any): Observable<HealthProfessional> {
    return this.http.put<HealthProfessional>(
      `${environment.apiRoot}${HEALTH_PROFESSIONAL_URL}/${healthProfessionalId}/${ADD_PATIENT_URL}`,
      { patientId: patientId }
    );
  }

  removePatient(healthProfessionalId: string, patientId: any): Observable<HealthProfessional> {
    return this.http.put<HealthProfessional>(
      `${environment.apiRoot}${HEALTH_PROFESSIONAL_URL}/${healthProfessionalId}/${REMOVE_PATIENT_URL}`,
      { patientId: patientId }
    );
  }

  getUser(userId): Observable<User> {
    return this.http.get<User>(`${environment.apiRoot}${USER_URL}/${userId}`);
  }

  getHealthProfessional(id: string): Observable<HealthProfessional> {
    return this.http.get<HealthProfessional>(`${environment.apiRoot}${HEALTH_PROFESSIONAL_URL}/${id}`);
  }

  getHealthProfessionalByCpf(cpf: string, type?: string): Observable<HealthProfessional> {
    const params = type ? { type } : {};
    return this.http.get<HealthProfessional>(`${environment.apiRoot}${HEALTH_PROFESSIONAL_URL}/${cpf}`, {
      params
    });
  }

  getPatientsById(id: string, userId: string): Observable<Patient[]> {
    return this.http.get<Patient[]>(
      `${environment.apiRoot}${HEALTH_PROFESSIONAL_URL}/${userId}/patients/${id}`
    );
  }

  getPatientsByUserId(query: string, userId: string): Observable<Patient[]> {
    if (query !== null && !this.onlyNumbers(query)) {
      query = query.toLocaleLowerCase();
    }
    return this.http.get<Patient[]>(
      `${environment.apiRoot}${HEALTH_PROFESSIONAL_URL}/${userId}/patients?query=${query}`
    );
  }

  getPatientsByUserIdWithPagination(
    query: string,
    userId: string,
    pageSize: number,
    skip: number
  ): Observable<any> {
    let params = new HttpParams().set('skip', skip.toString()).set('pageSize', pageSize.toString());
    if (query !== null) {
      if (!this.onlyNumbers(query)) {
        query = query.toLocaleLowerCase();
      }
      params = params.set('query', query);
    }
    return this.http.get<any>(
      `${environment.apiRoot}${HEALTH_PROFESSIONAL_URL}/${userId}/patients/filter`,
      { params }
    );
  }

  private onlyNumbers(value: string): Boolean {
    return value && value.match(/^[0-9]+$/) != null;
  }

  getHealthProfessionals(): Observable<HealthProfessional[]> {
    return this.http.get<HealthProfessional[]>(`${environment.apiRoot}${HEALTH_PROFESSIONAL_URL}`);
  }

  getHealthProfessionalsByProfessionalType(professionalType: string, filter: any): Observable<any> {
    return this.http.get<any>(
      `${environment.apiRoot}${HEALTH_PROFESSIONAL_URL}/professional-type/${professionalType}`,
      {
        params: filter
      }
    );
  }

  getHealthProfessionalsByFilter(
    filter: any,
    skip?: string,
    limit?: string
  ): Observable<HealthProfessional[]> {
    const params = new HttpParams()
      .set('filter', JSON.stringify(filter))
      .set('skip', skip)
      .set('limit', limit);

    return this.http.get<HealthProfessional[]>(`${environment.apiRoot}${HEALTH_PROFESSIONAL_URL}`, {
      params
    });
  }

  getHealthProfessionalByUserId(userId: string): Observable<HealthProfessional> {
    return this.http.get<HealthProfessional>(
      `${environment.apiRoot}${HEALTH_PROFESSIONAL_URL}/user/${userId}`
    );
  }
  addCommercialData(userId: string, commercialData: any) {
    return this.http.put<HealthProfessional>(
      `${environment.apiRoot}${HEALTH_PROFESSIONAL_URL}/add-commercial-data/${userId}`,
      commercialData
    );
  }

  update(id: string, data: any): Observable<HealthProfessional> {
    return this.http.put<HealthProfessional>(
      `${environment.apiRoot}${HEALTH_PROFESSIONAL_URL}/user/${id}`,
      data
    );
  }

  resolvePending(professionalType) {
    return this.http.put(`${environment.apiRoot}${HEALTH_PROFESSIONAL_URL}/pendency`, {
      professionalType
    });
  }

  exportData() {
    return this.http
      .get(`${environment.apiRoot}${HEALTH_PROFESSIONAL_URL}/export`)
      .subscribe((response: any) => {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = 'medicos.csv';
        a.click();
      });
  }

  uploadDocument(base64: string) {
    return this.http.post<UploadResponse>(
      `${environment.apiRoot}${HEALTH_PROFESSIONAL_URL}/upload-document`,
      { base64 }
    );
  }

  importPatients(healthProfessionalId: string, file: Blob) {
    const formData = new FormData();
    formData.append('patients', file);
    return this.http
      .post<{ count: number }>(
        `${environment.apiRoot}${HEALTH_PROFESSIONAL_URL}/${healthProfessionalId}/patients/import`,
        formData
      )
      .toPromise();
  }

  unsubscribe(id: string) {
    return this.http.delete(`${environment.apiRoot}${HEALTH_PROFESSIONAL_URL}/${id}`);
  }

  uploadLogo({ file, base64 }: CroppedEvent, commercialId: string) {
    const data = new FormData();

    data.append('logo', file);
    return this.http
      .put<any>(
        `${environment.apiRoot}${HEALTH_PROFESSIONAL_URL}/commercial-data/${commercialId}/logo`,
        data
      )
      .toPromise();
  }

  removeLog(commercialId: string) {
    return this.http.delete(
      `${environment.apiRoot}${HEALTH_PROFESSIONAL_URL}/commercial-data/${commercialId}/logo`
    );
  }
}
