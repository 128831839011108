<app-alternative-navbar [title]="navTitle" titleIcon="fas"></app-alternative-navbar>
<app-sidebar-menu></app-sidebar-menu>

<div class="container mt-4">
  <form nz-form [formGroup]="form">
    <div class="row justify-content-md-center">
      <div class="col-md-5">
        <div class="row">
          <div class="col">
            <nz-form-item>
              <nz-form-label>Data da Dispensação:<span class="mandatory"> *</span></nz-form-label>
              <nz-form-control nzHasFeedback>
                <input
                  nz-input
                  type="date"
                  formControlName="dispensationDate"
                  name="dispensationDate"
                  theme
                />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-md-6">
            <nz-form-item>
              <nz-form-label nzFor="cpfBuyer"
                >CPF do comprador<span class="mandatory"> *</span></nz-form-label
              >
              <nz-form-control nzHasFeedback>
                <input
                  nz-input
                  id="cpfBuyer"
                  mask="000.000.000-00"
                  formControlName="cpfBuyer"
                  theme
                />
                <nz-form-explain *ngIf="form.get('cpfBuyer').dirty && form.get('cpfBuyer').errors">
                  <ng-container *ngIf="form.get('cpfBuyer').hasError('required')">
                    Campo obrigatório, por favor digite seu CPF.
                  </ng-container>
                  <ng-container
                    *ngIf="
                      !form.get('cpfBuyer').hasError('required') && form.get('cpfBuyer').hasError('cpf')
                    "
                  >
                    CPF inválido.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="row" [formGroup]="form.get('preWriterRegister')">
          <div class="col-md-6">
            <nz-form-item>
              <nz-form-label nzFor="preWriterRegister-numero"
                >Registro do prescritor<span class="mandatory"> *</span></nz-form-label
              >
              <nz-form-control nzHasFeedback>
                <input formControlName="number" id="preWriterRegister-numero" nz-input theme />
                <nz-form-explain
                  *ngIf="
                    form.get('preWriterRegister.number').dirty &&
                    form.get('preWriterRegister.number').errors
                  "
                >
                  <ng-container *ngIf="form.get('preWriterRegister.number').hasError('required')">
                    Campo obrigatório, por favor informe o número do prescritor.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-md-6">
            <nz-form-item>
              <nz-form-label nzFor="preWriterRegister-uf"
                >UF<span class="mandatory"> *</span></nz-form-label
              >
              <nz-form-control nzHasFeedback>
                <nz-select
                  formControlName="uf"
                  id="preWriterRegister-uf"
                  nzShowSearch
                  nzAllowClear
                  nzDropdownClassName="account-select"
                  nzPlaceHolder="Selecione"
                  theme
                >
                  <nz-option
                    *ngFor="let state of states"
                    [nzLabel]="state?.sigla"
                    [nzValue]="state?.sigla"
                  >
                  </nz-option>
                </nz-select>
                <nz-form-explain
                  *ngIf="
                    form.get('preWriterRegister.uf').dirty && form.get('preWriterRegister.uf').errors
                  "
                >
                  <ng-container *ngIf="form.get('preWriterRegister.uf').hasError('required')">
                    Campo obrigatório, por favor selecione o UF do prescritor.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <nz-form-item>
              <nz-form-control>
                <app-input-file
                  accept=".pdf"
                  formControlName="pdf"
                  textButton="Selecione uma receita"
                  transformTo="base64"
                  maxSize="3145728"
                  name="pdf"
                  [file]="file"
                  [(filename)]="filename"
                  theme
                ></app-input-file>
                <nz-form-explain *ngIf="form.get('pdf').dirty && form.get('pdf').errors">
                  <ng-container *ngIf="form.get('pdf').hasError('required')">
                    Arquivo obrigatório, por favor selecione um PDF.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-end">
            <button nz-button nzType="primary" (click)="save()">Salvar</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
