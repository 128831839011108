import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NzModalRef, NzNotificationService } from 'ng-zorro-antd';
import { RetentionService } from '@app/modules/document/services/retention.service';

@Component({
  selector: 'app-modal-cancel-retention',
  templateUrl: './modal-cancel-retention.component.html',
  styleUrls: ['./modal-cancel-retention.component.scss']
})
export class ModalCancelRetentionComponent implements OnInit {
  userId: string;
  retentionId: string;
  loading: boolean = false;
  @Input() isVisible = true;
  @Output() updateListPrescriptionEvent = new EventEmitter<any>();
  reason: string = '';

  constructor(
    private nzModalRef: NzModalRef,
    private notification: NzNotificationService,
    private retentionService: RetentionService
  ) {}

  ngOnInit() {}

  async handleOk() {
    if (this.reason === '') {
      this.notification.warning('Aviso', 'O motivo é obrigatório');
      return;
    }

    if (this.reason.length < 10) {
      this.notification.warning('Aviso', 'O motivo não pode ter menos que 10 caracteres');
      return;
    }
    const data = { reason: this.reason, userId: this.userId, retentionId: this.retentionId };
    this.retentionService.cancel(data).subscribe(response => {
      if (!response.erro) {
        this.notification.success('Sucesso', response.message);
        this.updateListPrescriptionEvent.emit('');
        this.nzModalRef.destroy();
      }
    });
  }

  handleCancel(): void {
    this.nzModalRef.destroy();
  }
}
