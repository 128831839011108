<header id="alternative-navbar">
  <nav class="navbar navbar-expand-lg navbar-light">
    <div>
      <a class="navbar-brand" (click)="redirect()" style="margin-top: -118px;">
        <img
          [src]="isThemeActiveReceitaDigital() ? 'assets/img/logo-03.png' : 'assets/img/mrd/logo-03.png'"
          [alt]="isThemeActiveReceitaDigital() ? 'Logo Receita Digital' : 'Logo MRD'"
        />
      </a>
      <app-icon-menu></app-icon-menu>
    </div>

    <div class="container-fluid-new pb-5">
      <div class="row flex-wrap justify-content-md-center">
        <div class="col-md-12">
          <div class="collapse navbar-collapse" id="navbar-default">
            <ul class="navbar-nav">
              <li class="nav-item">
                <a href="#" class="nav-link" (click)="$event.preventDefault()">
                  <div class="photo" *ngIf="user">
                    <img [alt]="user.name" class="img-fluid" src="/assets/img/default-avatar.png" />
                  </div>
                </a>
              </li>
              <h1 class="navbar-text" *ngIf="user.userType === 'pharmacy'">
                <h2 class="card-title color-white">{{ completeUser?.businessName }}</h2>
                <h6 class="card-subtitle mb-2 color-white">
                  CNPJ: {{ completeUser?.cnpj | mask: '00.000.000/0000-00' }}
                </h6>
              </h1>
              <h1 class="navbar-text" *ngIf="user.userType === 'pharmacist'">
                <h2 class="card-title color-white">{{ user?.name }}</h2>
                <h6 class="card-subtitle mb-2 color-white">
                  CPF: {{ user?.cpf | mask: '000.000.000-00' }}
                </h6>
              </h1>
            </ul>
            <ul class="navbar-nav ml-lg-auto align-items-center">
              <li class="nav-item">
                <a
                  routerLink="/entry/login"
                  (click)="logout()"
                  class="nav-link nav-link-icon logoutIcon"
                >
                  <i class="fa fa-exit icon-exit"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-md-2 tile-container ml-2 mr-2">
          <a routerLink="/dashboard">
            <div class="card">
              <div class="card-body carddispensar">
                <div class="align-items-center flex-column justify-content-center">
                  <label class="font-white padding font-card"
                    >DISPENSAR<br />
                    <i class="fas fa-plus-square" style="color: #fff;"></i>
                  </label>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-2 tile-container ml-2 mr-2" *ngIf="user?.userType === 'pharmacy'">
          <a routerLink="/historic">
            <div class="card">
              <div class="card-body cardhistorico">
                <div class="align-items-center flex-column justify-content-center">
                  <label class="font-white padding font-card"
                    >HISTÓRICO DE<br />RETENÇÕES&nbsp;
                    <i class="fa fa-history" style="color: #fff;"></i>
                  </label>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-2 tile-container ml-2 mr-2" *ngIf="user?.userType === 'pharmacy'">
          <a routerLink="/pharmacist/">
            <div class="card">
              <div class="card-body cardfarmaceuticos">
                <div class="align-items-center flex-column justify-content-center">
                  <label class="font-green padding font-card"
                    >FARMACÊUTICOS<br />
                    <i class="fa fa-user-friends"></i>
                  </label>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-2 tile-container ml-2 mr-2">
          <a routerLink="/profile">
            <div class="card">
              <div class="card-body cardarquivopdfs">
                <div class="align-items-center flex-column justify-content-center">
                  <label class="font-green padding font-card"
                    >MEUS DADOS<br />
                    <i class="fa fa-account-box"></i>
                  </label>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </nav>
</header>
