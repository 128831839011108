import { CommonModule, registerLocaleData } from '@angular/common';
import pt from '@angular/common/locales/pt';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PipesModule } from '@app/shared/pipes';
import { ThemeModule, themeMrd, themeReceitaDigital } from '@app/theme';
import { environment } from '@env/environment';
import { InViewportModule } from 'ng-in-viewport';
import { NgZorroAntdModule, NZ_I18N, pt_BR } from 'ng-zorro-antd';
import { NgxMaskModule } from 'ngx-mask';
import { ComponentsModule } from './components/components.module';
import { DirectivesModule } from './directives/directives.module';
import { LayoutModule } from './layout/layout.module';

registerLocaleData(pt);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    LayoutModule,
    InViewportModule,
    NgZorroAntdModule,
    DirectivesModule,
    PipesModule,
    ComponentsModule,
    ThemeModule.forRoot({
      themes: [themeReceitaDigital, themeMrd],
      active: environment.template
    }),
    NgxMaskModule.forRoot()
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    LayoutModule,
    InViewportModule,
    NgZorroAntdModule,
    DirectivesModule,
    PipesModule,
    ComponentsModule,
    ThemeModule,
    NgxMaskModule
  ],
  providers: [{ provide: NZ_I18N, useValue: pt_BR }]
})
export class SharedModule {}
