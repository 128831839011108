<section class="section-hero header" theme>
  <div class="container d-flex align-items-center justify-content-center flex-column">
    <a routerLink="/" class="brand-bg">
      <img
        *ngIf="isThemeActiveReceitaDigital; else mrdLogo"
        src="assets/img/logo-01.jpg"
        alt="Logo Receita Digital"
        style="position: relative; top: -12px;"
      />

      <ng-template #mrdLogo>
        <img src="assets/img/mrd/logo-com-texto.png" class="mrd-logo" alt="Logo MRD" />
      </ng-template>
    </a>
    <h1 class="mb-5 mt-5">
      Defina uma senha para ativar sua conta
    </h1>
  </div>
</section>
<section class="section container d-flex justify-content-center align-items-center p-4" theme>
  <div class="d-flex flex-column form">
    <p class="pr-3">
      Crie uma senha forte utilizando seis caracteres ou mais. Ela deve conter uma combinação de letras
      maiúsculas e minúsculas, números e símbolos.
    </p>
    <form nz-form [formGroup]="passwordForm">
      <div class="row">
        <div class="col-md-11">
          <nz-form-item class="mb-4">
            <nz-form-control nzHasFeedback>
              <app-input-password placeholder="Senha" formControlName="password"></app-input-password>
              <nz-form-explain
                *ngIf="passwordForm.get('password').dirty && passwordForm.get('password').errors"
              >
                <ng-container *ngIf="passwordForm.get('password').hasError('required')">
                  A senha é obrigatória.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
            <app-password-strength
              [password]="passwordForm.value.password"
              (isStrong)="isPasswordStrong = $event"
            ></app-password-strength>
          </nz-form-item>
        </div>
        <div class="col-md-11">
          <nz-form-item class="mb-4">
            <nz-form-control nzHasFeedback>
              <app-input-password
                placeholder="Confirme sua senha"
                formControlName="confirmPassword"
              ></app-input-password>
              <nz-form-explain
                *ngIf="
                  passwordForm.get('confirmPassword').dirty && passwordForm.get('confirmPassword').errors
                "
              >
                <ng-container *ngIf="passwordForm.get('confirmPassword').hasError('required')">
                  A senha de confirmação é obrigatória.
                </ng-container>
                <ng-container *ngIf="passwordForm.get('confirmPassword').hasError('notEqual')">
                  Senhas diferentes.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-md-11 d-flex justify-content-end">
          <button (click)="activateAccount()" class="btn btn-ok" [disabled]="!passwordForm.valid">
            Ativar conta
          </button>
        </div>
      </div>
    </form>
  </div>
</section>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<app-modal-activate-account></app-modal-activate-account>
