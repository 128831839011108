<div
  theme
  class="modal-header"
  [ngStyle]="
    colorSetting && {
      'background-color': colorSetting?.secondary,
      'border-color': colorSetting?.secondary
    }
  "
>
  <h4 class="modal-title pull-left text-white">Cadastrar novo modelo</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div>
  <div class="modal-body">
    <div *ngIf="message">
      <nz-alert nzType="warning" [nzMessage]="message"></nz-alert>
      <br />
    </div>

    <div class="prescription" [formGroup]="documentModelForm">
      <nz-form-item>
        <nz-form-label nzFor="title" theme [ngStyle]="colorSetting && { color: colorSetting?.primary }"
          >Título<span class="mandatory"> *</span></nz-form-label
        >
        <nz-form-control nzHasFeedback>
          <input
            nz-input
            formControlName="title"
            attr.id="title"
            placeholder="Digite o título do modelo..."
            [ngStyle]="
              colorSetting && {
                'background-color': colorSetting?.inputBackground,
                'border-color': colorSetting?.inputBorder
              }
            "
            theme
          />
          <nz-form-explain
            *ngIf="documentModelForm.get('title').dirty && documentModelForm.get('title').errors"
          >
            <ng-container *ngIf="documentModelForm.get('title').hasError('required')">
              Campo obrigatório, por favor digite o título.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>
      <ng-container formArrayName="medicaments" *ngIf="documentType === typesOfDocuments[0]">
        <ng-container
          *ngFor="let medicament of documentModelForm.get('medicaments')['controls']; let i = index"
          [formGroup]="medicament"
        >
          <div class="medication-container">
            <div *ngIf="(documentModelForm.value?.medicaments)[i]?.medicamentId">
              <h4 class="title">
                {{ i + 1 }}. {{ (documentModelForm.value?.medicaments)[i]?.name }}
                <p class="mb-0 description">
                  {{ (documentModelForm.value?.medicaments)[i]?.apresentacaoCustomizada }}
                </p>
              </h4>
              <h2 class="activePrinciples">
                {{ (documentModelForm.value?.medicaments)[i]?.principle }} -
                {{ (documentModelForm.value?.medicaments)[i]?.description }}
              </h2>
            </div>

            <div
              *ngIf="!(documentModelForm.value?.medicaments)[i]?.medicamentId"
              class="free-prescription"
            >
              <h4 class="title">{{ i + 1 }}.</h4>
              <h4 class="title break-line">{{ (documentModelForm.value?.medicaments)[i]?.name }}</h4>
            </div>

            <nz-form-item>
              <nz-form-label [nzFor]="'dosage_' + i" class="sr-only">Posologia</nz-form-label>
              <nz-form-control>
                <textarea
                  [id]="'dosage_' + i"
                  appAutoSize
                  appFocus
                  formControlName="dosage"
                  nz-input
                  placeholder="Posologia"
                  disabled="true"
                ></textarea>
              </nz-form-control>
            </nz-form-item>
            <app-auto-width-input-number
              [id]="'quantidade' + i"
              formControlName="prescribedQuantity"
              disabled="true"
              [colorSetting]="colorSetting"
            >
            </app-auto-width-input-number>
            <!-- <button *ngIf="!signed" (click)="deletePrescription(i)" class="btn btn-icon btn-simple delete-medication-btn"
              type="button">
              <span class="btn-inner--icon"><i class="fa fa-trash-solid"></i></span>
            </button> -->
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="documentType === typesOfDocuments[1]">
        <div class="medication-container">
          <div *ngFor="let item of documentModel.examList">
            Material: {{ item.material }}
            <div>
              Exames:
              <div *ngFor="let exam of item.exams" style="padding-left: 20px;">
                {{ exam.name }}
              </div>
            </div>
          </div>
          <div *ngIf="getText(documentModel.justification)">
            <p class="mt-4 mb-0">Justificativa:</p>
            <nz-form-item>
              <nz-form-control>
                <textarea rows="4" attr.id="text" nz-input formControlName="text" disabled></textarea>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="documentType === typesOfDocuments[2]">
        <div class="medication-container">
          <nz-form-item>
            <nz-form-control>
              <textarea rows="4" [attr.id]="'text'" nz-input formControlName="text" disabled></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </ng-container>
      <ng-container *ngIf="documentType === typesOfDocuments[3]">
        <div class="medication-container">
          <nz-form-item>
            <nz-form-control>
              <textarea rows="4" [attr.id]="'text'" nz-input formControlName="text" disabled></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="ok-btn btn-cancel"
      nz-button
      (click)="modalRef.hide()"
      theme
      [ngStyle]="colorSetting && { color: colorSetting.primary }"
    >
      Cancelar
    </button>
    <button
      theme
      class="ok-btn btn-salvar"
      nz-button
      (click)="saveDocumentModel()"
      [disabled]="!documentModelForm.valid"
      [ngStyle]="
        colorSetting && {
          'background-color': colorSetting.primary,
          'border-color': colorSetting.primary
        }
      "
    >
      Salvar <i class="fa"></i>
    </button>
  </div>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
