import { ToJsonPipe } from './to-json.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsCompleteNamePipe } from './is-complete-name-pipe.pipe';
import { FormattedTelPipe } from './formatted-tel.pipe';
import { FormattedCelPipe } from './formatted-cel.pipe';
import { IsTelPipe } from './is-tel.pipe';
import { OnlyNumbersPipe } from './only-numbers.pipe';
import { IsCpfPipe } from './is-cpf.pipe';
import { FormattedCepPipe } from './formatted-cep.pipe';
import { FormattedCpfPipe } from './formatted-cpf.pipe';
import { FormattedCnpjPipe } from './formatted-cnpj.pipe';
import { SafePipe } from './safe.pipe';
import { FormattedMapaSipeagroPipe } from './formatted-mapa-sipeagro.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    IsCompleteNamePipe,
    FormattedTelPipe,
    FormattedCelPipe,
    IsTelPipe,
    OnlyNumbersPipe,
    IsCpfPipe,
    FormattedCepPipe,
    FormattedCpfPipe,
    FormattedCnpjPipe,
    SafePipe,
    ToJsonPipe,
    FormattedMapaSipeagroPipe
  ],
  exports: [
    IsCompleteNamePipe,
    FormattedTelPipe,
    FormattedCelPipe,
    IsTelPipe,
    OnlyNumbersPipe,
    IsCpfPipe,
    FormattedCepPipe,
    FormattedCpfPipe,
    FormattedCnpjPipe,
    SafePipe,
    ToJsonPipe,
    FormattedMapaSipeagroPipe
  ]
})
export class PipesModule {}
