<form nz-form [formGroup]="form" theme>
  <div class="row justify-content-between">
    <nz-alert
      class="mb-4"
      nzType="warning"
      nzMessage="Para agilizar seu cadastro, buscaremos dados complementares no site da Receita Federal.
Este é um procedimento seguro e o sigilo dos seus dados é garantido pela nossa plataforma"
    ></nz-alert>
    <div class="col-md-6">
      <div class="row align-items-center">
        <div class="col-md">
          <nz-form-item>
            <nz-form-label nzFor="cpf">CPF <span class="mandatory">*</span></nz-form-label>
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                id="cpf"
                formControlName="cpf"
                mask="000.000.000-00"
                placeholder="000.000.000-00"
              />
              <nz-form-explain *ngIf="cpfCtrl.dirty && cpfCtrl.errors">
                <ng-container *ngIf="cpfCtrl.hasError('required')">
                  Campo obrigatório, por favor digite seu CPF.
                </ng-container>
                <ng-container *ngIf="!cpfCtrl.hasError('required') && cpfCtrl.hasError('cpf')">
                  CPF inválido, por favor digite um CPF válido.
                </ng-container>
                <ng-container *ngIf="cpfCtrl.hasError('alreadyExists')">
                  CPF já cadastrado.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-md">
          <nz-form-item>
            <nz-form-label nzFor="birthdate"
              >Data de nascimento <span class="mandatory">*</span></nz-form-label
            >
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                id="birthdate"
                type="tel"
                mask="00/00/0000"
                formControlName="birthdate"
                placeholder="DD/MM/AAAA"
              />
              <nz-form-explain *ngIf="dateCtrl.dirty && dateCtrl.errors">
                <ng-container *ngIf="dateCtrl.hasError('required')">
                  Campo obrigatório, por favor digite sua data de nascimento.
                </ng-container>
                <ng-container *ngIf="!dateCtrl.hasError('required') && dateCtrl.hasError('birthdate')">
                  Data inválida.
                </ng-container>
                <ng-container *ngIf="dateCtrl.hasError('invalidDate') && dateCtrl.value.length > 0">
                  Data inválida.
                </ng-container>
                <ng-container *ngIf="dateCtrl.hasError('nonMatchingCpf')">
                  Data de nascimento não corresponde ao CPF informado.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-auto pt-3">
          <button
            nz-button
            class="btn-primary"
            (click)="consultCpf()"
            [disabled]="cpfCtrl.invalid || dateCtrl.invalid"
          >
            OK
          </button>
        </div>

        <ng-container *ngIf="verifiedCpf">
          <div class="col-12">
            <nz-form-item>
              <nz-form-label nzFor="fullname"
                >Nome completo <span class="mandatory">*</span></nz-form-label
              >
              <nz-form-control nzHasFeedback>
                <input
                  formControlName="fullname"
                  id="fullname"
                  nz-input
                  placeholder="ex: João Gilberto"
                  maxlength="128"
                  autocomplete="disabled"
                />
                <nz-form-explain *ngIf="form.get('fullname').dirty && form.get('fullname').errors">
                  <ng-container *ngIf="form.get('fullname').hasError('required')">
                    Campo obrigatório, por favor digite o seu nome e sobrenome.
                  </ng-container>
                  <ng-container
                    *ngIf="
                      !form.get('fullname').hasError('required') &&
                      form.get('fullname').hasError('fullname')
                    "
                  >
                    Por favor digite o seu nome e sobrenome.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-12">
            <nz-form-item>
              <nz-form-label nzFor="name"
                >Nome de exibição/social <span class="mandatory">*</span></nz-form-label
              >
              <nz-form-control nzHasFeedback>
                <input
                  formControlName="name"
                  id="name"
                  nz-input
                  placeholder="ex: João Gilberto"
                  maxlength="128"
                  autocomplete="disabled"
                />
                <nz-form-explain *ngIf="form.get('name').dirty && form.get('name').errors">
                  <ng-container *ngIf="form.get('name').hasError('required')">
                    Campo obrigatório, por favor digite um nome de exibição.
                  </ng-container>
                  <ng-container
                    *ngIf="!form.get('name').hasError('required') && form.get('name').hasError('name')"
                  >
                    Por favor digite um nome de exibição.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-4">
                <nz-form-item>
                  <nz-form-label nzFor="sex">Sexo <span class="mandatory">*</span></nz-form-label>
                  <nz-form-control nzHasFeedback>
                    <nz-select formControlName="sex" id="sex" nzPlaceHolder="Selecione">
                      <nz-option nzLabel="Masculino" nzValue="M"></nz-option>
                      <nz-option nzLabel="Feminino" nzValue="F"></nz-option>
                    </nz-select>
                    <nz-form-explain *ngIf="form.get('sex').dirty && form.get('sex').errors">
                      <ng-container *ngIf="form.get('sex').hasError('required')">
                        Campo obrigatório, por favor selecione o sexo.
                      </ng-container>
                    </nz-form-explain>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div class="col-5" [formGroup]="form.get('regionalCouncilNumber')">
                <nz-form-item>
                  <nz-form-label nzFor="regionalCouncilNumber-numero"
                    >{{ regionalCouncilLabel }} <span class="mandatory">*</span></nz-form-label
                  >
                  <nz-form-control nzHasFeedback>
                    <input
                      formControlName="number"
                      id="regionalCouncilNumber-numero"
                      nz-input
                      type="number"
                      autocomplete="disabled"
                      (keypress)="keypress()"
                    />
                    <nz-form-explain
                      *ngIf="
                        form.get('regionalCouncilNumber.number').dirty &&
                        form.get('regionalCouncilNumber.number').errors
                      "
                    >
                      <ng-container
                        *ngIf="form.get('regionalCouncilNumber.number').hasError('required')"
                      >
                        Campo obrigatório, por favor digite seu {{ regionalCouncilLabel }}.
                      </ng-container>
                    </nz-form-explain>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div class="col-3" [formGroup]="form.get('regionalCouncilNumber')">
                <nz-form-item>
                  <nz-form-label nzFor="uf">UF<span class="mandatory"> *</span></nz-form-label>
                  <nz-form-control nzHasFeedback>
                    <nz-select
                      formControlName="uf"
                      id="uf"
                      nzShowSearch
                      nzAllowClear
                      nzDropdownClassName="account-select"
                      nzPlaceHolder="Selecione"
                    >
                      <nz-option
                        *ngFor="let state of states"
                        [nzLabel]="state?.sigla"
                        [nzValue]="state?.sigla"
                      >
                      </nz-option>
                    </nz-select>
                    <nz-form-explain
                      *ngIf="
                        form.get('regionalCouncilNumber.uf').dirty &&
                        form.get('regionalCouncilNumber.uf').errors
                      "
                    >
                      <ng-container *ngIf="form.get('regionalCouncilNumber.uf').hasError('required')">
                        Campo obrigatório, por favor selecione o UF do CRM.
                      </ng-container>
                    </nz-form-explain>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <div class="row" *ngIf="isVeterinarian">
              <nz-form-item class="col-12">
                <nz-form-label nzFor="mapaSipeagro">MAPA Sipeagro </nz-form-label>
                <nz-form-control nzHasFeedback>
                  <input
                    formControlName="mapaSipeagro"
                    id="mapaSipeagro"
                    nz-input
                    type="number"
                    autocomplete="disabled"
                  />
                  <nz-form-explain
                    *ngIf="form.get('mapaSipeagro').dirty && form.get('mapaSipeagro').errors"
                  >
                    <ng-container *ngIf="form.get('mapaSipeagro').hasError('required')">
                      Campo obrigatório, por favor digite seu MAPA Sipeagro.
                    </ng-container>
                  </nz-form-explain>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <ng-container *ngIf="isDentist; else doctorSpecialties">
            <div class="col-12">
              <nz-form-item>
                <nz-form-label
                  >Especialidades(s) <span class="max-quantity">no máximo 3 opções </span
                  ><span class="mandatory"> *</span></nz-form-label
                >
                <nz-form-control>
                  <nz-select
                    id="specialties"
                    nzSize="large"
                    nzMode="multiple"
                    nzDropdownClassName="account-select"
                    nzPlaceHolder="Selecione a sua especialidade"
                    [(ngModel)]="selectedSpecialties"
                    (ngModelChange)="addSpecialties()"
                    [ngModelOptions]="{ standalone: true }"
                    nzAllowClear
                    nzShowSearch
                  >
                    <nz-option
                      *ngFor="let specialty of specialtiesOptions; trackBy: trackById"
                      class="disableClick"
                      [nzLabel]="specialty?.title"
                      [nzValue]="specialty?._id"
                    >
                    </nz-option>
                  </nz-select>
                  <nz-form-explain
                    *ngIf="form.get('specialties').dirty && !form.get('specialties').errors"
                  >
                  </nz-form-explain>
                </nz-form-control>
              </nz-form-item>
            </div>
          </ng-container>

          <ng-template #doctorSpecialties formArrayName="specialties">
            <div
              class="col-12"
              *ngFor="let specialty of specialtiesForm.controls; let i = index"
              [@slideInOut]
              [formGroup]="specialty"
            >
              <div class="row justify-content-between">
                <div [ngClass]="specialtiesForm.length > 1 ? 'col-11' : 'col-12'">
                  <nz-divider
                    nzOrientation="left"
                    [nzText]="'Especialidades ' + (specialtiesForm.length > 1 ? i + 1 : '')"
                  ></nz-divider>
                </div>
                <div
                  class="col-1 pl-0 d-flex align-items-center justify-content-center"
                  *ngIf="specialtiesForm.length > 1"
                >
                  <a
                    (click)="$event.preventDefault()"
                    [nzContent]="contentTemplateSpecialty"
                    class="popover-trigger"
                    nz-popover
                    nzPlacement="right"
                  >
                    <i class="fa fa-ellipsis-solid"></i>
                  </a>
                  <ng-template #contentTemplateSpecialty>
                    <a (click)="specialtiesForm.removeAt(i)"><i class="fa fa-trash-solid"></i></a>
                  </ng-template>
                </div>
              </div>
              <div class="row">
                <div [ngClass]="isVeterinarian ? 'col-12' : 'col-7'">
                  <nz-form-item>
                    <nz-form-label [nzFor]="'specialty_' + i"
                      >Especialidade <span class="mandatory">*</span></nz-form-label
                    >
                    <nz-form-control nzHasFeedback *ngIf="specialty.get('specialty') as specialtyId">
                      <nz-select
                        [id]="'specialty_' + i"
                        formControlName="specialty"
                        nzSize="large"
                        nzDropdownClassName="account-select"
                        nzPlaceHolder="Selecione uma especialidade"
                        nzAllowClear
                        nzShowSearch
                      >
                        <nz-option
                          *ngFor="let specialty of specialtiesOptions; trackBy: trackById"
                          class="disableClick"
                          [nzLabel]="specialty?.title"
                          [nzValue]="specialty?._id"
                        >
                        </nz-option>
                      </nz-select>
                      <nz-form-explain *ngIf="specialtyId.dirty && specialtyId.errors">
                        <ng-container *ngIf="specialtyId.hasError('required')">
                          Campo obrigatório, por favor selecione uma especialidade.
                        </ng-container>
                      </nz-form-explain>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div class="col-5" *ngIf="!isVeterinarian">
                  <nz-form-item *ngIf="specialty.get('rqe') as rqe">
                    <div class="row">
                      <div class="col-5 pr-0">
                        <nz-form-label [nzFor]="'rqe_' + i"
                          >RQE <span class="mandatory">*</span></nz-form-label
                        >
                      </div>
                      <div class="col pl-0">
                        <label
                          nz-checkbox
                          formControlName="notExists"
                          (nzCheckedChange)="checkRQE(rqe, $event)"
                          style="font-size: 14px;"
                          >Não possuo</label
                        >
                      </div>
                    </div>
                    <nz-form-control nzHasFeedback>
                      <input nz-input [id]="'rqe_' + i" formControlName="rqe" maxlength="20" />
                      <nz-form-explain *ngIf="rqe.dirty && rqe.errors">
                        <ng-container *ngIf="rqe.hasError('required')">
                          Campo obrigatório, por favor informe o RQE da especialidade.
                        </ng-container>
                      </nz-form-explain>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </div>
            <div class="col-12 pb-4" *ngIf="specialtiesForm.length < 3">
              <button
                (click)="addSpecialty()"
                class="btn btn-icon btn-success btn-add text-capitalize"
                type="button"
              >
                <span class="btn-inner--text">Adicionar Outra Especialidade</span>
                <span class="btn-inner--icon"><i class="fa fa-plus"></i></span>
              </button>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </div>

    <div class="col-md-5" [@slideInOut] *ngIf="verifiedCpf">
      <nz-form-item>
        <nz-form-label nzFor="email">E-mail<span class="mandatory"> *</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            nz-input
            id="email"
            type="email"
            formControlName="email"
            placeholder="usuario@email.com"
            style="text-transform: lowercase;"
            (keydown.space)="$event.preventDefault()"
            autocomplete="disabled"
          />
          <nz-form-explain *ngIf="form.get('email').dirty && form.get('email').errors">
            <ng-container *ngIf="form.get('email').hasError('required')">
              Campo obrigatório, por favor digite seu e-mail.
            </ng-container>
            <ng-container
              *ngIf="!form.get('email').hasError('required') && form.get('email').hasError('email')"
            >
              E-mail inválido, por favor digite um e-mail válido.
            </ng-container>
            <ng-container *ngIf="form.get('email').hasError('alreadyExists')">
              E-mail já cadastrado, por favor escolha outro e-mail.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzFor="emailConfirmation"
          >Confirmação de e-mail<span class="mandatory"> *</span>
        </nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            nz-input
            id="emailConfirmation"
            type="email"
            formControlName="emailConfirmation"
            placeholder="usuario@email.com"
            style="text-transform: lowercase;"
            (keydown.space)="$event.preventDefault()"
            onpaste="false"
            autocomplete="disabled"
          />
          <nz-form-explain>
            <ng-container
              *ngIf="
                form.get('emailConfirmation').hasError('required') && form.get('emailConfirmation').dirty
              "
            >
              <span class="error"> Campo obrigatório, por favor digite seu e-mail.</span><br />
            </ng-container>

            <ng-container
              *ngIf="
                form.get('emailConfirmation').invalid && form.get('emailConfirmation').value.length > 0
              "
            >
              <span class="error">E-mail inválido, por favor digite um e-mail válido.</span><br />
            </ng-container>
            <ng-container
              *ngIf="
                form.hasError('emailMatch') &&
                form.get('email').dirty &&
                form.get('email').value.length > 0 &&
                form.get('emailConfirmation').dirty &&
                form.get('emailConfirmation').value.length > 0
              "
            >
              <span class="error">Os endereços de e-mail não correspondem.</span><br />
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="form.get('cellphone') as cellphone">
        <nz-form-label nzFor="cellphone">Telefone 1 <span class="mandatory">*</span></nz-form-label>
        <nz-form-control>
          <input
            nz-input
            id="cellphone"
            [mask]="cellphone.value.length <= 10 ? '(00) 0000-00009' : '(00) 00000-0000'"
            formControlName="cellphone"
            placeholder="(21) 92222-2222"
            autocomplete="disabled"
          />
          <nz-form-explain *ngIf="cellphone.dirty && cellphone.errors">
            <ng-container *ngIf="cellphone.hasError('required')">
              Campo obrigatório, por favor digite seu telefone para contato.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzFor="telephone">Telefone 2</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            id="telephone"
            [mask]="form.get('telephone').value.length <= 10 ? '(00) 0000-00009' : '(00) 00000-0000'"
            formControlName="telephone"
            placeholder="(21) 2222-2222"
            autocomplete="disabled"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <ng-container *ngIf="verifiedCpf && commercialForm?.controls.length">
    <ng-container formArrayName="commercialData">
      <div
        *ngFor="let group of commercialForm.controls; let i = index"
        [@slideInOut]
        [formGroup]="group"
      >
        <div class="row justify-content-between">
          <div [ngClass]="commercialForm.length > 1 ? 'col-11' : 'col-12'">
            <nz-divider
              nzOrientation="left"
              [nzText]="'Dados comerciais ' + (commercialForm.length > 1 ? i + 1 : '')"
            ></nz-divider>
          </div>
          <div
            class="col-1 pl-0 d-flex align-items-center justify-content-center"
            *ngIf="commercialForm.length > 1"
          >
            <a
              (click)="$event.preventDefault()"
              [nzContent]="contentTemplateComercial"
              class="popover-trigger"
              nz-popover
              nzPlacement="right"
            >
              <i class="fa fa-ellipsis-solid"></i>
            </a>
            <ng-template #contentTemplateComercial>
              <a (click)="commercialForm.removeAt(i)"><i class="fa fa-trash-solid"></i></a>
            </ng-template>
          </div>
        </div>
        <div class="row mb-3 mt-3 justify-content-between">
          <div class="col-md-6">
            <div class="row align-items-center">
              <div class="col-12">
                <nz-form-item>
                  <nz-form-label [nzFor]="'nome_' + i"
                    >Nome<span class="mandatory"> *</span>
                  </nz-form-label>
                  <nz-form-control nzHasFeedback>
                    <input
                      [id]="'nome_' + i"
                      formControlName="name"
                      nz-input
                      placeholder="ex: Clínica Dona Ana"
                      maxlength="128"
                      autocomplete="disabled"
                    />
                    <nz-form-explain *ngIf="group.get('name').dirty && group.get('name').errors">
                      <ng-container *ngIf="group.get('name').hasError('required')">
                        Campo obrigatório, por favor digite o nome.
                      </ng-container>
                    </nz-form-explain>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div class="col-12">
                <nz-form-item>
                  <nz-form-label [nzFor]="'email_' + i">E-mail</nz-form-label>
                  <nz-form-control nzHasFeedback>
                    <input
                      [id]="'email_' + i"
                      formControlName="email"
                      nz-input
                      placeholder="usuario@email.com"
                      type="email"
                      autocomplete="disabled"
                    />
                    <nz-form-explain *ngIf="group.get('email').dirty && group.get('email').errors">
                      <ng-container
                        *ngIf="
                          (!group.get('email').hasError('required') &&
                            group.get('email').hasError('email')) ||
                          group.get('email').invalid
                        "
                      >
                        E-mail inválido, por favor digite um e-mail válido.
                      </ng-container>
                    </nz-form-explain>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="row align-items-center">
              <div class="col-12">
                <nz-form-item>
                  <nz-form-label [nzFor]="'telephone_' + i" class="mb-0"
                    >Telefone 1 <span class="mandatory">*</span>
                    <app-popover-button
                      content="Será exibido no cabeçalho dos documentos emitidos na plataforma"
                    ></app-popover-button>
                  </nz-form-label>
                  <nz-form-control>
                    <input
                      [id]="'telephone_' + i"
                      [mask]="
                        group.get('telephone').value.length <= 10 ? '(00) 0000-00009' : '(00) 00000-0000'
                      "
                      formControlName="telephone"
                      nz-input
                      placeholder="(21) 92222-2222"
                      autocomplete="disabled"
                    />
                    <nz-form-explain
                      *ngIf="group.get('telephone').dirty && group.get('telephone').errors"
                    >
                      <ng-container *ngIf="group.get('telephone').hasError('required')">
                        Campo obrigatório, por favor digite o telefone comercial.
                      </ng-container>
                    </nz-form-explain>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div class="col-12">
                <nz-form-item>
                  <nz-form-label [nzFor]="'cellphone_' + i">Telefone 2</nz-form-label>
                  <nz-form-control>
                    <input
                      [id]="'cellphone_' + i"
                      [mask]="
                        group.get('cellphone').value.length <= 10 ? '(00) 0000-00009' : '(00) 00000-0000'
                      "
                      formControlName="cellphone"
                      nz-input
                      placeholder="(21) 2222-2222"
                      autocomplete="disabled"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
          <div
            class="col-12 mt-3"
            *ngIf="group.get('address') as addressGroup"
            [formGroup]="addressGroup"
          >
            <div class="row">
              <div class="col-md-3">
                <nz-form-item>
                  <nz-form-label nzFor="cep">CEP <span class="mandatory">*</span></nz-form-label>
                  <nz-form-control nzHasFeedback>
                    <input
                      mask="00.000-000"
                      formControlName="cep"
                      id="cep"
                      (keyup)="onKey($event, addressGroup)"
                      nz-input
                      placeholder="00.000-000"
                      autocomplete="disabled"
                    />
                    <nz-form-explain
                      *ngIf="addressGroup.get('cep').dirty && addressGroup.get('cep').errors"
                    >
                      <ng-container *ngIf="addressGroup.get('cep').hasError('required')">
                        Campo obrigatório, por favor digite o cep.
                      </ng-container>
                      <ng-container
                        *ngIf="
                          !addressGroup.get('cep').hasError('required') &&
                          addressGroup.get('cep').hasError('cep')
                        "
                      >
                        Cep inválido.
                      </ng-container>
                    </nz-form-explain>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div class="col-md-6">
                <nz-form-item>
                  <nz-form-label nzFor="street"
                    >Logradouro<span class="mandatory"> *</span></nz-form-label
                  >
                  <nz-form-control nzHasFeedback>
                    <input
                      id="street"
                      formControlName="street"
                      nz-input
                      placeholder="Digite o logradouro"
                      maxlength="128"
                      autocomplete="disabled"
                    />
                    <nz-form-explain
                      *ngIf="addressGroup.get('street').dirty && addressGroup.get('street').errors"
                    >
                      <ng-container *ngIf="addressGroup.get('street').hasError('required')">
                        Campo obrigatório, por favor digite o logradouro.
                      </ng-container>
                    </nz-form-explain>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div class="col-md-3">
                <nz-form-item>
                  <nz-form-label nzFor="addressNumber"
                    >Número<span class="mandatory"> *</span></nz-form-label
                  >
                  <nz-form-control nzHasFeedback>
                    <input
                      formControlName="number"
                      id="addressNumber"
                      nz-input
                      placeholder="Digite o número"
                      maxlength="10"
                      autocomplete="disabled"
                    />
                    <nz-form-explain
                      *ngIf="addressGroup.get('number').dirty && addressGroup.get('number').errors"
                    >
                      <ng-container *ngIf="addressGroup.get('number').hasError('required')">
                        Campo obrigatório, por favor digite o número.
                      </ng-container>
                    </nz-form-explain>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div class="col-md-3">
                <nz-form-item>
                  <nz-form-label nzFor="complement">Complemento</nz-form-label>
                  <nz-form-control nzHasFeedback>
                    <input
                      formControlName="complement"
                      id="complement"
                      nz-input
                      placeholder="Digite o complemento"
                      maxlength="128"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div class="col-md-3">
                <nz-form-item>
                  <nz-form-label nzFor="neighborhood"
                    >Bairro<span class="mandatory"> *</span></nz-form-label
                  >
                  <nz-form-control nzHasFeedback>
                    <input
                      formControlName="neighborhood"
                      id="neighborhood"
                      nz-input
                      placeholder="Digite o bairro"
                      maxlength="128"
                      autocomplete="disabled"
                    />
                    <nz-form-explain
                      *ngIf="
                        addressGroup.get('neighborhood').dirty && addressGroup.get('neighborhood').errors
                      "
                    >
                      <ng-container *ngIf="addressGroup.get('neighborhood').hasError('required')">
                        Campo obrigatório, por favor digite o bairro.
                      </ng-container>
                    </nz-form-explain>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div class="col-md-4">
                <nz-form-item>
                  <nz-form-label nzFor="city">Cidade <span class="mandatory">*</span></nz-form-label>
                  <nz-form-control nzHasFeedback>
                    <input
                      formControlName="city"
                      id="city"
                      nz-input
                      placeholder="Digite a cidade"
                      maxlength="64"
                      autocomplete="disabled"
                    />
                    <nz-form-explain
                      *ngIf="addressGroup.get('city').dirty && addressGroup.get('city').errors"
                    >
                      <ng-container *ngIf="addressGroup.get('city').hasError('required')">
                        Campo obrigatório, por favor digite a cidade.
                      </ng-container>
                    </nz-form-explain>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div class="col-md-2">
                <nz-form-item>
                  <nz-form-label nzFor="uf">UF <span class="mandatory">*</span></nz-form-label>
                  <nz-form-control nzHasFeedback>
                    <nz-select
                      formControlName="uf"
                      id="uf"
                      nzAllowClear
                      nzShowSearch
                      nzDropdownClassName="account-select"
                      nzPlaceHolder="Selecione"
                    >
                      <nz-option
                        *ngFor="let state of states"
                        [nzLabel]="state?.sigla"
                        [nzValue]="state?.sigla"
                      ></nz-option>
                    </nz-select>
                    <nz-form-explain
                      *ngIf="addressGroup.get('uf').dirty && addressGroup.get('uf').errors"
                    >
                      <ng-container *ngIf="addressGroup.get('uf').hasError('required')">
                        Campo obrigatório, por favor selecione o UF.
                      </ng-container>
                    </nz-form-explain>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="row mb-3">
      <div class="col-10">
        <button
          (click)="addComercial()"
          class="btn btn-icon btn-success btn-add text-capitalize"
          type="button"
        >
          <span class="btn-inner--text">Adicionar Dados Comerciais</span>
          <span class="btn-inner--icon"><i class="fa fa-plus"></i></span>
        </button>
      </div>
    </div>
  </ng-container>
</form>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
