<section class="section-hero header" theme>
  <div class="container d-flex align-items-center justify-content-center flex-column">
    <a routerLink="/" class="brand-bg">
      <img
        [ngStyle]="
          isThemeActiveReceitaDigital()
            ? {
                position: 'relative',
                top: '-12px'
              }
            : { position: 'relative', top: '-48px' }
        "
        [src]="
          isThemeActiveReceitaDigital() ? 'assets/img/logo-01.jpg' : 'assets/img/mrd/logo-com-texto.png'
        "
        class="img-fluid"
        alt="Logo Receita Digital"
      />
    </a>
    <h1 class="mb-5 mt-5">
      Login
    </h1>
  </div>
</section>
<section class="section container d-flex justify-content-center align-items-center" theme>
  <div class="w-46 ml-5 d-flex flex-column">
    <form nz-form [formGroup]="form" (ngSubmit)="login()">
      <div class="row">
        <div class="col-10">
          <nz-form-item class="mb-4" *ngIf="form.get('email') as email">
            <nz-form-label nzFor="email" class="label-control">E-mail</nz-form-label>
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                id="email"
                type="email"
                placeholder="usuário@email.com"
                formControlName="email"
                class="label-control"
                style="text-transform: lowercase;"
                (keydown.space)="$event.preventDefault()"
              />
              <nz-form-explain *ngIf="email.dirty && email.errors">
                <ng-container *ngIf="email.hasError('required')">
                  Campo obrigatório, por favor digite seu e-mail.
                </ng-container>
                <ng-container *ngIf="!email.hasError('required') && email.hasError('email')">
                  E-mail inválido, por favor digite um e-mail válido.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-10">
          <nz-form-item class="mb-4">
            <nz-form-label nzFor="senha">Senha</nz-form-label>
            <nz-form-control nzHasFeedback>
              <app-input-password
                placeholder="Digite a senha"
                formControlName="password"
              ></app-input-password>
              <nz-form-explain *ngIf="form.get('password').dirty && form.get('password').errors">
                <ng-container *ngIf="form.get('password').hasError('required')">
                  Campo obrigatório, por favor digite uma senha.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-2">
          <nz-form-item class="mb-4">
            <nz-form-label>&nbsp;</nz-form-label>
            <nz-form-control>
              <button class="btn btn-success"><i class="fa fa-arrow-right"></i></button>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
    <a routerLink="/entry/reset-password" class="mb-4">Esqueceu a senha?</a>
    <a *ngIf="isThemeActiveReceitaDigital()" routerLink="/entry/account-type"
      >Ainda não possui uma conta?</a
    >
  </div>
</section>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
