<div class="modal-header" theme>
  <h4 class="modal-title pull-left text-white">Editar paciente</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" theme>
  <form nz-form [formGroup]="form">
    <div class="row justify-content-between">
      <div [ngClass]="isDependent ? 'col-md-12' : 'col-md-6'">
        <div class="row align-items-center">
          <div class="col-md">
            <nz-form-item>
              <nz-form-label nzFor="cpf">CPF <span class="mandatory">*</span></nz-form-label>
              <nz-form-control nzHasFeedback>
                <input
                  nz-input
                  id="cpf"
                  formControlName="cpf"
                  placeholder="000.000.000-00"
                  mask="000.000.000-00"
                  autocomplete="disabled"
                />
                <nz-form-explain *ngIf="cpfCtrl.dirty && cpfCtrl.errors">
                  <ng-container *ngIf="cpfCtrl.hasError('required')">
                    Campo obrigatório, por favor digite seu CPF.
                  </ng-container>
                  <ng-container *ngIf="!cpfCtrl.hasError('required') && cpfCtrl.hasError('cpf')">
                    CPF inválido, por favor digite um CPF válido.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md">
            <nz-form-item>
              <nz-form-label nzFor="dateOfBirth"
                >Data de nascimento<span class="mandatory"> *</span></nz-form-label
              >
              <nz-form-control nzHasFeedback>
                <input
                  nz-input
                  id="dateOfBirth"
                  type="date"
                  formControlName="dateOfBirth"
                  placeholder="DD/MM/AAAA"
                  [max]="currentDate"
                  autocomplete="disabled"
                />

                <nz-form-explain *ngIf="dateCtrl.dirty && dateCtrl.errors">
                  <ng-container *ngIf="dateCtrl.hasError('required')">
                    Campo obrigatório, por favor digite sua data de nascimento.
                  </ng-container>
                  <ng-container
                    *ngIf="!dateCtrl.hasError('required') && dateCtrl.hasError('dateOfBirth')"
                  >
                    Nome inválido.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md">
            <nz-form-item>
              <nz-form-label nzFor="sex">Sexo <span class="mandatory">*</span></nz-form-label>
              <nz-form-control nzHasFeedback>
                <select formControlName="sex" id="sex" nz-input nzPlaceHolder="Selecione">
                  <option value="M">Masculino</option>
                  <option value="F">Feminino</option>
                </select>
                <nz-form-explain *ngIf="form.get('sex').dirty && form.get('sex').errors">
                  <ng-container *ngIf="form.get('sex').hasError('required')">
                    Campo obrigatório, por favor selecione o sexo.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-12">
            <nz-form-item *ngIf="form.get('fullname') as fullname">
              <nz-form-label nzFor="fullname"
                >Nome completo <span class="mandatory">*</span></nz-form-label
              >
              <nz-form-control nzHasFeedback>
                <input
                  formControlName="fullname"
                  id="fullname"
                  nz-input
                  placeholder="ex: João Gilberto"
                  [disabled]="!!fullname.value"
                  autocomplete="disabled"
                />
                <nz-form-explain *ngIf="fullname.dirty && fullname.errors">
                  <ng-container *ngIf="fullname.hasError('required')">
                    Campo obrigatório, por favor digite o seu nome e sobrenome.
                  </ng-container>
                  <ng-container *ngIf="!fullname.hasError('required') && fullname.hasError('fullname')">
                    Por favor digite o seu nome e sobrenome.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-12">
            <nz-form-item>
              <div class="row">
                <div class="col-md-6">
                  <nz-form-label nzFor="name"
                    >Nome de exibição/social <span class="mandatory">*</span></nz-form-label
                  >
                </div>
                <div class="col">
                  <label
                    #repeatName
                    nz-checkbox
                    (nzCheckedChange)="checkRepeatName($event)"
                    [nzDisabled]="!form.get('fullname').value"
                    style="font-size: 13px;margin-right: -11px;"
                    >Repetir nome completo</label
                  >
                </div>
              </div>
              <nz-form-control nzHasFeedback>
                <input
                  formControlName="name"
                  id="name"
                  nz-input
                  placeholder="ex: João Gilberto"
                  maxlength="128"
                  autocomplete="disabled"
                />
                <nz-form-explain *ngIf="form.get('name').dirty && form.get('name').errors">
                  <ng-container *ngIf="form.get('name').hasError('required')">
                    Campo obrigatório, por favor digite um nome de exibição.
                  </ng-container>
                  <ng-container
                    *ngIf="!form.get('name').hasError('required') && form.get('name').hasError('name')"
                  >
                    Por favor digite um nome de exibição.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-12" [formGroup]="form.get('membership')">
            <nz-form-item>
              <nz-form-label nzFor="mothersName"
                >Nome da mãe<span class="mandatory"> *</span></nz-form-label
              >
              <nz-form-control nzHasFeedback>
                <input
                  nz-input
                  placeholder="Digite o nome da mãe"
                  formControlName="mothersName"
                  id="mothersName"
                  maxlength="128"
                  autocomplete="disabled"
                />
                <nz-form-explain
                  *ngIf="
                    form.get('membership.mothersName').dirty && form.get('membership.mothersName').errors
                  "
                >
                  <ng-container *ngIf="form.get('membership.mothersName').hasError('required')">
                    Campo obrigatório, por favor digite o nome da mãe.
                  </ng-container>
                  <ng-container
                    *ngIf="
                      !form.get('membership.mothersName').hasError('required') &&
                      form.get('membership.mothersName').hasError('membership.mothersName')
                    "
                  >
                    Nome inválido.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>

      <div class="col-md-5" *ngIf="!isDependent">
        <nz-form-item *ngIf="form.get('cellphone') as cellphone">
          <nz-form-label nzFor="cellphone">Telefone 1</nz-form-label>
          <nz-form-control nzHasFeedback>
            <input
              nz-input
              id="cellphone"
              [mask]="cellphone.value.length <= 10 ? '(00) 0000-00009' : '(00) 00000-0000'"
              placeholder="(21) 92222-2222"
              formControlName="cellphone"
              autocomplete="disabled"
            />
            <nz-form-explain *ngIf="cellphone.dirty && cellphone.errors">
              <ng-container *ngIf="cellphone.hasError('required')">
                Campo obrigatório, por favor digite seu telefone para contato.
              </ng-container>
            </nz-form-explain>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="form.get('telephone') as telephone">
          <nz-form-label nzFor="telephone">Telefone 2</nz-form-label>
          <nz-form-control>
            <input
              nz-input
              id="telephone"
              [mask]="telephone.value.length <= 10 ? '(00) 0000-00009' : '(00) 00000-0000'"
              formControlName="telephone"
              placeholder="(21) 2222-2222"
              autocomplete="disabled"
            />
            <nz-form-explain *ngIf="telephone.dirty && telephone.errors">
              <ng-container *ngIf="telephone.hasError('telephone')">
                Telefone inválido, por favor digite um número válido.
              </ng-container>
            </nz-form-explain>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <div class="row">
            <div [ngClass]="hasAccount ? 'col-4' : 'col-5'">
              <nz-form-label nzFor="email"
                >E-mail de login <span class="mandatory" *ngIf="hasAccount">*</span></nz-form-label
              >
            </div>
            <div class="col">
              <label *ngIf="hasAccount" class="has-account"> Paciente com conta ativada</label>
            </div>
          </div>
          <nz-form-control nzHasFeedback *ngIf="form.get('email') as email">
            <input
              nz-input
              id="email"
              type="email"
              formControlName="email"
              style="text-transform: lowercase;"
              (keydown.space)="$event.preventDefault()"
              autocomplete="disabled"
            />
            <nz-form-explain *ngIf="email.touched && email.errors">
              <ng-container *ngIf="email.hasError('exists'); else required">
                E-mail já existe, por favor escolha outro e-mail.
              </ng-container>
              <ng-template #required>
                <ng-container *ngIf="email.hasError('required') && email.dirty; else invalid">
                  <span class="error">Campo obrigatório, por favor digite seu e-mail.</span>
                </ng-container>
              </ng-template>
              <ng-template #invalid>
                <ng-container *ngIf="email.invalid && email.value.length > 0">
                  <span class="error">E-mail inválido, por favor digite um e-mail válido.</span>
                </ng-container>
              </ng-template>
            </nz-form-explain>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="emailContact">E-mail de contato</nz-form-label>
          <nz-form-control nzHasFeedback *ngIf="form.get('emailContact') as email">
            <input
              nz-input
              id="emailContact"
              type="email"
              formControlName="emailContact"
              style="text-transform: lowercase;"
              (keydown.space)="$event.preventDefault()"
              autocomplete="disabled"
            />
            <nz-form-explain *ngIf="email.touched && email.errors">
              <ng-container *ngIf="email.hasError('exists'); else required">
                E-mail já existe, por favor escolha outro e-mail.
              </ng-container>
              <ng-template #required>
                <ng-container *ngIf="email.hasError('required') && email.dirty; else invalid">
                  <span class="error">Campo obrigatório, por favor digite seu e-mail.</span>
                </ng-container>
              </ng-template>
              <ng-template #invalid>
                <ng-container *ngIf="email.invalid && email.value.length > 0">
                  <span class="error">E-mail inválido, por favor digite um e-mail válido.</span>
                </ng-container>
              </ng-template>
            </nz-form-explain>
          </nz-form-control>
        </nz-form-item>
      </div>

      <nz-divider nzOrientation="left" nzText="Endereço" *ngIf="!isDependent"></nz-divider>

      <div class="col-md-12" *ngIf="form.get('address') as address">
        <div class="row" [formGroup]="address">
          <div class="col-md-3">
            <nz-form-item>
              <nz-form-label nzFor="cep">CEP</nz-form-label>
              <nz-form-control nzHasFeedback>
                <input
                  (keyup)="onKey($event)"
                  mask="00.000-000"
                  formControlName="cep"
                  id="cep"
                  nz-input
                  placeholder="00.000-000"
                  autocomplete="disabled"
                />
                <nz-form-explain *ngIf="form.get('address.cep').dirty && form.get('address.cep').errors">
                  <ng-container *ngIf="form.get('address.cep').hasError('required')">
                    Campo obrigatório, por favor digite seu CEP.
                  </ng-container>
                  <ng-container
                    *ngIf="
                      !form.get('address.cep').hasError('required') &&
                      form.get('address.cep').hasError('address.cep')
                    "
                  >
                    CEP inválido, por favor digite um CEP válido.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md-6">
            <nz-form-item>
              <nz-form-label nzFor="logradouro">Logradouro</nz-form-label>
              <nz-form-control nzHasFeedback>
                <input
                  formControlName="street"
                  id="street"
                  nz-input
                  placeholder="Digite o logradouro"
                  maxlength="128"
                  autocomplete="disabled"
                />
                <nz-form-explain
                  *ngIf="form.get('address.street').dirty && form.get('address.street').errors"
                >
                  <ng-container *ngIf="form.get('address.street').hasError('required')">
                    Campo obrigatório, por favor digite o logradouro.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-12 col-md-3">
            <nz-form-item>
              <nz-form-label nzFor="number">Número</nz-form-label>
              <nz-form-control nzHasFeedback>
                <input
                  formControlName="number"
                  id="number"
                  nz-input
                  placeholder="Digite o número"
                  maxlength="10"
                  autocomplete="disabled"
                />
                <nz-form-explain
                  *ngIf="form.get('address.number').dirty && form.get('address.number').errors"
                >
                  <ng-container *ngIf="form.get('address.number').hasError('required')">
                    Campo obrigatório, por favor digite o número.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-12 col-md-3">
            <nz-form-item>
              <nz-form-label nzFor="complement">Complemento</nz-form-label>
              <nz-form-control nzHasFeedback>
                <input
                  formControlName="complement"
                  id="complement"
                  nz-input
                  placeholder="Digite o complemento"
                  maxlength="128"
                  autocomplete="disabled"
                />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-12 col-md-3">
            <nz-form-item>
              <nz-form-label nzFor="neighborhood">Bairro</nz-form-label>
              <nz-form-control nzHasFeedback>
                <input
                  formControlName="neighborhood"
                  id="neighborhood"
                  nz-input
                  placeholder="Digite o bairro"
                  maxlength="128"
                  autocomplete="disabled"
                />
                <nz-form-explain
                  *ngIf="
                    form.get('address.neighborhood').dirty && form.get('address.neighborhood').errors
                  "
                >
                  <ng-container *ngIf="form.get('address.neighborhood').hasError('required')">
                    Campo obrigatório, por favor digite o bairro.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-12 col-md-4">
            <nz-form-item>
              <nz-form-label nzFor="city">Cidade</nz-form-label>
              <nz-form-control nzHasFeedback>
                <input
                  formControlName="city"
                  id="city"
                  nz-input
                  placeholder="Digite a cidade"
                  maxlength="64"
                  autocomplete="disabled"
                />
                <nz-form-explain
                  *ngIf="form.get('address.city').dirty && form.get('address.city').errors"
                >
                  <ng-container *ngIf="form.get('address.city').hasError('required')">
                    Campo obrigatório, por favor digite a cidade.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-12 col-md-2">
            <nz-form-item>
              <nz-form-label nzFor="uf">UF</nz-form-label>
              <nz-form-control nzHasFeedback>
                <nz-select
                  formControlName="uf"
                  id="uf"
                  nzAllowClear
                  nzShowSearch
                  nzPlaceHolder="Selecione"
                >
                  <nz-option
                    *ngFor="let state of states"
                    [nzLabel]="state?.sigla"
                    [nzValue]="state?.sigla"
                  ></nz-option>
                </nz-select>
                <nz-form-explain *ngIf="form.get('address.uf').dirty && form.get('address.uf').errors">
                  <ng-container *ngIf="form.get('address.uf').hasError('required')">
                    Campo obrigatório, por favor selecione o UF.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer" theme>
  <button class="ok-btn" nz-button class="btn-primary" (click)="close()">
    Cancelar
  </button>
  <button class="ok-btn" nz-button class="btn-primary" (click)="submitForm()">
    Salvar <i class="fa"></i>
  </button>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
