<app-alternative-navbar titleIcon="fas fa-history"></app-alternative-navbar>
<app-sidebar-menu></app-sidebar-menu>

<div class="container my-4" theme>
  <div class="container-fluid patient p-3" *ngIf="patient?.name" >
    <label>PACIENTE: {{ patient.name }}</label>
  </div>
  <div class="container-fluid professional p-3" *ngIf="healthProfessional?.name" >
    <label>MÉDICO: {{ healthProfessional.name }}</label>
  </div>

  <nz-collapse [nzBordered]="false" *ngIf="notSelectedMedicaments.length" >
    <nz-collapse-panel
      nzHeader="Medicamentos não selecionados"
      class="not-selected-medicaments"
      [ngClass]="{ 'pb-3': selectedCount(notSelectedMedicaments) }"
    >
      <div class="checkbox-item" *ngFor="let medicament of notSelectedMedicaments">
        <label nz-checkbox class="medicament-name" [(ngModel)]="medicament.include">{{
          medicament.name
        }}</label>
        <p class="pl-4">{{ medicament.dosage }}</p>
      </div>

      <button
        *ngIf="selectedCount(notSelectedMedicaments)"
        class="btn btn-icon btn-add-outiline text-capitalize trash-solid-position"
        type="button"
        (click)="includeMedicaments()"
      >
        <span class="btn-inner--text"
          >Incluir ({{ selectedCount(notSelectedMedicaments) }}) medicamento<span
            *ngIf="selectedCount(notSelectedMedicaments) > 1"
            >s</span
          ></span
        >
        <span class="btn-inner--icon" theme><i class="fa fa-plus"></i></span>
      </button>
    </nz-collapse-panel>
  </nz-collapse>

  <div class="container-fluid medico-selecionado p-3">
    <span>Medicamentos selecionados</span>
  </div>

  <div
    class="container-fluid medico-selecionado-conteudo selected-medicaments pt-3 mb-2"
    *ngFor="let medicament of selectedMedicaments"
  >
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="showModalAlert(medicament)"
    >
      <span aria-hidden="true" style="font-size: 2rem;">&times;</span>
    </button>

    <button
      *ngIf="medicament.items?.length"
      type="button"
      class="close pull-right mr-3"
      aria-label="Close"
      (click)="showModal(medicament)"
    >
      <span class="btn-inner--icon"><i class="fa fa-pencil-solid"></i></span>
    </button>

    <label class="pl-4 m-0">{{ medicament.name }}</label>
    <p class="pl-4">{{ medicament.dosage }}</p>

    <div
      class="container-fluid medico-selecionado-conteudo pt-3 pb-3 d-flex justify-content-center"
      *ngIf="!medicament.items || !medicament.items.length"
    >
      <button
        class="btn btn-icon btn-add-outiline text-capitalize"
        type="button"
        (click)="showModal(medicament)"
      >
        <span class="btn-inner--text">Adicionar Dispensação</span>
        <span class="btn-inner--icon" theme><i class="fa fa-plus"></i></span>
      </button>
    </div>

    <nz-collapse class="caixas pb-3" *ngIf="medicament.items?.length">
      <nz-collapse-panel
        *ngFor="let item of medicament.items"
        nzHeader="{{ item.nome }} - {{ item.apresentacaoCustomizada }}"
      >
        <p class="title4 mb-1">{{ item.principio }}</p>
        <p class="title3 mb-1">{{ item.laboratorio }}</p>
        <p class="title3 mt-2">
          <span
            class="exchange-icon"
            [ngStyle]="{ 'background-color': item.intercambialidade ? '#5ce1c2' : '#ebebeb' }"
            ><i class="fas fa-exchange"></i
          ></span>
          Intercambialidade?
          {{ item.intercambialidade ? 'Sim' : 'Não' }}
        </p>
        <p class="title5 m-0" *ngIf="item.caixas?.length">
          <span class="badge-caixa">{{ item.caixas.length }}</span> Quantas unidades desse produto:
          {{ item.caixas.length }}
        </p>
        <p class="title6 pl-4 m-0 ml-1" *ngFor="let caixa of item.caixas">
          Número de lote caixa: {{ caixa.nome }}
        </p>
      </nz-collapse-panel>
    </nz-collapse>
  </div>
</div>

<div class="footer bg-dark d-flex align-items-center fixed-bottom justify-content-end">
  <button
    type="button"
    class="btn btn-success"
    (click)="finish()"
    theme
    [disabled]="!selectedMedicaments.length || dispensedMedicaments < selectedMedicaments.length"
  >
    CONFIRMAR VENDA ({{ dispensedMedicaments }}/{{ selectedMedicaments.length }})
    <i class="fa fa-arrow-right"></i>
  </button>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
