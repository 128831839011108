<!--Dados de Contato-->
<fieldset class="w-100 mb-2" [formGroup]="form">
  <legend theme>Dados de Contato</legend>
  <nz-alert
    class="mb-4"
    nzType="warning"
    *ngIf="!isPet"
    nzMessage="Ao preencher os dados de contato você poderá enviar o link de acesso aos documentos digitais de forma automática por SMS, WhatsApp e e-mail. No caso do e-mail você também facilitará o acesso do paciente ao Portal do Paciente."
  ></nz-alert>
  <div class="row">
    <div class="col-lg-3 col-md-3 col-sm-12">
      <nz-form-item *ngIf="form.get('cellphone') as cellphone">
        <nz-form-label nzFor="cellphone">Telefone 1 </nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            nz-input
            id="cellphone"
            [mask]="cellphone.value.length <= 10 ? '(00) 0000-00009' : '(00) 00000-0000'"
            formControlName="cellphone"
            autocomplete="disabled"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-12">
      <nz-form-item *ngIf="form.get('telephone') as telephone">
        <nz-form-label nzFor="telephone" class="cellphone">Telefone 2</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            id="telephone"
            [mask]="telephone.value.length <= 10 ? '(00) 0000-00009' : '(00) 00000-0000'"
            formControlName="telephone"
            autocomplete="disabled"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="emailContact">E-mail de contato</nz-form-label>
        <nz-form-control nzHasFeedback *ngIf="form.get('emailContact') as email">
          <input
            nz-input
            id="emailContact"
            type="email"
            formControlName="emailContact"
            style="text-transform: lowercase;"
            (keydown.space)="$event.preventDefault()"
            autocomplete="disabled"
          />
          <nz-form-explain *ngIf="email.touched && email.errors">
            <ng-container *ngIf="email.hasError('exists'); else required">
              E-mail já existe, por favor escolha outro e-mail.
            </ng-container>
            <ng-template #required>
              <ng-container *ngIf="email.hasError('required') && email.dirty; else invalid">
                <span class="error">Campo obrigatório, por favor digite seu e-mail.</span>
              </ng-container>
            </ng-template>
            <ng-template #invalid>
              <ng-container *ngIf="email.invalid && email.value.length > 0">
                <span class="error">E-mail inválido, por favor digite um e-mail válido.</span>
              </ng-container>
            </ng-template>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="email">E-mail de login</nz-form-label>
        <nz-form-control nzHasFeedback *ngIf="form.get('email') as email">
          <input
            nz-input
            id="email"
            type="email"
            formControlName="email"
            style="text-transform: lowercase;"
            (keydown.space)="$event.preventDefault()"
            autocomplete="disabled"
          />
          <nz-form-explain *ngIf="email.touched && email.errors">
            <ng-container *ngIf="email.hasError('exists'); else required">
              E-mail já existe, por favor escolha outro e-mail.
            </ng-container>
            <ng-template #required>
              <ng-container *ngIf="email.hasError('required') && email.dirty; else invalid">
                <span class="error">Campo obrigatório, por favor digite seu e-mail.</span>
              </ng-container>
            </ng-template>
            <ng-template #invalid>
              <ng-container *ngIf="email.invalid && email.value.length > 0">
                <span class="error">E-mail inválido, por favor digite um e-mail válido.</span>
              </ng-container>
            </ng-template>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</fieldset>
