<form [formGroup]="form">
  <ng-container formArrayName="protocols">
    <div
      *ngFor="let protocolFormGroup of protocols.controls; let i = index"
      [formGroup]="protocolFormGroup"
      class="container-protocol"
    >
      <div class="protocol-inputs">
        <div *ngIf="protocolFormGroup.value as protocol">
          <h4 class="title">{{ i + 1 }}. {{ protocol.name }}</h4>
          <div
            *ngFor="let phaseFormGroup of protocolFormGroup.get('phases')['controls']; let j = index"
            [formGroup]="phaseFormGroup"
          >
            <div *ngIf="phaseFormGroup.value as phase">
              <div *ngIf="j" class="mt-4"></div>
              <h6 *ngIf="phase.name" class="title">{{ phase.name }}</h6>
              <nz-form-item>
                <nz-form-control>
                  <textarea
                    [ngStyle]="{ color: colorSetting?.primary }"
                    [id]="'dosage_' + j"
                    appAutoSize
                    appFocus
                    nz-input
                    [value]="phase.dosage"
                    disabled
                    class="dosage"
                  ></textarea>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <label [ngStyle]="{ color: colorSetting?.primary, 'font-weight': 'bold' }"
                  >Observação</label
                >
                <nz-form-control>
                  <textarea
                    [ngStyle]="{ color: colorSetting?.primary, 'border-color': colorSetting?.primary }"
                    appFocus
                    appAutoSize
                    focus="true"
                    placeholder="Digite uma observação"
                    formControlName="observation"
                    nz-input
                    class="medicationNameInput"
                  ></textarea>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>
      </div>
      <div class="protocol-btn-remove">
        <span class="btn-inner--icon p-5" style="cursor: pointer;" (click)="deleteProtocol(i)"
          ><i class="fa fa-trash-solid"></i
        ></span>
      </div>
    </div>
  </ng-container>
</form>
