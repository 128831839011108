import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Paciente } from '@app/shared/models/paciente';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

export const FEDERAL_REVENUE = 'federal-revenue';

@Injectable({
  providedIn: 'root'
})
export class FederalRevenueService {
  constructor(private http: HttpClient) {}

  consultCpf(cpf: string, birthdate?: string): Observable<Paciente> {
    const params = birthdate ? { birthdate } : {};
    return this.http.get<Paciente>(`${environment.apiRoot}${FEDERAL_REVENUE}/cpf/${cpf}`, { params });
  }

  consultCnpj(cnpj: string): Observable<any> {
    return this.http.get<any>(`${environment.apiRoot}${FEDERAL_REVENUE}/cnpj/${cnpj}`);
  }
}
