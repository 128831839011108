export const environment = {
  production: true,
  environment: 'test',
  apiRoot: 'https://api.testing.receitadigital.com/api/',
  apiV1: 'https://api.testing.receitadigital.com/v1',
  plusGApp: 'https://assinatura.gestao.plus',
  envPlusSecretKey: '637e2ffee279a02ff6d1ccec224d01db',
  envPlusSecretKeyIS: 'a5e82ce673fe7007f96fb2c2b7fce055',
  envKeySpecialty: 'fr1MzCU8RC3YPIX8prW9EalrEfoYiWqi8afdVbDk',
  envKeyMedicament: 'wBwNQLTg2t3bTFWjNhgZ01iFEnJC3Mu05D4G23IE',
  envAdminApiKey: 'RfMlZ8UB8GiRkdfeN4c5COCL58ldtauB',
  debounceTime: 300,
  maintenance: false,
  template: '',
  tenantId: ''
};
