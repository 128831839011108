<ng-template #tplContent let-params let-ref="tplModal">
  <div class="container row">
    <div class="col-8 description-area">
      <h4 class="title text-primary">
        {{ title }}
      </h4>
      <h4 class="subtitle text-primary">{{ subtitle }}</h4>
      <br />
      <h4 class="certificate-info text-primary">{{ content }}</h4>
    </div>
    <div class="col-4 logos-area">
      <div>
        <img class="logo" src="assets/img/logo-01.png" alt="" />
        <img class="plus" src="assets/img/mais.png" alt="" />
        <img class="logo" src="assets/img/safe-web.png" alt="" />
      </div>
      <a (click)="redirect()">Clique aqui e faça o seu</a>
    </div>
  </div>
</ng-template>
<ng-template #tplFooter>
  <div>
    <span
      >*O desconto apresentado é exclusivo para parceiros Receita Digital no primeiro ano de utilização.
      Este certificado não possui limitação no número de assinaturas.</span
    >
  </div>
</ng-template>
