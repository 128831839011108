<div class="modal-body">
  <form nz-form [formGroup]="form">
    <div class="row">
      <nz-form-item class="col-md-12">
        <nz-form-label nzFor="name" theme>Nome <span class="mandatory">*</span></nz-form-label>
        <nz-form-control>
          <nz-input-group>
            <input
              theme
              type="text"
              nz-input
              placeholder="Digite o nome do paciente"
              formControlName="name"
              maxlength="128"
            />
          </nz-input-group>
          <nz-form-explain *ngIf="form.get('name').dirty && form.get('name').errors">
            <ng-container *ngIf="form.get('name').hasError('required')">
              Campo obrigatório, por favor digite o seu nome e sobrenome.
            </ng-container>
            <ng-container
              *ngIf="!form.get('name').hasError('required') && form.get('name').hasError('nome')"
            >
              Nome inválido.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="col-6">
        <nz-form-label nzFor="sex" theme>Sexo<span class="mandatory"> *</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <select formControlName="sex" id="sex" nz-input nzPlaceHolder="Selecione" theme>
            <option value="M">Masculino</option>
            <option value="F">Feminino</option>
          </select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="col-md-7">
        <div class="row">
          <div class="col-6">
            <nz-form-label nzFor="cpf" theme
              >CPF <span class="mandatory" *ngIf="!noCpf.nzChecked">*</span></nz-form-label
            >
          </div>
          <div class="col">
            <label #noCpf nz-checkbox (nzCheckedChange)="checkCpf($event)" style="font-size: 14px;"
              >Sem CPF</label
            >
          </div>
        </div>
        <nz-form-control nzHasFeedback>
          <nz-input-group>
            <input
              type="text"
              nz-input
              formControlName="cpf"
              placeholder="000.000.000-00"
              mask="000.000.000-00"
              theme
            />
          </nz-input-group>
          <nz-form-explain *ngIf="form.get('cpf').dirty && form.get('cpf').errors">
            <ng-container *ngIf="form.get('cpf').hasError('required')">
              O CPF é obrigatório
            </ng-container>
            <ng-container
              *ngIf="!form.get('cpf').hasError('required') && form.get('cpf').hasError('cpf')"
            >
              CPF inválido, por favor digite um CPF válido.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="col-md-5">
        <nz-form-label nzFor="dateOfBirth" theme
          >Data de nascimento <span class="mandatory">*</span>
        </nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            nz-input
            id="dateOfBirth"
            type="date"
            formControlName="dateOfBirth"
            [max]="currentDate"
            [min]="minDate"
            theme
          />
          <nz-form-explain *ngIf="form.get('dateOfBirth').dirty && form.get('dateOfBirth').errors">
            <ng-container *ngIf="form.get('dateOfBirth').hasError('required')">
              Campo obrigatório, por favor digite sua data de nascimento.
            </ng-container>
            <ng-container
              *ngIf="
                !form.get('dateOfBirth').hasError('required') &&
                form.get('dateOfBirth').hasError('isNotMinor')
              "
            >
              Dependente deve ser menor de idade.
            </ng-container>
            <ng-container
              *ngIf="
                !form.get('dateOfBirth').hasError('required') &&
                !form.get('dateOfBirth').hasError('isNotMinor') &&
                form.get('dateOfBirth').errors
              "
            >
              Data de nascimento inválida.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>

      <div
        class="col-12"
        [formGroup]="form.get('membership')"
        *ngIf="form.get('membership.mothersName') as mothersName"
      >
        <nz-form-item>
          <div class="row">
            <div class="col-6">
              <nz-form-label nzFor="mothersName" theme
                >Nome da mãe<span class="mandatory"> *</span></nz-form-label
              >
            </div>
            <div class="col">
              <label
                #responsibleName
                nz-checkbox
                (nzCheckedChange)="checkResponsibleName($event)"
                style="font-size: 14px;"
                >Repetir seu nome</label
              >
            </div>
          </div>
          <nz-form-control nzHasFeedback>
            <input
              nz-input
              placeholder="Digite o nome da mãe"
              formControlName="mothersName"
              id="mothersName"
              maxlength="128"
              theme
            />
            <nz-form-explain *ngIf="mothersName.dirty && mothersName.errors">
              <ng-container *ngIf="mothersName.hasError('required')">
                Campo obrigatório, por favor digite o nome da mãe.
              </ng-container>
              <ng-container
                *ngIf="
                  !mothersName.hasError('required') && mothersName.hasError('membership.mothersName')
                "
              >
                Nome inválido.
              </ng-container>
            </nz-form-explain>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>

  <div class="row">
    <div class="col-8">
      <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
      <nz-spin *ngIf="loading" nzSimple [nzIndicator]="indicatorTemplate"> </nz-spin>
    </div>

    <div class="col-4">
      <button
        type="button"
        class="btn btn-icon btn-success btn-add text-capitalize btn-save"
        (click)="submitForm()"
      >
        <span class="btn-inner--text">Salvar</span>
      </button>
    </div>
  </div>
</div>
