<footer theme>
  <div class="d-flex align-items-center">
    <span>© Receita Digital 2022 </span>
    <span class="m-2 ml-2">•</span>
    <span>Grupo Consulta Remédios</span>
  </div>
  <div class="box-version-and-social-medias">
    <div class="versions">
      <span class="m-2">F: {{ frontendVersion }}</span>
      <span class="m-2">B: {{ backendVersion }}</span>
    </div>
    <div class="social-medias">
      <a href="https://www.instagram.com/receitadigital" target="_blank" style="    margin-right: 10px;"
        ><i class="fa fa-instagram"></i
      ></a>
      <a href="https://www.facebook.com/ReceitaDigital/" target="_blank" style="    margin-right: 10px;"
        ><i class="fa fa-facebook"></i
      ></a>
      <a href="https://www.linkedin.com/company/receitadigital/" target="_blank"
        ><i class="fa fa-linkedin"></i
      ></a>
    </div>
  </div>
</footer>
