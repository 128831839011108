<div [formGroup]="form" theme>
  <nz-form-item *ngIf="mobile">
    <label [for]="id" [ngStyle]="{ color: colorSetting?.primary }">{{ label }}</label>
    <div class="input-group">
      <span class="input-group-btn">
        <button class="btn btn-minus" type="button" (click)="minus()">
          <span
            class="btn-inner--icon"
            [ngStyle]="{
              'background-color': colorSetting?.primary,
              'border-color': colorSetting?.primary
            }"
            ><i class="fa fa-minus"></i
          ></span>
        </button>
      </span>
      <input
        [id]="id"
        type="number"
        class="form-control text-center"
        maxlength="3"
        formControlName="quantity"
        [ngStyle]="{ 'background-color': colorSetting?.secondary }"
        readonly
      />
      <span class="input-group-btn">
        <button class="btn btn-plus" type="button" (click)="plus()">
          <span
            class="btn-inner--icon"
            [ngStyle]="{
              'background-color': colorSetting?.primary,
              'border-color': colorSetting?.primary
            }"
            ><i class="fa fa-plus"></i
          ></span>
        </button>
      </span>
    </div>
  </nz-form-item>

  <nz-form-item class="input-number-item" *ngIf="!mobile">
    <nz-form-label
      class="label-input"
      [nzFor]="id"
      [ngStyle]="{
        'background-color': colorSetting?.primary,
        'border-color': colorSetting?.primary
      }"
      >{{ label }}</nz-form-label
    >
    <nz-form-control>
      <nz-input-number
        [id]="id"
        [nzMin]="min"
        [nzMax]="max"
        [nzStep]="step"
        [style.width]="inputSize + 'px'"
        (ngModelChange)="inputSize = $event"
        formControlName="quantity"
        [nzDisabled]="disabled"
        [ngStyle]="{
          'background-color': colorSetting?.secondary,
          'border-color': colorSetting?.secondary
        }"
      ></nz-input-number>
    </nz-form-control>
  </nz-form-item>
</div>
