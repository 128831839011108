<div class="form-filter">
  <h2 class="title">
    {{ titles[userType] }}
  </h2>
  <br />
  <form nz-form>
    <div class="row" *ngIf="dependents?.length">
      <div class="col-md-3">
        <nz-form-item>
          <nz-form-label nzFor="dependent">Dependente</nz-form-label>
          <nz-form-control>
            <nz-select
              id="dependent"
              nzPlaceHolder="Selecione"
              [(ngModel)]="filter.dependent"
              [ngModelOptions]="{ standalone: true }"
              (ngModelChange)="submit()"
              nzAllowClear
              theme
            >
              <nz-option nzLabel="Selecione" [nzValue]="null"></nz-option>
              <nz-option
                *ngFor="let dependent of dependents"
                [nzLabel]="dependent.name"
                [nzValue]="dependent.id"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div class="row align-items-center">
      <ng-container *ngIf="userType === 'pharmacy'">
        <div class="col-md-3">
          <nz-form-item>
            <nz-form-label nzFor="createdAt">Data da Dispensação:</nz-form-label>
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                type="date"
                placeholder="Data da Dispensação"
                [(ngModel)]="filter.createdAt"
                name="createdAt"
                theme
              />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-md-3">
          <nz-form-item>
            <nz-form-label nzFor="nome">CPF Comprador:</nz-form-label>
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                type="text"
                placeholder="ex: 000.000.000-00"
                mask="000.000.000-00"
                [(ngModel)]="filter.cpfBuyer"
                name="cpfBuyer"
                theme
              />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-md-3">
          <nz-form-label nzFor="nome">Cód. Dispensação:</nz-form-label>
          <nz-form-item>
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                type="text"
                [(ngModel)]="filter.dispensationCode"
                placeholder="ex: Kk0rbLca"
                name="dispensationCode"
                theme
              />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-md-3">
          <nz-form-item>
            <nz-form-label nzFor="nome">Registro:</nz-form-label>
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                type="text"
                [(ngModel)]="filter.prescriberRegisterNumber"
                name="prescriberRegisterNumber"
                theme
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </ng-container>
      <ng-container *ngIf="userType === 'healthProfessional' || userType === 'patient'">
        <div class="col-md-3">
          <nz-form-item>
            <nz-form-label *ngIf="tab === 'prescription'" nzFor="createdAt"
              >Data da Prescrição:</nz-form-label
            >
            <nz-form-label *ngIf="tab === 'attestation'" nzFor="createdAt"
              >Data do Atestado:</nz-form-label
            >
            <nz-form-label *ngIf="tab === 'exam'" nzFor="createdAt">Data do Exame:</nz-form-label>
            <nz-form-label *ngIf="tab === 'orientation'" nzFor="createdAt"
              >Data do Documento:</nz-form-label
            >
            <nz-form-control nzHasFeedback>
              <input nz-input type="date" [(ngModel)]="filter.createdAt" name="createdAt" theme />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-md-3">
          <nz-form-label nzFor="prescriptionCode" *ngIf="tab === 'prescription'"
            >Cód. Prescrição:</nz-form-label
          >
          <nz-form-label nzFor="prescriptionCode" *ngIf="tab === 'attestation'"
            >Cód. Atestado:</nz-form-label
          >
          <nz-form-label nzFor="prescriptionCode" *ngIf="tab === 'exam'">Cód. Exame:</nz-form-label>
          <nz-form-label nzFor="code" *ngIf="tab === 'orientation'">Cód. Documento:</nz-form-label>

          <nz-form-item>
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                type="text"
                [(ngModel)]="filter.code"
                placeholder="ex: P93.449.895.040.878"
                name="code"
                mask="S00.000.000.000.000"
                theme
              />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-md-3" *ngIf="userType === 'healthProfessional'">
          <nz-form-label nzFor="patient">Paciente:</nz-form-label>
          <nz-form-item>
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                type="text"
                [(ngModel)]="filter.patient"
                placeholder="ex: Luiz carlos"
                name="patient"
                theme
              />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-md-3" *ngIf="userType === 'patient'">
          <nz-form-label nzFor="healthProfessional">Prescritor:</nz-form-label>
          <nz-form-item>
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                type="text"
                [(ngModel)]="filter.healthProfessional"
                placeholder="ex: Luiz Carlos"
                name="healthProfessional"
                theme
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </ng-container>

      <div class="col-auto pt-4" *ngIf="userType && userType !== 'pharmacy'">
        <label nz-checkbox name="archived" [(ngModel)]="filter.archived" (ngModelChange)="submit()"
          >Arquivados</label
        >
      </div>

      <div class="col-auto pt-4">
        <nz-form-item>
          <nz-form-control>
            <button nz-button type="button" class="btn-buscar-historico" (click)="submit()" theme>
              <i nz-icon nzType="search"></i>
              Buscar
            </button>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
</div>
