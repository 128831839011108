<ng-template #tplTitle>
  <header class="modal-header" *ngIf="patient" theme>
    <img *ngIf="patient.img" class="mr-4 img" [src]="'assets/img/' + patient.img" />
    <img
      *ngIf="!patient.img && patient.responsible"
      src="assets/icons/hand-holding-medical.svg"
      class="mr-4 img"
    />
    <div class="texto-header">
      <h5 class="mt-0 title" theme>{{ patient.name }}</h5>
      <p class="document" *ngIf="patient.cpf" theme>CPF: {{ patient.cpf | formattedCpf }}</p>
      <p class="document red" *ngIf="!patient.cpf">
        <span *ngIf="patient.responsible">MENOR </span>SEM CPF
      </p>
    </div>
  </header>
</ng-template>
<ng-template #tplContent>
  <div class="container" *ngIf="patient">
    <h4 class="title">
      DADOS BÁSICOS
      <button (click)="showEditPatient(patient)" title="Editar" class="btn btn-icon">
        <span class="btn-inner--icon"><i class="fa fa-pencil-solid"></i></span>
      </button>
      <app-popover-button class="m-0" [content]="popoverContent"> </app-popover-button>
    </h4>
    <h4 *ngIf="hasAccount" class="has-account">
      Paciente com conta ativada
    </h4>
    <ul class="list-unstyled">
      <li class="list-item-icon" *ngIf="patient.cellphone">
        <i class="fa fa-phone-alt-solid"></i> {{ patient.cellphone | formattedCel }}
      </li>
      <li class="list-item-icon" title="E-mail de login" *ngIf="patient.user?.email">
        <i class="fa fa-envelop-solid"></i> {{ patient.user.email }}
      </li>
      <li
        class="list-item-icon"
        title="E-mail de contato"
        *ngIf="patient.user?.emailContact && patient.user?.emailContact !== patient.user?.email"
      >
        <i class="fa fa-envelop-solid"></i> {{ patient.user.emailContact }}
      </li>
      <li class="list-item-icon" *ngIf="patient.dateOfBirth">
        <i class="fa fa-birthday-cake-solid"></i> {{ patient.dateOfBirth | date: 'dd/MM/yyyy':'GMT' }}
      </li>
      <li class="list-item-icon" *ngIf="getAddress(patient.address)">
        <i class="fa fa-map-marker-alt-solid"></i>{{ getAddress(patient.address) }}
      </li>
      <li class="list-item-icon" *ngIf="patient.address?.cep">
        <i class="fa fa-map-marker-alt-solid"></i>CEP: {{ patient.address.cep | mask: '00.000-000' }}
      </li>
    </ul>
    <div *ngIf="patient.responsible">
      <h4 class="title">
        DADOS DO RESPONSÁVEL
        <button (click)="showEditPatient(patient.responsible, true)" title="Editar" class="btn btn-icon">
          <span class="btn-inner--icon"><i class="fa fa-pencil-solid"></i></span>
        </button>
        <app-popover-button class="m-0" [content]="popoverContent"> </app-popover-button>
      </h4>
      <h4 *ngIf="hasAccount" class="has-account">
        Paciente com conta ativada
      </h4>
      <ul class="list-unstyled">
        <li class="list-item-icon">
          <i class="fa fa-user-solid" *ngIf="patient.responsible.name"></i>
          {{ patient.responsible.name }}
        </li>
        <li class="list-item-icon">
          <i class="fa fa-address-card" *ngIf="patient.responsible.cpf"></i>
          {{ patient.responsible.cpf | formattedCpf }}
        </li>
        <li class="list-item-icon">
          <i class="fa fa-phone-alt-solid" *ngIf="patient.responsible.cellphone"></i>
          {{ patient.responsible.cellphone | formattedCel }}
        </li>
        <li class="list-item-icon">
          <i class="fa fa-envelop-solid" *ngIf="patient.responsible.user?.email"></i>
          {{ patient.responsible.user?.email }}
        </li>
        <li class="list-item-icon">
          <i class="fa fa-birthday-cake-solid" *ngIf="patient.responsible.dateOfBirth"></i>
          {{ patient.responsible.dateOfBirth | date: 'dd/MM/yyyy':'GMT' }}
        </li>
        <li class="list-item-icon" *ngIf="patient.responsible.address">
          <i class="fa fa-map-marker-alt-solid"></i> {{ patient.responsible.address.neighborhood }},
          {{ patient.responsible.address.city }}, {{ patient.responsible.address.uf }}
        </li>
      </ul>
    </div>
    <div *ngIf="patient.dependents?.length">
      <h4 class="title">DEPENDENTES</h4>
      <ul class="list-unstyled">
        <li *ngFor="let dependent of patient.dependents" class="list-item-icon">
          {{ dependent.name }}
          <button
            *ngIf="!hasDependent(dependent)"
            (click)="addPatient(dependent)"
            title="Vincular paciente"
            class="btn-icon mr-0 p-0"
          >
            <i class="fa fa-plus"></i>
          </button>
          <button
            *ngIf="hasDependent(dependent)"
            (click)="removePatient(dependent)"
            title="Desvincular paciente"
            class="btn-icon mr-0 p-0"
          >
            <i class="fa fa-trash-solid"></i>
          </button>
        </li>
      </ul>
    </div>
  </div>
  <app-patient-record
    *ngIf="hasPatientRecordFeature"
    [patient]="patient"
    [customStyle]="patientRecordStyle"
  ></app-patient-record>
</ng-template>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
