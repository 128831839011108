<div class="page-register">
  <section class="section-hero section-header" theme>
    <div class="container">
      <a routerLink="/" class="brand-bg">
        <img
          [ngStyle]="
            isThemeActiveReceitaDigital()
              ? {
                  position: 'relative',
                  top: '-12px'
                }
              : { position: 'relative', top: '-48px' }
          "
          [src]="
            isThemeActiveReceitaDigital()
              ? 'assets/img/logo-01.jpg'
              : 'assets/img/mrd/logo-com-texto.png'
          "
          class="img-fluid"
          alt="Logo Receita Digital"
        />
      </a>
      <div class="row">
        <div class="col-md-10">
          <h1>
            Cadastre-se <br />
            em nossa plataforma
          </h1>
        </div>
      </div>
    </div>
  </section>
  <section class="register-form">
    <div class="container pt-4" [ngSwitch]="routerParam">
      <app-health-professional-form
        *ngIf="routerParam === 'dentist' || routerParam === 'doctor' || routerParam === 'veterinarian'"
        [professionalType]="routerParam"
        (formChange)="form = $event"
      ></app-health-professional-form>

      <app-patient-form *ngSwitchCase="'patient'" (formChange)="form = $event"></app-patient-form>

      <app-pharmacy-form *ngSwitchCase="'pharmacy'" (formChange)="form = $event"></app-pharmacy-form>

      <div class="terms-area" *ngIf="viewBtnSaveAndTermsDisclaimer">
        <label
          nz-checkbox
          class="terms-disclaimer"
          [(ngModel)]="isCheckedTerms"
          *ngIf="isThemeActiveReceitaDigital(); else mrd"
        >
          Ao clicar em Salvar, você concorda com os<button
            class="terms-btn"
            (click)="openTermsAndConditionsModal()"
            theme
          >
            Termos de Uso e Política de Privacidade</button
          >do Receita Digital.
        </label>
        <ng-template #mrd>
          <label nz-checkbox class="terms-disclaimer" [(ngModel)]="isCheckedTerms">
            Ao clicar em Salvar, você concorda com os<button
              class="terms-btn"
              (click)="openTerms()"
              theme
            >
              Termos de Uso</button
            >e<button class="terms-btn" (click)="openConditions()" theme>Política de Privacidade</button
            >do Meu Receituário Digital/Receita Digital.
          </label>
        </ng-template>
      </div>
    </div>
  </section>
</div>
<div class="footer-save d-flex align-items-center fixed-bottom justify-content-between mt-3" theme>
  <div class="ml-2">
    <div
      class="copyright"
      [ngStyle]="{
        'font-size': isMobile && viewBtnSaveAndTermsDisclaimer ? '11px' : '14px'
      }"
    >
      <div>
        <span *ngIf="isThemeActiveReceitaDigital()"
          >© Receita Digital {{ actualDate.getFullYear() }}</span
        >
        <span *ngIf="!isThemeActiveReceitaDigital()">Powered by Receita Digital ®</span>
        <span class="m-2 ml-2">•</span>
        <span>Grupo Consulta Remédios</span>
      </div>
      <div>
        <span class="m-2">F: {{ frontendVersion }}</span>
        <span class="m-2">B: {{ backendVersion$ | async }}</span>
      </div>
    </div>
  </div>
  <div *ngIf="!isMobile"></div>
  <button
    *ngIf="viewBtnSaveAndTermsDisclaimer"
    type="button"
    class="btn footer-save-btn"
    (click)="submit()"
  >
    Salvar <i class="fa fa-arrow-right"></i>
  </button>
</div>

<app-modal-terms-and-conditions> </app-modal-terms-and-conditions>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<app-dialog-modal></app-dialog-modal>
