import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-unsigned-prescription-modal',
  templateUrl: './unsigned-prescription-modal.component.html',
  styleUrls: ['./unsigned-prescription-modal.component.scss']
})
export class UnsignedPrescriptionModalComponent implements OnInit {
  tplModal: NzModalRef;

  @ViewChild('tplTitle', { static: false })
  tplTitle: TemplateRef<{}>;

  @ViewChild('tplContent', { static: true })
  tplContent: TemplateRef<{}>;

  @ViewChild('tplFooter', { static: true })
  tplFooter: TemplateRef<{}>;

  title: string;
  code: string;

  constructor(private modalService: NzModalService) {}

  ngOnInit() {}

  createTplModal(code: string) {
    this.code = code;
    switch (code[0]) {
      case 'P':
      case 'I':
        this.title = 'Receita salva com sucesso!';
        break;
      case 'A':
        this.title = 'Atestado salvo com sucesso!';
        break;
      case 'E':
        this.title = 'Exame salvo com sucesso!';
        break;
      case 'O':
        this.title = 'Orientação salva com sucesso!';
        break;
      default:
        this.title = 'Documento salvo com sucesso!';
    }

    this.tplModal = this.modalService.create({
      nzContent: this.tplContent,
      nzFooter: this.tplFooter,
      nzClosable: false,
      nzWidth: 650,
      nzMaskClosable: false,
      nzOnCancel: () => {},
      nzWrapClassName: 'modal-unsigned-prescription',
      nzOnOk: () => console.log('Click ok')
    });
  }

  close(): void {
    this.tplModal.destroy();
  }
}
