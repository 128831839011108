import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { PharmacistService } from '@app/modules/pharmacist/services/pharmacist.service';
import { Pharmacy } from '@app/shared/models';
import { User } from '@app/shared/models/decodedLoginToken';

@Component({
  selector: 'app-pharmacy-option',
  templateUrl: './pharmacy-option.component.html',
  styleUrls: ['./pharmacy-option.component.scss']
})
export class PharmacyOptionComponent implements OnInit {
  pharmacies: Pharmacy[] = [];
  selectedPharmacy: any = null;
  loading: boolean;

  constructor(
    private router: Router,
    private authService: AuthService,
    private pharmacistService: PharmacistService
  ) {
    const state = this.router.getCurrentNavigation().extras.state;
    if (state) {
      this.pharmacies = state.pharmacies;
    } else {
      const extraData = JSON.parse(localStorage.getItem('extra_data'));
      if (extraData) {
        this.router.navigate(['/dashboard']);
      }
    }
  }

  async ngOnInit() {
    if (!this.pharmacies) {
      this.getPharmacies(this.user);
    }
  }

  get user(): User {
    return this.authService.user();
  }

  async getPharmacies(user) {
    this.loading = true;
    const result = await this.pharmacistService.getPharmacies(user._id).toPromise();
    this.loading = false;
    if (result.length === 1) {
      localStorage.setItem(
        'extra_data',
        JSON.stringify({ pharmacyUserId: result[0].userId, pharmacyId: result[0]._id })
      );
      this.router.navigate(['/dashboard']);
    } else {
      this.pharmacies = result;
    }
  }

  continue() {
    localStorage.setItem(
      'extra_data',
      JSON.stringify({
        pharmacyUserId: this.selectedPharmacy.userId,
        pharmacyId: this.selectedPharmacy._id
      })
    );
    this.router.navigate(['/dashboard']);
  }
}
