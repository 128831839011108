import { Patient } from '@app/shared/models/patient';
import { CidItem } from './CidItem';
import { HealthProfessional } from './health-professional';

export class Orientation {
  _id: string;
  code: string;
  createdAt: Date;
  status: string;
  type: string;
  patient: Patient;
  healthProfessional: HealthProfessional;
  title: string;
  orientations: string[];
  local: any;
  cid: CidItem;
}
