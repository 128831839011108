import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '@app/core/services/validation.service';
import { UserService } from '@app/modules/user/user.service';
import { Patient } from '@app/shared/models';
import { Paciente } from '@app/shared/models/paciente';
import { environment } from '@env/environment';
import moment from 'moment';
import { of } from 'rxjs';
import { map, filter, debounceTime, switchMap, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-simplified-pet-patient-form',
  templateUrl: './simplified-pet-patient-form.component.html',
  styleUrls: ['./simplified-pet-patient-form.component.scss']
})
export class SimplifiedPetPatientFormComponent implements OnInit {
  @Input() patient: Paciente | Patient;
  @Input() isResponsible = false;
  @Input() existingPatient = false;

  @Output() formChange = new EventEmitter<FormGroup>();

  form: FormGroup;
  consultResponsible = false;

  constructor(private fb: FormBuilder, private userService: UserService) {
    this.buildForm();
  }

  ngOnInit(): void {
    if (this.existingPatient && this.isResponsible) {
      this.patchFormResponsible({
        patientExists: true,
        data: this.patient
      });
    } else {
      this.patchValueForm(this.patient);
      this.disabledFields(this.patient);
    }

    this.formChange.emit(this.form);

    const emailForm = this.form.get('responsible.email');
    emailForm.valueChanges
      .pipe(
        map(email => {
          const value = email && email.toLowerCase();
          emailForm.setValue(value, { emitEvent: false });
          return value;
        }),
        filter(_ => emailForm.valid && emailForm.value),
        debounceTime(environment.debounceTime),
        switchMap(email => this.userService.checkEmailExists(email).pipe(catchError(err => of(err))))
      )
      .subscribe(res => {
        if (res.status === 200) {
          emailForm.setErrors({ exists: true });
        } else if (res.status === 404) {
          emailForm.setErrors(null);
        } else if (res.status === 400) {
          emailForm.setErrors({ invalid: true });
        }
      });

    this.form.get('responsible.emailContact').valueChanges.subscribe(email => {
      this.form
        .get('responsible.emailContact')
        .setValue(email && email.toLowerCase(), { emitEvent: false });
    });
  }

  get formResponsible(): FormGroup {
    return <FormGroup>this.form.get('responsible');
  }

  buildForm() {
    this.form = this.fb.group({
      _id: [null],
      name: ['', Validators.required],
      species: ['', Validators.required],
      sex: [''],
      race: [''],
      dateOfBirth: [''],
      weight: [''],
      fur: [''],
      castration: [false, Validators.required],
      temperament: [''],
      microchipNumber: [''],
      responsible: this.fb.group({
        _id: [null],
        cpf: ['', [Validators.required, ValidationService.cpfValidator]],
        fullname: ['', Validators.required],
        name: ['', Validators.required],
        sex: ['', Validators.required],
        dateOfBirth: ['', Validators.required],
        membership: this.fb.group({
          mothersName: ['', Validators.required]
        }),
        cellphone: [''],
        telephone: [''],
        email: ['', Validators.email],
        emailContact: ['', Validators.email],
        address: this.fb.group({
          uf: [''],
          city: [''],
          complement: [''],
          number: [''],
          street: [''],
          neighborhood: [''],
          cep: ['']
        }),
        pendency: [null]
      })
    });
  }

  patchValueForm(patient: Paciente) {
    if (patient.status === 1) {
      const nascimento = moment(patient.nascimento, 'DD/MM/YYYY');

      const valuesDisableds = {
        _id: patient.id,
        cpf: patient.cpf,
        fullname: patient.nome,
        name: patient.nome,
        sex: patient.genero,
        dateOfBirth: nascimento.format('YYYY-MM-DD'),
        membership: {
          mothersName: patient.mae
        }
      };

      if (this.isResponsible) {
        this.formResponsible.patchValue(valuesDisableds);
      } else {
        this.form.patchValue(valuesDisableds);
      }
    } else {
      this.form.get('pendency').setValue(patient);
    }
  }

  patchFormResponsible({ patientExists, data }) {
    if (data) {
      if (patientExists) {
        this.patchFormResponsiblePatientExists(data);
      } else {
        this.patchFormResponsibleNotPatientExists(data);
      }

      this.consultResponsible = true;
      this.disabledFieldsForm();
      this.formResponsible.get('cpf').disable();
    }
  }

  private patchFormResponsiblePatientExists(data: Patient) {
    this.formResponsible.patchValue(data);
    if (data.dateOfBirth) {
      const dateStr = String(data.dateOfBirth);
      const dateOfBirth = moment(dateStr.substring(0, 10)).format('YYYY-MM-DD');
      this.formResponsible.get('dateOfBirth').setValue(dateOfBirth);
    }

    if (data && data.user && data.user.email) {
      this.formResponsible.get('email').setValue(data.user.email);
    }
  }

  private patchFormResponsibleNotPatientExists(data: Paciente) {
    const nascimento = moment(data.nascimento, 'DD/MM/YYYY');
    const patientValues = {
      cpf: data.cpf,
      fullname: data.nome,
      name: data.nome,
      sex: data.genero,
      dateOfBirth: nascimento.format('YYYY-MM-DD'),
      membership: {
        mothersName: data.mae
      }
    };
    this.formResponsible.patchValue(patientValues);
  }

  disabledFields(patient: Paciente) {
    if (patient.status === 1) {
      this.disabledFieldsForm();
    }
  }

  disabledFieldsForm() {
    if (!this.isResponsible) {
      this.form.get('cpf').disable();
      this.form.get('fullname').disable();
      this.form.get('dateOfBirth').disable();
      this.form.get('sex').disable();
      this.form.get('membership.mothersName').disable();
    }
    this.formResponsible.get('fullname').disable();
    this.formResponsible.get('dateOfBirth').disable();
    this.formResponsible.get('sex').disable();
    this.formResponsible.get('membership.mothersName').disable();
  }

  get fullnameResponsible() {
    return this.form.get('responsible').get('fullname');
  }
}
