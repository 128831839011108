<nz-modal
  theme
  class="modal-rqe"
  [(nzVisible)]="isVisible"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  [nzStyle]="{ top: '20px' }"
  nzWidth="600"
  nzKeyboard="false"
  nzMaskClosable="false"
  nzClosable="false"
>
  <ng-template #modalContent>
    <h2 class="title">
      <img src="assets/img/news.svg" alt="News" class="news-logo" />
      Doutor<span *ngIf="healthProfessional.sex === 'F'">a</span>, precisamos da sua atenção!
    </h2>
    <p>
      Para atender as exigências do Art 2º da Resolução nº 2299/2021 do CFM, em vigor desde o dia
      25/12/21, solicitamos que preencha os dados do seu RQE - Registro de Qualificação de Especialidsta
      no campo <i>"especialidades"</i> do seu cadastro. Caso não tenha uma especialidade registrada no
      CFM, favor marcar a opção <span class="bold">"Não possuo RQE"</span>. Neste caso, não poderemos
      exibir a informação no cabeçalho dos documentos gerados dentro da plataforma.
    </p>
    <p style="font-size: 13px">
      Para conhecer a Resolução nº 2299-21 do CFM,
      <a href="https://sistemas.cfm.org.br/normas/visualizar/resolucoes/BR/2021/2299" target="_blank"
        >clique aqui</a
      >.
    </p>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="submit(false)" [nzLoading]="refuseLoading">
      Não possuo RQE
    </button>
    <button nz-button nzType="primary" (click)="submit(true)">
      Preencher RQE
    </button>
  </ng-template>
</nz-modal>
