<app-alternative-navbar titleIcon="fas fa-history"></app-alternative-navbar>
<app-sidebar-menu></app-sidebar-menu>

<div class="container mt-4" theme>
  <form (submit)="register()">
    <div class="row justify-content-md-center">
      <div>
        <div class="container-serp">
          <div class="document-serp d-flex column">
            <img alt="border" src="assets/icons/consultemptyborder.svg" />
            <div>
              <h1>PDF localizado em nossa base!</h1>
              <h2>Atenção! Existem vendas registradas para esta receita!</h2>
              <p>
                Esta receita não foi gerada pela Receita Digital e nem pelos nossos parceiros, mas
                existem registros de vendas para este PDF. <br />
                <br />
                Isso significa que alguma outra farmácia já recebeu este mesmo arquivo PDF e registrou a
                venda de medicamentos. <br />
                <br />Confira o histórico abaixo antes de fazer novas vendas:
              </p>
            </div>
          </div>

          <div class="document-serp medicaments-card d-flex column">
            <div class="checkbox-group m-2">
              <div class="checkbox-item" *ngFor="let medicament of medicaments">
                <ng-container *ngIf="showDispensations && medicament.dispensations">
                  <div class="dispensation pl-5" *ngFor="let dispensation of medicament.dispensations">
                    <em class="fas fa-calendar-alt calendar">&nbsp; {{ dispensation.lastUpdated | date: 'dd/MM/yyyy
                      HH:mm'
                      }}</em>
                    <div class="box">
                      <p class="title1" title="Nome">{{ dispensation.name }}</p>

                      <span class="quantity" title="Quantidade">{{ dispensation.quantity }}</span>

                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <button class="btn btn-icon btn-add-outiline text-capitalize" type="submit">
            <span class="btn-inner--text">Adicionar Dispensação</span>
            <span class="btn-inner--icon" theme><em class="fa fa-plus"></em></span>
          </button>
        </div>
      </div>
    </div>
  </form>

  <div class="footer bg-dark d-flex align-items-center fixed-bottom">
    <div class="back-dispensation">
      <button type="button" class="btn btn-secondary" (click)="register()" theme>
        <em class="fa fa-arrow-left"></em>
        CANCELAR DISPENSAÇÃO
      </button>
    </div>

    <div class="go-dispensation">
      <button type="button" class="btn btn-success" (click)="register()" theme>
        CONTINUAR PARA DISPENSAÇÃO
        <em class="fa fa-arrow-right"></em>
      </button>
    </div>
  </div>
</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
