import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HealthProfessionalService } from '@app/modules/health-professional/services/health-professional.service';
import { DialogModalComponent } from '@app/shared/components/dialog-modal/dialog-modal.component';
import { Paciente } from '@app/shared/models/paciente';
import { markFormGroup } from '@app/utils/markFormGroup';
import { NzNotificationService } from 'ng-zorro-antd';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PatientsService } from '../../services/patients.service';
import { PetPatientService } from '../../services/pet-patient.service';

@Component({
  selector: 'app-modal-add-patient',
  templateUrl: './modal-add-patient.component.html',
  styleUrls: ['./modal-add-patient.component.scss']
})
export class ModalAddPatientComponent {
  @Input() prescriber: any;
  @Input() patient: Paciente;
  @Input() isResponsible = false;
  @Input() existingPatient = false;
  @Input() registeredByResponsible = false;
  @Input() isPet: boolean = false;
  @Output() updateListPatientEvent = new EventEmitter<any>();

  @ViewChild(DialogModalComponent, { static: true })
  dialogModal: DialogModalComponent;

  loading = false;
  form: FormGroup;

  constructor(
    private router: Router,
    public modalRef: BsModalRef,
    private patientService: PatientsService,
    private petPatientService: PetPatientService,
    private healthProfessionalService: HealthProfessionalService,
    private notification: NzNotificationService
  ) {}

  get hasPendency() {
    return !this.patient || this.patient.status !== 1;
  }

  get isUnderAge() {
    if (!this.hasPendency) {
      const nascimento = this.patient.nascimento;
      const date = nascimento.split('/').map(s => +s);
      const birthdate = new Date(date[2], date[1] - 1, date[0]);
      const diffDate = Date.now() - birthdate.getTime();
      const ageDate = new Date(diffDate);
      const years = Math.abs(ageDate.getUTCFullYear() - 1970);
      return years < 18;
    }
    return this.patient.menorIdade;
  }

  async submit() {
    markFormGroup(this.form);

    const form = this.form.getRawValue();
    if (!form.pendency && this.patient.status === -1) {
      form.pendency = this.patient;
    }

    if (this.form.valid) {
      try {
        this.loading = true;
        const { responsible } = form;
        if (responsible) {
          const responsibleSaved = responsible._id
            ? await this.updatePatient(responsible._id, responsible)
            : await this.savePatient(responsible);
          form.responsible = responsibleSaved._id;
        }

        if (!this.registeredByResponsible) {
          const patientSaved = await this.saveAddPatient(form);

          const msg = !form.email
            ? 'E-mail do paciente não cadastrado.<br>' +
              'Seu paciente precisará fazer um novo<br>cadastro para acessar o Perfil do Paciente'
            : '';
          this.notification.success('Paciente cadastrado com sucesso!', msg);
          this.updateListPatientEvent.emit(patientSaved);
        } else {
          if (this.isPet) {
            form.originHost = window.location.origin;
            await this.petPatientService.save(form).toPromise();
          } else {
            await this.savePatient(form);
          }
          this.dialogModal.createTplModal(
            'Parabéns! \n Cadastro realizado com sucesso.',
            'Acesse seu e-mail e ative sua conta!'
          );
          this.router.navigate(['/entry/login']);
        }
        this.modalRef.hide();
      } catch (err) {
        console.error(err);
        const msg = err.error ? err.error.message : 'Erro ao salvar paciente';
        this.notification.error('Erro', msg);
        if (msg === 'E-mail já cadastrado') {
          if (this.isPet)
            this.form.controls['responsible'].get('email').setErrors({ alreadyExists: true });
          else this.form.get('email').setErrors({ alreadyExists: true });
        } else {
          if (this.isPet) this.form.controls['responsible'].get('email').setErrors(null);
          else this.form.get('email').setErrors(null);
        }
      } finally {
        this.loading = false;
      }
    }
  }

  private savePatient(patient: any) {
    patient.originHost = window.location.origin;
    return this.patientService.save(patient).toPromise();
  }

  private updatePatient(responsibleId, patient: any) {
    patient.originHost = window.location.origin;
    return this.patientService.update(responsibleId, patient).toPromise();
  }
  private saveAddPatient(patient: any) {
    patient.originHost = window.location.origin;
    return this.healthProfessionalService.savePatient(this.prescriber._id, patient).toPromise();
  }

  private addPatient(patientId: string) {
    return this.healthProfessionalService.addPatient(this.prescriber._id, patientId).toPromise();
  }

  close() {
    this.modalRef.hide();
  }
}
