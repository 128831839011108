import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from '@app/core/services/core.service';
import { FederalRevenueService } from '@app/modules/entry/services/federal-renevue.service';
import { DialogModalComponent } from '@app/shared/components/dialog-modal/dialog-modal.component';
import { CpfCnpjApiErrors } from '@app/shared/models/cpf-cnpj-api-errors.enum';
import { ThemeService } from '@app/theme';
import { markFormGroup } from '@app/utils/markFormGroup';
import { NzNotificationService } from 'ng-zorro-antd';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { RegisterService } from '../../services/register.service';
import { ModalTermsAndConditionsComponent } from './modal-terms-and-conditions/modal-terms-and-conditions.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  actualDate = new Date();
  isDisabledButton = true;
  isCheckedTerms = false;
  isVisible = false;
  loading = false;

  form: FormGroup;
  routerParam: string;
  backendVersion$: Observable<string>;

  @ViewChild(DialogModalComponent, { static: true })
  dialogModal: DialogModalComponent;

  @ViewChild(ModalTermsAndConditionsComponent, { static: true })
  modalTermsAndConditionsComponent: ModalTermsAndConditionsComponent;
  isMobile = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = this.coreService.isViewPortMobile();
  }

  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    private registerService: RegisterService,
    private notification: NzNotificationService,
    private federalRevenueService: FederalRevenueService,
    private themeService: ThemeService,
    private coreService: CoreService
  ) {
    this.backendVersion$ = this.coreService.getBackendVersion();
  }

  ngOnInit(): void {
    this.routerParam = this._route.snapshot.paramMap.get('type');
  }

  get verifiedCpf() {
    return !!this.form && this.form.value.verifiedCpf;
  }

  get verifiedCnpj() {
    return !!this.form && this.form.value.verifiedCnpj;
  }

  get frontendVersion(): string {
    return this.coreService.frontendVersion;
  }

  get viewBtnSaveAndTermsDisclaimer(): boolean {
    return (
      ((this.routerParam === 'patient' ||
        this.routerParam === 'doctor' ||
        this.routerParam === 'dentist' ||
        this.routerParam === 'veterinarian') &&
        this.verifiedCpf) ||
      (this.routerParam === 'pharmacy' && this.verifiedCnpj)
    );
  }

  handleError(err: HttpErrorResponse) {
    console.error(err);
    const message = err.error ? err.error.message : 'Houve um erro no cadastro';
    return throwError(message);
  }

  openTermsAndConditionsModal() {
    this.modalTermsAndConditionsComponent.openModal();
  }

  openTerms() {
    window.open('assets/file/mrd_termos.pdf', '_blank');
  }

  openConditions() {
    window.open('assets/file/mrd_politica.pdf', '_blank');
  }

  async isValidCpfCnpj(cpf, cnpj) {
    try {
      this.loading = true;
      let consult: any;
      let message = '';
      if (cpf) {
        consult = await this.federalRevenueService.consultCpf(cpf).toPromise();
        message =
          'A importação de dados do CPF está com instabilidade, preencha manualmente ou tente novamente mais tarde.';
      } else {
        consult = await this.federalRevenueService.consultCnpj(cnpj).toPromise();
        message =
          'A importação de dados do CNPJ está com instabilidade, preencha manualmente ou tente novamente mais tarde.';
      }
      this.loading = false;

      if (consult.status === 1) {
        return true;
      }
      if (consult.status === -1 || consult.erroCodigo >= 1000) {
        consult.erro = consult.erroCodigo >= 1000 ? consult.erro : message;
        this.form.value.pendency = consult;
        return true;
      }
      if (consult.status === 0 && consult.erroCodigo && consult.erroCodigo < 1000) {
        if (Object.values(CpfCnpjApiErrors).includes(consult.erroCodigo)) {
          this.notification.warning('Aviso', consult.erro);
          return false;
        }
      }
      return false;
    } catch (error) {
      this.loading = false;
    }
  }

  submit() {
    if (this.routerParam === 'veterinarian') {
      const specialties = this.form.get('specialties') as FormArray;
      const rqe = specialties.controls[0];
      rqe.setValidators(null);
      rqe.disable();
    }

    markFormGroup(this.form);
    this.markVerifiedCnpj();
    if (
      (this.routerParam === 'doctor' ||
        this.routerParam === 'dentist' ||
        this.routerParam === 'veterinarian') &&
      this.form.value.specialties.length === 0
    ) {
      this.notification.warning('Aviso', 'Você precisa escolher ao menos uma especialidade.');
    } else if (this.form.invalid) {
      this.notification.warning('Aviso', 'Confira os campos obrigatórios');
    } else if (!this.isCheckedTerms) {
      this.notification.warning(
        'Aviso',
        'Para concluir o seu cadastro é necessário concordar com os Termo de Uso e Política de Privacidade da Receita Digital'
      );
    } else {
      this.registerUser(this.form.getRawValue());
    }
  }

  private markVerifiedCnpj() {
    const controlCnpj = this.form.get('verifiedCnpj');
    if (controlCnpj) {
      controlCnpj.setValue(true);
    }
  }

  private registerUser(data: any) {
    this.loading = true;
    this.registerService
      .register(this.routerParam, data)
      .pipe(take(1), catchError(this.handleError))
      .subscribe(
        res => {
          this.loading = false;
          this.dialogModal.createTplModal(
            'Parabéns! \n Cadastro realizado com sucesso.',
            'Acesse seu e-mail e ative sua conta!'
          );
          this.router.navigate(['/entry/login']);
        },
        errMsg => {
          this.loading = false;
          this.notification.error('Erro', errMsg);

          this.form.get('cpf').setErrors(null);
          this.form.get('email').setErrors(null);
          if (errMsg === 'E-mail já cadastrado') {
            this.form.get('email').setErrors({ alreadyExists: true });
          } else if (errMsg === 'CPF já cadastrado') {
            this.form.get('cpf').setErrors({ alreadyExists: true });
          }
        }
      );
  }

  async submitRegisterForm() {
    markFormGroup(this.form);
    this.form.value.pendency = undefined;

    if (this.form.valid) {
      const isValid = await this.isValidCpfCnpj(this.form.value.cpf, this.form.value.cnpj);
      if (!isValid) {
        return;
      }
    }

    if (this.isCheckedTerms === false) {
      this.notification.warning(
        'Aviso',
        'Para concluir o seu cadastro é necessário concordar com os Termo de Uso e Política de Privacidade da Receita Digital'
      );
    } else if (this.form.valid) {
      this.loading = true;
      this.form.value.email = this.form.value.email.toLowerCase();
      this.form.value.name = this.form.value.name.trim();

      this.registerService
        .register(this.routerParam, this.form.getRawValue())
        .pipe(take(1), catchError(this.handleError))
        .subscribe(
          res => {
            this.loading = false;
            this.dialogModal.createTplModal(
              'Parabéns! \n Cadastro realizado com sucesso.',
              'Acesse seu e-mail e ative sua conta'
            );
            this.router.navigate(['/entry/login']);
          },
          err => {
            this.notification.warning('Aviso', err);
            this.loading = false;
          }
        );
    }
  }

  isThemeActiveReceitaDigital() {
    const active = this.themeService.getActiveTheme();
    return active.name === 'theme-receita-digital';
  }
}
