<app-alternative-navbar title="Receitas" titleIcon="fas fa-list-ul"></app-alternative-navbar>
<app-sidebar-menu></app-sidebar-menu>

<div class="container">
  <div class="listing">
    <nz-card *ngFor="let prescription of prescriptions" [nzBordered]="false" theme>
      <nz-tabset [nzTabBarExtraContent]="cardPrescription">
        <nz-tab nzTitle="Receita">
          <p><span class="bold">Código da receita: </span>{{ prescription.code }}</p>
          <p>
            <span class="bold">Data de emissão: </span
            >{{ prescription.createdAt | date: 'dd/MM/yyyy, HH:mm' }}
          </p>
          <p>
            <span class="bold">Quantidade de medicamentos: </span>
            {{ prescription?.amountOfMedicines }}
          </p>
        </nz-tab>
        <nz-tab
          *ngIf="prescription.healthProfessional as healthProfessional"
          [nzTitle]="getProfessionalTypeName(healthProfessional.professionalType)"
        >
          <p><span class="bold">Nome: </span>{{ healthProfessional.name }}</p>
          <p>
            <span class="bold">{{ healthProfessional.regionalCouncilNumber.label }}: </span
            >{{ healthProfessional.regionalCouncilNumber?.number }} -
            {{ healthProfessional.regionalCouncilNumber?.uf }}
          </p>
          <p *ngIf="prescription.local as local">
            <span class="bold">Endereço: </span>{{ local.name }} - {{ local.address?.city }} /
            {{ local.address?.uf }}
          </p>
        </nz-tab>
        <nz-tab *ngIf="prescription.patient as patient" nzTitle="Paciente">
          <p><span class="bold">Nome: </span>{{ patient.name }}</p>
          <p><span class="bold">CPF: </span>{{ patient.cpf }}</p>
          <p>
            <span class="bold">Endereço: </span>{{ patient.address?.city }} -
            {{ patient.address?.uf }}
          </p>
        </nz-tab>
      </nz-tabset>

      <ng-template #cardPrescription>
        <button (click)="changeRoute(prescription.code)" class="btn btn-success" theme>
          Abrir receita
        </button>
      </ng-template>
    </nz-card>
  </div>
</div>
<div class="bg-dark-green"></div>
