
    <nav class="navbar navbar-expand-lg navbar-light" theme>
      <a class="navbar-brand">
        <img
          [src]="isThemeActiveReceitaDigital() ? 'assets/img/logo-03.png' : 'assets/img/mrd/logo-03.png'"
          [alt]="isThemeActiveReceitaDigital() ? 'Logo Receita Digital' : 'Logo MRD'"
        />
      </a>
      <div class="brand-bg bg-primary" *ngIf="brandBackGround">
        <h4 class="title-semi-bold">{{ title }}</h4>
      </div>
      <div class="navbar-text" *ngIf="!brandBackGround">
        <h4 class="title-semi-bold">{{ title }}</h4>
      </div>
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar-default"
          aria-controls="navbar-default"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbar-default">
          <div class="navbar-header"></div>
          <div class="navbar-collapse-header">
            <div class="row">
              <div class="col-6 collapse-brand">
                <a href="#">
                  <img src="assets/img/logo-03.png" />
                </a>
              </div>
              <div class="col-6 collapse-close">
                <button
                  type="button"
                  class="navbar-toggler"
                  data-toggle="collapse"
                  data-target="#navbar-default"
                  aria-controls="navbar-default"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>

          <ul class="navbar-nav ml-lg-auto align-items-center">
            <ng-container *ngIf="isLoggedOut">
              <li class="nav-item">
                <a
                  class="btn btn-md register-btn"
                  [ngClass]="navbarBackGround === 'bg-primary' ? 'btn-info' : 'btn-primary'"
                  routerLink="/entry/account-type"
                >
                  Cadastre-se
                </a>
              </li>
              <li class="nav-item">
                <a
                  routerLink="/login"
                  class="btn btn-icon btn-circle"
                  [ngClass]="navbarBackGround === 'bg-primary' ? 'btn-info' : 'btn-alt-black'"
                >
                  <span class="btn-inner--icon"><i class="fa fa-user-solid"></i></span>
                </a>
              </li>
            </ng-container>

            <ng-container *ngIf="isLoggedIn">
              <li class="nav-item">
                <button (click)="toggle()" *ngIf="false">Mudar Tema</button>
              </li>
              <li class="nav-item">
                <a href="#" class="nav-link" (click)="$event.preventDefault()">
                  <div class="photo">
                    <img
                      *ngIf="user as u"
                      [alt]="u.name"
                      class="img-fluid"
                      src="/assets/img/default-avatar.png"
                    />
                  </div>
                </a>
              </li>
              <li class="nav-item" *ngIf="!disableNavigation">
                <a
                  routerLink="/entry/login"
                  (click)="logout()"
                  class="nav-link nav-link-icon logoutIcon"
                >
                  <i class="fa fa-exit" id="logout-confirmation"></i>
                </a>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </nav>
  