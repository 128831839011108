import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const MEDICAMENT_URL = 'medicament';

@Injectable({ providedIn: 'root' })
export class CrService {
  constructor(private http: HttpClient) {}

  searchMedicament(name: string): Observable<any[]> {
    return this.http.get<any>(`${environment.apiRoot}cr/search`, { params: { name } }).pipe(
      map(({ items }) =>
        items.map(item => ({
          nome: item.productName,
          apresentacaoCustomizada: item.name,
          tipo: this.translateClassification(item.classification),
          classification: item.classification,
          principio: item.substance,
          laboratorio: item.factory,
          ean: `${item.sku}`
        }))
      )
    );
  }

  private translateClassification(classification: string) {
    switch (classification) {
      case 'generic':
        return 'Genérico';
      case 'similar':
        return 'Similar';
      case 'reference':
        return 'Referência';
      case 'biologic':
        return 'Biológico';
      case 'interchangeable':
        return 'Similar Intercambiável';
      case 'new':
        return 'Novo';
      case 'specific':
        return 'Específico';
      case 'other':
        return 'Outro';
      case 'radiofarmac':
        return 'Radiofármaco';
      default:
        return classification;
    }
  }
}
