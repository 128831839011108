<p>Pagamento do plano</p>

<p>Para concluir a contratação do plano, preencha os dados para pagamento da mensalidade.</p>

<p>
  A data da primeira cobrança será dia {{ billingDate | date: 'dd/MM/yyyy' }}. A liberação do seu acesso
  acontecerá em até 24h, após validarmos sua habilitação junto ao seu Conselho de Classe.
</p>

<p>Caso desista da contratação nesse período</p>

<!-- <p>CRIAR ADESÃO AO PLANO DA RECEITA DIGITAL</p> -->

<form [formGroup]="form" autocomplete="on">
  <div formGroupName="customer">
    <nz-divider nzText="Dados pessoais do dono do cartão" nzOrientation="left"></nz-divider>
    <div class="row">
      <nz-form-item class="col-sm">
        <nz-form-label>Nome Completo<span class="mandatory">*</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input nz-input formControlName="name" autocomplete="name" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="col-sm">
        <nz-form-label>CPF/CNPJ<span class="mandatory">*</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input nz-input formControlName="identity" mask="CPF_CNPJ" autocomplete="cpf" />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="row">
      <nz-form-item class="col-sm">
        <nz-form-label>Telefone<span class="mandatory">*</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input nz-input formControlName="phone" mask="(00) 00000-0000" autocomplete="tel" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="col-sm">
        <nz-form-label>E-mail<span class="mandatory">*</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input nz-input formControlName="email" autocomplete="email" />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div formGroupName="address">
      <nz-divider nzText="Endereço do dono do cartão" nzOrientation="left"></nz-divider>
      <div class="row">
        <nz-form-item class="col-sm-4">
          <nz-form-label>CEP<span class="mandatory">*</span></nz-form-label>
          <nz-form-control nzHasFeedback>
            <input
              nz-input
              (keyup)="consultCep($event.target.value)"
              mask="00000-000"
              formControlName="zipCode"
              autocomplete="postal-code"
            />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="col-sm-5">
          <nz-form-label>Logradouro<span class="mandatory">*</span></nz-form-label>
          <nz-form-control nzHasFeedback>
            <input nz-input formControlName="street" autocomplete="street-address" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="col-sm-3">
          <nz-form-label>Número<span class="mandatory">*</span></nz-form-label>
          <nz-form-control nzHasFeedback>
            <input nz-input formControlName="number" type="number" #numberInput autocomplete="off" />
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="row">
        <nz-form-item class="col-sm-4">
          <nz-form-label>Bairro<span class="mandatory">*</span></nz-form-label>
          <nz-form-control nzHasFeedback>
            <input nz-input formControlName="district" autocomplete="address-level2" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="col-sm-5">
          <nz-form-label>Cidade<span class="mandatory">*</span></nz-form-label>
          <nz-form-control nzHasFeedback>
            <input nz-input formControlName="cityName" autocomplete="address-level1" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="col-sm-3">
          <nz-form-label>UF<span class="mandatory">*</span></nz-form-label>
          <nz-form-control nzHasFeedback>
            <nz-select nzShowSearch nzPlaceHolder="Selecione" formControlName="stateInitials">
              <nz-option
                *ngFor="let state of states"
                [nzLabel]="state?.sigla"
                [nzValue]="state?.sigla"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </div>

  <div formGroupName="creditCard">
    <nz-divider nzText="Dados do Cartão" nzOrientation="left"></nz-divider>

    <div class="row">
      <nz-form-item class="col-sm">
        <nz-form-label>Número do cartão<span class="mandatory">*</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            nz-input
            type="tel"
            autocomplete="cc-number"
            formControlName="cardNumber"
            placeholder="•••• •••• •••• ••••"
            ccNumber
          />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="col-sm">
        <nz-form-label>Nome impresso no cartão<span class="mandatory">*</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input nz-input formControlName="holder" autocomplete="cc-name" />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="row">
      <nz-form-item class="col-sm">
        <nz-form-label>Data de vencimento<span class="mandatory">*</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            #expireInput
            nz-input
            type="tel"
            autocomplete="cc-exp"
            formControlName="expirationDate"
            placeholder="••/••••"
            mask="00/0000"
            appDate
            ccExp
          />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="col-sm">
        <nz-form-label>Código de segurança<span class="mandatory">*</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            #cvcInput
            nz-input
            formControlName="securityCode"
            type="tel"
            autocomplete="cc-csc"
            placeholder="•••"
            required
            ccCVC
          />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
