<button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
  <span aria-hidden="true">&times;</span>
</button>
<h1 class="title-alert">Atenção</h1>

<p class="alert-message mb-1">
  Deseja excluir o medicamento
</p>

<span class="medicament-info">
  {{ medicament.name }}
</span>

<p class="alert-message mb-1">
  da sua lista de dispensação?
</p>

<p class="text-message mb-1">
  Você pode excluir um medicamento da sua lista de dispensação caso o comprador/paciente não vá
  comprá-lo. Ao fazer isso, o registro da dispensação não será efetuado no sistema. Mas fique tranquilo!
  Você poderá incluir este medicamento novamente em uma dispensação futura!
</p>

<div class="row baseboard mb-3 pb-3 mt-3 pt-3">
  <div class="col-6">
    <button type="button" class="btn btn-icon text-capitalize btn-keep" (click)="cancel()">
      <span class="btn-inner--text">MANTER MEDICAMENTO</span>
    </button>
  </div>

  <div class="col-6">
    <button type="button" class="btn btn-icon text-capitalize btn-remove" (click)="remove(medicament)">
      <span class="btn-inner--text">EXCLUIR MEDICAMENTO</span>
    </button>
  </div>
</div>
