<div [style.width.px]="width">
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <iframe
      #iframe
      title="Assinatura Digital"
      [src]="src | safe"
      [height]="height"
      [width]="width"
      (load)="onload(iframe)"
    ></iframe>
  </div>
</div>
