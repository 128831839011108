<div class="modal-header" theme>
  <h4 class="modal-title pull-left text-white">Novo paciente</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <app-simplified-patient-form
    *ngIf="!isUnderAge && !isResponsible"
    [patient]="patient"
    (formChange)="form = $event"
  ></app-simplified-patient-form>
  <app-simplified-underage-patient-form
    *ngIf="(isUnderAge || isResponsible) && !isPet"
    [patient]="patient"
    [isResponsible]="isResponsible"
    [existingPatient]="existingPatient"
    (formChange)="form = $event"
  ></app-simplified-underage-patient-form>
  <app-simplified-pet-patient-form
    *ngIf="isPet"
    [patient]="patient"
    [isResponsible]="isResponsible"
    [existingPatient]="existingPatient"
    (formChange)="form = $event"
  ></app-simplified-pet-patient-form>
</div>
<div class="modal-footer">
  <button class="ok-btn btn-cancel" nz-button (click)="close()" theme>
    Cancelar
  </button>
  <button class="ok-btn btn-salvar" nz-button (click)="submit()" theme>Salvar <i class="fa"></i></button>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
