import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Buyer, HealthProfessional } from '@app/shared/models';
import { Retention } from '@app/shared/models/retention';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BuyerService } from '../../services/buyer.service';
import { RetentionService } from '../../services/retention.service';
import { ModalSearchComponent } from '../modal-search/modal-search.component';

@Component({
  selector: 'app-retention-confirmation',
  templateUrl: './retention-confirmation.component.html',
  styleUrls: ['./retention-confirmation.component.scss']
})
export class RetentionConfirmationComponent implements OnInit {
  retention: Retention;
  buyer: Buyer;
  medicamentsRetained;
  modalRef: BsModalRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private retentionService: RetentionService,
    private buyerService: BuyerService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(async params => {
      const dispensationCode = params['dispensationCode'];
      this.retention = await this.retentionService.getRetentionByCode(dispensationCode).toPromise();
      this.setInfoInMedicamentsRetained();
      if (this.retention.prescription.patient.cpf === this.retention.cpfBuyer) {
        this.buyer = new Buyer();
        this.buyer.name = this.retention.prescription.patient.name;
        this.buyer.cpf = this.retention.prescription.patient.cpf;
        this.buyer.address = this.retention.prescription.patient.address;
        this.buyer.telephone = this.retention.prescription.patient.telephone;
        this.buyer.cellphone = this.retention.prescription.patient.cellphone;
        this.buyer.identity = this.retention.prescription.patient.identity;
      } else {
        this.buyer = await this.buyerService.getBuyerByCpf(this.retention.cpfBuyer).toPromise();
      }
    });
  }

  setInfoInMedicamentsRetained() {
    for (const medicamentRetained of this.retention.medicaments) {
      const medicament = this.retention.prescription.medicaments.filter(
        m => m.ean === medicamentRetained.ean
      )[0];
      medicamentRetained.name = medicament.name;
      medicamentRetained.principle = medicament.principle;
      medicamentRetained.dosage = medicament.dosage;
    }
  }

  getPreWriterInfo(prescription) {
    const preWriter = {
      name: '',
      cpf: '',
      label: '',
      regionalCouncil: { label: '', number: 0, uf: '' },
      specialties: '',
      contact: ''
    };

    if (prescription) {
      preWriter.label = HealthProfessional.getProfessionalTypeName(
        prescription.healthProfessional.professionalType
      );
      preWriter.name = prescription.healthProfessional.name;
      preWriter.cpf = prescription.healthProfessional.cpf;
      preWriter.regionalCouncil.label = HealthProfessional.getRegionalCouncilLabel(
        prescription.healthProfessional.professionalType
      );
      preWriter.regionalCouncil.number = prescription.healthProfessional.regionalCouncilNumber.number;
      preWriter.regionalCouncil.uf = prescription.healthProfessional.regionalCouncilNumber.uf;
      preWriter.specialties = prescription.healthProfessional.specialties.map(s => s.title).join(', ');
    }

    return preWriter;
  }

  getAgePatient() {
    if (this.retention !== undefined) {
      const nascimento = new Date(this.retention.prescription.patient.dateOfBirth);
      const hoje = new Date();
      let diferencaAnos = hoje.getFullYear() - nascimento.getFullYear();
      if (
        new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate()) <
        new Date(hoje.getFullYear(), nascimento.getMonth(), nascimento.getDate())
      ) {
        diferencaAnos--;
      }
      return diferencaAnos;
    }
    return 0;
  }

  retainAgain() {
    this.router.navigate([`prescription/confirmation/${this.retention.prescription.code}`]);
  }

  validatePrescription() {
    this.modalRef.hide();
    const prescriptionCode = (<HTMLInputElement>document.getElementById('prescriptionCode')).value;
    localStorage.setItem('prescriptionCode', prescriptionCode);
    this.router.navigate([`/prescription/confirmation/${prescriptionCode}`]);
  }

  getMedicamentsRetained(prescription) {
    const medicaments = prescription.medicaments;
    const retention = prescription.retention;
  }

  openModal() {
    this.modalRef = this.modalService.show(ModalSearchComponent, {
      backdrop: 'static',
      keyboard: false
    });
  }

  async changeRoute(route: string) {
    this.router.navigate([`/${route}`]);
  }
}
