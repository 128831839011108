class Pendency {
  question: string;
  answer: string;
}

export class ManipulatedProfile {
  _id: any;
  patient: any;
  prescriber: any;
  weight: number;
  height: number;
  skinType: number;
  oilinessDegree: number;
  photoaging: number;
  procedures: number[];
  pigmentation: number;
  skinflaccidity: number;
  sensitivity: number;
  wrinklesdepth: number;
  hydrationlevel: number;
  procedureTime: number;
  requiredTreatment: number;
  treatmentRoutines: number[];
  pathologies: number[];
  status: string;
  dosage: string;
  pendency: Pendency;
  medicaments: any[];

  static RASCUNHO = 'Rascunho';
  static ENVIADO = 'Enviado';

  static get skinTypes() {
    return [
      { label: 'Fototipo I', value: 1 },
      { label: 'Fototipo II', value: 2 },
      { label: 'Fototipo III', value: 3 },
      { label: 'Fototipo IV', value: 4 },
      { label: 'Fototipo V', value: 5 }
    ];
  }

  static get degreesOfOiliness() {
    return [
      { label: 'Normal', value: 1 },
      { label: 'Oleosa', value: 2 },
      { label: 'Seca', value: 3 },
      { label: 'Mista', value: 4 }
    ];
  }

  static get treatments() {
    return [{ label: 'Oral', value: 1 }, { label: 'Topico', value: 2 }, { label: 'Ambos', value: 3 }];
  }

  static get proceduresOptions() {
    return [
      { label: 'Ultrassom Microfocada', value: 1 },
      { label: 'Radiofrequencia', value: 2 },
      { label: 'Microagulhamento', value: 3 },
      { label: 'Luz pulsada', value: 4 },
      { label: 'Peeling', value: 5 },
      { label: 'Prenchimento', value: 6 },
      { label: 'Toxina Butolinica', value: 7 },
      { label: 'Bioestimulador', value: 8 }
    ];
  }
  static get proceduresTime() {
    return [
      { label: 'Hoje', value: 1 },
      { label: 'Ate uma semana', value: 2 },
      { label: 'Ate quinze dias', value: 3 },
      { label: 'Ate 30 dias', value: 4 },
      { label: 'Mais de 30 dias', value: 5 }
    ];
  }

  static get treatmentRoutinesOptions() {
    return [
      { label: 'Limpeza', value: 1 },
      { label: 'Tonificação', value: 2 },
      { label: 'Hidratação', value: 3 },
      { label: 'Fotoproteção', value: 4 },
      { label: 'Tratamento especifico', value: 5 }
    ];
  }

  static get pathologiesOptions() {
    return [
      { label: 'Acene', value: 1 },
      { label: 'Rosacea', value: 2 },
      { label: 'Melanoses', value: 3 },
      { label: 'Fotoenvelhecimento agudo', value: 4 },
      { label: 'Dermatite atópica', value: 5 },
      { label: 'Psioriase', value: 6 },
      { label: 'Melasma', value: 7 },
      { label: 'Outra(Será criado posteriormente uma fórrmula adicional se necessário)', value: 8 }
    ];
  }
}
