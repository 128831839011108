<div class="modal-header">
  <h4 class="modal-title pull-left text-white">Buscar receita</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form (submit)="search(param.value)">
  <div class="modal-body">
    <p>Digite abaixo o CPF (apenas números) do paciente ou o código da receita</p>
    <input nz-input #param theme />
    <label>
      <i class="fa fa-arrow-right" (click)="search(param.value)"></i>
    </label>
  </div>
</form>
