import { Component, HostListener } from '@angular/core';
import { CoreService } from '@app/core/services/core.service';
import { ThemeService } from '@app/theme';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  actualDate = new Date();
  backendVersion$: Observable<string>;
  isMobile = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = this.coreService.isViewPortMobile();
  }

  constructor(private themeService: ThemeService, private coreService: CoreService) {
    this.backendVersion$ = this.coreService.getBackendVersion();
  }

  get frontendVersion(): string {
    return this.coreService.frontendVersion;
  }

  isThemeActiveReceitaDigital() {
    const active = this.themeService.getActiveTheme();
    return active.name === 'theme-receita-digital';
  }
}
