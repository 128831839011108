<div class="patient-record">
  <!-- menu -->
  <div *ngIf="loading" class="loading-area">
    <ng-template #indicatorTemplate>
      <i nz-icon nzType="loading"></i>
    </ng-template>
    <nz-spin nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
  </div>
  <div
    *ngIf="!loading && patientRecords.length"
    class="records-area"
    [ngStyle]="customStyle?.recordsArea"
  >
    <div (scroll)="onScroll($event)" class="records" [ngClass]="{ 'opacity-record': loading }">
      <nz-list>
        <nz-list-item *ngFor="let record of patientRecords" class="record-card-area">
          <div class="record-card-area">
            <app-record-card
              [record]="record"
              [tags]="tags"
              [merging]="merging"
              (updateRecordList)="updateRecordList()"
              (setLoading)="setLoading($event)"
              (showCheckbox)="showCheckbox($event)"
              (addNewRecordToMerge)="addNewRecordToMerge($event)"
            ></app-record-card>
          </div>
        </nz-list-item>
      </nz-list>
    </div>
  </div>

  <div class="description-area">
    <div class="input-area" *ngIf="!merging">
      <button
        *ngIf="!record"
        class="tags-list"
        nz-button
        nz-popover
        [nzPopoverContent]="contentTemplate"
        nzPopoverTrigger="click"
      >
        #
      </button>
      <ng-template #contentTemplate>
        <div class="tag" *ngFor="let tag of tags">
          <a (click)="setSelectedTag(tag)"> #{{ tag.name }}: {{ tag.description }} </a>
          <ng-container *ngIf="tag.children.length > 0">
            <a
              *ngFor="let children of tag.children; index as i"
              (click)="childrenSelected(tag, children)"
              >{{ i + 1 }} - {{ children.description }}</a
            >
            <br />
          </ng-container>
        </div>
      </ng-template>

      <nz-input-group>
        <nz-form-control>
          <input
            [ngStyle]="customStyle?.input"
            #recordInput
            type="text"
            nz-input
            [nzAutocomplete]="autoName"
            [placeholder]="selectedTag ? '#' + selectedTag.name + ': ' : ''"
            [(ngModel)]="record"
            (ngModelChange)="onChangeTag()"
          />
          <nz-autocomplete #autoName nzBackfill>
            <ng-container *ngFor="let tag of filteredTags">
              <nz-auto-optgroup [nzLabel]="tag.children.length > 0 ? groupTitle : ''">
                <ng-template #groupTitle>
                  <span style="height: 100px;" *ngIf="tag.children.length > 0">
                    #{{ tag.name }}: {{ tag.description }}
                  </span>
                </ng-template>

                <nz-auto-option
                  (click)="childrenSelected(tag, children)"
                  *ngFor="let children of tag.children; index as i"
                  [nzLabel]="'#' + children.description"
                  [nzValue]="'children.description'"
                  class="mao"
                >
                  {{ i + 1 }} - {{ children.description }}
                </nz-auto-option>

                <nz-auto-option
                  *ngIf="tag.children.length == 0"
                  (click)="setSelectedTag(tag, recordInput.value)"
                  [nzLabel]="'#' + tag.description"
                  [nzValue]="'tag.description'"
                  class="mao"
                >
                  #{{ tag.name }}: {{ tag.description }}
                </nz-auto-option>
              </nz-auto-optgroup>
            </ng-container>
          </nz-autocomplete>
        </nz-form-control>
      </nz-input-group>
    </div>

    <button class="round-btn send-btn" (click)="submitRecord()" *ngIf="!merging">
      <i class="fas fa-arrow-right"></i>
    </button>

    <button
      nz-button
      type="button"
      nzType="primary"
      class="btn-green cancel-merging-btn"
      *ngIf="merging"
      (click)="cancelMergeSelection()"
    >
      Cancelar <i class="fas fa-times"></i>
    </button>

    <button
      nz-button
      type="button"
      nzType="primary"
      class="btn-green merging-btn"
      *ngIf="merging"
      (click)="changeMergeConfirmation()"
    >
      Unificar <i class="fas fa-check"></i>
    </button>
  </div>

  <ng-container *ngIf="mergeConfirmation">
    <nz-modal
      [(nzVisible)]="mergeConfirmation"
      nzTitle="Selecione a nova tag principal"
      (nzOnCancel)="cancelMerge()"
      (nzOnOk)="mergeRecords()"
    >
      <div *ngIf="mergingRecords" class="loading-area modal-loading">
        <ng-template #indicatorTemplate>
          <i nz-icon nzType="loading"></i>
        </ng-template>
        <nz-spin nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
      </div>

      <div [ngClass]="{ 'opacity-record': updatingTag }">
        <ng-container *ngIf="distinctTags">
          <p>
            Escolha a nova tag do registro:
          </p>
          <ng-container *ngFor="let tag of distinctTags; let i = index">
            <div>
              <a (click)="setPrincipalTag(tag)">{{ i + 1 }}: {{ tag.description }}</a
              ><i *ngIf="tag._id == principalTag._id" class="fas fa-check"></i>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!distinctTags">
          <p>Deseja confirmar a mesclagem dos registros selecionados?</p>
        </ng-container>
      </div>
    </nz-modal>
  </ng-container>
</div>
