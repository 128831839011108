import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PharmacyService } from '@app/modules/pharmacy/services/pharmacy.service';
import { User } from '@app/shared/models/decodedLoginToken';
import { NzModalRef, NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-modal-edit-pharmacy-covid',
  templateUrl: './modal-edit-pharmacy-covid.component.html',
  styleUrls: ['./modal-edit-pharmacy-covid.component.scss']
})
export class ModalEditPharmacyCovidComponent implements OnInit {
  @Input() completeUser: any;
  @Output() updateUserEvent = new EventEmitter<User>();

  covidForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private pharmacyService: PharmacyService,
    private nzModalRef: NzModalRef,
    private notification: NzNotificationService
  ) {}

  ngOnInit() {
    this.covidForm = this.fb.group({
      covid: [`${this.completeUser.covid}`, Validators.required]
    });
  }

  async save(param) {
    if (param === '') {
      this.notification.warning('Atenção', 'Valor do teste Covid não informado');
    } else {
      try {
        const pharmacyId = this.completeUser._id;
        this.completeUser.covid = this.covidForm.get('covid').value;
        await this.pharmacyService.update(pharmacyId, this.completeUser).toPromise();
        this.notification.success('Sucesso', 'Informação atualizada com sucesso.');
        this.updateUserEvent.emit(this.completeUser);
        this.close();
      } catch (err) {
        console.error(err);
        this.notification.error('Erro', 'Erro ao atualizar informação');
      }
    }
  }

  close() {
    this.nzModalRef.close();
  }
}
