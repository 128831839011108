<fieldset class="w-100 mb-2" [formGroup]="form" theme>
  <legend>Paciente Pet</legend>

  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="nome">Nome do Pet<span class="mandatory"> *</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            nz-input
            placeholder="ex: Rex"
            formControlName="name"
            id="nome"
            maxlength="128"
            autocomplete="disabled"
          />
          <nz-form-explain *ngIf="form.get('name').dirty && form.get('name').errors">
            <ng-container *ngIf="form.get('name').hasError('required')">
              Campo obrigatório, por favor digite o nome e sobrenome.
            </ng-container>
            <ng-container
              *ngIf="!form.get('name').hasError('required') && form.get('name').hasError('nome')"
            >
              Por favor digite o nome e sobrenome.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="species">Espécie <span class="mandatory">*</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <select
            id="species"
            formControlName="species"
            nz-input
            class="capitalize"
            (change)="getRaces($event.target.value)"
          >
            <option *ngFor="let spec of species" [value]="spec.title">{{ spec.title }}</option>
          </select>
          <nz-form-explain *ngIf="form.get('species').dirty && form.get('species').errors">
            <ng-container *ngIf="form.get('species').hasError('required')">
              Campo obrigatório, por favor selecione o UF.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="race">Raça</nz-form-label>
        <nz-form-control nzHasFeedback>
          <select id="race" formControlName="race" nz-input class="capitalize">
            <option *ngFor="let race of races" [value]="race.title">{{ race.title }}</option>
          </select>
          <nz-form-explain *ngIf="form.get('race').dirty && form.get('race').errors">
            <ng-container *ngIf="form.get('race').hasError('required')">
              Campo obrigatório, por favor selecione a raça.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3 col-md-3 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="dateOfBirth">Data de nascimento</nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            nz-input
            type="date"
            formControlName="dateOfBirth"
            placeholder="DD/MM/AAAA"
            id="dateOfBirth"
            [max]="currentDate"
            theme
          />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="dateOfBirth">Idade Aproximada</nz-form-label>
        <nz-form-control nzHasFeedback>
          <span>aaa</span>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="sex">Sexo</nz-form-label>
        <nz-form-control nzHasFeedback>
          <select formControlName="sex" id="sex" nz-input nzPlaceHolder="Selecione">
            <option value="M">Masculino</option>
            <option value="F">Feminino</option>
          </select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="fur">Pelagem</nz-form-label>
        <nz-form-control nzHasFeedback>
          <select formControlName="sex" id="sex" nz-input nzPlaceHolder="Selecione">
            <option *ngFor="let fur of furs" [value]="fur.title" class="capitalize">{{
              fur.title
            }}</option>
          </select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3 col-md-3 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="approximateAge">Peso</nz-form-label>
        <nz-form-control nzHasFeedback>
          <input nz-input formControlName="weight" placeholder="5" id="weight" autocomplete="disabled" />

          <nz-form-explain *ngIf="form.get('weight').dirty && form.get('weight').errors">
            <ng-container *ngIf="form.get('weight').hasError('required')">
              Campo obrigatório, por favor digite data de nascimento.
            </ng-container>
            <ng-container
              *ngIf="!form.get('weight').hasError('required') && form.get('weight').hasError('weight')"
            >
              Nome inválido.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="approximateAge">Castração</nz-form-label>
        <nz-form-control nzHasFeedback>
          <select formControlName="castration" id="castration" nz-input nzPlaceHolder="Selecione">
            <option [ngValue]="true">Sim</option>
            <option [ngValue]="false">Não</option>
          </select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="temperament">Temperamento</nz-form-label>
        <nz-form-control nzHasFeedback>
          <select formControlName="temperament" id="temperament" nz-input nzPlaceHolder="Selecione">
            <option value="aggressive">Agressivo</option>
            <option value="fearful">Medroso</option>
            <option value="dominant">Dominante</option>
            <option value="hyperactive">Hiperativo</option>
            <option value="alert">Alerta</option>
            <option value="docile">Dócil</option>
          </select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="microchipNumber">Nº do Microchip</nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            nz-input
            formControlName="microchipNumber"
            placeholder="5"
            id="microchipNumber"
            autocomplete="disabled"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div class="row mb-3 mt-3 align-items-center" *ngIf="!isResponsible && consulted">
    <div class="col-12">
      <button class="ok-btn btn-cancel" nz-button (click)="changedNewRepnsible()">
        Alterar Responsável
      </button>
    </div>
  </div>

  <div class="row mb-3" *ngIf="!isResponsible && !consulted">
    <div class="col 12">
      <nz-divider nzOrientation="horizontal"></nz-divider>
    </div>
    <div class="col-12" [formGroup]="form.get('responsible')">
      <nz-form-item>
        <nz-form-label nzFor="cpfResponsible"
          >CPF do responsável <span class="mandatory">*</span></nz-form-label
        >
        <div class="row align-items-center">
          <div class="col-5">
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                id="cpfResponsible"
                placeholder="Digite o CPF"
                mask="000.000.000-00"
                formControlName="cpf"
                autocomplete="disabled"
              />
            </nz-form-control>
          </div>
          <button
            nz-button
            class="btn-primary"
            (click)="searchCpf()"
            [disabled]="loading || cpf.invalid || (cpf.errors && cpf.hasError('cpf'))"
          >
            <span *ngIf="!loading">OK</span>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              *ngIf="loading"
            ></span>
          </button>
        </div>
      </nz-form-item>
    </div>
  </div>
</fieldset>
