<div class="page-content">
  <app-navigation [brandBackGround]="false" navbarBackGround="bg-primary" title="Informações da Receita">
  </app-navigation>
  <section class="container-fluid">
    <div class="row">
      <div class="col sucessfull bg-green full-height">
        <h4 class="title text-white">
          Venda/retenção confirmada com sucesso!
        </h4>
        <div class="code text-white">
          <span class="title-2">CÓDIGO DE DISPENSAÇÃO</span>
          <span class="codeNumber">{{ retention?.dispensationCode }}</span>
        </div>
        <br />
        <div class="code text-white">
          <span class="title-2">CÓDIGO DA RECEITA</span>
          <span class="codeNumber">{{ retention?.prescription?.code }}</span>
        </div>
      </div>

      <div class="col data-col">
        <div class="toolbar bg-gray-black data-height text-white title">
          <span>DADOS PARA EXPORTAÇÃO SNGPC:</span>
          <br />
          <div class="row">
            <div class="col">
              <span>PACIENTE</span>
              <br />
              <div class="data">
                <span>{{ retention?.prescription?.patient?.name }}</span><br />                
                <span>
                  {{ retention?.prescription?.patient?.address.street}}, 
                  nº {{ retention?.prescription?.patient?.address.number}}
                  {{ retention?.prescription?.patient?.address.complement}},
                  {{ retention?.prescription?.patient?.address.city}}/
                  {{ retention?.prescription?.patient?.address.uf}}
                </span><br />
                <span>{{ getAgePatient()}} Anos</span> <br />
                <span>Gênero: {{ retention?.prescription?.patient?.sex === 'M' ? 'Masculino': 'Feminino'}}</span> <br />         
               
              </div>
            </div>
            <div class="col">
              <span>COMPRADOR</span>
              <br />
              <div class="data">
                <span>{{ buyer?.name }}</span><br />                
                <span>
                  {{ buyer?.address?.street}}, 
                  nº {{ buyer?.address?.number}}
                  {{ buyer?.address?.complement}},
                  {{ buyer?.address?.city}}/
                  {{ buyer?.address?.uf}}
                </span><br />
                <span *ngIf="buyer?.telephone">Tel: {{ buyer?.telephone | formattedTel }}<br /></span>
                <span *ngIf="buyer?.cellphone">Cel: {{ buyer?.cellphone | formattedCel }}<br /></span>
                <span>RG {{ buyer?.identity}}</span>
              </div>
            </div>

          </div> <br />         

          <span>PRESCRITOR</span>
          <br />
          <div class="data">
            <span>{{ getPreWriterInfo(retention?.prescription).name }}</span><br />
            <span>{{ getPreWriterInfo(retention?.prescription).regionalCouncil.label }}:
              {{ getPreWriterInfo(retention?.prescription).regionalCouncil.number }}/{{
                getPreWriterInfo(retention?.prescription).regionalCouncil.uf
              }}
            </span><br />
            <span>
              {{ retention?.prescription?.local?.address.street}}, 
              nº {{ retention?.prescription?.local?.address.number}}
              {{ retention?.prescription?.local?.address.complement}},
              {{ retention?.prescription?.local?.address.city}}/
              {{ retention?.prescription?.local?.address.uf}}
            </span><br /> 
            <span *ngIf="retention?.prescription?.healthProfessional.telephone">Tel: {{ retention?.prescription?.healthProfessional.telephone | formattedTel }}</span><br />
            <span *ngIf="retention?.prescription?.healthProfessional.cellphone">Cel: {{ retention?.prescription?.healthProfessional.cellphone | formattedCel }}</span>
           
          </div> <br />
        </div>

        <div class="listing">          
          <div class="text-black medicaments">MEDICAMENTOS</div>
          <div class="text-black medicaments" *ngFor="let medicament of retention?.medicaments">            
            <span class="title-2 name">{{ medicament.name }} - {{ medicament.principle }}</span>
            <span class="title-2 name">Posologia: {{ medicament.dosage }}</span>
            <div class="quantity text-white">
              <span class="title">QUANTIDADE</span>
              <span class="number">{{ medicament.quantityRetained }}</span>
            </div>
          </div>
        </div>

        <div class="btns">
          <div class="btn-medicament" *ngIf="retention?.prescription?.amountOfMedicines">
            <button class="btn btn-primary btn-md register-btn other-medicament" (click)="retainAgain()">
              SELECIONAR OUTRO MEDICAMENTO
            </button>
          </div>
          <div class="btn-prescription">
            <button class="btn btn-primary btn-md register-btn other-prescription" (click)="openModal()">
              SELECIONAR OUTRA RECEITA
            </button>
          </div>
          <div class="btn-prescription">
            <button
              class="btn btn-primary btn-md register-btn other-prescription"
              (click)="changeRoute('dashboard')"
            >
              FINALIZAR
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</div>
