import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  ViewEncapsulation
} from '@angular/core';
import { ColorSetting } from '@app/shared/models/color-setting';

@Component({
  selector: 'app-model-list-favorite-prescription',
  templateUrl: './model-list-favorite-document.component.html',
  styleUrls: ['./model-list-favorite-document.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModelListFavoriteDocumentComponent implements OnInit, AfterViewInit {
  @Input() documentsTemplate: any[];
  @Input() documentType: string;
  @Input() colorSetting: ColorSetting;
  @Input() hideEdit = false;
  @Output() selectEvent = new EventEmitter<any>();
  @Output() editEvent = new EventEmitter<any>();
  @Output() deleteEvent = new EventEmitter<number>();

  @ViewChild('modalHeader', { static: false }) modalHeader: HTMLDivElement;

  typesOfDocuments = ['Prescrição', 'Exame', 'Atestado', 'Outros'];

  constructor(public modalRef: BsModalRef) {}

  ngOnInit() {}

  ngAfterViewInit() {
    // this.modalHeader.style.backgroundColor = 'black';
  }

  activeChange(item) {
    item.active = !item.active;
  }

  onSelect(item) {
    this.selectEvent.emit(item);
    this.modalRef.hide();
  }

  onEdit(item) {
    this.editEvent.emit(item);
    this.modalRef.hide();
  }

  onDelete(item) {
    this.deleteEvent.emit(item.id);
  }

  getText(arrayText: any[]) {
    return arrayText.reduce((acc, val) => (acc ? `${acc}\n${val}` : val), '');
  }
}
