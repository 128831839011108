import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ThemeModule, themeMrd, themeReceitaDigital } from '@app/theme';
import { environment } from '@env/environment';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { ChartsModule } from 'ng2-charts';
import { NgxMaskModule } from 'ngx-mask';
import { MyPieChartComponent } from '../charts/my-pie-chart/my-pie-chart.component';
import { AddButtonComponent } from './add-button/add-button.component';
import { AutoWidthInputNumberComponent } from './auto-width-input-number/auto-width-input-number.component';
import { DialogModalComponent } from './dialog-modal/dialog-modal.component';
import { AddressDataFormComponent } from './form-data/address-data-form/address-data-form.component';
import { ContactsDataFormComponent } from './form-data/contacts-data-form/contacts-data-form.component';
import { PersonalDataFormComponent } from './form-data/personal-data-form/personal-data-form.component';
import { UnderageDataFormComponent } from './form-data/underage-data-form/underage-data-form.component';
import { InputFileComponent } from './input-file/input-file.component';
import { InputPasswordComponent } from './input-password/input-password.component';
import { MenuComponent } from './menu/menu.component';
import { ModalActivateAccountComponent } from './modal-activate-account/modal-activate-account.component';
import { PopoverButtonComponent } from './popover-button/popover-button.component';
import { RdButtomGroupIconComponent } from './rd-buttom-group-icon/rd-buttom-group-icon.component';
import { RdTabsComponent } from './rd-tabs/rd-tabs.component';
import { PetDataFormComponent } from './form-data/pet-data-form/pet-data-form.component';

@NgModule({
  declarations: [
    AutoWidthInputNumberComponent,
    MenuComponent,
    MyPieChartComponent,
    DialogModalComponent,
    InputFileComponent,
    ModalActivateAccountComponent,
    InputPasswordComponent,
    AddButtonComponent,
    PopoverButtonComponent,
    AddressDataFormComponent,
    ContactsDataFormComponent,
    PersonalDataFormComponent,
    UnderageDataFormComponent,
    RdButtomGroupIconComponent,
    RdTabsComponent,
    PetDataFormComponent
  ],
  exports: [
    AutoWidthInputNumberComponent,
    MenuComponent,
    MyPieChartComponent,
    DialogModalComponent,
    InputFileComponent,
    ModalActivateAccountComponent,
    InputPasswordComponent,
    AddButtonComponent,
    PopoverButtonComponent,
    AddressDataFormComponent,
    ContactsDataFormComponent,
    PersonalDataFormComponent,
    UnderageDataFormComponent,
    RdButtomGroupIconComponent,
    RdTabsComponent,
    PetDataFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    ChartsModule,
    RouterModule,
    NgxMaskModule.forRoot(),
    ThemeModule.forRoot({
      themes: [themeReceitaDigital, themeMrd],
      active: environment.template
    })
  ]
})
export class ComponentsModule {}
