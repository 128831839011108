import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PdfService } from '@app/modules/document/services/pdf.service';
import { PATIENT_URL } from '@app/modules/patients/services/patients.service';
import { Patient } from '@app/shared/models';
import { PdfData } from '@app/shared/models/pdf-data';
import { Prescription } from '@app/shared/models/prescription';
import { ThemeService } from '@app/theme';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export const PRESCRIPTION_URL = `prescription`;
export const PRESCRIPTION_PATIENT_URL = `prescription/patient`;
export const PRESCRIPTION_PRESCRIBER_URL = `prescription/prescriber`;
export const MEDICAMENT_URL = 'medicament';

@Injectable({ providedIn: 'root' })
export class PrescriptionService {
  private savedPatient = false;

  medicamentsRetained = [];
  prescription: any;
  pdfBase64: any;
  filename: string;

  constructor(
    private http: HttpClient,
    private pdfService: PdfService,
    private themeService: ThemeService
  ) {}

  private get theme() {
    return this.themeService.getActiveTheme().name;
  }

  save(prescription: any) {
    if (prescription.code) {
      return this.http.put(
        `${environment.apiRoot}${PRESCRIPTION_URL}/${prescription.code}`,
        prescription
      );
    }
    return this.http.post(`${environment.apiRoot}${PRESCRIPTION_URL}`, prescription);
  }

  getMedicationsPrescription(prescriptionCode) {
    return this.http
      .get(`${environment.apiRoot}${PRESCRIPTION_URL}/${prescriptionCode}/medicamentsAfterRetetion`)
      .toPromise()
      .then((res: any) => {
        return res.map(m => ({ ...m, valid: false }));
      });
  }

  getPrescriptionsPatient(param: string): Observable<Prescription[]> {
    return this.http.get<Prescription[]>(`${environment.apiRoot}${PRESCRIPTION_URL}/find/${param}`);
  }

  getPrescriptionsByPatientId(patientId: string, filter: any): Observable<any> {
    return this.http.get<any>(`${environment.apiRoot}${PRESCRIPTION_PATIENT_URL}/${patientId}`, {
      params: filter
    });
  }

  getPrescriptionsByPrescriberUserId(prescriber: string, filter: any): Observable<any> {
    return this.http.get<any>(`${environment.apiRoot}${PRESCRIPTION_PRESCRIBER_URL}/${prescriber}`, {
      params: filter
    });
  }

  getPrescription(prescriptionCode): Observable<Prescription> {
    return this.http.get<Prescription>(`${environment.apiRoot}${PRESCRIPTION_URL}/${prescriptionCode}`);
  }

  getPrescriptionbyCode(prescriptionCode): Observable<Prescription> {
    return this.http.get<Prescription>(
      `${environment.apiRoot}${PRESCRIPTION_URL}/code/${prescriptionCode}`
    );
  }

  getPrescriptions(): Observable<Prescription[]> {
    return this.http.get<Prescription[]>(`${environment.apiRoot}${PRESCRIPTION_URL}`);
  }

  getPrescriptionsByFilter(filter: any): Promise<Prescription[]> {
    return this.http
      .get<Prescription[]>(`${environment.apiRoot}${PRESCRIPTION_URL}/filter`, {
        params: filter
      })
      .toPromise();
  }

  getPatientPrescription(prescriptionCode): Observable<Patient> {
    return this.http.get<Patient>(
      `${environment.apiRoot}${PRESCRIPTION_URL}/${prescriptionCode}/patient`
    );
  }

  getPatients(payload: string): Observable<Patient[]> {
    payload = this.unMask(payload);
    const prefix = this.onlyNumbers(payload) ? 'cpf/' : 'name/';
    return this.http.get<Patient[]>(`${environment.apiRoot}${PATIENT_URL}/${prefix}${payload}`);
  }

  getPrescriptionByPdf(pdf: Blob) {
    const formData = new FormData();
    formData.append('pdf', pdf);
    return this.http
      .post<any>(`${environment.apiRoot}bundle`, formData)
      .pipe(
        tap(prescription => {
          this.prescription = prescription;
          this.blobToBase64(pdf).then(base64 => (this.pdfBase64 = base64));
        })
      )
      .toPromise();
  }

  postDispensation(dispensation: any) {
    return this.http.post(`${environment.apiRoot}bundle/dispensation`, dispensation).toPromise();
  }

  private unMask(value: string): string {
    return value.replace(/[\.\-]+/g, '');
  }

  private onlyNumbers(value: String): Boolean {
    return value.match(/^[0-9]+$/) != null;
  }

  confirm(retention, pharmacyId, code) {
    localStorage.setItem('medicamentsRetained', JSON.stringify(retention.medicamentsRetained));
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .put(
        `${environment.apiRoot}${PRESCRIPTION_URL}/${code}/retain`,
        { retention, pharmacyId },
        { headers }
      )
      .toPromise()
      .then(res => {
        return res;
      });
  }

  setSavedPatient(): void {
    this.savedPatient = true;
  }

  getSavedPatient(): boolean {
    return this.savedPatient;
  }

  setMedicamentsRetained(medicaments): void {
    this.medicamentsRetained = medicaments;
  }

  getMedicamentsRetained() {
    return this.medicamentsRetained;
  }

  async downloadPrescriptionPdf(code: string, secretCode = '') {
    const pdf = await this.pdfService.downloadPdfDocument(code, secretCode);
    this.pdfService.openPdf(pdf);
    return pdf;
  }

  async generatePdf(code: string) {
    const pdf = await this.pdfService.generatePdf(code, this.theme);
    this.pdfService.openPdf(pdf);
  }

  async downloadOrGeneratePdf(code: string) {
    let pdf: PdfData;
    try {
      pdf = await this.pdfService.downloadPdfDocument(code);
    } catch (err) {
      pdf = await this.pdfService.generatePdf(code, this.theme);
    }

    this.pdfService.openPdf(pdf);
  }

  delete(prescriptionId: number): Observable<any> {
    return this.http.delete(`${environment.apiRoot}${PRESCRIPTION_URL}/${prescriptionId}`);
  }

  blobToBase64(blob: Blob): Promise<any> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
}
