import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { AccountService } from '../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd';
import { markFormGroup } from '@app/utils/markFormGroup';
import { DialogModalComponent } from '@app/shared/components/dialog-modal/dialog-modal.component';
import { ModalActivateAccountComponent } from '@app/shared/components/modal-activate-account/modal-activate-account.component';
import { ThemeService } from '@app/theme';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent implements OnInit {
  token: string;
  loading: boolean;
  userType: string;
  passwordForm = new FormGroup({});
  isPasswordStrong = false;

  @ViewChild(ModalActivateAccountComponent, { static: true })
  professionalModal: ModalActivateAccountComponent;

  @ViewChild(DialogModalComponent, { static: true })
  dialogModal: DialogModalComponent;

  private validatePassword: ValidationErrors | null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private notification: NzNotificationService,
    private themeService: ThemeService
  ) {}

  ngOnInit() {
    this.validatePassword = (form: FormGroup) => {
      const { password, confirmPassword } = form.value;
      if (password !== confirmPassword) {
        form.get('confirmPassword').setErrors({ ...form.get('confirmPassword').errors, notEqual: true });
        return { notEqual: true };
      } else {
        form.get('confirmPassword').setErrors(null);
      }

      if (!this.isPasswordStrong) {
        return { weakPassword: true };
      }

      return null;
    };

    this.passwordForm = new FormGroup(
      {
        password: new FormControl('', [Validators.required, Validators.minLength(6)]),
        confirmPassword: new FormControl('', Validators.required)
      },
      this.validatePassword
    );

    this.activatedRoute.paramMap.subscribe(params => {
      this.token = params.get('token');
    });

    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.userType) {
        this.userType = queryParams.userType;
      }
    });
  }

  async activateAccount() {
    try {
      markFormGroup(this.passwordForm);
      if (this.passwordForm.valid) {
        this.loading = true;
        const token = this.token;
        const form = { token: token, ...this.passwordForm.value };
        await this.accountService.activateAccount(form);
        this.loading = false;
        this.notification.success('Conta ativada com sucesso', null);
        this.router.navigate([`/entry`]);
      }
    } catch (err) {
      this.loading = false;
      this.notification.error('Aviso', err.error.message);
    }
  }

  get isThemeActiveReceitaDigital() {
    const active = this.themeService.getActiveTheme();
    return active.name === 'theme-receita-digital';
  }
}
