import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PetPatient } from '@app/shared/models/pet-patient';
import { Race } from '@app/shared/models/races';
import { Species } from '@app/shared/models/species';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

export const PET_PATIENT_URL = 'petPatient';

@Injectable({
  providedIn: 'root'
})
export class PetPatientService {
  constructor(private http: HttpClient) {}

  getSpecies(): Observable<Species[]> {
    return this.http.get<Species[]>(`${environment.apiRoot}${PET_PATIENT_URL}/species`);
  }

  getRaces(speciesId): Observable<Race[]> {
    return this.http.get<Race[]>(`${environment.apiRoot}${PET_PATIENT_URL}/${speciesId}/races`);
  }

  save(patient: PetPatient): Observable<PetPatient> {
    return this.http.post<PetPatient>(`${environment.apiRoot}${PET_PATIENT_URL}`, patient);
  }
}
