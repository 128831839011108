import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from '@app/modules/user/user.service';
import { ColorSetting } from '@app/shared/models/color-setting';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-modal-sucess-send-document',
  templateUrl: './modal-sucess-send-document.component.html',
  styleUrls: ['./modal-sucess-send-document.component.scss']
})
export class ModalSucessSendDocumentComponent implements OnInit {
  @Output() closeModal = new EventEmitter<any>();
  isSuccessMsg = true;
  title: string;
  code: string;
  resend: boolean;
  colorSetting: ColorSetting;

  form: FormGroup;

  constructor(public modalRef: BsModalRef, private fb: FormBuilder, private userService: UserService) {}

  ngOnInit() {
    if (this.resend) {
      this.setTitle();
    } else {
      this.form = this.fb.group({
        doNotShowAgainModalSuccessPrescription: [false]
      });
      this.listenDoNotShowAgainModalSuccessPrescription();
    }
  }
  setTitle() {
    switch (this.code[0]) {
      case 'P':
      case 'I':
        this.title = `Receita ${this.resend ? 're' : ''}enviada com sucesso!`;
        break;
      case 'A':
        this.title = `Atestado ${this.resend ? 're' : ''}enviado com sucesso!`;
        break;
      case 'E':
        this.title = `Exame ${this.resend ? 're' : ''}enviado com sucesso!`;
        break;
      case 'O':
      default:
        this.title = `Documento ${this.resend ? 're' : ''}enviado com sucesso!`;
    }
  }

  close(): void {
    this.modalRef.hide();
    this.closeModal.emit();
  }

  listenDoNotShowAgainModalSuccessPrescription() {
    this.form
      .get('doNotShowAgainModalSuccessPrescription')
      .valueChanges.pipe(
        switchMap(value => this.userService.checkUiDoNotShowAgainModalSuccessPrescription(value))
      )
      .subscribe();
  }
}
