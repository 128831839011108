import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-dialog-modal',
  templateUrl: './dialog-modal.component.html',
  styleUrls: ['./dialog-modal.component.scss']
})
export class DialogModalComponent implements OnInit {
  tplModal: NzModalRef;

  @Input() title?: string;
  @Input() subtitle?: string;

  @ViewChild('tplContent', { static: true })
  tplContent: TemplateRef<{}>;

  @ViewChild('tplFooter', { static: true })
  tplFooter: TemplateRef<{}>;

  constructor(private modalService: NzModalService) {}

  ngOnInit() {}

  createTplModal(title, subtitle): void {
    this.title = title;
    this.subtitle = subtitle;
    this.tplModal = this.modalService.create({
      nzContent: this.tplContent,
      nzFooter: this.tplFooter,
      nzClosable: false,
      nzWidth: 650,
      nzMaskClosable: false,
      nzOnCancel: () => {},
      nzOnOk: () => console.log('Click ok')
    });
  }

  destroyTplModal(): void {
    this.tplModal.destroy();
  }
}
