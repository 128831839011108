import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { CoreModule } from '@app/core';
import { numeroEspecial } from '@app/shared/pipes/helpers';
import { IsCompleteNamePipe } from '@app/shared/pipes/is-complete-name-pipe.pipe';
import { IsTelPipe } from '@app/shared/pipes/is-tel.pipe';
import { OnlyNumbersPipe } from '@app/shared/pipes/only-numbers.pipe';
import * as moment from 'moment';

@Injectable({
  providedIn: CoreModule
})
export class ValidationService {
  constructor() {}

  static nomeValidator(control: FormControl): { [key: string]: boolean } | null {
    if (control.value === '' || new IsCompleteNamePipe().transform(control.value)) {
      return null;
    } else {
      return { nome: true };
    }
  }

  static telefoneValidator(control: FormControl): { [key: string]: boolean } | null {
    console.log('Telefone: ', control.value);
    if (control.value === '') {
      return null;
    }
    if (numeroEspecial(control.value) && new OnlyNumbersPipe().transform(control.value).length >= 8) {
      return null;
    } else if (new IsTelPipe().transform(control.value)) {
      return null;
    } else {
      return { telefone: true };
    }
  }

  static celularValidator2(control: FormControl): { [key: string]: boolean } | null {
    if (control.value === '') {
      return null;
    }
    if (numeroEspecial(control.value) && new OnlyNumbersPipe().transform(control.value).length >= 8) {
      return null;
    } else if (new IsTelPipe().transform(control.value)) {
      return null;
    } else {
      return { celular: true };
    }
  }

  static celularValidator(control: FormControl): { [key: string]: boolean } | null {
    if (control.value === '') {
      return null;
    }
    if (new OnlyNumbersPipe().transform(control.value).length === 11) {
      return null;
    } else {
      return { celular: true };
    }
  }

  static passwordConfirmationValidator(control: FormControl): { [key: string]: boolean } | null {
    if (control.get('password').value === control.get('passwordConfirmation').value) {
      return null;
    }
    control.get('passwordConfirmation').setErrors({ doNotMatch: true });
  }

  static cepValidator(control: FormControl): { [key: string]: boolean } | null {
    if (!control.value || new OnlyNumbersPipe().transform(control.value).length === 8) {
      return null;
    }
    return { cep: true };
  }

  static emailMatch(control: FormControl): { emailMatch: boolean } {
    const email = control.get('email');
    const emailConfirmation = control.get('emailConfirmation');
    if (email && emailConfirmation) {
      const match = email.value === emailConfirmation.value;
      const errors = emailConfirmation.errors || {};
      let error = null;

      if (match) {
        delete errors.emailMatch;
        error = errors;
      } else {
        error = { ...errors, emailMatch: true };
      }

      if (Object.entries(error).length === 0) {
        error = null;
      }

      if (emailConfirmation.dirty) {
        emailConfirmation.setErrors(error);
      }

      return error;
    }

    return null;
  }

  static mandatoryContact(control: FormControl): { emailMatch: boolean } {
    if (control.get('cellphone').value !== '' || control.get('telephone').value !== '') {
      control.get('cellphone').setErrors(null);
      control.get('telephone').setErrors(null);

      return null;
    }
    control.get('cellphone').setErrors({ mandatoryContact: true });
    control.get('telephone').setErrors({ mandatoryContact: true });
  }

  static cpfValidator({ value }: FormControl): { [key: string]: boolean } | null {
    let Soma;
    let Resto;
    Soma = 0;
    const strCPF = value && value.replace(/[^0-9]+/g, '');
    if (!strCPF) {
      return null;
    }
    if (
      strCPF === '00000000000' ||
      strCPF === '11111111111' ||
      strCPF === '22222222222' ||
      strCPF === '33333333333' ||
      strCPF === '44444444444' ||
      strCPF === '55555555555' ||
      strCPF === '66666666666' ||
      strCPF === '77777777777' ||
      strCPF === '88888888888' ||
      strCPF === '99999999999'
    ) {
      return { cpf: true };
    }

    for (let i = 1; i <= 9; i++) {
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    }
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) {
      Resto = 0;
    }
    if (Resto !== parseInt(strCPF.substring(9, 10))) {
      return { cpf: true };
    }

    Soma = 0;
    for (let i = 1; i <= 10; i++) {
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    }
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) {
      Resto = 0;
    }
    if (Resto !== parseInt(strCPF.substring(10, 11))) {
      return { cpf: true };
    }
    return null;
  }

  static cnpjValidator(control: FormControl): { [key: string]: boolean } | null {
    return ValidationService.isCnpjValid(control.value) ? null : { cnpj: true };
  }

  static isCnpjValid(value) {
    if (!value) return false;

    // Aceita receber o valor como string, número ou array com todos os dígitos
    const isString = typeof value === 'string';
    const validTypes = isString || Number.isInteger(value) || Array.isArray(value);

    // Elimina valor em formato inválido
    if (!validTypes) return false;

    // Filtro inicial para entradas do tipo string
    if (isString) {
      // Limita ao máximo de 18 caracteres, para CNPJ formatado
      if (value.length > 18) return false;

      // Teste Regex para veificar se é uma string apenas dígitos válida
      const digitsOnly = /^\d{14}$/.test(value);
      // Teste Regex para verificar se é uma string formatada válida
      const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value);

      // Se o formato é válido, usa um truque para seguir o fluxo da validação
      if (digitsOnly || validFormat) true;
      // Se não, retorna inválido
      else return false;
    }

    // Guarda um array com todos os dígitos do valor
    const match = value.toString().match(/\d/g);
    const numbers = Array.isArray(match) ? match.map(Number) : [];

    // Valida a quantidade de dígitos
    if (numbers.length !== 14) return false;

    // Elimina inválidos com todos os dígitos iguais
    const items = Array.from(new Set(numbers));
    if (items.length === 1) return false;

    // Cálculo validador
    const calc = x => {
      const slice = numbers.slice(0, x);
      let factor = x - 7;
      let sum = 0;

      for (let i = x; i >= 1; i--) {
        const n = slice[x - i];
        sum += n * factor--;
        if (factor < 2) factor = 9;
      }

      const result = 11 - (sum % 11);

      return result > 9 ? 0 : result;
    };

    // Separa os 2 últimos dígitos de verificadores
    const digits = numbers.slice(12);

    // Valida 1o. dígito verificador
    const digit0 = calc(12);
    if (digit0 !== digits[0]) return false;

    // Valida 2o. dígito verificador
    const digit1 = calc(13);
    return digit1 === digits[1];
  }

  static dateValidator({ format = 'DDMMYYYY', max = new Date() } = {}): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value.length !== format.length) {
        return { invalidDate: true };
      }

      const date = moment(control.value, format);
      if (!date.isValid()) {
        return { invalidDate: true };
      }

      if (max && max.getTime() < date.toDate().getTime()) {
        return { invalidDate: true };
      }

      return null;
    };
  }

  static minLengthArray(min: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } => {
      if (c.value.length >= min) {
        return null;
      }

      return { minLengthArray: true };
    };
  }

  static printInvalidFields(form: FormGroup) {
    const arr = Object.entries(form.controls)
      .map(e => ({ entry: e[0], value: e[1].valid }))
      .filter(e => !e.value);
    console.log(arr);
  }

  static atLeastOne(formArray: FormArray): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      return !formArray.controls.length ? { required: true } : null;
    };
  }
}
