import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { Paciente } from '../models/paciente';

const API_UL = `${environment.apiRoot}bigboost`;

@Injectable({
  providedIn: 'root'
})
export class BigboostService {
  constructor(private http: HttpClient) {}

  consultCpf(cpf: string, birthdate?: string): Observable<Paciente> {
    const params = birthdate ? { birthdate } : {};
    return this.http.get<Paciente>(`${API_UL}/cpf/${cpf}`, { params });
  }
}
