<nz-modal
  theme
  class="modal-cip"
  [nzTitle]="title"
  [(nzVisible)]="isVisible"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  [nzStyle]="{ top: '20px' }"
  nzWidth="800"
  nzKeyboard="false"
  nzMaskClosable="false"
  nzClosable="false"
>
  <ng-template #modalContent>
    <p class="bold">RESUMO</p>
    <p>
      O Projeto Piloto SERP tem o objetivo de centralizar a troca de informações de Prescrições e
      Dispensações Digitais.
    </p>
    <p>
      Para isso precisamos da sua autorização para compartilhar seus dados de identificação com as
      instituições que estão participando deste projeto.
    </p>
    <br />
    <p class="bold">Quais dados vamos compartilhar?</p>
    <ol>
      <li>Nome</li>
      <li>CPF</li>
      <li>CRF/UF</li>
    </ol>
    <br />
    <p class="bold">Com quais instituições vamos compartilhar?</p>
    <ul>
      <li>CIP (A centralizadora)</li>
      <li>Prontmed (plataforma de prontuários eletrônicos)</li>
    </ul>

    <p>Confira abaixo o Termo de Consentimento <span class="bold">na íntegra!</span></p>

    <hr />

    <h4 class="title">Termo de Consentimento para Tratamento de Dados</h4>
    <h5 class="subtitle">- Projeto Piloto para emissão da Receita Digital -</h5>

    <p class="bold">Olá, seja bem-vindo!</p>
    <p>
      A <span class="bold">Receita Digital</span> é uma empresa inovadora, que transforma o
      relacionamento entre médicos, dentistas, pacientes e farmácias em um processo simples, prático e
      ágil, através de tecnologia.
    </p>
    <p>
      Nossa Plataforma é estruturada para oferecer a melhor solução em prescrição, gestão e dispensação
      de receitas digitais no mercado.
    </p>
    <p>
      Para que possamos desenvolver e continuar estruturando melhorias em nossa Plataforma, convidamos
      você, farmacêutico, para participar do nosso projeto piloto de registro centralizado de prescrições
      médicas e odontológicas e venda de medicamentos.
    </p>
    <p>
      Ao aceitar os termos desse documento, você concordará que a RECEITA DIGITAL realize o tratamento de
      seus dados pessoais, no que se referem a coleta, produção, recepção, classificação, utilização,
      acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento,
      eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão
      ou extração.
    </p>
    <p>
      Por isso, pedimos que leia atentamente este documento e, em caso de dúvida, contate-nos através do
      e-mail privacidade@receitadigital.com ou consulte nossa Política de Privacidade.
    </p>
    <p>
      Salientamos que a participação do projeto não é obrigatória, mas será um prazer imenso contar com
      você na nossa jornada para promover a dispensação de medicamentos de forma ainda mais prática e
      segura!
    </p>

    <h5 class="subtitle">- Termos Gerais -</h5>

    <p class="bold">1. Sobre o que se trata o projeto?</p>
    <p>
      1.1. A Receita Digital disponibilizará na sua farmácia uma interface inovadora, onde será possível
      que você, farmacêutico responsável pelo atendimento, tenha acesso à prescrição de medicamentos de
      forma online (ou seja, sem a necessidade de qualquer documento físico ou preenchimento de
      formulários), com poucos cliques.
    </p>
    <p>Como vai funcionar?</p>
    <p>
      1.2. O médico irá emitir a receita médica do paciente, em formato digital, através do sistema do
      parceiro Prontmed, que é uma plataforma especializada em prontuários médicos, e encaminhará este
      arquivo digital à CIP (Câmara Interbancária de Pagamentos), que centralizará e armazenará os dados
      e arquivos desta prescrição eletrônica.
    </p>
    <p>
      1.3. Você, farmacêutico, realizará a validação e autenticação das informações desta receita, de
      acordo com a legislação vigente. Caso efetue a venda de algum medicamento, você coletará e validará
      os dados do comprador, através de apresentação do documento de identificação apresentado, e
      registrará as informações de dispensação do medicamento em nosso sistema.
    </p>
    <p>
      1.4. A Receita Digital, para isso, informará à CIP quando houver registro de compra de
      medicamentos, para que outros estabelecimentos, acessem seu histórico de dispensação e tomem a
      melhor decisão de acordo com a legislação vigente e melhores práticas de atendimento farmacêutico.
      A CIP, por sua vez, irá informar a Prontmed (e, com isso, o médico responsável), que o paciente
      adquiriu o medicamento, auxiliando no acompanhamento do tratamento.
    </p>
    <p>
      1.5. Ou seja, trata-se de uma operação que trará um maior controle na dispensação de medicamentos,
      pois você terá mais segurança de que a prescrição expedida está sendo corretamente utilizada pelo
      Usuário solicitante, bem como dispensará o uso de receitas médicas em papel, evitando o extravio,
      vazamento e perda das informações. Você, profissional, terá muitos benefícios!
    </p>
    <p>
      1.6. Para que seja possível a realização do projeto, portanto, será necessária a coleta e
      armazenamento de dados pessoais, nos termos abaixo explicitados.
    </p>
    <p class="bold">2. Tipos de Dados Pessoais Coletados:</p>
    <p>
      2.1. Ao concordar com esse termo, você autorizará que a Receita Digital utilize os seguintes dados
      pessoais:
    </p>
    <ol>
      <li>Nome completo;</li>
      <li>CPF;</li>
      <li>Data de nascimento;</li>
      <li>Sexo;</li>
      <li>Filiação;</li>
      <li>E-mail;</li>
      <li>CRF;</li>
      <li>Informações sobre os estabelecimentos ao qual está vinculado.</li>
    </ol>
    <p class="bold">3. Finalidades do Tratamento dos Dados</p>
    <p>3.1. O tratamento dos dados pessoais será realizado considerando as seguintes finalidades:</p>
    <ul>
      <li>Registrar atendimentos;</li>
      <li>Dispensar medicamentos;</li>
      <li>
        Impossibilitar a utilização da prescrição médica em prazo maior do que permitido pela legislação
        vigente ou em quantidades incompatíveis com o tratamento prescrito;
      </li>
      <li>Cumprimento de exigências legais pelos órgãos reguladores;</li>
      <li>Validar e autenticar o acesso.</li>
    </ul>
    <p>
      3.2. Os dados tratados não serão utilizados pela Receita Digital ou por seus parceiros para outras
      finalidades que não seja a descrita no item 3.1., tampouco compartilhar dados desnecessários para
      essa finalidade.
    </p>

    <p class="bold">4. Compartilhamento de Dados Pessoais</p>
    <p>
      4.1. Ao concordar com os termos do termo, você autoriza a Receita Digital a compartilhar os dados
      pessoais com os agentes de tratamento descritos acima, os seguintes dados pessoais:
      <span class="bold">(I)</span> Nome completo; <span class="bold">(II)</span> C.P.F.;
      <span class="bold">(III)</span> Número do Registro no Conselho Regional de Farmácia. Os demais
      dados permanecerão apenas no Banco de Dados da Receita Digital.
    </p>
    <p>
      4.2. No que se refere à análise das informações sobre como seus dados trafegaram pelo processo
      descrito no item 1, seus dados serão tratados por:
    </p>
    <table style="width:100%">
      <tr>
        <th>Nome da Empresa</th>
        <th>CNPJ</th>
        <th>Endereço</th>
        <th>Contato</th>
      </tr>
      <tr>
        <td>Prontmed</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>CIP</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>Receita Digital</td>
        <td>34.307.169/0001-00</td>
        <td>Rua Mem de Sá, 167, Icaraí, Niterói/RJ</td>
        <td></td>
      </tr>
    </table>

    <p class="bold">5. Segurança dos Dados</p>
    <p>
      5.1. A Receita Digital e os parceiros do projeto, se responsabilizam pela manutenção de medidas de
      segurança, técnicas e administrativas aptas a proteger os dados pessoais de acessos não autorizados
      e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer
      forma de tratamento inadequado ou ilícito.
    </p>
    <p class="bold">6. Término do Tratamento dos Dados -</p>
    <p>
      6.1. O titular está ciente que os Controladores dados poderão manter e tratar os dados pessoais do
      Titular durante todo o período em que forem pertinentes para o projeto, de acordo com as
      finalidades listadas, da seguinte forma:
    </p>

    <table style="width:100%;">
      <tr>
        <th>Nome da Empresa</th>
        <th>Período de Retenção</th>
      </tr>
      <tr>
        <td>Prontmed</td>
        <td>Enquanto houver a prestação de serviços ao médico conveniado.</td>
      </tr>
      <tr>
        <td>CIP</td>
        <td>Até o encerramento do projeto piloto.</td>
      </tr>
      <tr>
        <td>Receita Digital</td>
        <td>Por até 5 anos, de acordo com a legislação vigente.</td>
      </tr>
    </table>

    <p>
      6.2. Dados pessoais anonimizados, sem possibilidade de associação ao indivíduo, poderão ser
      mantidos por período indefinido.
    </p>
    <p>
      6.3. Você poderá solicitar através do e-mail privacidade@receitadigital.com a qualquer momento, que
      sejam eliminados os dados pessoais não anonimizados, desde que não haja base legal para retenção
      dos dados pessoais.
    </p>
    <p class="bold">7. Direitos do Titular</p>
    <p>
      7.1. O Titular tem direito a obter da Controladora, em relação aos dados por ele tratados, a
      qualquer momento e mediante requisição, através do e-mail privacidade@receitadigital.com, os
      direitos previstos no artigo 18 da Lei Geral de Proteção de Dados, a saber: I - confirmação da
      existência de tratamento; II - acesso aos dados; III - correção de dados incompletos, inexatos ou
      desatualizados; IV - anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou
      tratados em desconformidade com o disposto na Lei nº 13.709; V - portabilidade dos dados a outro
      fornecedor de serviço ou produto, mediante requisição expressa e observados os segredos comercial e
      industrial, de acordo com a regulamentação do órgão controlador; V - portabilidade dos dados a
      outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a
      regulamentação da autoridade nacional, observados os segredos comercial e industrial; VI -
      eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas hipóteses
      previstas no art. 16 da Lei nº 13.709; VII - informação das entidades públicas e privadas com as
      quais o controlador realizou uso compartilhado de dados; VIII - informação sobre a possibilidade de
      não fornecer consentimento e sobre as consequências da negativa; IX - revogação do consentimento,
      nos termos do § 5º do art. 8º da Lei nº 13.709.
    </p>
    <p class="bold">8. Direito de Revogação do Consentimento</p>
    <p>
      8.1. Este consentimento poderá ser revogado pelo Titular, a qualquer momento, mediante solicitação
      via e-mail ao encarregado de proteção de dados.
    </p>
    <p>Rio de Janeiro, 04 de novembro de 2021.</p>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="close()">
      <span *ngIf="!isAnswered">Agora não</span>
      <span *ngIf="isAnswered">Cancelar</span>
    </button>
    <button
      nz-button
      nzType="danger"
      (click)="refuse()"
      [nzLoading]="refuseLoading"
      [disabled]="acceptLoading"
    >
      <span *ngIf="!isAnswered">Recusar</span>
      <span *ngIf="isAnswered">Revogar</span>
    </button>
    <button
      nz-button
      nzType="primary"
      (click)="accept()"
      [nzLoading]="acceptLoading"
      [disabled]="refuseLoading"
    >
      Aceitar
    </button>
  </ng-template>
</nz-modal>
