<nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate" nzSize="large">
  <input
    nz-input
    [type]="isPasswordVisible ? 'text' : 'password'"
    [placeholder]="placeholder"
    [formControlName]="formControlName"
    [attr.id]="formControlName"
    theme
  />
</nz-input-group>
<ng-template #suffixTemplate>
  <i
    nz-icon
    [nzType]="isPasswordVisible ? 'eye-invisible' : 'eye'"
    (click)="isPasswordVisible = !isPasswordVisible"
  ></i>
</ng-template>
