<!--Dados de Endereço-->
<fieldset class="w-100 mb-2" [formGroup]="form" theme>
  <legend>Dados de Endereço</legend>
  <nz-alert
    class="mb-4"
    nzType="warning"
    [nzMessage]="alertMsg"
    *ngIf="viewAlertMsg && !isPet"
  ></nz-alert>
  <div class="row">
    <div class="col-12 col-md-12 col-xl-2">
      <nz-form-item>
        <nz-form-label nzFor="cep">CEP <span class="mandatory" *ngIf="required">*</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            nz-input
            id="cep"
            formControlName="cep"
            placeholder="00.000-000"
            mask="00.000-000"
            (keyup)="onKey($event)"
            autocomplete="disabled"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="logradouro"
          >Logradouro <span class="mandatory" *ngIf="required">*</span></nz-form-label
        >
        <nz-form-control nzHasFeedback>
          <input
            formControlName="street"
            id="street"
            nz-input
            placeholder="Digite o logradouro"
            maxlength="128"
            autocomplete="disabled"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="number"
          >Número <span class="mandatory" *ngIf="required">*</span></nz-form-label
        >
        <nz-form-control nzHasFeedback>
          <input
            formControlName="number"
            id="number"
            nz-input
            placeholder="Digite o número"
            maxlength="10"
            autocomplete="disabled"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="complement">Complemento</nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            formControlName="complement"
            id="complement"
            nz-input
            placeholder="Digite o complemento"
            maxlength="128"
            autocomplete="disabled"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-4 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="neighborhood"
          >Bairro <span class="mandatory" *ngIf="required">*</span></nz-form-label
        >
        <nz-form-control nzHasFeedback>
          <input
            formControlName="neighborhood"
            id="neighborhood"
            nz-input
            placeholder="Digite o bairro"
            maxlength="128"
            autocomplete="disabled"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="city"
          >Cidade <span class="mandatory" *ngIf="required">*</span></nz-form-label
        >
        <nz-form-control nzHasFeedback>
          <input
            formControlName="city"
            id="city"
            nz-input
            placeholder="Digite a cidade"
            maxlength="64"
            autocomplete="disabled"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="uf">UF <span class="mandatory" *ngIf="required">*</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <select
            id="uf"
            formControlName="uf"
            nz-input
            nzAllowClear
            nzShowSearch
            nzPlaceHolder="Selecione"
          >
            <option *ngFor="let state of states" [value]="state?.sigla">{{ state?.sigla }}</option>
          </select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</fieldset>
