<section class="section-hero section-header">
  <div class="container d-flex align-items-center justify-content-center flex-column">
    <a routerLink="/" class="brand-bg">
      <img
        [ngStyle]="
          isThemeActiveReceitaDigital()
            ? {
                position: 'relative',
                top: '-12px'
              }
            : { position: 'relative', top: '-48px' }
        "
        [src]="
          isThemeActiveReceitaDigital() ? 'assets/img/logo-01.jpg' : 'assets/img/mrd/logo-com-texto.png'
        "
        class="img-fluid"
        alt="Logo Receita Digital"
      />
    </a>
    <h1 class="mt-5">
      Recupere sua senha
    </h1>
  </div>
  <div class="section-footer p-3 d-flex align-items-center justify-content-center">
    <h5>Digite o e-mail cadastrado para recuperar sua senha.</h5>
  </div>
</section>
<section class="section container d-flex justify-content-center align-items-center">
  <div class="w-46 ml-5 d-flex flex-column">
    <form nz-form [formGroup]="form">
      <div class="row">
        <div class="col-md-11">
          <nz-form-item class="mb-4">
            <nz-form-control nzHasFeedback>
              <input nz-input type="email" placeholder="E-mail" formControlName="accountField" />
              <nz-form-explain *ngIf="form.get('accountField').dirty && form.get('accountField').errors">
                <ng-container>
                  E-mail inválido, por favor digite um e-mail válido.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-md-11">
          <div class="row">
            <div class="col-sm-12 col-md-5 d-flex align-items-center">
              <button routerLink="/entry/" class="btn btn-back">Voltar</button>
            </div>
            <div class="col-sm-12 col-md-7 d-flex justify-content-end">
              <button (click)="sendEmail()" class="btn btn-success">Prosseguir</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
