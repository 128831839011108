import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class PatientRecordService {
  constructor(private http: HttpClient) {}

  getTags() {
    return this.http.get(`${environment.apiRoot}tags`);
  }

  getRecords(patientId: string, skip: number, limit: number) {
    const params = { patientId, skip, limit } as any;
    return this.http.get(`${environment.apiRoot}patient-records`, { params });
  }

  submitRecord(record: any) {
    return this.http.post(`${environment.apiRoot}patient-records`, record);
  }

  updateRecord(record: any) {
    return this.http.patch(`${environment.apiRoot}patient-records/${record._id}`, record);
  }

  deleteRecord(id: string) {
    return this.http.delete(`${environment.apiRoot}patient-records/${id}`);
  }

  mergeRecords(records: any[], principalTag: string) {
    return this.http.post(`${environment.apiRoot}patient-records/merge`, { records, principalTag });
  }
}
