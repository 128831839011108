<div class="record-card">
  <div *ngIf="loading" class="loading-area">
    <ng-template #indicatorTemplate>
      <i nz-icon nzType="loading"></i>
    </ng-template>
    <nz-spin nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
  </div>
  <div class="record" [ngClass]="{ 'opacity-record': loading }">
    <div class="info-area" *ngIf="!showHistory">
      <div class="infos">
        <ng-container *ngIf="!record.tag && !record.editable">
          <!-- titulo para registro de sistema -->
          <span class="gray"
            ><span class="title"> Sistema</span> {{ record.createdAt | date: 'dd/MM/yyyy HH:mm ' }}:
            {{ record.content }}</span
          >
        </ng-container>

        <ng-container *ngIf="record.tag">
          <span class="title">{{
            record.tag.parentId ? record.tag.parentId.description : record.tag.description
          }}</span
          >&nbsp;
          <span class="date">{{ record.createdAt | date: 'dd/MM/yyyy HH:mm' }}</span>
        </ng-container>

        <ng-container *ngIf="!record.tag">
          <span class="title">-</span>&nbsp;
          <span class="date">{{ record.createdAt | date: 'dd/MM/yyyy HH:mm' }}</span>
        </ng-container>
      </div>
      <i class="fas fa-angle-down show-icon" *ngIf="!merging" (click)="showOptions()"></i>
      <label
        nz-checkbox
        *ngIf="merging && record.editable"
        [(ngModel)]="checked"
        (ngModelChange)="selectRecordToMerge($event)"
      ></label>
    </div>

    <span class="content" *ngIf="!editing && !showHistory">{{
      record.tag && record.tag.parentId
        ? getIndex(record.tag._id) + ' - ' + record.tag.description
        : record.content
    }}</span>

    <div *ngIf="editing" class="edit-area">
      <input nz-input type="text" [(ngModel)]="record.content" />
      <div class="btns">
        <button
          type="button"
          class="btn btn-icon btn-success btn-add text-capitalize btn-save"
          (click)="updateRecord()"
        >
          <span class="btn-inner--text">Salvar</span>
        </button>

        <button
          type="button"
          class="btn btn-icon btn-success btn-add text-capitalize btn-save cancel-btn"
          (click)="editRecord()"
        >
          <span class="btn-inner--text">Cancelar</span>
        </button>
      </div>
    </div>

    <div *ngIf="showHistory" class="history-area">
      <i class="fas fa-times close-icon" (click)="setShowHistory()"></i>
      <ng-container *ngFor="let historic of record.historic">
        <div>
          <span> {{ historic.actionType == 'created' ? ' Registro criado em ' : 'Editado em ' }}</span>
          <span>{{ historic.date | date: 'dd/MM/yyyy HH:mm' }}</span>
        </div>
      </ng-container>
    </div>

    <!-- menu - registro -->
    <div class="menu backgroun-gray" *ngIf="show">
      <i
        class="fas fa-hashtag option"
        title="Editar Tag"
        (click)="editTag()"
        *ngIf="record.editable"
      ></i>
      <i
        class="fas fa-pen option"
        title="Editar texto do registro"
        (click)="editRecord()"
        *ngIf="record.editable"
      ></i>
      <i class="fas fa-trash option" title="Excluir registro" (click)="deleteRecord()"></i>
      <div (click)="mergeRecords()" *ngIf="record.editable">
        <i class="fas fa-chevron-right"></i>
        <i class="fas fa-chevron-left"></i>
      </div>
      <i class="fas fa-history option" title="Histórico" (click)="setShowHistory()"></i>
    </div>
  </div>

  <nz-modal
    [(nzVisible)]="editingTag"
    nzTitle="Editar Tag"
    (nzOnCancel)="handleCancel('editingTag')"
    (nzOnOk)="handleOk('editingTag')"
  >
    <p>{{ record.tag ? 'Tag atual: ' + record.tag.description : 'Registro sem tag' }}</p>

    <p>Escolha a nova tag:</p>
    <div class="pointer" *ngFor="let tag of tags; let i = index" (click)="changeTagConfirmation(tag)">
      <span *ngIf="tag.children.length == 0">{{ i + 1 }}. {{ tag.name }}: {{ tag.description }}</span>
    </div>
  </nz-modal>

  <ng-container *ngIf="showTagConfirmation">
    <nz-modal
      [(nzVisible)]="showTagConfirmation"
      nzTitle="Nova tag"
      (nzOnCancel)="handleCancel('showTagConfirmation')"
      (nzOnOk)="changeTag()"
    >
      <div *ngIf="updatingTag" class="loading-area modal-loading">
        <ng-template #indicatorTemplate>
          <i nz-icon nzType="loading"></i>
        </ng-template>
        <nz-spin nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
      </div>

      <div [ngClass]="{ 'opacity-record': updatingTag }">
        <p>
          Definir nova tag como: <span class="bold">{{ newTag.name }} - {{ newTag.description }}</span
          >?
        </p>
      </div>
    </nz-modal>
  </ng-container>
</div>
