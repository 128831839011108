import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: InputFileComponent, multi: true }]
})
export class InputFileComponent implements OnInit, ControlValueAccessor {
  @Input() accept = '';
  @Input() textButton = '';
  @Input() transformTo = '';
  @Input() maxSize: number = null;
  @Input() placeholder = 'Arquivo não selecionado';

  @Input()
  set file(file: any) {
    if (file) {
      this.form.get('file').setValue(file);
    }
  }

  @Input() filename = '';
  @Output() filenameChange = new EventEmitter<string>();

  form: FormGroup = new FormGroup({
    file: new FormControl('')
  });

  @ViewChild('38fn57dfjek3', { static: false })
  input: ElementRef;

  constructor(private notification: NzNotificationService) {}

  ngOnInit() {}

  registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe(value => fn(value.file));
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {}

  writeValue(value: any): void {
    if (value) {
      this.form.setValue({ file: value });
    } else {
      this.reset();
      this.filename = '';
    }
  }

  reset() {
    if (this.input) {
      this.input.nativeElement['value'] = null;
    }
  }

  public onChange(event) {
    const files = event.target.files;
    if (files.length === 0) {
      return;
    }

    const file = files[0];
    this.filename = file.name;

    if (this.accept != null) {
      const accepts = this.accept.split(',');
      const result = accepts.filter(accept => new RegExp(accept.replace('*', '.*')).test(file.type));
      if (result.length === 0) {
        this.notification.warning('Aviso', `Somente arquivo com extensão ${this.accept} é permitido`);
        return false;
      }
    }

    if (this.maxSize != null && file.size > this.maxSize) {
      this.notification.warning('Aviso', `O arquivo não pode ser maior que ${this.maxSize} bytes`);
      return false;
    }

    if (this.transformTo) {
      const reader = new FileReader();
      reader.onload = e => this.form.setValue({ file: reader.result });
      switch (this.transformTo) {
        case 'base64':
          reader.readAsDataURL(file);
          break;
        default:
          reader.readAsText(file);
      }
    } else {
      this.form.setValue({ file });
    }

    this.filenameChange.emit(this.filename);
  }

  openChooseFile() {
    this.input.nativeElement.click();
  }
}
