<header>
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <a class="navbar-brand" routerLink="/">
        <img src="assets/img/logo-01.jpg" alt="Logo Receita Digital" id="brand-home" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbar-default"
        aria-controls="navbar-default"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbar-default">
        <div class="navbar-collapse-header">
          <div class="row">
            <div class="col-6 collapse-brand">
              <a href="#">
                <img src="assets/img/logo-01.jpg" />
              </a>
            </div>
            <div class="col-6 collapse-close">
              <button
                type="button"
                class="navbar-toggler"
                data-toggle="collapse"
                data-target="#navbar-default"
                aria-controls="navbar-default"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>

        <ul class="navbar-nav ml-lg-auto align-items-center">
          <!-- <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="">
              Início
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              Para pacientes
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              Para Médicos
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              Para Farmácias
            </a>
          </li> -->
          <!-- <li class="nav-item">
            <a class="nav-link" href="#">
              Contato
            </a>
          </li> -->
          <ng-container *ngIf="isLoggedOut">
            <li class="nav-item">
              <a class="btn btn-primary btn-md register-btn" routerLink="/entry/account-type">
                Cadastre-se
              </a>
            </li>
            <li class="nav-item" theme>
              <a routerLink="/entry/login" class="btn btn-icon btn-success btn-circle">
                <span class="btn-inner--icon"><i class="fa fa-user-solid"></i></span>
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </nav>
</header>
