<section class="section-hero section-header" theme>
  <div class="container d-flex align-items-center justify-content-center flex-column">
    <a routerLink="/" class="brand-bg">
      <img
        [ngStyle]="
          isThemeActiveReceitaDigital()
            ? {
                position: 'relative',
                top: '-12px'
              }
            : { position: 'relative', top: '-48px' }
        "
        [src]="
          isThemeActiveReceitaDigital() ? 'assets/img/logo-01.jpg' : 'assets/img/mrd/logo-com-texto.png'
        "
        class="img-fluid"
        alt="Logo Receita Digital"
      />
    </a>
    <h1 class="mt-5">
      Defina sua nova senha
    </h1>
  </div>
</section>
<section class="section container d-flex justify-content-center align-items-center" theme>
  <div class="w-50 ml-5 d-flex flex-column">
    <p class="pr-3">
      Crie uma senha forte utilizando seis caracteres ou mais. Ela deve conter uma combinação de letras
      maiúsculas e minúsculas, números e símbolos.
    </p>
    <form nz-form [formGroup]="form">
      <div class="row">
        <div class="col-md-11">
          <nz-form-item class="mb-4">
            <nz-form-control nzHasFeedback>
              <app-input-password placeholder="Senha" formControlName="password"></app-input-password>
              <nz-form-explain *ngIf="form.get('password').dirty && form.get('password').errors">
                <ng-container *ngIf="form.get('password').hasError('required')">
                  A senha é obrigatória.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
            <app-password-strength [password]="form.value.password"></app-password-strength>
          </nz-form-item>
        </div>
        <div class="col-md-11">
          <nz-form-item class="mb-4">
            <nz-form-control nzHasFeedback>
              <app-input-password
                placeholder="Confirme sua senha"
                formControlName="confirmPassword"
              ></app-input-password>
              <nz-form-explain
                *ngIf="form.get('confirmPassword').dirty && form.get('confirmPassword').errors"
              >
                <ng-container *ngIf="form.get('confirmPassword').hasError('required')">
                  A senha de confirmação é obrigatória.
                </ng-container>
              </nz-form-explain>
              <nz-form-explain
                *ngIf="form.get('confirmPassword').dirty && form.get('confirmPassword').errors"
              >
                <ng-container *ngIf="form.get('confirmPassword').hasError('mismatch')">
                  Senhas diferentes.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-md-11 d-flex justify-content-end">
          <button (click)="changePassword()" class="btn btn-ok" [disabled]="!form.valid">
            SALVAR
          </button>
        </div>
      </div>
    </form>
  </div>
</section>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
