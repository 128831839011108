<section class="section-hero bg-green">
  <div class="container d-flex align-items-center justify-content-center flex-column">
    <a routerLink="/" class="brand-bg">
      <img src="assets/img/logo-01.png" class="img-fluid" alt="Logo Receita Digital" />
    </a>
    <h1 class="mb-5 mt-5">
      Farmácias
    </h1>
  </div>
</section>
<section class="section container d-flex justify-content-center align-items-center" theme>
  <div>
    <h2 class="title">
      Selecione uma farmácia para continuar.
    </h2>
    <div class="row">
      <div class="col-9">
        <nz-select nzShowSearch nzPlaceHolder="Selecione uma farmácia" [(ngModel)]="selectedPharmacy">
          <nz-option
            *ngFor="let pharmacy of pharmacies"
            [nzValue]="pharmacy"
            [nzLabel]="pharmacy.businessName"
          ></nz-option>
        </nz-select>
      </div>
      <div class="col-3">
        <button class="btn btn-success" (click)="continue()" [disabled]="!selectedPharmacy">
          <i class="fa fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>
</section>
