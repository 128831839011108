<app-alternative-navbar title="Informações da Receita" titleIcon="fa-assignment"></app-alternative-navbar>
<app-sidebar-menu></app-sidebar-menu>

<div class="section pt-0 container-fluid d-flex flex-column overflow-hidden">
  <div class="row">
    <div class="col-md-6 p-0">
      <div class="toolbar bg-green flex-column">
        <h4 class="title text-white">
          Dados do Paciente
        </h4>
      </div>
      <section class="p-50 pl-80 patient">
        <div class="row">
          <div class="col-md-12">
            <div class="label">
              <ng-container *ngIf="prescription.patient as patient">
                <div class="label">
                  Nome: <span class="regular">{{ patient.name }}</span>
                </div>
                <div class="label mt-15">
                  CPF: <span class="regular">{{ patient.cpf | mask: '000.000.000-00' }}</span>
                </div>
              </ng-container>
            </div>
            <ng-container *ngIf="prescription.healthProfessional as healthProfessional">
              <div class="label mt-38">
                {{ getProfessionalTypeName() }}:
                <span class="regular">{{ healthProfessional.name }}</span>
              </div>
              <div class="label mt-15">
                {{ healthProfessional.regionalCouncilNumber?.label }}:
                <span class="regular"
                  >{{ healthProfessional.regionalCouncilNumber?.number }}/{{
                    healthProfessional.regionalCouncilNumber?.uf
                  }}</span
                >
              </div>
              <div class="label mt-15">
                Especialidade:
                <span class="regular">{{ getSpecialties() }}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </section>
    </div>
    <div class="col-md-6 p-0">
      <div class="toolbar bg-gray-black flex-column">
        <h4 class="title text-white">
          Dados da Receita
        </h4>
      </div>
      <section class="bg-secondary prescription">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <span class="code">Código:</span>
                <span class="font-xl-36 font-light ml-1">{{ prescription.code }}</span>
              </div>
              <div class="col-md-6">
                <div class="download-pdf" (click)="downloadPrescriptionPdf(prescription)">
                  <i
                    nz-icon
                    nzType="file-pdf"
                    nzTheme="twotone"
                    nzTwotoneColor="#B22222"
                    class="icon-pdf"
                  ></i
                  ><br />
                  <span>Clique para baixar o documento assinado</span>
                </div>
              </div>
            </div>

            <h4 class="subtitle mt-38">
              Selecione abaixo as unidades de medicamentos que deseja vender. Ao confirmar a venda os
              medicamentos selecionados serão retidos no sistema.
            </h4>
            <label
              (ngModelChange)="checkAll($event)"
              [(ngModel)]="allChecked"
              [nzIndeterminate]="indeterminate"
              class="custom-check select-all"
              nz-checkbox
              >SELECIONAR TODOS</label>


          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <ng-container *ngFor="let medicament of prescription.medicaments; let i = index">
            <div class="medication-container d-flex align-items-center">
              <label
                [(ngModel)]="medicament.valid"
                (nzCheckedChange)="refreshStatus()"
                class="custom-check mr-1 align-self-start"
                [nzDisabled]="medicament?.availableQuantity === 0"
                nz-checkbox
              ></label>
              <div class="medication">
                <pre><h4 class="title mb-0">{{ i + 1 }} - {{ medicament?.name }}</h4></pre>
                <pre
                  *ngIf="medicament?.description"
                ><p class="mb-0 description">{{ medicament?.description }}</p></pre>
                <pre>Posologia: {{ medicament?.dosage }}</pre>
                <app-auto-width-input-number
                  [(ngModel)]="medicament.availableQuantity"
                  [id]="'quantidade_' + i"
                  [max]="quantities[i]"
                  [disabled]="medicament?.availableQuantity === 0"
                >
                </app-auto-width-input-number>
              </div>
            </div>
          </ng-container>
          </div>
        </div>
      </section>
  </div>
</div>

<div class="footer bg-dark d-flex align-items-center fixed-bottom justify-content-end">
  <button type="button" class="btn btn-success" (click)="changeRoute()" theme>
    Voltar
    <i class="fa fa-undo"></i>
  </button>
  <button type="button" class="btn btn-success" (click)="openModalBuyerData()" theme>
    VALIDAR SELEÇÃO {{ checkedNumber ? '(' + checkedNumber + ')' : '' }}
    <i class="fa fa-arrow-right"></i>
  </button>

