<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="header">
      <span>TERMO DE USO E POLÍTICA DE PRIVACIDADE – RECEITA DIGITAL</span>
      <nz-divider nzOrientation="left"></nz-divider>
    </div>

    <div class="modal-body">
      <app-terms-and-conditions></app-terms-and-conditions>
    </div>

    <div class="modal-footer">
      <button class="btn-ok" nz-button (click)="close()" theme>
        Ok
      </button>
    </div>
  </div>
</div>
