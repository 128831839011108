import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { ModalEditPharmacyTechnicianComponent } from '@app/modules/dashboard/modal-edit-pharmacy-technician/modal-edit-pharmacy-technician.component';
import { SharedModule } from '@app/shared';
import { ThemeModule, themeMrd, themeReceitaDigital } from '@app/theme';
import { environment } from '@env/environment';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxPhotoEditorModule } from 'ngx-photo-editor';
import { PatientsModule } from '../patients/patients.module';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { ModalCipAcceptanceComponent } from './modal-cip-acceptance/modal-cip-acceptance.component';
import { ModalComplementInfoPatientComponent } from './modal-complement-info-patient/modal-complement-info-patient.component';
import { ModalCovidComponent } from './modal-covid/modal-covid.component';
import { ModalRqeWarningComponent } from './modal-rqe-warning/modal-rqe-warning.component';
import { ModalTelemedicineContractModule } from './modal-telemedicine-contract/modal-telemedicine-contract.module';
import { ModalWomensMonthComponent } from './modal-womens-month/modal-womens-month.component';
import { IndexComponent } from './pages/index/index.component';

@NgModule({
  imports: [
    SharedModule,
    DashboardRoutingModule,
    NgxLoadingModule.forRoot({}),
    ModalModule.forRoot(),
    ModalTelemedicineContractModule,
    PatientsModule,
    NgxPhotoEditorModule,
    ThemeModule.forRoot({ themes: [themeReceitaDigital, themeMrd], active: environment.template })
  ],
  declarations: [
    IndexComponent,
    ModalCovidComponent,
    ModalCipAcceptanceComponent,
    ModalRqeWarningComponent,
    ModalWomensMonthComponent,
    ModalEditPharmacyTechnicianComponent,
    ModalComplementInfoPatientComponent
  ],
  entryComponents: [
    ModalCovidComponent,
    ModalCipAcceptanceComponent,
    ModalRqeWarningComponent,
    ModalWomensMonthComponent,
    ModalEditPharmacyTechnicianComponent,
    ModalComplementInfoPatientComponent
  ],
  exports: [ModalCipAcceptanceComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class DashboardModule {}
