import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PatientRecordService } from '@app/modules/patients/services/patient-record.service';

@Component({
  selector: 'app-record-card',
  templateUrl: './record-card.component.html',
  styleUrls: ['./record-card.component.scss']
})
export class RecordCardComponent implements OnInit {
  @Input() record: any;
  @Input() tags: any[];
  @Input() merging: boolean;
  @Output() updateRecordList: EventEmitter<any> = new EventEmitter<any>();
  @Output() setLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showCheckbox: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() addNewRecordToMerge: EventEmitter<any> = new EventEmitter<any>();

  loading: boolean;
  checked: boolean;
  recordsToMerging: any[];
  show: boolean;
  newTag: any;
  editing: boolean;
  showHistory: boolean;
  editingTag: boolean;
  updatingTag: boolean;
  showTagConfirmation: boolean;

  constructor(private patientRecordService: PatientRecordService) {}

  ngOnInit() {}

  showOptions() {
    this.show = !this.show;
  }

  editTag() {
    this.editingTag = true;
  }

  editRecord() {
    this.editing = !this.editing;
    this.show = !this.show;
  }

  setShowHistory() {
    this.showHistory = !this.showHistory;
    this.show = false;
  }

  mergeRecords() {
    this.showCheckbox.emit(true);
    this.showOptions();
  }

  selectRecordToMerge(event) {
    this.addNewRecordToMerge.emit({ record: this.record, event });
  }

  async updateRecord() {
    this.loading = true;
    const recordUpdated = await this.patientRecordService.updateRecord(this.record).toPromise();
    this.record = recordUpdated;
    this.editing = false;
    this.loading = false;
  }

  async deleteRecord() {
    await this.patientRecordService.deleteRecord(this.record._id).toPromise();
    this.updateRecordList.emit();
    this.loading = false;
  }

  getIndex(tagId) {
    const parent = this.record.tag.parentId;
    const children = parent.children;
    const index = children.findIndex(c => c._id === tagId);
    return index + 1;
  }

  changeTagConfirmation(newTag) {
    this.newTag = newTag;
    this.showTagConfirmation = true;
  }

  handleOk(flag) {
    this.editingTag = false;
  }

  handleCancel(flag: string) {
    if (flag === 'editingTag') {
      this.editingTag = false;
    } else {
      this.showTagConfirmation = false;
    }
  }

  async changeTag() {
    this.record.tag = this.newTag;
    this.updatingTag = true;
    const recordUpdated = await this.patientRecordService.updateRecord(this.record).toPromise();
    this.record = recordUpdated;
    this.showTagConfirmation = false;
    this.editingTag = false;
    this.show = false;
    this.updatingTag = false;
  }

  getContent(content) {
    document.getElementById('content').innerText = content;
  }
}
