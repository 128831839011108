<ng-template #tplContent>
  <div class="container">
    <h4 class="title text-primary">
      {{ title }}
    </h4>
    <p>Código do documento: {{ code }}</p>
    <br />
  </div>
</ng-template>
<ng-template #tplFooter>
  <div class="container d-flex align-items-end flex-column modal-footer" theme>
    <div class="row">
      <button (click)="close()" class="btn btn-ok" theme>
        Ok
      </button>
    </div>
  </div>
</ng-template>
