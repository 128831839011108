<app-alternative-navbar-cip
  *ngIf="hasCipFeature; else templateOld"
  [completeUser]="completeUser"
></app-alternative-navbar-cip>

<!-- ************************************************************************************************************** -->
<ng-template #templateOld>
  <header id="alternative-navbar">
    <nav class="navbar navbar-expand-lg navbar-light">
      <a class="navbar-brand" (click)="redirect()">
        <img
          [src]="isThemeActiveReceitaDigital() ? 'assets/img/logo-03.png' : 'assets/img/mrd/logo-03.png'"
          [alt]="isThemeActiveReceitaDigital() ? 'Logo Receita Digital' : 'Logo MRD'"
        />
      </a>
      <div class="container-fluid">
        <div class="collapse navbar-collapse" id="navbar-default">
          <div class="navbar-header">
            <app-icon-menu *ngIf="user?.userType !== 'admin'"></app-icon-menu>
            <h1 class="navbar-text" *ngIf="title">
              <span class="pipe-menu">|</span> {{ title }}
              <i class="fa icon-title" [ngClass]="titleIcon"></i>
            </h1>
          </div>

          <ul class="navbar-nav ml-lg-auto align-items-center ">
            <li class="nav-item" *ngIf="false">
              <button (click)="toggle()">Mudar Tema</button>
            </li>
            <li class="nav-item" *ngIf="user">
              <a routerLink="/profile" class="nav-link">
                <div title="Meus Dados">
                  <ng-container *ngIf="avatarB64; else noAvatar">
                    <img [alt]="user.name" class="avatar" [src]="avatarB64" />
                  </ng-container>
                  <ng-template #noAvatar>
                    <img
                      [alt]="user.name"
                      class="avatar"
                      src="/assets/img/default-avatar.png"
                      *ngIf="user.sex !== 'F'"
                    />
                    <img
                      [alt]="user.name"
                      class="avatar"
                      src="/assets/img/default-avatar.F.png"
                      *ngIf="user.sex === 'F'"
                    />
                  </ng-template>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="/entry/login" (click)="logout()" class="nav-link nav-link-icon logoutIcon">
                <i class="fa fa-exit icon-exit"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</ng-template>
