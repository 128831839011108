<app-alternative-navbar [title]="navTitle" titleIcon="fas fa-history"></app-alternative-navbar>
<app-sidebar-menu></app-sidebar-menu>

<div class="container historic">
  <app-form-filter
    [userType]="userType"
    [tab]="selectedTab"
    [dependents]="patient?.dependents"
    (search)="search($event)"
  ></app-form-filter>

  <div>
    <nz-tabset (nzSelectChange)="tabChange($event)">
      <nz-tab nzTitle="Receitas locais">
        <nz-table
          #nzTable
          [nzData]="prescriptions"
          [nzLoading]="loading"
          [nzTotal]="paginationData[0].totalRecord"
          [nzPageSize]="pageSize"
          nzFrontPagination="false"
          (nzPageIndexChange)="pageIndexChanged($event)"
          nzNoResult="Você não possui nenhuma prescrição"
          [nzScroll]="scrollConfig"
        >
          <thead nzAlign="center" (nzSortChange)="onSortChange($event)">
            <tr>
              <th nzShowExpand></th>
              <th *ngIf="userType === 'pharmacy'" nzShowSort nzSortKey="updatedAt">
                Data da Dispensação
              </th>
              <th
                *ngIf="userType === 'patient' || userType === 'healthProfessional'"
                nzShowSort
                nzSortKey="updatedAt"
              >
                Data de atualização
              </th>
              <th>Código da prescrição</th>
              <th *ngIf="userType === 'healthProfessional'"></th>
              <th *ngIf="userType === 'pharmacy'">CPF do comprador</th>
              <th *ngIf="userType === 'pharmacy'">Código da dispensação</th>
              <th *ngIf="userType === 'pharmacy'">Registro do Prescritor</th>
              <th *ngIf="userType === 'patient'" nzShowSort nzSortKey="healthProfessional.name">
                Médico(a)/Dentista Prescritor(a)
              </th>
              <th *ngIf="userType === 'healthProfessional'" nzShowSort nzSortKey="patient.name">
                Paciente
              </th>
              <th *ngIf="userType !== 'pharmacy'">Status</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <ng-template ngFor let-item [ngForOf]="nzTable.data" *ngIf="userType === 'pharmacy'">
              <ng-container>
                <tr>
                  <td nzShowExpand [(nzExpand)]="item.expand"></td>
                  <td>{{ item.updatedAt | date: 'dd/MM/yyyy, HH:mm' }}</td>
                  <td>{{ item.prescription?.code }}</td>
                  <td>{{ item.cpfBuyer | formattedCpf }}</td>
                  <td>{{ item.dispensationCode }}</td>
                  <td>
                    {{ item.healthProfessional?.regionalCouncilNumber.number }}
                  </td>
                  <td *ngIf="userType !== 'pharmacy'">{{ item.prescription?.status }}</td>
                  <td>
                    <div class="row justify-content-start">
                      <div [ngClass]="user.userType === 'pharmacy' ? 'col' : 'col-2'">
                        <div
                          (click)="
                            downloadPrescriptionPdf({
                              code: item.prescription.code,
                              patient: item.patient
                            })
                          "
                          title="Baixar prescrição"
                        >
                          <i
                            nz-icon
                            nzType="file-pdf"
                            nzTheme="twotone"
                            nzTwotoneColor="#B22222"
                            class="icon-pdf"
                          ></i>
                        </div>
                      </div>
                      <div [ngClass]="user.userType === 'pharmacy' ? 'col' : 'col-2'">
                        <a
                          title="Cancelar retenção"
                          (click)="showModalCancelRetention(item)"
                          *ngIf="
                            item.prescription.status === 'Dispensada' ||
                            item.prescription.status === 'Dispensada Parcial' ||
                            item.prescription.status === 'Dispensada Total'
                          "
                        >
                          <i class="fa fa-trash-solid"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr [(nzExpand)]="item.expand" *ngFor="let medicament of item.medicaments">
                  <td></td>
                  <td colspan="5">
                    <span class="title"> {{ getPreWriterInfo(item).label }}: </span>
                    <span>{{ getPreWriterInfo(item).name }}</span>
                    <br />
                    <div class="d-flex">
                      <span class="title"> Medicamento: </span>
                      <span class="break-line"> {{ medicament.description }}</span>
                    </div>
                    <span class="title"> Quantidade prescrita: </span
                    ><span> {{ medicament.prescribedQuantity }}</span>
                    <br />
                    <span class="title"> Quantidade retida: </span
                    ><span> {{ medicament.quantityRetained }}</span>
                    <br />
                    <br />
                    <div *ngIf="item.buyer">
                      <span class="title"> Nome do comprador: </span><span> {{ item.buyer?.name }}</span>
                      <br />
                      <span class="title"> RG do comprador: </span
                      ><span> {{ item.buyer?.identity }}</span>
                      <br />
                      <span class="title"> Endereço do comprador: </span
                      ><span>
                        {{ item.buyer?.address.street }}, {{ item.buyer?.address.number }},
                        {{ item.buyer?.address.neighborhood }} - {{ item.buyer?.address.uf }}</span
                      >
                    </div>
                  </td>
                </tr>
              </ng-container>
            </ng-template>

            <ng-template
              ngFor
              let-item
              [ngForOf]="nzTable.data"
              *ngIf="userType === 'patient' || userType === 'healthProfessional'"
            >
              <ng-container>
                <tr>
                  <td nzShowExpand [(nzExpand)]="item.expand"></td>
                  <td>{{ item.updatedAt | date: 'dd/MM/yyyy, HH:mm' }}</td>
                  <td>{{ item.code }}</td>
                  <td *ngIf="userType === 'patient'">
                    {{ item.healthProfessional?.name }}
                  </td>
                  <td *ngIf="userType === 'healthProfessional'" class="p-0">
                    <img
                      *ngIf="item.patient?.responsible"
                      src="assets/icons/hand-holding-medical.svg"
                      width="22px"
                    />
                  </td>
                  <td *ngIf="userType === 'healthProfessional'" class="pl-0">
                    {{ item.patient?.name }}
                    <span class="responsible-name" *ngIf="item.patient?.responsible"
                      ><br />RESPONSÁVEL: {{ item.patient.responsible.name }}</span
                    >
                  </td>
                  <td *ngIf="userType !== 'pharmacy'">{{ item.status }}</td>
                  <td>
                    <div class="row justify-content-start">
                      <div class="col-2">
                        <div (click)="downloadPrescriptionPdf(item)" title="Baixar prescrição">
                          <i
                            nz-icon
                            nzType="file-pdf"
                            nzTheme="twotone"
                            nzTwotoneColor="#B22222"
                            class="icon-pdf"
                          ></i>
                        </div>
                      </div>

                      <div
                        *ngIf="userType === 'healthProfessional' && item.status !== 'Não Assinada'"
                        class="col-2"
                      >
                        <a title="Repetir prescrição" (click)="navigateToNew(item, 'prescription')">
                          <i class="fa fa-repeat"></i>
                        </a>
                      </div>

                      <div
                        *ngIf="userType === 'healthProfessional' && item.status === 'Não Assinada'"
                        class="col-2"
                      >
                        <a title="Editar prescrição" (click)="navigateToEdit(item, 'prescription')">
                          <i class="fa fa-pencil-solid"></i>
                        </a>
                      </div>

                      <div
                        class="col-2"
                        *ngIf="userType === 'healthProfessional' && hasSignedItem(prescriptions)"
                      >
                        <a
                          *ngIf="item.status === 'Assinada' || item.status === 'Dispensada Parcial'"
                          title="Reenviar prescrição"
                          (click)="showModalSendDocument(item)"
                        >
                          <img src="assets/icons/reenvio.png" width="22px" />
                        </a>
                      </div>

                      <div class="col-2" *ngIf="userType !== 'pharmacy' && !item.archived">
                        <a title="Arquivar" (click)="archiveItem(item.code)">
                          <i class="fas fa-archive"></i>
                        </a>
                      </div>

                      <div class="col-2" *ngIf="userType !== 'pharmacy' && item.archived">
                        <a title="Desarquivar" (click)="unarchiveItem(item.code)">
                          <i class="fas fa-inbox"></i>
                        </a>
                      </div>

                      <div class="col-2">
                        <a
                          title="Excluir prescrição"
                          (click)="showModalCancelPrescrition(item)"
                          *ngIf="
                            (item.status === 'Não Assinada' || item.status === 'Assinada') &&
                            userType === 'healthProfessional'
                          "
                        >
                          <i class="fa fa-trash-solid"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr [nzExpand]="item.expand">
                  <td></td>
                  <td colspan="6">
                    <div *ngFor="let medicament of item.medicaments; let i = index" class="row">
                      <div class="col-sm">
                        {{ i + 1 }})<span> {{ medicament.name }}</span>
                      </div>
                      <div class="col-sm">
                        <span>{{ medicament.dosage }}</span>
                      </div>
                      <div class="col-sm">
                        <span>[{{ medicament.prescribedQuantity }}] Embalagem</span>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </ng-template>
          </tbody>
        </nz-table>
      </nz-tab>

      <nz-tab nzTitle="Atestados" *ngIf="userType === 'healthProfessional' || userType === 'patient'">
        <nz-table
          #nzTable
          [nzData]="attestations"
          [nzLoading]="loading"
          [nzTotal]="paginationData[1].totalRecord"
          [nzPageSize]="pageSize"
          (nzPageIndexChange)="pageIndexChanged($event)"
          nzFrontPagination="false"
          nzNoResult="Você não possui nenhum atestado"
          [nzScroll]="scrollConfig"
        >
          <thead nzAlign="center" (nzSortChange)="onSortChange($event)">
            <tr>
              <th nzShowExpand></th>
              <th nzShowSort nzSortKey="updatedAt">
                Data de atualização
              </th>
              <th>Código do atestado</th>
              <th *ngIf="userType === 'healthProfessional'"></th>
              <th *ngIf="userType === 'patient'">Médico(a)/Dentista Prescritor(a)</th>
              <th *ngIf="userType === 'healthProfessional'" nzShowSort nzSortKey="patient.name">
                Paciente
              </th>
              <th>Status</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <ng-template
              ngFor
              let-item
              [ngForOf]="nzTable.data"
              *ngIf="userType === 'patient' || userType === 'healthProfessional'"
            >
              <ng-container>
                <tr>
                  <td nzShowExpand [(nzExpand)]="item.expand"></td>
                  <td>{{ item.updatedAt | date: 'dd/MM/yyyy, HH:mm' }}</td>
                  <td>{{ item.code }}</td>
                  <td *ngIf="userType === 'patient'">
                    {{ item.healthProfessional?.name }}
                  </td>
                  <td *ngIf="userType === 'healthProfessional'" class="p-0">
                    <img
                      *ngIf="item.patient?.responsible"
                      src="assets/icons/hand-holding-medical.svg"
                      width="22px"
                    />
                  </td>
                  <td *ngIf="userType === 'healthProfessional'" class="pl-0">
                    {{ item.patient?.name }}
                    <span class="responsible-name" *ngIf="item.patient?.responsible"
                      ><br />RESPONSÁVEL: {{ item.patient.responsible.name }}</span
                    >
                  </td>
                  <td>{{ getMaleStatus(item.status) }}</td>
                  <td>
                    <div class="row justify-content-start">
                      <div class="col-2">
                        <div (click)="downloadPrescriptionPdf(item)" title="Baixar atestado">
                          <i
                            nz-icon
                            nzType="file-pdf"
                            nzTheme="twotone"
                            nzTwotoneColor="#B22222"
                            class="icon-pdf"
                          ></i>
                        </div>
                      </div>
                      <div
                        *ngIf="userType === 'healthProfessional' && item.status === 'Não Assinada'"
                        class="col-2"
                      >
                        <a title="Editar atestado" (click)="navigateToEdit(item, 'attestation')">
                          <i class="fa fa-pencil-solid"></i>
                        </a>
                      </div>

                      <div
                        *ngIf="userType === 'healthProfessional' && item.status !== 'Não Assinada'"
                        class="col-2"
                      >
                        <a title="Repetir atestado" (click)="navigateToNew(item, 'attestation')">
                          <i class="fa fa-repeat"></i>
                        </a>
                      </div>

                      <div
                        class="col-2"
                        *ngIf="userType === 'healthProfessional' && hasSignedItem(attestations)"
                      >
                        <a
                          *ngIf="item.status === 'Assinada'"
                          title="Reenviar atestado"
                          (click)="showModalSendDocument(item)"
                        >
                          <img src="assets/icons/reenvio.png" width="22px" />
                        </a>
                      </div>

                      <div class="col-2" *ngIf="userType !== 'pharmacy' && !item.archived">
                        <a title="Arquivar" (click)="archiveItem(item.code)">
                          <i class="fas fa-archive"></i>
                        </a>
                      </div>

                      <div class="col-2" *ngIf="userType !== 'pharmacy' && item.archived">
                        <a title="Desarquivar" (click)="unarchiveItem(item.code)">
                          <i class="fas fa-inbox"></i>
                        </a>
                      </div>

                      <div class="col-2">
                        <a
                          title="Excluir atestado"
                          (click)="showModalCancelAttestation(item)"
                          *ngIf="
                            (item.status === 'Não Assinada' || item.status === 'Assinada') &&
                            userType === 'healthProfessional'
                          "
                        >
                          <i class="fa fa-trash-solid"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr [nzExpand]="item.expand">
                  <td></td>
                  <td colspan="6">
                    <span *ngFor="let text of item.description; let i = index">
                      <br *ngIf="i > 0" />
                      {{ text }}
                      <br />
                    </span>
                  </td>
                </tr>
              </ng-container>
            </ng-template>
          </tbody>
        </nz-table>
      </nz-tab>

      <nz-tab nzTitle="Exames" *ngIf="userType === 'healthProfessional' || userType === 'patient'">
        <nz-table
          #nzTable
          [nzData]="exams"
          [nzLoading]="loading"
          nzFrontPagination="false"
          [nzPageSize]="pageSize"
          [nzTotal]="paginationData[2].totalRecord"
          (nzPageIndexChange)="pageIndexChanged($event)"
          [nzNoResult]="'Você não possui nenhum exame.'"
          [nzScroll]="scrollConfig"
        >
          <thead nzAlign="center" (nzSortChange)="onSortChange($event)">
            <tr>
              <th nzShowExpand></th>
              <th nzShowSort nzSortKey="updatedAt">
                Data de atualização
              </th>
              <th>Código do exame</th>
              <th *ngIf="userType === 'healthProfessional'"></th>
              <th *ngIf="userType === 'pharmacy'">Registro do Prescritor</th>
              <th *ngIf="userType === 'patient'">Médico(a)/Dentista Prescritor(a)</th>
              <th *ngIf="userType === 'healthProfessional'" nzShowSort nzSortKey="patient.name">
                Paciente
              </th>
              <th *ngIf="userType !== 'pharmacy'">Status</th>
              <th *ngIf="userType !== 'pharmacy'">Ações</th>
            </tr>
          </thead>
          <tbody>
            <ng-template
              ngFor
              let-item
              [ngForOf]="nzTable.data"
              *ngIf="userType === 'patient' || userType === 'healthProfessional'"
            >
              <ng-container>
                <tr>
                  <td nzShowExpand [(nzExpand)]="item.expand"></td>
                  <td>{{ item.updatedAt | date: 'dd/MM/yyyy, HH:mm' }}</td>
                  <td>{{ item.code }}</td>
                  <td *ngIf="userType === 'patient'">
                    {{ item.healthProfessional?.name }}
                  </td>
                  <td *ngIf="userType === 'healthProfessional'" class="p-0">
                    <img
                      *ngIf="item.patient?.responsible"
                      src="assets/icons/hand-holding-medical.svg"
                      width="22px"
                    />
                  </td>
                  <td *ngIf="userType === 'healthProfessional'" class="pl-0">
                    {{ item.patient?.name }}
                    <span class="responsible-name" *ngIf="item.patient?.responsible"
                      ><br />RESPONSÁVEL: {{ item.patient.responsible.name }}</span
                    >
                  </td>
                  <td *ngIf="userType !== 'pharmacy'">{{ getMaleStatus(item.status) }}</td>
                  <td>
                    <div class="row justify-content-start">
                      <div class="col-2">
                        <div (click)="downloadPrescriptionPdf(item)" title="Baixar exame">
                          <i
                            nz-icon
                            nzType="file-pdf"
                            nzTheme="twotone"
                            nzTwotoneColor="#B22222"
                            class="icon-pdf"
                          ></i>
                        </div>
                      </div>
                      <div
                        *ngIf="userType === 'healthProfessional' && item.status === 'Não Assinada'"
                        class="col-2"
                      >
                        <a title="Editar exame" (click)="navigateToEdit(item, 'exam')">
                          <i class="fa fa-pencil-solid"></i>
                        </a>
                      </div>

                      <div
                        *ngIf="userType === 'healthProfessional' && item.status !== 'Não Assinada'"
                        class="col-2"
                      >
                        <a title="Repetir exame" (click)="navigateToNew(item, 'exam')">
                          <i class="fa fa-repeat"></i>
                        </a>
                      </div>

                      <div
                        class="col-2"
                        *ngIf="userType === 'healthProfessional' && hasSignedItem(exams)"
                      >
                        <a
                          *ngIf="item.status === 'Assinada'"
                          title="Reenviar prescrição"
                          (click)="showModalSendDocument(item)"
                        >
                          <img src="assets/icons/reenvio.png" width="22px" />
                        </a>
                      </div>

                      <div class="col-2" *ngIf="userType !== 'pharmacy' && !item.archived">
                        <a title="Arquivar" (click)="archiveItem(item.code)">
                          <i class="fas fa-archive"></i>
                        </a>
                      </div>

                      <div class="col-2" *ngIf="userType !== 'pharmacy' && item.archived">
                        <a title="Desarquivar" (click)="unarchiveItem(item.code)">
                          <i class="fas fa-inbox"></i>
                        </a>
                      </div>

                      <div class="col-2">
                        <a
                          title="Excluir exame"
                          (click)="showModalCancelExam(item)"
                          *ngIf="
                            (item.status === 'Não Assinada' || item.status === 'Assinada') &&
                            userType === 'healthProfessional'
                          "
                        >
                          <i class="fa fa-trash-solid"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr [nzExpand]="item.expand">
                  <td></td>
                  <td colspan="6">
                    <div class="col" *ngFor="let examItem of item.examList">
                      <div class="row">
                        <span class="title">Material:</span
                        ><span class="break-line"> {{ examItem.material }}</span>
                      </div>
                      <div class="row">
                        <span class="title">Exame:</span>
                      </div>
                      <div class="row">
                        <ol>
                          <li *ngFor="let exam of examItem.exams">{{ exam.name }}</li>
                        </ol>
                      </div>
                    </div>
                    <div class="col" *ngIf="item.justification">
                      <div class="row">
                        <span class="title">Justificativa:</span>
                      </div>
                      <div class="row">
                        <span *ngFor="let text of item.justification; let i = index">
                          <br *ngIf="i > 0" />
                          {{ text }}
                          <br />
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </ng-template>
          </tbody>
        </nz-table>
      </nz-tab>
      <nz-tab
        nzTitle="Outros documentos"
        *ngIf="userType === 'healthProfessional' || userType === 'patient'"
      >
        <nz-table
          #nzTable
          [nzData]="orientations"
          [nzLoading]="loading"
          nzFrontPagination="false"
          [nzPageSize]="pageSize"
          [nzTotal]="paginationData[3].totalRecord"
          (nzPageIndexChange)="pageIndexChanged($event)"
          nzNoResult="Você não possui nenhum documento."
          [nzScroll]="scrollConfig"
        >
          <thead nzAlign="center" (nzSortChange)="onSortChange($event)">
            <tr>
              <th nzShowExpand></th>
              <th nzShowSort nzSortKey="updatedAt">
                Data de atualização
              </th>
              <th>Código do documento</th>
              <th *ngIf="userType === 'healthProfessional'"></th>
              <th *ngIf="userType === 'pharmacy'">Registro do Prescritor</th>
              <th *ngIf="userType === 'patient'">Médico(a)/Dentista Prescritor(a)</th>
              <th *ngIf="userType === 'healthProfessional'" nzShowSort nzSortKey="patient.name">
                Paciente
              </th>
              <th *ngIf="userType !== 'pharmacy'">Status</th>
              <th *ngIf="userType !== 'pharmacy'">Ações</th>
            </tr>
          </thead>
          <tbody>
            <ng-template
              ngFor
              let-item
              [ngForOf]="nzTable.data"
              *ngIf="userType === 'patient' || userType === 'healthProfessional'"
            >
              <ng-container>
                <tr>
                  <td nzShowExpand [(nzExpand)]="item.expand"></td>
                  <td>{{ item.updatedAt | date: 'dd/MM/yyyy, HH:mm' }}</td>
                  <td>{{ item.code }}</td>
                  <td *ngIf="userType === 'patient'">
                    {{ item.healthProfessional?.name }}
                  </td>
                  <td *ngIf="userType === 'healthProfessional'" class="p-0">
                    <img
                      *ngIf="item.patient?.responsible"
                      src="assets/icons/hand-holding-medical.svg"
                      width="22px"
                    />
                  </td>
                  <td *ngIf="userType === 'healthProfessional'" class="pl-0">
                    {{ item.patient?.name }}
                    <span class="responsible-name" *ngIf="item.patient?.responsible"
                      ><br />RESPONSÁVEL: {{ item.patient.responsible.name }}</span
                    >
                  </td>
                  <td *ngIf="userType !== 'pharmacy'">{{ getMaleStatus(item.status) }}</td>
                  <td>
                    <div class="row justify-content-start">
                      <div class="col-2">
                        <div (click)="downloadPrescriptionPdf(item)" title="Baixar documento">
                          <i
                            nz-icon
                            nzType="file-pdf"
                            nzTheme="twotone"
                            nzTwotoneColor="#B22222"
                            class="icon-pdf"
                          ></i>
                        </div>
                      </div>
                      <div
                        *ngIf="userType === 'healthProfessional' && item.status === 'Não Assinada'"
                        class="col-2"
                      >
                        <a title="Editar documento" (click)="navigateToEdit(item, 'orientation')">
                          <i class="fa fa-pencil-solid"></i>
                        </a>
                      </div>

                      <div
                        *ngIf="userType === 'healthProfessional' && item.status !== 'Não Assinada'"
                        class="col-2"
                      >
                        <a title="Repetir documento" (click)="navigateToNew(item, 'orientation')">
                          <i class="fa fa-repeat"></i>
                        </a>
                      </div>

                      <div
                        class="col-2"
                        *ngIf="userType === 'healthProfessional' && hasSignedItem(orientations)"
                      >
                        <a
                          *ngIf="item.status === 'Assinada'"
                          title="Reenviar documento"
                          (click)="showModalSendDocument(item)"
                        >
                          <img src="assets/icons/reenvio.png" width="22px" />
                        </a>
                      </div>

                      <div class="col-2" *ngIf="userType !== 'pharmacy' && !item.archived">
                        <a title="Arquivar" (click)="archiveItem(item.code)">
                          <i class="fas fa-archive"></i>
                        </a>
                      </div>

                      <div class="col-2" *ngIf="userType !== 'pharmacy' && item.archived">
                        <a title="Desarquivar" (click)="unarchiveItem(item.code)">
                          <i class="fas fa-inbox"></i>
                        </a>
                      </div>

                      <div class="col-2">
                        <a
                          title="Excluir documento"
                          (click)="showModalCancelOrientation(item)"
                          *ngIf="
                            (item.status === 'Não Assinada' || item.status === 'Assinada') &&
                            userType === 'healthProfessional'
                          "
                        >
                          <i class="fa fa-trash-solid"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr [nzExpand]="item.expand">
                  <td></td>
                  <td colspan="6">
                    <div class="col">
                      <div class="row">
                        <span class="title">Título:</span
                        ><span class="break-line"> {{ item.title }}</span>
                      </div>
                      <div class="row">
                        <span class="title">Conteúdo:</span>
                        <span *ngIf="item.orientations?.length === 1" class="break-line">
                          {{ item.orientations[0] }}
                        </span>
                      </div>
                      <div class="row" *ngIf="item.orientations.length > 1">
                        <span *ngFor="let text of item.orientations; let i = index">
                          <br *ngIf="i > 0" />
                          {{ text }}
                          <br />
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </ng-template>
          </tbody>
        </nz-table>
      </nz-tab>
      <nz-tab nzTitle="Receitas de terceiros" *ngIf="userType === 'pharmacy'">
        <nz-table
          #nzTable
          [nzData]="retentionOfThirdParties"
          [nzLoading]="loading"
          [nzTotal]="paginationData[1].totalRecord"
          [nzPageSize]="pageSize"
          (nzPageIndexChange)="pageIndexChanged($event)"
          nzFrontPagination="false"
          nzNoResult="Você ainda não realizou nenhuma retenção"
          [nzScroll]="scrollConfig"
        >
          <thead nzAlign="center">
            <tr>
              <th></th>
              <th>Data da Dispensação</th>
              <th>CPF do comprador</th>
              <th>Código da dispensação</th>
              <th>Registro do Prescritor</th>
              <th>Arquivo na base</th>
            </tr>
          </thead>
          <tbody *ngFor="let item of nzTable.data">
            <tr>
              <td></td>
              <td>{{ item.createdAt | date: 'dd/MM/yyyy, HH:mm' }}</td>
              <td>{{ item?.cpfBuyer | formattedCpf }}</td>
              <td>{{ item.dispensationCode }}</td>
              <td>
                {{ item.preWriterRegister?.number || item.prescriber?.regionalCouncilNumber.number }}
              </td>
              <td>
                <div class="prescription">
                  <div>
                    <a title="Baixar prescrição" (click)="downloadPrescriptionPdfOfThirdParties(item)">
                      <i
                        nz-icon
                        nzType="file-pdf"
                        nzTheme="twotone"
                        nzTwotoneColor="#B22222"
                        class="icon-pdf"
                      ></i>
                    </a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </nz-tab>
    </nz-tabset>
  </div>
</div>
<ngx-loading [show]="loadingFilter" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<app-modal-send-document></app-modal-send-document>
