import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { PrescriptionService } from '../../services/prescription.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Prescription } from '@app/shared/models/prescription';
import { HealthProfessional } from '@app/shared/models/health-professional';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class ListingComponent implements OnInit {
  initLoading = true;
  loadingMore = false;
  prescriptions: Prescription[];
  showPrescriptionTab: Boolean = true;
  showDoctorTab: Boolean = false;
  showPatientTab: Boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private prescriptionService: PrescriptionService,
    private notification: NzNotificationService
  ) {
    const state = this.router.getCurrentNavigation().extras.state;
    if (state) {
      this.prescriptions = state.prescriptions;
    }
  }

  ngOnInit() {
    if (!this.prescriptions) {
      this.route.params.subscribe(params => {
        const param = params['param'];
        this.requests(param);
      });
    }
  }

  private async requests(param: string) {
    this.prescriptions = await this.prescriptionService.getPrescriptionsPatient(param).toPromise();
  }

  changeRoute(prescriptionCode) {
    this.router.navigate(['/prescription/confirmation', prescriptionCode]);
    this.notification.warning(
      'Atenção',
      'Esta prescrição só é válida mediante o registro da dispensação.'
    );
  }

  getProfessionalTypeName(professionalType: string) {
    return HealthProfessional.getProfessionalTypeName(professionalType);
  }
}
