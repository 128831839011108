<div class="prescription" [formGroup]="prescriptionForm">
  <nz-form-item *ngIf="!editMode">
    <label nz-checkbox formControlName="showPatientCpf">
      <span>
        {{ showCpfText }}
      </span>
    </label>
  </nz-form-item>
  <ng-container formArrayName="medicaments">
    <ng-container
      *ngFor="let medicamentFormGroup of prescriptionForm.get('medicaments')['controls']; let i = index"
      [formGroup]="medicamentFormGroup"
    >
      <div class="medication-container" *ngIf="medicamentFormGroup.value as medicament">
        <div class="add-prescription d-flex" *ngIf="medicament.chooseMedicament">
          <ng-container>
            <textarea
              [ngStyle]="{ color: colorSetting?.primary }"
              [nzAutocomplete]="autoMedication"
              appFocus
              focus="true"
              placeholder="Nome do medicamento ou princípio ativo"
              class="medicationNameInput"
              formControlName="medication"
              nz-input
            ></textarea>
            <button
              class="btn btn-add-name"
              nz-button
              [disabled]="!medicamentFormGroup.get('medication')?.value"
              (click)="addManual(medicamentFormGroup)"
              [ngStyle]="{
                'background-color': colorSetting?.secondary,
                'border-color': colorSetting?.secondary
              }"
            >
              <i class="fas fa-arrow-right"></i>
            </button>
            <nz-autocomplete #autoMedication class="medicationAutoComplete" nzBackfill>
              <div *ngIf="search">
                <ng-container *ngFor="let option of medications$ | async">
                  <nz-auto-option
                    (click)="selectMedicament(medicamentFormGroup, option)"
                    [nzLabel]="option.nome"
                    [nzValue]="option"
                  >
                    <span>{{ option?.nome }}</span>
                    <span id="indexPresentation">, &nbsp;{{ option.apresentacaoCustomizada }}</span>
                    <span *ngIf="option?.tipo">, &nbsp;{{ option?.tipo }}</span>
                  </nz-auto-option>
                </ng-container>
                <nz-auto-option
                  (click)="addManual(medicamentFormGroup)"
                  *ngIf="medicament.medication"
                  [nzValue]="medicament.medication"
                  class="text-center"
                >
                  <p
                    class="text-underline title-semi-bold mb-0 flex-grow-1"
                    [ngStyle]="{ color: colorSetting?.secondary }"
                  >
                    Adicionar texto livre
                  </p>
                </nz-auto-option>
              </div>
            </nz-autocomplete>
          </ng-container>
        </div>

        <div *ngIf="medicament.addedMedication">
          <div *ngIf="medicament.medicamentId">
            <h4 class="title" *ngIf="!medicament.chooseMedicament">
              {{ i + 1 }}. {{ medicament.name }}
            </h4>

            <h2 class="activePrinciples">{{ medicament.principle }} - {{ medicament.description }}</h2>
          </div>

          <div
            *ngIf="!medicament.medicamentId && !medicament.chooseMedicament"
            class="free-prescription"
          >
            <h4 class="title">{{ i + 1 }}. {{ medicament.name }}</h4>
          </div>
          <h2 class="activePrinciples" *ngIf="medicament.principle || medicament.description">
            {{ medicament.principle }}
            <span *ngIf="medicament.principle && medicament.description"> - </span
            >{{ medicament.description }}
          </h2>

          <nz-form-item>
            <nz-form-label [nzFor]="'dosage_' + i" class="sr-only">Posologia</nz-form-label>
            <nz-form-control>
              <textarea
                [focus]="!adding"
                [id]="'dosage_' + i"
                appAutoSize
                appFocus
                formControlName="dosage"
                nz-input
                placeholder="Escreva aqui a posologia..."
                [disabled]="signed || medicament.disabled"
              ></textarea>
            </nz-form-control>
          </nz-form-item>
          <app-auto-width-input-number
            [id]="'quantidade' + i"
            formControlName="prescribedQuantity"
            [disabled]="signed || medicament.disabled"
            [colorSetting]="colorSetting"
          >
          </app-auto-width-input-number>

          <div
            class="buttons-edit-prescription"
            *ngIf="!signed && !medicament.chooseMedicament && !medicament.manipuled"
          >
            <button
              *ngIf="!medicament.disabled"
              (click)="editMedicament(medicamentFormGroup)"
              title="Alterar medicamento"
              class="btn btn-icon btn-simple"
              type="button"
            >
              <span class="btn-inner--icon"><i class="fa fa-pencil-solid"></i></span>
            </button>
            <button
              (click)="deletePrescription(i)"
              title="Remover medicamento"
              class="btn btn-icon btn-simple"
              type="button"
            >
              <span class="btn-inner--icon"><i class="fa fa-trash-solid"></i></span>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div class="add-prescription" *ngIf="!signed">
    <button
      (click)="addRow()"
      *ngIf="!adding"
      class="btn btn-icon btn-add-outiline text-capitalize"
      type="button"
      [ngStyle]="{
        'background-color': colorSetting?.secondary,
        'border-color': colorSetting?.secondary
      }"
    >
      <span class="btn-inner--text">Adicionar Medicamento</span>
      <span
        class="btn-inner--icon"
        [ngStyle]="{
          'background-color': colorSetting?.primary,
          'border-color': colorSetting?.primary
        }"
        ><i class="fa fa-plus"></i
      ></span>
    </button>
  </div>
</div>
