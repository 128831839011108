import { Component, EventEmitter, Input, TemplateRef, ViewChild, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ColorSetting } from '@app/shared/models/color-setting';
import { markFormGroup } from '@app/utils/markFormGroup';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-modal-select-layout',
  templateUrl: './modal-select-layout.component.html',
  styleUrls: ['./modal-select-layout.component.scss']
})
export class ModalSelectLayoutComponent {
  @Input() colorSetting: ColorSetting;
  @Output() selectLayout = new EventEmitter<any>();

  tplModal: NzModalRef;

  @ViewChild('tplContent', { static: true })
  tplContent: TemplateRef<{}>;

  @ViewChild('tplFooter', { static: true })
  tplFooter: TemplateRef<{}>;

  form: FormGroup;
  layout = 'simple';
  showPrescriberCpf = false;
  patientDoesNotHaveAddress = false;

  constructor(private modalService: NzModalService, private fb: FormBuilder) {}

  async createTplModal(patientDoesNotHaveAddress = false) {
    this.patientDoesNotHaveAddress = patientDoesNotHaveAddress;

    this.tplModal = this.modalService.create({
      nzContent: this.tplContent,
      nzFooter: this.tplFooter,
      nzClosable: false,
      nzWidth: 600,
      nzMaskClosable: false,
      nzOnOk: () => {},
      nzOnCancel: () => {}
    });

    if (patientDoesNotHaveAddress) {
      this.form = this.fb.group({
        uf: ['', Validators.required],
        city: ['', Validators.required],
        complement: [''],
        number: ['', Validators.required],
        street: ['', Validators.required],
        neighborhood: ['', Validators.required],
        cep: ['', Validators.required]
      });
    }

    this.tplModal.afterOpen.subscribe(() => {
      this.setBackgroundColorFooter();
    });
  }

  get showAddressForm(): boolean {
    return this.patientDoesNotHaveAddress && this.layout === 'special';
  }

  setBackgroundColorFooter() {
    const elements = document.getElementsByClassName('ant-modal-footer');
    if (elements) {
      if (this.colorSetting) {
        elements[0].setAttribute('style', `background-color: ${this.colorSetting.footer}`);
      } else {
        elements[0].setAttribute('style', `background-color: var(--bg-modal-header)`);
      }
    }
  }

  changeLayout() {
    if (this.showAddressForm) {
      this.tplModal.getInstance().nzWidth = 800;
    } else {
      this.tplModal.getInstance().nzWidth = 600;
    }
  }

  submit() {
    if (this.layout === 'special' && this.showAddressForm) {
      markFormGroup(this.form);
      if (this.form.valid) {
        this.emitEventAndCloseModal();
      }
    } else {
      this.emitEventAndCloseModal();
    }
  }

  emitEventAndCloseModal() {
    this.selectLayout.emit({
      layout: this.layout,
      addressValue: this.form ? this.form.value : null,
      showPrescriberCpf: this.showPrescriberCpf && this.layout === 'special'
    });
    this.close();
  }

  close() {
    this.tplModal.close();
    this.tplModal.destroy();
  }
}
