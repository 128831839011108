<form nz-form [formGroup]="form" theme>
  <div class="row">
    <nz-alert
      class="mb-4"
      nzType="warning"
      nzMessage="Para agilizar seu cadastro, buscaremos dados complementares no site da Receita Federal.
Este é um procedimento seguro e o sigilo dos seus dados é garantido pela nossa plataforma"
    ></nz-alert>
  </div>
  <app-personal-data-form [form]="form"></app-personal-data-form>
  <app-contacts-data-form [form]="form"></app-contacts-data-form>
  <app-address-data-form [form]="form.get('address')"></app-address-data-form>
</form>
