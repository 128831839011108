import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '@app/core/services/validation.service';
import { CepService } from '@app/modules/entry/services/cep.service';
import { ModalAddPatientComponent } from '@app/modules/patients/pages/modal-add-patient/modal-add-patient.component';
import { BrazilianStates } from '@app/shared/data/Brazilian-states';
import { BrazilState } from '@app/shared/models';
import { Paciente } from '@app/shared/models/paciente';
import { BigboostService } from '@app/shared/services/bigboost.service';
import * as moment from 'moment';
import { NzNotificationService } from 'ng-zorro-antd';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-patient-form',
  templateUrl: './patient-form.component.html',
  styleUrls: ['./patient-form.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        group([animate(300, style({ height: 0 })), animate('400ms ease-in-out', style({ opacity: 0 }))])
      ]),
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        group([
          animate(300, style({ height: '*' })),
          animate('400ms ease-in-out', style({ opacity: 1 }))
        ])
      ])
    ])
  ]
})
export class PatientFormComponent implements OnInit {
  @Input() patient: Paciente;
  @Input() isRegisteredByPatient = true;
  @Input() getForm: Observable<boolean>;
  @Output() submit = new EventEmitter<FormGroup>();
  @Output() formChange = new EventEmitter<FormGroup>();

  form = this.fb.group({
    name: ['', [Validators.required, ValidationService.nomeValidator]],
    fullname: ['', [Validators.required, ValidationService.nomeValidator]],
    noCpf: [false],
    cpf: ['', Validators.required],
    sex: ['', Validators.required],
    birthdate: ['', [Validators.required, ValidationService.dateValidator()]],
    dateOfBirth: ['', [Validators.required, ValidationService.dateValidator({ format: 'YYYY-MM-DD' })]],
    cellphone: ['', Validators.required],
    telephone: [''],
    email: ['', [Validators.required, Validators.email]],
    membership: this.fb.group({
      mothersName: ['', Validators.required]
    }),
    address: this.fb.group({
      uf: ['', Validators.required],
      city: ['', Validators.required],
      complement: [''],
      number: ['', Validators.required],
      street: ['', Validators.required],
      neighborhood: ['', Validators.required],
      cep: ['', [Validators.required, ValidationService.cepValidator]]
    }),
    pendency: [null],
    verifiedCpf: [false]
  });

  states: BrazilState[] = BrazilianStates;
  loading = false;
  consult: any;
  isPet: boolean;
  private lastCpf: string;
  private lastDateOfBirth: string;

  constructor(
    private fb: FormBuilder,
    private cepService: CepService,
    private modalService: BsModalService,
    private notification: NzNotificationService,
    private bigboostService: BigboostService
  ) {}

  ngOnInit() {
    this.initForm();

    if (this.patient) {
      this.handleConsultCpf(this.patient);
    }

    this.formChange.emit(this.form);
  }

  private initForm() {
    if (this.isRegisteredByPatient) {
      this.form.addControl('identity', this.fb.control('', Validators.required));
      this.form.addControl(
        'emailConfirmation',
        this.fb.control('', Validators.compose([Validators.required, Validators.email]))
      );
      this.form.validator = ValidationService.emailMatch;

      this.form.get('emailConfirmation').valueChanges.subscribe(email => {
        this.form.get('emailConfirmation').setValue(email && email.toLowerCase(), { emitEvent: false });
      });
    }

    this.form.get('cpf').valueChanges.subscribe(cpf => {
      if (this.lastCpf !== cpf && !this.hasPendency) {
        this.verifiedCpf = false;
      }
    });

    this.form.get('birthdate').valueChanges.subscribe(birthdate => {
      const dateOfBirth = moment(birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      if (this.lastDateOfBirth !== dateOfBirth) {
        if (!this.hasPendency) {
          this.verifiedCpf = false;
        }
        this.form.get('dateOfBirth').setValue(dateOfBirth);
      }
    });

    this.form.get('email').valueChanges.subscribe(email => {
      this.form.get('email').setValue(email && email.toLowerCase(), { emitEvent: false });
    });
  }

  get hasPendency() {
    return (this.patient && this.patient.status !== 1) || this.form.value.pendency;
  }

  get cpfCtrl() {
    return this.form.get('cpf');
  }

  get dateCtrl() {
    return this.form.get('birthdate');
  }

  get verifiedCpf() {
    return this.form.get('verifiedCpf').value;
  }

  set verifiedCpf(value: boolean) {
    this.form.get('verifiedCpf').setValue(value);
  }

  async consultCpf() {
    const { cpf, birthdate: date, noCpf } = this.form.value;
    // A data que vem no formulário não vem no formato para que possa ser formatada.
    // Essa linha vai fazer com que a data fique no formato 00/00/0000.
    const dateOfBirth = moment(date, 'DDMMYYYY').format('YYYY-MM-DD');

    if (
      this.cpfCtrl.valid &&
      this.dateCtrl.valid &&
      (this.lastCpf !== cpf || this.lastDateOfBirth !== dateOfBirth || !this.verifiedCpf)
    ) {
      try {
        this.loading = true;
        const consult = await this.bigboostService.consultCpf(cpf, dateOfBirth).toPromise();
        this.consult = consult;
        this.loading = false;
        if (noCpf || consult.menorIdade) {
          this.showModalAddPatient(consult, noCpf);
        } else {
          this.handleConsultCpf(consult);
        }
      } catch (err) {
        console.error(err);
        this.loading = false;
        const consult = {
          status: -1,
          cpf,
          erroCodigo: err.status,
          erro: 'A importação de dados do cpf está com instabilidade'
        };
        this.form.get('pendency').setValue(consult);
        if (err.status === 401) {
          this.notification.warning('Aviso', 'Data de nascimento não corresponde ao CPF informado');
          this.form.get('birthdate').setErrors({ nonMatchingCpf: true });
          this.verifiedCpf = false;
        } else {
          this.handleConsultCpf(consult);
        }
      }
      this.lastCpf = cpf;
      this.lastDateOfBirth = dateOfBirth;
    }
  }

  private showModalAddPatient(consult, noCpf) {
    const initialState = {
      patient: consult,
      registeredByResponsible: true,
      isPet: this.isPet,
      isResponsible: noCpf || !consult.menorIdade,
      existingPatient: false
    };
    const modal = this.modalService.show(ModalAddPatientComponent, {
      class: 'modal-lg',
      initialState,
      backdrop: 'static',
      keyboard: false
    });
  }

  private handleConsultCpf(consult: Paciente) {
    this.form.get('name').reset();
    this.form.get('name').enable();

    if (consult.status === 1) {
      this.form.get('fullname').disable();
      this.form.get('membership.mothersName').disable();
      this.form.get('pendency').setValue(null);
      this.setForm(consult);
      if (!this.isRegisteredByPatient) {
        this.form.get('cpf').disable({ emitEvent: false });
        this.form.get('birthdate').disable({ emitEvent: false });
      }
    } else {
      this.form.get('fullname').enable();
      this.form.get('membership.mothersName').enable();
      this.form.get('pendency').setValue(consult);
      this.form.get('cpf').setValue(consult.cpf);
      this.resetForm();
      if (consult.erro) {
        this.notification.warning(
          'Aviso',
          'A importação de dados do CPF está com instabilidade, mas você poderá se cadastrar preenchendo os campos manualmente.'
        );
      }
    }
    this.verifiedCpf = true;
  }

  private setForm(consult: Paciente) {
    this.form.get('cpf').setValue(consult.cpf);
    this.form.get('fullname').setValue(consult.nome);
    this.form.get('name').setValue(consult.nome);
    this.form.get('sex').setValue(consult.genero);
    this.form.get('membership.mothersName').setValue(consult.mae);
    if (!this.form.value.dateOfBirth) {
      const nascimento = moment(consult.nascimento, 'DD/MM/YYYY');
      this.form.get('dateOfBirth').setValue(nascimento.format('YYYY-MM-DD'));
      this.form.get('birthdate').setValue(nascimento.format('DDMMYYYY'));
    }

    this.lastCpf = this.form.value.cpf;
    this.lastDateOfBirth = this.form.value.dateOfBirth;
  }

  private resetForm() {
    this.form.get('fullname').reset();
    this.form.get('sex').reset();
    this.form.get('membership.mothersName').reset();
  }

  async onKey(event: any) {
    if (event.target.value.length === 10) {
      const cep = event.target.value.replace(/[^\d]+/g, '');
      const data = await this.cepService.consult(cep);
      if (!data.erro) {
        this.form.controls['address'].setValue({
          uf: data.uf,
          street: data.logradouro,
          neighborhood: data.bairro,
          city: data.localidade,
          complement: data.complemento,
          number: null,
          cep: data.cep
        });
      }
    }
  }

  checkEmail(disable: boolean) {
    if (disable) {
      this.form.get('email').reset();
      this.form.get('email').disable();
    } else {
      this.form.get('email').enable();
    }
  }
}
