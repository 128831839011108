import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '@app/modules/user/user.service';
import { NoConcurrent } from '@app/shared/decorators/no-concurrent';
import { User } from '@app/shared/models/decodedLoginToken';
import { LoginResponse } from '@app/shared/models/loginResponse';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@env/environment';
import { LocalStorage } from '@ngx-pwa/local-storage';
import * as JwtDecode from 'jwt-decode';
import { of } from 'rxjs/internal/observable/of';
import { mergeMap, tap } from 'rxjs/operators';

export const USER_URL = 'user';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private _localStorage: LocalStorage,
    private userService: UserService,
    private jwtHelperService: JwtHelperService
  ) {}

  get token(): string {
    return localStorage.getItem('token');
  }

  get isLoggedIn(): boolean {
    return this.token && !this.jwtHelperService.isTokenExpired(this.token);
  }

  get isLoggedOut(): boolean {
    return !this.isLoggedIn;
  }

  user(): User {
    return this.token && this.decodeToken(this.token);
  }

  @NoConcurrent()
  login({ email, password }: { email: string; password: string }) {
    return this.http.post(`${environment.apiRoot}${USER_URL}/login`, { email, password }).pipe(
      tap((res: LoginResponse) => {
        this.setSession(res.token);
        this.userService.clearAvatar();
      })
    );
  }

  refreshToken() {
    return this.http.get(`${environment.apiRoot}${USER_URL}/refresh`).pipe(
      tap((res: LoginResponse) => this.setSession(res.token)),
      mergeMap(_ => of(this.user()))
    );
  }

  logout() {
    this._localStorage.removeItemSubscribe('id_token');
    this._localStorage.removeItemSubscribe('expire_at');
    localStorage.removeItem('extra_data');
    localStorage.removeItem('userId');
    localStorage.removeItem('userType');
    localStorage.removeItem('fullUserId');
    localStorage.removeItem('token');
    localStorage.removeItem('hideWomensModal');
    this.userService.clearAvatar();
  }

  decodeToken(token: string): any {
    return JwtDecode(token);
  }

  private setSession(token: string) {
    localStorage.setItem('token', token);
    this._localStorage.setItemSubscribe('id_token', token);
  }
}
