import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { HealthProfessionalService } from '@app/modules/health-professional/services/health-professional.service';
import { Address, HealthProfessional, Patient } from '@app/shared/models';
import { User } from '@app/shared/models/decodedLoginToken';
import { NzModalRef, NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PatientsService } from '../../services/patients.service';
import { ModalEditPatientComponent } from '../modal-edit-patient/modal-edit-patient.component';

@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.scss']
})
export class PatientDetailsComponent implements OnInit {
  @Output() close = new EventEmitter<boolean>();
  @Output() update = new EventEmitter<Patient>();

  tplModal: NzModalRef;

  @ViewChild('tplTitle', { static: true })
  tplTitle: TemplateRef<{}>;

  @ViewChild('tplContent', { static: true })
  tplContent: TemplateRef<{}>;

  @ViewChild('tplFooter', { static: true })
  tplFooter: TemplateRef<{}>;

  patient: Patient;
  prescriber: HealthProfessional;
  private patientsCache = new Map<string, boolean>();
  patientRecordStyle = {
    recordsArea: { height: '25vh' },
    input: { height: '95px', 'border-radius': '12px' }
  };

  loading = false;

  constructor(
    private modalService: NzModalService,
    private bsModalService: BsModalService,
    private notification: NzNotificationService,
    private authService: AuthService,
    private patientsService: PatientsService,
    private healthProfessionalService: HealthProfessionalService
  ) {}

  ngOnInit() {}

  get user(): User {
    return this.authService.user();
  }

  createTplModal(patient: Patient, prescriber: HealthProfessional): void {
    this.patient = patient;
    this.prescriber = prescriber;
    this.tplModal = this.modalService.create({
      nzTitle: this.tplTitle,
      nzContent: this.tplContent,
      nzFooter: null,
      nzClosable: true,
      nzWidth: 828,
      nzMaskClosable: true,
      nzOnCancel: () => (this.patient = null),
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () => console.log('Click ok')
    });

    if (this.patient.dependents) {
      this.patient.dependents.forEach(({ _id: id }) => {
        this.hasPatient(id);
      });
    }
  }

  private hasPatient(id: string) {
    this.healthProfessionalService.getPatientsById(id, this.user._id).subscribe(
      p => this.patientsCache.set(id, true),
      err => {
        if (err.status === 404) {
          this.patientsCache.set(id, false);
        } else {
          console.error(err);
        }
      }
    );
  }

  get hasPatientRecordFeature() {
    return this.user && this.user.features.find(f => f.name === 'Prontuário');
  }

  destroyTplModal(): void {
    this.tplModal.destroy();
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.destroyTplModal();
  }

  get hasAccount() {
    return this.patient.user && !this.patient.user.activationToken && this.patient.user.email;
  }

  get popoverContent() {
    return this.hasAccount
      ? 'O(a) paciente já ativou a conta na plataforma! Apenas algumas informações poderão ser alteradas por você. ' +
          'Se precisar de ajuda, entre em contato com nosso atendimento em receitadigital.com'
      : 'Clique no lápis para alterar os dados do paciente. ' +
          'Esta ação só será possível se o paciente ainda não tiver ativado a conta na plataforma.';
  }

  getAddress(address: Address) {
    if (!address) {
      return '';
    }
    return (
      (address.street || '') +
      (address.number ? ', ' + address.number : '') +
      (address.complement ? ', ' + address.complement : '') +
      (address.neighborhood ? ', ' + address.neighborhood : '') +
      (address.city ? ', ' + address.city : '') +
      (address.uf ? (address.city ? '-' : '') + address.uf : '')
    );
  }

  hasDependent(patient: Patient) {
    return this.patientsCache.get(patient._id);
  }

  addPatient(patient: Patient) {
    this.healthProfessionalService.addPatient(this.prescriber._id, patient._id).subscribe(_ => {
      this.hasPatient(patient._id);
      this.update.emit();
    });
  }

  removePatient(patient: Patient) {
    this.healthProfessionalService.removePatient(this.prescriber._id, patient._id).subscribe(_ => {
      this.hasPatient(patient._id);
      this.update.emit();
    });
  }

  showEditPatient(patient: Patient, isResponsible = false) {
    const initialState = { patient };

    const modal = this.bsModalService.show(ModalEditPatientComponent, {
      initialState,
      class: patient.responsible ? '' : 'modal-lg',
      backdrop: 'static',
      keyboard: false
    });
    modal.content.submit.subscribe(async (p: Patient) => {
      this.loading = true;
      const saved = await this.submitForm(p);
      if (isResponsible) {
        this.patient.responsible = saved;
      } else {
        this.patient = saved;
      }
      this.loading = false;
    });
  }

  private async submitForm(patient: Patient) {
    try {
      const saved = await this.patientsService.update(patient._id, patient).toPromise();
      this.update.emit(saved);
      this.notification.success('Sucesso', 'Paciente salvo com sucesso');
      return saved;
    } catch (err) {
      console.error(err);
      this.notification.error('Erro', 'Erro ao salvar paciente');
      return null;
    }
  }
}
