<div>
  <!-- <h4>
    TERMO DE USO E POLÍTICA DE PRIVACIDADE – RECEITA DIGITAL
  </h4> -->
  POR FAVOR, LEIA ATENTAMENTE ESTE TERMO DE USO E POLÍTICA DE PRIVACIDADE, POIS AO CLICAR EM “LI E ACEITO
  OS TERMOS DE USO E POLÍTICA DE PRIVACIDADE” VOCÊ ESTÁ ACEITANDO ELETRONICAMENTE AS REGRAS AQUI
  ESTABELECIDAS E DEMONSTRANDO A SUA

  <br />
  CONCORDÂNCIA AO USAR NOSSA PLATAFORMA OU APLICATIVO.
  <br />
  Em caso de dúvidas, antes de utilizar nossa plataforma/aplicativo, entre em contato por meio do nosso
  canal de atendimento: contato@receitadigital.com
  <br /><br />
  1 - INTRODUÇÃO
  <br />
  O site/ plataforma receitadigital.com e aplicativo, fazem parte de um hub de serviços de tecnologia
  oferecidos pela K&I - KNOWLEDGE AND INTELLIGENCE TECNOLOGIA LTDA, inscrita no CNPJ: 34.307.169/0001-00,
  com sede na Rua do Carmo, nº 71- 5º andar, Centro – Rio de Janeiro- RJ, CEP 20011-020, simplesmente
  denominada como “Receita Digital”. A empresa referida neste Termo de uso e Política de privacidade será
  denominada como “Receita Digital” e compromete-se a adotar as providências necessárias e de sua
  responsabilidade para a proteção da privacidade dos usuários nos serviços oferecidos. Este Termo de Uso
  e Política de privacidade descreve as regras aplicáveis ao contratante/usuário no acesso e uso da nossa
  plataforma e aplicativo. Ela foi elaborada para reafirmar o nosso compromisso com a privacidade e a
  segurança relacionadas aos serviços interativos e às informações coletadas e armazenadas dos nossos
  usuários. O documento abrange o tratamento que a nossa plataforma e aplicativo dão às informações
  capazes de identificar nossos contratantes/usuários. Todas as informações serão tratadas em
  concordância com a Lei Geral de Proteção de Dados Pessoais de 14 de agosto de 2018 (Lei nº
  13.709/2018).
  <br /><br />
  2 - DENOMINAÇÕES DESTE TERMO DE USO E POLÍTICA DE PRIVACIDADE.
  <br />
  2.1) A manutenção/suporte técnico utilizado por prescritores, farmácias e pacientes será realizado por
  meio do fale conosco.

  <br />
  2.2) Fale Conosco: Canal de contato entre a Receita Digital e seus contratantes (prescritores,
  farmácias e pacientes) para recebimento de chamados de Suporte ou Manutenção.

  <br />
  2.3) Contratante: aquele que usa a plataforma/aplicativo e se sujeita a estes Termos de Uso e Política
  de Privacidade, sejam prescritores/Estabelecimentos de saúde, farmácias/drogarias e pacientes.

  <br />
  2.4) Comprador: pessoa que apresenta a receita nos estabelecimentos comerciais Farmácias/Drogarias para
  compra. Poderá ser o próprio paciente/cliente ou outra pessoa autorizada pelo paciente/cliente.

  <br />
  2.5) Customização: inserções, adequações, modificações e desenvolvimento de funções na plataforma
  mediante demanda especial do Contratante. As customizações não interferem sobre os direitos de
  propriedade da plataforma que permanece em poder da Receita Digital.

  <br />
  2.6) Dados: qualquer informação inserida na plataforma/aplicativo, seja na forma escrita, de áudio,
  vídeo ou audiovisual.

  <br />
  2.7) Farmácia/ Drogaria: estabelecimento comercial público ou particular onde ocorre a dispensação e
  comercialização de medicamentos prescritos e produtos para a saúde.

  <br />
  2.8) Prescritores: profissionais da saúde que prescrevem receitas de medicamentos a seus pacientes/
  clientes dentro da plataforma Receita Digital.

  <br />
  2.9) Manutenção: serviços que visam manter o uso adequado da plataforma/aplicativo da Receita Digital,
  como correções e configurações.

  <br />
  2.10) Mensagem de Dados: toda informação gerada, enviada, recebida ou comunicada por meio eletrônico,
  ou similar.

  <br />
  2.11) Mudanças e ajustes: incremento nas funcionalidades ou tecnologia da plataforma, resultando ou não
  em nova versão, disponibilizadas a critério da equipe de desenvolvimento e programação da Receita
  Digital.

  <br />
  2.12) Paciente/Cliente: pessoa sujeita a tratamento prescritor que recebe a prescrição de medicamentos.

  <br />
  2.13) Planos de saúde: empresas que operam planos de saúde para a oferta de serviços prescritores,
  hospitalares, exames, tratamentos, mediante contrapartida financeira.

  <br />
  2.14) Plataforma/Aplicativo: programa de computador da marca Receita Digital, em suas diferentes
  versões e funcionalidades, de propriedade exclusiva da K&I - KNOWLEDGE AND INTELLIGENCE TECNOLOGIA
  LTDA, inscrita no CNPJ: 34.307.169/0001-00, protegido e regulado pela Lei 9.609/98.

  <br />
  2.15) Política de Privacidade: regras sobre privacidade, uso e transmissão de dados disponível no
  site www.receitadigital.com e www.receitadigital.com.br e www.receitadigital.med.br

  <br />
  2.16) Medicamentos/Produtos: itens de produção da Indústria que compõem o banco de dados da Receita
  Digital.

  <br />
  2.17) Suporte: Serviço de Atendimento ao usuário para esclarecimento de dúvidas quanto à instalação e
  utilização da plataforma.

  <br />
  2.18) Usuário: pessoa física ou jurídica (farmácias e drogarias) que é o nosso destinatário final de
  produtos ou serviços, nos termos do art. 2o e parágrafo único do Código de Defesa do Consumidor - Lei
  8.078, de 11 de setembro de 1990 que utiliza a plataforma Receita Digital.

  <br /><br />
  3- PLATAFORMA/ APLICATIVO DE PRODUTOS E SERVIÇOS
  <br />
  O Termo de Uso e Política de Privacidade aceitos constituem o contrato entre a Receita Digital e o
  Usuário/Contratante, e são suficientes neste momento para a formação e produção dos respectivos efeitos
  contratuais no caso de prescritores e pacientes Prescritores, Farmácias/Drogarias estão sujeitos à
  Proposta Comercial no que for cabível. A Receita Digital licencia o uso da plataforma aos mesmos de
  forma gratuita para a versão básica (Beta e funcionalidades do pacote “básico).
  <br />
  A Receita Digital se reserva o direito de divulgar promoções pontuais, que não configuram compromisso
  permanente junto aos usuários. Documentação complementar poderá ser produzida, vindo a integrar este
  Termo de Uso. Este Termo de Uso é composto por regras de licenciamento de uso do Software e Serviços de
  tecnologia da informação.

  <br />
  A plataforma/aplicativo da Receita Digital possibilita o acesso a informações por integrantes da cadeia
  do negócio e profissionais da Contratante (estabelecimentos de saúde e/ou farmácias e drogarias),
  mediante autorização prévia de compartilhamento de dados.

  <br />
  Cada camada da plataforma/aplicativo contempla informações relevantes para determinados públicos,
  Prescritores/Estabelecimentos de saúde, Farmácias/Drogarias, Pacientes e Planos de Saúde e o acesso a
  estas informações dar-se-á por meio de cadastro/ emissão de senhas exclusivas/ assinaturas com
  certificação digital e autorizações prévias, a fim de atender às regras da Política de Privacidade.

  <br />
  Ao realizar o cadastro na plataforma/aplicativo Receita Digital, o contratante/usuário deverá fornecer
  dados, tais como: informações pessoais identificáveis para criação de perfil e Informações
  profissionais e de pessoa jurídica. O contratante/usuário reconhece a importância da veracidade das
  informações fornecidas de si mesmo e das empresas e se compromete a mantê-las atualizadas, completas e
  verdadeiras assumindo a correspondente responsabilidade caso não sejam.

  <br />
  As futuras Extensões de funcionalidade estão cobertas por este Termo de Uso e Política de Privacidade e
  seguem todas as suas regras, inclusive às relativas à Política de Privacidade.

  <br />
  O uso adequado da plataforma/aplicativo pressupõe o atendimento a requisitos técnicos indispensáveis
  por parte do Contratante.

  <br />
  A Receita Digital é exclusiva proprietária e detentora dos direitos autorais sobre a plataforma/
  aplicativo protegidos e regulados pela Lei 9.609/98. Face aos direitos autorais que lhe pertencem,
  detém a exclusiva liberdade de exploração da plataforma/aplicativo, para os fins a que se destina.

  <br />
  A infração aos direitos autorais da Receita Digital implica em indenização por perdas e danos e/ou
  lucros cessantes na forma da lei.

  <br />
  Dentre outras práticas inaceitáveis e ilegais, considera-se violação de direitos autorais da Receita
  Digital a utilização, modificação, comercialização ou integração desautorizada da
  plataforma/aplicativo.

  <br />
  Para fins de auditoria e verificação de contrafação, pirataria ou uso indevido da
  plataforma/aplicativo, a Receita Digital detém livre acesso ao ambiente computacional dos
  Contratantes/Usuários.

  <br />
  A Receita Digital, caso entenda ser viável tecnicamente, prestará serviços de integração da
  plataforma/aplicativo com outros softwares/sistemas do Contratante/usuário, mediante prévio acordo
  convencionado entre as partes.

  <br />
  A Receita Digital desaconselha que integrações sejam realizadas diretamente pelo Contratante/Usuário,
  pois poderão desestabilizar nossa plataforma/aplicativo ou impactar seu funcionamento. Nestes casos, a
  Receita Digital não será responsabilizada, porém, pode auxiliar mediante demanda personalizada e
  proposta específica.

  <br />
  <br />
  4 - REFERENTE À MARCA, WEBSITE E PLATAFORMA/APLICATIVO

  <br />
  A Receita Digital é marca registrada da K&I - KNOWLEDGE AND INTELLIGENCE TECNOLOGIA LTDA, inscrita no
  CNPJ: 34.307.169/0001-00 protegida junto ao INPI, conforme Lei 9.279/96. É proibida a utilização
  desautorizada da referida marca, que incluem o nome e logotipo, salvo mediante consentimento expresso
  da Receita Digital.

  <br />

  Estão reservados à K&I - KNOWLEDGE AND INTELLIGENCE TECNOLOGIA LTDA, todos os direitos autorais
  relativos ao website, plataforma, aplicativo, desenho, programação, conteúdo, ficando expressamente
  proibida a reprodução, comunicação, distribuição e transformação dos referidos elementos protegidos,
  salvo mediante consentimento expresso da Receita Digital. A Receita Digital não utilizará marcas do
  Contratante/Usuário, exceto para divulgação da condição de Usuário na plataforma/aplicativo da Receita
  Digital e mediante consentimento do Contratante/usuário. O Contratante/Usuário poderá impedir a
  divulgação de sua marca como Usuário na plataforma/aplicativo da Receita Digital, mediante comunicação
  expressa e inequívoca à Receita Digital, com prazo razoável para cumprimento.
  <br />
  <br />
  5 - DA LICENÇA E PERMISSÃO DE USO
  <br />
  No caso da versão Premium e/ou aquisição de serviços/funcionalidades para utilizar a
  plataforma/aplicativo o Contratante/Usuário deverá estar adimplente com relação ao valor da licença e
  dos serviços, se for o caso, manter conexão à internet de boa qualidade, de acordo com as orientações
  da Receita Digital e respeitar o limite de um usuários e/ou prescrição ou dispensação por licença
  adquirida.

  <br />
  A conexão à internet de boa qualidade é fundamental ao adequado funcionamento da plataforma/aplicativo
  da Receita Digital, bem como permite a sua atualização tecnológica e outros serviços.

  <br />
  A plataforma/aplicativo não admite utilização off-line. O uso da plataforma/aplicativo pressupõe o
  respeito e prévia adesão à Política de Privacidade e aos direitos fundamentais a privacidade, imagem,
  honra, direitos autorais e regras de preservação de dados, inclusive condições comerciais, pedidos,
  registros pessoais e cadastrais. O Contratante/usuário deverá informar à Receita Digital sobre bloqueio
  ou restrições de uso da plataforma/aplicativo Receita Digital mediante comunicação prévia comprovada e
  inequívoca.

  <br />
  <br />
  6 - DOS DADOS FORNECIDOS E ARMAZENADOS
  <br />
  É fundamental que o Contratante/Usuário detenha a titularidade ou autorização para inserção e/ou acesso
  aos Dados. O Contratante/Usuário é responsável pelos Dados transmitidos, inseridos e acessados. A
  Receita Digital é responsável pelo seu armazenamento e acesso para oferecer com eficiência todos
  serviços de seu interesse no site/plataforma e aplicativo, bem como para garantir sua segurança,
  monitorar fraudes, cumprir nossas obrigações legais ou disponibilizar um canal de atendimento
  especializado para os nossos usuários.

  <br />
  Consentir com o uso de cookies de acordo com os termos desta política ao acessar nosso site/plataforma
  ou aplicativo pela primeira vez nos permitirá usar cookies toda vez que você acessar nosso
  site/plataforma ou aplicativo.

  <br />
  Caso o Contratante tenha interesse na exclusão ou limitação de acesso e/ou uso de Dados, deverá
  informar à Receita Digital, mediante comunicação prévia aos administradores da plataforma/aplicativo de
  comprovada e inequívoca pelo e-mail contato@receitadigital.com.

  <br />
  O Contratante/ usuário que não concordar com o presente Termo e quiser proceder com o cancelamento de
  seu cadastro, removerá seus dados para futuras consultas, mas esta remoção não funcionará de maneira
  retroativa, ou seja, as ações anteriores à essa decisão continuarão armazenadas e com uso de acordo com
  os termos previstos no presente Instrumento.

  <br />
  A plataforma/aplicativo pertence exclusivamente à Receita Digital, assim, a mesma está autorizada a
  incluir produtos, pacote de serviços próprios e de seus parceiros comerciais no mesmo, ceder espaços
  publicitários bem como explorar amplamente a plataforma/aplicativo dentro de suas aptidões técnicas.

  <br />
  A inserção de Dados deve respeitar a Política de Privacidade da plataforma/aplicativo Receita Digital.

  <br /><br />
  7 - TERMO DE GARANTIA E VALIDADE TÉCNICA
  <br />
  Durante a vigência contratual, a receita Digital garante o funcionamento adequado da
  plataforma/aplicativo Receita Digital para os fins a que se destina.

  <br />
  Erros são naturais e aceitáveis em plataforma/aplicativos, principalmente em sua versão Beta. Portanto,
  não dão ensejo à garantia, inclusive porque esta é de versão gratuita para todos os usuários
  (prescritores/Estabelecimentos de saúde, farmácias/drogarias e pacientes). O uso da garantia pressupõe
  o atendimento das instruções da plataforma/aplicativo, disponibilizadas no website Receita Digital e
  atualização tecnológica. Dentro do prazo de validade técnica, a Receita Digital garante a prestação de
  serviços relativos ao adequado funcionamento da plataforma/aplicativo. A plataforma/aplicativo será
  considerada adequada desde que suficiente na utilização para os fins a que se propõe, segundo suas
  especificações técnicas. Por tratar-se de bem móvel imaterial, sujeito a adaptações e incremento
  tecnológico, a Receita Digital não garante que a plataforma/aplicativo não apresentará erros, mas fará
  o possível para minimizá-los e/ou consertá-los caso ocorram.

  <br /><br />
  8 - DAS RESPONSABILIDADES
  <br />
  A responsabilidade da Receita Digital é de meio, e não de finalidade ou resultado sobre o uso ou a
  expectativa do Contratante/usuário com relação à plataforma/aplicativo e serviços ofertados.

  <br />
  <br />
  A Receita Digital poderá disponibilizar o software, o serviço, a plataforma, o aplicativo, o ambiente
  técnico, o atendimento, dentre outros, a fim de que o Contratante e demais

  <br />
  Usuários interajam, se relacionem, publiquem, acessem, utilizem ou pratiquem quaisquer outros atos
  autorizados, ofertados ou disponíveis no plataforma/aplicativo Receita Digital, de forma direta, sem a
  intermediação da Receita Digital.

  <br />
  A Receita Digital não intermedia, não edita, não audita, não fiscaliza e não monitora as ações e
  relações entre os Contratantes/Usuários e demais Usuários e estes e terceiros.

  <br />
  Eventuais intervenções da Receita Digital quanto ao uso da plataforma/aplicativo somente serão
  realizadas para fins de incremento de suas aptidões ou correções de erros e problemas.

  <br />
  Algumas disposições específicas sobre a responsabilidade compartilhada da Receita Digital e
  Contratante/Usuário:

  <br />
  Fato de Terceiro – A Receita Digital e o Contratante/Usuário reconhecem que a plataforma/aplicativo é
  segura, todavia, assim como ocorre em outros ambientes virtuais, está sujeito a ameaças e violação
  criminosa, sendo estas causas excludentes da responsabilidade dos mesmos. Ainda, as tecnologias web são
  suportadas por serviços de comunicação, tais como, internet e rede de telecomunicações, não oferecidos
  pela Receita Digital e que podem impactar no desempenho da plataforma/aplicativo, sendo considerados
  como fatos de terceiros, excludentes de responsabilidade.

  <br />
  Dever de Colaboração Recíproca – Receita Digital e Contratante/usuário deverão fornecer assistência
  razoável um ao outro, sem nenhum custo, para responder auditorias regulatórias, inspeções, inquéritos
  ou pedidos de autoridade relativos a plataforma/aplicativo, serviços ou transmissão de dados.

  <br />
  Informação – O Contratante/Usuário deverá notificar a Receita Digital de qualquer informação que receba
  sobre a segurança da plataforma/aplicativo, incluindo informação confirmada ou não confirmada de
  eventos adversos, graves ou inesperados associados com o uso do mesmo. Caso Fortuito ou Força Maior – A
  Receita Digital e o Contratante/Usuário não responderão por prejuízos resultantes de caso fortuito ou
  força maior, nos termos do Artigo 393, do Código Civil. São exemplos de Caso Fortuito ou Força Maior
  excludentes de responsabilidade, dentre outros, os seguintes eventos:

  <br />
  a) falhas decorrentes de um fato ou impedimento além do controle razoável da Receita Digital e do
  Contratante/Usuário;

  <br />
  b) atos ou fatos cuja inconformidade a Receita Digital e o Contratante/Usuário não poderiam
  razoavelmente esperar ou levar em conta no momento da conclusão do termo;

  <br />
  c) fatos que a Receita Digital e o Contratante/Usuário não poderiam razoavelmente ter evitado ou
  superado seus efeitos, inclusive intervenção de hackers, crackers, softwares de terceiros, antivírus,
  firewall, proxy, entre outros;

  <br />
  d) guerra (declarada ou não), conflitos armados ou ameaça grave destes, ataque hostil, bloqueio,
  embargo militar, invasão, ato de inimigo estrangeiro, guerra civil, rebelião, motim e revolução,
  comoção ou desordem, violência de multidão ou ato de desobediência civil;

  <br />
  e) ato de sabotagem, terrorismo ou pirataria, inclusive pirataria virtual; f) ato de autoridade,
  regulação, expropriação ou aquisição compulsória;
  <br />
  <br />
  g) fatos da natureza, pestes, epidemia, desastres naturais como tempestade, ciclone, tufão, furacão,
  relâmpago, tornado, tempestade violenta, terremoto, atividades vulcânicas; deslizamento de terras,
  maremoto, tsunami, inundação, danos ou destruição pela seca;

  <br />
  h) explosão, incêndio, raio, destruição de máquinas, equipamentos, fábricas, e de qualquer tipo de
  instalação, break-down de transportes, telecomunicações ou de corrente elétrica;

  <br />
  i) perturbação geral no trabalho, tais como, mas não limitadas a boicotar, greve, lock-out, ocupação de
  fábricas e instalações; Tempo Razoável - quando a duração ou impedimento invocado superar o prazo de 60
  (sessenta) dias, privando substancialmente a Receita Digital e o Contratante/Usuário dos efeitos deste
  termo, qualquer um destes poderá rescindir o contrato, sem ônus ou indenização.

  <br />
  Limitação da Responsabilidade: Respeitadas às previsões legais relativas a esta espécie de
  Termo/Contrato, a Receita Digital não será responsabilizada pelo uso abusivo da plataforma/aplicativo
  ou serviços quando o Contratante/Usuário pratica ofensa, difamação, ameaça, assédio, contrafação ou
  outras formas de violação de direitos pessoais ou proprietários, inclusive por lucros cessantes, perda
  de dados, descontinuidade de negócios, relacionados ao uso ou mau uso da plataforma/aplicativo, mesmo
  nos casos em que a Receita Digital tenha sido comunicada. Limitação Econômica da Responsabilidade:
  Tratando-se de hipótese em que é admitida a limitação da responsabilidade, segundo as regras legais e
  parâmetros jurisdicionais de cada País, em qualquer circunstância, a responsabilidade integral da
  Receita Digital, com relação à totalidade dos danos suportados, não será superior a R$ 1.000,00 (mil
  reais). Em conformidade ao art. 48 da Lei nº 13.709, a Receita Digital comunicará ao
  Contratante/usuário e à Autoridade Nacional de Proteção de Dados (ANPD) a ocorrência de incidente de
  segurança que possa acarretar risco ou dano relevante ao Contratante/usuário.
  <br />
  <br />
  9 - POLÍTICA DE PRIVACIDADE

  <br />
  A Política de Privacidade da Receita Digital contém normas de uso correto da plataforma/aplicativo e
  serviços oferecidos no que respeita aos dados pessoais dos Contratantes, Usuários e terceiros que
  tenham acesso aos mesmos.

  <br />
  A Política de Privacidade protege e regula especialmente os direitos da personalidade referentes à
  imagem, honra e vida privada das pessoas e empresas no âmbito do uso da plataforma/aplicativo.

  <br />
  É nossa Política de Privacidade que define a forma e limites de uso dos seus dados pessoais pela
  Receita Digital, sempre no intuito de melhorarmos nossa experiência com o Contratante/usuário. A
  legitimidade do objetivo da ação sempre prevalecerá.

  <br />
  A Contratante/Usuário permite, de acordo com a Política de Privacidade, que dados sejam transmitidos
  por meio da plataforma/aplicativo, para ações comerciais, promocionais e estatísticas, mediante prévia
  autorização por Mensagem de Dados. O objetivo será sempre oferecer com eficiência todos serviços de
  interesse do contratante/usuário na plataforma/aplicativo, bem como para garantir sua segurança,
  monitorar fraudes, cumprir nossas obrigações legais ou disponibilizar um canal de atendimento
  especializado para os contratantes/usuários.

  <br />
  Em caso de fusão, aquisição ou venda de participação societária pela Receita Digital, os dados, em sua
  totalidade, poderão ser transferidos ao terceiro envolvido a fim de garantir a continuidade de uso da
  plataforma/aplicativo.

  <br />
  É bom saber que os dados dos contratantes/usuários desde que manipulados de forma consciente,
  equilibrada e dentro da legalidade contribuem para o crescimento e aprimoramento da
  plataforma/aplicativo e serviços ofertados no conceito de nuvem, que pressupõe máxima integração,
  acessibilidade e proveito. Se você faz parte do grupo de pessoas que utilizam esse tipo de
  plataforma/aplicativo e serviços coligados compartilhe seus dados com empresas que possuem boas
  práticas e políticas claras de uso de dados.

  <br />
  10 - DA VIGÊNCIA DO USO DA PLATAFORMA/APLICATIVO
  <br />
  O prazo de uso da plataforma/aplicativo e serviços coligados da Receita Digital será indeterminado,
  salvo se a proposta comercial inicial dispuser o contrário.

  <br />
  O uso da plataforma/aplicativo poderá ser rescindido a qualquer tempo, mediante notificação expressa
  com 60 (sessenta) dias de antecedência.

  <br />
  O contrato entre a Receita Digital - K&I - KNOWLEDGE AND INTELLIGENCE TECNOLOGIA LTDA e o
  Contratante/Usuário poderá ser rescindido de pleno direito, independentemente de notificação ou
  interpelação, a qualquer tempo, no caso de qualquer das disposições deste Termo de Uso, podendo ainda,
  implicar em indenização por perdas e danos. Falência, recuperação judicial, extrajudicial ou liquidação
  da Receita Digital e/ou do Contratante/usuário implicam na rescisão contratual de pleno direito.

  <br /><br />
  11- DO PRAZO E REVOGAÇÃO DOS TERMOS DE USO E POLÍTICA DE PRIVACIDADE
  <br />
  Este Termo de Uso e Política de Privacidade entra em vigor na data de sua publicação, acima
  identificada, e seu prazo é indeterminado. O constante aprimoramento da plataforma/aplicativo Receita
  Digital e seus serviços bem como as alterações legais e mercadológicas poderá ensejar a modificação das
  regras previstas neste Termo de Uso e Política de Privacidade, neste caso, haverá a revogação deste
  instrumento, com imediata substituição por versão atualizada que passará a regular a relação entre a
  Receita Digital e o Contratante/usuário dentro de 10 (dez) dias úteis a contar da publicação.

  <br />
  A substituição deste Termo de Uso e Política de Privacidade por outro será divulgada nos canais de
  comunicação com o Contratante/usuário da Receita Digital, inclusive e-mail, porém, a responsabilidade
  de se manter informado é exclusivamente deste, que deverá visitar regularmente o site ou e-mails
  enviados pela Receita Digital.

  <br />
  A adesão à novos Termos de Uso e Política de Privacidade dar-se-á mediante a continuidade na utilização
  da plataforma/aplicativo Receita Digital e seus serviços coligados.

  <br />
  A discordância com os novos Termos de Uso e Política de Privacidade deverá ser imediatamente informada
  pelo Contratante/Usuário ao setor de Atendimento da Receita Digital para fins de solução da
  controvérsia ou rescisão do contrato.
  <br /><br />
  12 - SOBRE AS REGRAS GERAIS DESTE TERMO DE USO E POLÍTICA DE PRIVACIDADE
  <br />
  A Receita Digital poderá aceitar o descumprimento destas regras pelo Contratante/Usuário sem que
  signifique que renunciou a seus direitos, que as disposições foram alteradas ou que não possa vir a
  aplicá-las integralmente de forma imediata ou a qualquer tempo.

  <br />
  Se algumas regras deste Termo de Uso e Política de Privacidade não puderem ser aplicadas, este fato não
  afetará o todo do instrumento que permanecerá válido e vigente.

  <br />
  Se o Contratante/Usuário adquirir e disponibilizar a plataforma/aplicativo Receita Digital para outros
  usuários deverá informá-los sobre as condições deste Termo de Uso e Política de Privacidade, em
  especial de que o uso implicação em aceitação irrestrita das disposições previstas neste documento.

  <br />
  A Receita Digital e o Contratante/usuário reconhecem que este Termo de Uso e Política de Privacidade
  prevê condições proporcionais, não existindo vícios ou defeitos que possam acarretar a sua nulidade, em
  especial relacionados com dolo, erro, fraude, simulação ou coação, inexistindo qualquer fato que possa
  ser configurado como estado de perigo ou de necessidade.

  <br />
  As disposições deste Termo de Uso e Política de Privacidade prevalecerão sobre quaisquer outros
  entendimentos ou acordos anteriores.

  <br />
  A Receita Digital e o Contratante/usuário reconhecem a validade das mensagens de dados nas comunicações
  relativas ao uso da plataforma/ aplicativo e seus serviços, com equivalência probatória e funcional aos
  documentos em suporte de papel, desde que observada a efetividade da comunicação, sua integridade,
  autenticidade e a segurança do meio pelo qual são transmitidas.

  <br />
  A violação das disposições deste Termo de Uso e Política de Privacidade resultará ao infrator o dever
  de indenizar por perdas e dados, além de multa contratual a ser fixada por arbitramento, conforme as
  circunstâncias do caso.
  <br /><br />
  13- LEI APLICÁVEL <br />
  <br />
  O presente Termo/Contrato será regido pela legislação do Brasil.
  <br /><br />
  14- DO FORO
  <br />
  Ressalvados os direitos de consumidor, em que prevalece o foro do domicílio deste, qualquer questão ou
  controvérsia decorrente do presente Termo/Contrato será dirimida no foro da cidade do Rio de Janeiro –
  RJ. “Receita Digital” - K&I - KNOWLEDGE AND INTELLIGENCE TECNOLOGIA LTDA, inscrita no CNPJ:
  34.307.169/0001-00, com sede na Rua do Carmo, nº 71- 5º andar, Centro – Rio de Janeiro- RJ, CEP
  20011-020.

  <br /><br />
</div>
