<nz-modal
  theme
  class="modal-women"
  [(nzVisible)]="isVisible"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  [nzStyle]="{ top: '100px' }"
  nzWidth="400"
>
  <ng-template #modalContent>
    <div class="row">
      <div class="col-3 align-self-center">
        <img src="assets/icons/batom.png" alt="Batom" class="logo" />
      </div>
      <div class="col">
        <h2 class="title">
          Parabéns, Mulher!
        </h2>
        <p>
          Que tal comemorar aproveitando descontos especiais, frete grátis e kits exclusivos do
          <span class="bold">mês da mulher CR</span>? Clique e aproveite.
        </p>
      </div>
    </div>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="later()">
      Ver depois
    </button>
    <button nz-button nzType="primary" (click)="open()">
      Quero ver
    </button>
  </ng-template>
</nz-modal>
