import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PetPatientService } from '@app/modules/patients/services/pet-patient.service';
import { Furs } from '@app/shared/data/Fur';
import { Fur } from '@app/shared/models';
import { Race } from '@app/shared/models/races';
import { Species } from '@app/shared/models/species';

@Component({
  selector: 'app-pet-data-form',
  templateUrl: './pet-data-form.component.html',
  styleUrls: ['./pet-data-form.component.scss']
})
export class PetDataFormComponent implements OnInit {
  @Input() isResponsible = false;
  @Input() form: FormGroup;
  @Input() fullnameResponsible: string;

  @Output() patchFormEvent = new EventEmitter<any>();
  @Output() changeConsultResponsibleEvent = new EventEmitter<boolean>();

  loading: boolean;
  consulted: boolean;
  species: Species[];
  races: Race[];
  furs: Fur[] = Furs;
  showRepeatResponsibleName = true;

  constructor(private petPatientService: PetPatientService) {}

  async ngOnInit() {
    this.species = await this.petPatientService.getSpecies().toPromise();
  }

  get cpf() {
    return this.form.get('responsible.cpf');
  }

  get currentDate() {
    const now = new Date();
    const dd = String(now.getDate()).padStart(2, '0');
    const mm = String(now.getMonth() + 1).padStart(2, '0');
    const yyyy = now.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  }

  async getRaces(speciesId) {
    this.races = await this.petPatientService.getRaces(speciesId).toPromise();
  }
}
