import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '@app/core/services/validation.service';
import { HealthProfessionalService } from '@app/modules/health-professional/services/health-professional.service';
import { PatientsService } from '@app/modules/patients/services/patients.service';
import { HealthProfessional } from '@app/shared/models';
import { BigboostService } from '@app/shared/services/bigboost.service';
import { markFormGroup } from '@app/utils';
import { NzNotificationService } from 'ng-zorro-antd';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TypePatientEnum } from '../../enum/type-patient';

@Component({
  selector: 'app-modal-search',
  templateUrl: './modal-search.component.html',
  styleUrls: ['./modal-search.component.scss']
})
export class ModalSearchComponent implements OnInit {
  @Input() user: any;
  @Input() prescriber: HealthProfessional;
  @Input() addPatient = false;
  @Input() cpf: string;
  @Output() searchEvent = new EventEmitter();
  @Output() openModalAddPatientEvent = new EventEmitter<any>();
  @Output() updateListPatientEvent = new EventEmitter<any>();
  @Output() loadingSpinnerEvent = new EventEmitter<boolean>();

  form: FormGroup;
  loading = false;

  enumTypePatient = TypePatientEnum;
  typePatientSelect: TypePatientEnum;

  alertOptions = {
    nzDuration: 5000
  };

  constructor(
    private fb: FormBuilder,
    public modalRef: BsModalRef,
    private patientsService: PatientsService,
    private healthProfessionalService: HealthProfessionalService,
    private notification: NzNotificationService,
    private bigboostService: BigboostService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      cpf: [
        this.cpf,
        [
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(11),
          ValidationService.cpfValidator
        ]
      ],
      dateOfBirth: [],
      noCpf: [false]
    });

    if (this.cpf) {
      this.search();
    }

    this.form.get('noCpf').valueChanges.subscribe(value => {
      if (value) {
        this.form.get('dateOfBirth').clearValidators();
      } else {
        this.form.get('dateOfBirth').setValidators([Validators.required]);
      }
    });
  }

  get noCpf() {
    return this.form.value.noCpf;
  }

  async search() {
    markFormGroup(this.form);
    if (this.form.valid) {
      const { cpf, noCpf, dateOfBirth } = this.form.value;
      this.loadingSpinnerEvent.emit(true);

      if (this.typePatientSelect === this.enumTypePatient.ADULT) {
        const patientLinked = await this.patientLinked(cpf);
        if (Boolean(patientLinked)) {
          this.notification.warning(
            'Aviso',
            'O paciente informado já existe na sua lista de pacientes',
            this.alertOptions
          );
          this.loadingSpinnerEvent.emit(false);
          this.modalRef.hide();
          return;
        }
      }

      try {
        const patient = await this.getPatientByCpf(cpf);
        await this.addPatientToProfessional(patient._id);
        this.updateListPatientEvent.emit(patient);
        this.modalRef.hide();
        this.loadingSpinnerEvent.emit(false);
      } catch (erro) {
        if (erro.status === 404) {
          this.consultCpf(cpf, noCpf, dateOfBirth);
        } else {
          this.notification.error(
            'Atenção',
            'Ocorreu um erro ao tentar buscar paciente.',
            this.alertOptions
          );
        }
      }
    }
  }

  async consultCpf(cpf: string, noCpf: boolean, dateOfBirth?: string) {
    try {
      this.loadingSpinnerEvent.emit(true);
      const consult = await this.bigboostService.consultCpf(cpf, dateOfBirth).toPromise();
      this.modalRef.hide();
      this.loadingSpinnerEvent.emit(false);
      if (consult.menorIdade && this.enumTypePatient.ADULT === this.typePatientSelect) {
        this.notification.warning(
          'Aviso',
          ' O CPF é de um paciente menor, tente novamente como Paciente Menor',
          this.alertOptions
        );
      } else if (!consult.menorIdade && this.enumTypePatient.MINOR === this.typePatientSelect) {
        this.notification.warning(
          'Aviso',
          ' O CPF é de um paciente maior, tente novamente como Paciente Maior',
          this.alertOptions
        );
      } else {
        this.openModalAddPatientEvent.emit({
          patient: consult,
          isResponsible: noCpf,
          existingPatient: false
        });
      }
    } catch (error) {
      if (error.status === 401) {
        this.notification.warning(
          'Aviso',
          'A data de nascimento não pertence a este CPF. Favor digitar novamente.',
          this.alertOptions
        );
        this.loadingSpinnerEvent.emit(false);
        this.form.get('dateOfBirth').setErrors({ nonMatchingCpf: true });
        return;
      }
      this.notification.error(
        'Atenção',
        'A importação de dados do CPF está com instabilidade, preencha manualmente ou tente novamente mais tarde.',
        this.alertOptions
      );
      this.openModalAddPatientEvent.emit({
        patient: null,
        isResponsible: noCpf,
        existingPatient: false
      });
    }
  }

  async patientLinked(cpf: string) {
    const result = await this.healthProfessionalService
      .getPatientsByUserId(cpf, this.user._id)
      .toPromise();

    return result.length ? result[0] : null;
  }

  changeTypePatient(typePatient: TypePatientEnum) {
    this.typePatientSelect = typePatient;
    if (this.typePatientSelect === this.enumTypePatient.MINOR) {
      this.form.get('dateOfBirth').setValidators([Validators.required]);
    }
  }

  private async addPatientToProfessional(patientId: string) {
    await this.healthProfessionalService.addPatient(this.prescriber._id, patientId).toPromise();
  }

  private async getPatientByCpf(cpf: string) {
    return await this.patientsService.getPatientByCpf(cpf).toPromise();
  }
}
