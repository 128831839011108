import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { CroppedEvent } from 'ngx-photo-editor';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
export const USER_URL = 'user';
const ACCEPT_MANIPULATED_ACCEPTANCE_TERM = 'user/accept-manipulated-acceptance-term';
const ACCEPTED_MANIPULATED_TERM = 'user/accepted-manipulated-term';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) {}

  /*
   * ------------------------
   *  Cache da Imagem de Perfil
   * ------------------------
   */
  private _profileImage: string; // base64
  private _avatarSubject = new Subject<string>();

  getAvatar(): Observable<string> {
    const sub = this._avatarSubject;

    if (this._profileImage || this._profileImage === '') {
      setTimeout(() => sub.next(this._profileImage));
    } else {
      this.http
        .get<any>(`${environment.apiRoot}${USER_URL}/avatar`)
        .pipe(
          map(({ avatar }) => `data:image/jpeg;base64,` + avatar),
          tap(base64 => (this._profileImage = base64)),
          catchError(_ => {
            this._profileImage = '';
            return of(this._profileImage);
          })
        )
        .subscribe(res => sub.next(res));
    }

    return sub.asObservable();
  }

  updateAvatar({ file, base64 }: CroppedEvent): Observable<any> {
    const data = new FormData();

    data.append('avatar', file);
    return this.http.put<any>(`${environment.apiRoot}${USER_URL}/avatar`, data).pipe(
      map(res => {
        this._profileImage = base64;
        this._avatarSubject.next(base64);
      })
    ); // on success
  }

  clearAvatar(): void {
    this._profileImage = null;
  }

  getQuantityUsers(tenantId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiRoot}${USER_URL}/quantities`, { params: { tenantId } });
  }

  async acceptedManipulatedTerm(userId) {
    const response = await this.http
      .get<any>(`${environment.apiRoot}${ACCEPTED_MANIPULATED_TERM}/${userId}`)
      .toPromise();
    return response.manipulatedAccepranceTerm;
  }

  update(userId, data): Observable<any> {
    return this.http.put<any>(`${environment.apiRoot}${USER_URL}/${userId}`, data);
  }

  acceptManipulatedAcceptanceTerm(userId): Observable<any> {
    return this.http.put<any>(
      `${environment.apiRoot}${ACCEPT_MANIPULATED_ACCEPTANCE_TERM}/${userId}`,
      {}
    );
  }

  getUser(params: any): Observable<any> {
    return this.http.get<any>(`${environment.apiRoot}${USER_URL}`, { params });
  }

  checkEmailExists(email: string, tenantId?: string): Observable<any> {
    const params = { email };
    if (tenantId) {
      params['tenantId'] = tenantId;
    }
    return this.http.get<any>(`${environment.apiRoot}${USER_URL}/exists`, { params });
  }

  checkWomensMonth(): Observable<any> {
    return this.http.put<any>(`${environment.apiRoot}${USER_URL}/women`, {});
  }

  checkUiDoNotShowAgainModalSuccessPrescription(value: boolean): Observable<any> {
    return this.http.patch<any>(`${environment.apiRoot}${USER_URL}/ui`, {
      doNotShowAgainModalSuccessPrescription: value
    });
  }

  getControllerUserUi(id: string): Observable<any> {
    return this.http.get<any>(`${environment.apiRoot}${USER_URL}/${id}/ui`);
  }
}
