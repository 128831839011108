import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { ModalEditPersonalDataComponent } from '@app/modules/dashboard/modal-edit-personal-data/modal-edit-personal-data.component';
import { HealthProfessionalService } from '@app/modules/health-professional/services/health-professional.service';
import { PatientsService } from '@app/modules/patients/services/patients.service';
import { PharmacistService } from '@app/modules/pharmacist/services/pharmacist.service';
import { PharmacyService } from '@app/modules/pharmacy/services/pharmacy.service';
import { UserService } from '@app/modules/user/user.service';
import { BrazilianStates } from '@app/shared/data/Brazilian-states';
import { BrazilState } from '@app/shared/models';
import { User } from '@app/shared/models/decodedLoginToken';
import { PetPatient } from '@app/shared/models/pet-patient';
import { NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CroppedEvent } from 'ngx-photo-editor';
import { ModalDependentFormComponent } from '../modal-dependent-form/modal-dependent-form.component';
import { ModalEditAddressDataComponent } from '../modal-edit-address-data/modal-edit-address-data.component';
import { ModalEditPharmacyCovidComponent } from '../modal-edit-pharmacy-covid/modal-edit-pharmacy-covid.component';
import { ModalEditPharmacyTechnicianComponent } from '../modal-edit-pharmacy-technician/modal-edit-pharmacy-technician.component';

@Component({
  selector: 'app-my-data',
  templateUrl: './my-data.component.html',
  styleUrls: ['./my-data.component.scss', '../../dashboard/pages/index/index.component.scss']
})
export class MyDataComponent implements OnInit {
  modalRef: BsModalRef;
  states: BrazilState[] = BrazilianStates;
  completeUser: any;
  imageChangedEvent: any;
  logoChangedEvent: any;
  imageURL: any;
  avatarB64: string;
  loading = true;
  isModalCipVisible = false;
  showModal = '';
  isPetDataVisible = false;
  pet: PetPatient;

  private commercialId: any;

  private readonly MAX_FILE_SIZE_BYTES = 5 * 1024 * 1024; // 5 MB

  constructor(
    private router: Router,
    private authService: AuthService,
    private healthProfessionalService: HealthProfessionalService,
    private modalService: NzModalService,
    private patientService: PatientsService,
    private userService: UserService,
    private pharmacyService: PharmacyService,
    private pharmacistService: PharmacistService,
    private notification: NzNotificationService
  ) {
    const state = this.router.getCurrentNavigation().extras.state;
    if (state && state.showModalPersonalData) {
      this.showModal = 'showModalPersonalData';
    }
  }

  async ngOnInit() {
    this.updateUserData(await this.getCompleteUser());

    if (this.showModal) {
      this[this.showModal](true);
    }
  }

  get user(): User {
    return this.authService.user();
  }

  private updateUserData(data) {
    this.completeUser = data;
    this.loading = false;
    this.authService.refreshToken().subscribe();
  }

  private async getCompleteUser() {
    this.userService.getAvatar().subscribe(avatar => (this.avatarB64 = avatar));

    switch (this.user.userType) {
      case 'patient':
        return this.patientService.getPatientByUserId(this.user._id).toPromise();
      case 'healthProfessional':
        return this.healthProfessionalService.getHealthProfessional(this.user._id).toPromise();
      case 'pharmacist':
        return this.pharmacistService.getPharmacistByUserId(this.user._id).toPromise();
      default:
        return this.pharmacyService.getPharmacyByUserId(this.user._id).toPromise();
    }
  }

  get today() {
    return new Date();
  }

  get doctorName() {
    if (this.completeUser) {
      return `Dr${this.completeUser.sex === 'F' ? 'a' : ''}. ${this.completeUser.name}`;
    }
    return '';
  }

  get specialties() {
    return this.completeUser ? this.completeUser.specialties.map(s => s.title).join(', ') : '';
  }

  get cipFeature() {
    return this.user && this.user.features.find(f => f.name === 'CIP');
  }

  get cipFeatureAccepted() {
    if (this.cipFeature.accept === 'yes') {
      return 'Sim';
    }
    if (this.cipFeature.accept === 'no') {
      return 'Não';
    }
    return 'Não respondido';
  }

  changeCipVisibility(visibility: boolean) {
    this.isModalCipVisible = visibility;
  }

  showModalCip() {
    this.isModalCipVisible = true;
  }

  showModalPersonalData(touched = false) {
    const modal = this.modalService.create({
      nzContent: ModalEditPersonalDataComponent,
      nzComponentParams: {
        user: this.user,
        completeUser: this.completeUser,
        userTypeLogged: this.user.userType,
        touched
      },
      nzFooter: null,
      nzWidth: 800
    });
    modal.afterOpen.subscribe(() => {
      modal.getContentComponent().updateUserEvent.subscribe(user => this.updateUserData(user));
    });
  }

  showModalCovid() {
    const modal = this.modalService.create({
      nzContent: ModalEditPharmacyCovidComponent,
      nzComponentParams: { completeUser: this.completeUser },
      nzFooter: null,
      nzClosable: false
    });
    modal.afterOpen.subscribe(() => {
      modal.getContentComponent().updateUserEvent.subscribe(user => this.updateUserData(user));
    });
  }

  showModalBusinessData(isNew: boolean, data: any, index: number) {
    const modal = this.modalService.create({
      nzContent: ModalEditAddressDataComponent,
      nzComponentParams: {
        isNew,
        commercialDataIndex: index,
        commercialData: data,
        completeUser: this.completeUser,
        userType: this.user.userType
      },
      nzWidth: 600,
      nzFooter: null
    });

    modal.afterOpen.subscribe(() => {
      modal.getContentComponent().updateUserEvent.subscribe(user => this.updateUserData(user));
    });
  }

  async showModalRemoveBusinessData(index: number) {
    this.modalService.confirm({
      nzTitle: 'Alerta',
      nzContent: 'Tem certeza que deseja remover esses dados',
      nzOnOk: async () => {
        this.completeUser.commercialData.splice(index, 1);

        this.healthProfessionalService
          .update(this.completeUser.userId, this.completeUser)
          .subscribe(user => this.updateUserData(user));
      }
    });
  }

  showModalAddressData() {
    const modal = this.modalService.create({
      nzContent: ModalEditAddressDataComponent,
      nzComponentParams: { completeUser: this.completeUser, userType: this.user.userType },
      nzFooter: null
    });
    modal.afterOpen.subscribe(() => {
      modal.getContentComponent().updateUserEvent.subscribe(user => this.updateUserData(user));
    });
  }

  showModalPharmacyData() {
    const modal = this.modalService.create({
      nzContent: ModalEditPharmacyTechnicianComponent,
      nzComponentParams: { completeUser: this.completeUser, userType: this.user.userType },
      nzFooter: null
    });
    modal.afterOpen.subscribe(() => {
      modal.getContentComponent().updateUserEvent.subscribe(user => this.updateUserData(user));
    });
  }

  addDependent() {
    const modal = this.modalService.create({
      nzTitle: 'ADICIONAR DEPENDENTE',
      nzContent: ModalDependentFormComponent,
      nzComponentParams: { responsible: this.completeUser },
      nzFooter: null
    });

    modal.afterOpen.subscribe(_ => {
      modal.getContentComponent().submit.subscribe(patient => {
        patient.responsible = this.completeUser._id;
        this.patientService.save(patient).subscribe(
          res => {
            this.getCompleteUser();
            modal.close();
            this.notification.success('Sucesso', 'Dependente salvo com sucesso!');
          },
          err => {
            console.error(err);
            this.notification.error('Aviso', 'Erro ao salvar dependente');
          }
        );
      });
    });
  }

  editDependent(dependent: any) {
    const modal = this.modalService.create({
      nzTitle: 'EDITAR DEPENDENTE',
      nzContent: ModalDependentFormComponent,
      nzComponentParams: { responsible: this.completeUser, dependent },
      nzFooter: null
    });

    modal.afterOpen.subscribe(_ => {
      modal.getContentComponent().submit.subscribe(patient => {
        patient.responsible = this.completeUser._id;
        this.patientService.update(patient._id, patient).subscribe(
          res => {
            this.getCompleteUser();
            modal.close();
            this.notification.success('Sucesso', 'Dependente salvo com sucesso!');
          },
          err => {
            console.error(err);
            this.notification.error('Aviso', 'Erro ao salvar dependente');
          }
        );
      });
    });
  }

  showPetData(pet) {
    this.pet = pet;
    this.isPetDataVisible = true;
  }

  closePetModal() {
    this.isPetDataVisible = false;
  }

  fileChangeEvent(event) {
    this.imageChangedEvent = event;
  }

  async imageCropped(event: CroppedEvent) {
    try {
      this.loading = true;
      await this.userService.updateAvatar(event).toPromise();
      this.avatarB64 = event.base64;
      this.notification.success('Sucesso', 'Avatar salvo com sucesso!');
    } catch (err) {
      console.error(err);
      this.notification.error('Aviso', 'Erro ao salvar o seu avatar');
    }
    this.loading = false;
  }

  addLogo(event: Event, commercialId: string) {
    const target = event.target as HTMLInputElement;
    const size = target.files[0].size;
    if (size > this.MAX_FILE_SIZE_BYTES) {
      this.notification.warning(
        'Aviso',
        `O arquivo não pode ser maior que ${this.MAX_FILE_SIZE_BYTES / 1024 / 1024} MB`
      );
    } else {
      this.logoChangedEvent = event;
      this.commercialId = commercialId;
    }
  }

  async logoCropped(event: CroppedEvent) {
    try {
      this.loading = true;
      const { url } = await this.healthProfessionalService.uploadLogo(event, this.commercialId);
      this.completeUser.commercialData.forEach(c => {
        if (c._id === this.commercialId) {
          c.logoUrl = url;
        }
      });
      this.notification.success('Sucesso', 'Logo salvo com sucesso');
    } catch (error) {
      console.error(error);
      this.notification.error('Aviso', 'Erro ao salvar a logomarca');
    }
    this.loading = false;
  }

  showLogo(url: string, commercialId: string) {
    this.imageURL = url;
    this.commercialId = commercialId;
  }

  async removeLogo(commercialId: string) {
    this.modalService.confirm({
      nzTitle: 'Você tem certeza que deseja remover a Logo?',
      nzWidth: 300,
      nzContent: null,
      nzOnOk: async () => {
        try {
          await this.healthProfessionalService.removeLog(commercialId).toPromise();
          this.completeUser.commercialData.forEach(c => {
            if (c._id === commercialId) {
              c.logoUrl = null;
            }
          });
        } catch (error) {
          console.error(error);
        }
        this.loading = false;
      }
    });
  }
}
