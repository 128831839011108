import { Location } from '@angular/common';
import { ApplicationRef, Component, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval, ReplaySubject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { CoreService } from './core/services/core.service';
import { ThemeService } from './theme';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  route: string;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private _location: Location,
    private router: Router,
    private themeService: ThemeService,
    private titleService: Title,
    private swUpdate: SwUpdate,
    private appRef: ApplicationRef,
    private coreService: CoreService
  ) {
    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe(async val => {
      if (this._location.path() === '') {
        this.route = 'home';
      } else {
        this.route = this._location.path();
      }
    });

    const favicon: HTMLLinkElement = document.querySelector('#favicon');
    const theme = this.themeService.getActiveTheme();
    favicon.href = theme.favicon;
    this.titleService.setTitle(theme.title);

    const manifest: HTMLLinkElement = document.querySelector('#manifest');
    if (theme.name === 'theme-mrd') {
      manifest.remove();
    }

    this.updateClient();
    this.checkUpdate();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private updateClient() {
    if (this.swUpdate.isEnabled) {
      console.log('swUpdate enabled');
      this.swUpdate.available.subscribe(event => {
        console.log(`current`, event.current, `available `, event.available);
        this.swUpdate.activateUpdate().then(() => document.location.reload());
      });

      this.swUpdate.activated.subscribe(event => {
        console.log(`previous`, event.previous, `available `, event.current);
      });
    }
  }

  private checkUpdate() {
    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
    everySixHoursOnceAppIsStable$.subscribe(() => this.swUpdate.checkForUpdate());
  }
}
