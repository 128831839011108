<app-alternative-navbar titleIcon="fas fa-history"></app-alternative-navbar>
<app-sidebar-menu></app-sidebar-menu>

<div class="container mt-4" theme>
  <form (submit)="register()">
    <div class="row justify-content-md-center">
      <div>
        <div class="container-serp">
          <div class="document-serp d-flex column">
            <img alt="border" src="assets/icons/consultnullborder.svg" />
            <div>
              <h1>Receita não encontrada em nosso sistema!</h1>
              <p>
                Esta receita não foi gerada pela Receita Digital e nem pelos nossos <br /> parceiros.

                Também não existe registro para este PDF. <br />

                Clique abaixo para registrar as primeiras vendas. <br />
              </p>
            </div>
          </div>

          <button class="btn btn-icon btn-add-outiline text-capitalize" type="submit">
            <span class="btn-inner--text">Adicionar Dispensação</span>
            <span class="btn-inner--icon" theme><em class="fa fa-plus"></em></span>
          </button>
        </div>
      </div>
    </div>
  </form>

  <div class="footer bg-dark d-flex align-items-center fixed-bottom">
    <div class="back-dispensation">
      <button type="button" class="btn btn-secondary" (click)="register()" theme>
        <em class="fa fa-arrow-left"></em>
        CANCELAR DISPENSAÇÃO
      </button>
    </div>

    <div class="go-dispensation">
      <button type="button" class="btn btn-success" (click)="register()" theme>
        CONTINUAR PARA DISPENSAÇÃO
        <em class="fa fa-arrow-right"></em>
      </button>
    </div>
  </div>
</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
