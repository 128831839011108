<header theme>
  <span class="title">
    DADOS DO RESPONSÁVEL TÉCNICO
  </span>
</header>

<div class="container-modal-body">
  <form nz-form [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="row">
      <div [ngClass]="isEditing ? 'col-md-6' : 'col-md-5'">
        <nz-form-item>
          <nz-form-label nzFor="cpf" theme>CPF<span class="mandatory"> *</span></nz-form-label>
          <nz-form-control nzHasFeedback>
            <input
              nz-input
              id="cpf"
              formControlName="cpf"
              placeholder="000.000.000-00"
              mask="000.000.000-00"
              theme
            />
            <nz-form-explain *ngIf="form.get('cpf').dirty && form.get('cpf').errors">
              <ng-container *ngIf="form.get('cpf').hasError('required')">
                Campo obrigatório.
              </ng-container>
              <ng-container
                *ngIf="!form.get('cpf').hasError('required') && form.get('cpf').hasError('cpf')"
              >
                CPF inválido.
              </ng-container>
            </nz-form-explain>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div [ngClass]="isEditing ? 'col-md-6' : 'col-md-5'">
        <nz-form-item>
          <nz-form-label nzFor="birthDate"
            >Data Nascimento <span class="mandatory"> *</span></nz-form-label
          >
          <nz-form-control nzHasFeedback>
            <input nz-input type="date" placeholder="00/00/0000" formControlName="birthDate" theme />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="col-md-2 btn-ok-consult-cpf" *ngIf="!isEditing">
        <button
          nz-button
          class="btn-primary"
          type="button"
          (click)="consultCpf(form.get('cpf').value)"
          [disabled]="form.get('cpf').invalid || form.get('birthDate').invalid || loading"
        >
          <span *ngIf="!loading">Buscar</span>
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
            *ngIf="loading"
          ></span>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <nz-form-item>
          <nz-form-label nzFor="name" theme>Nome<span class="mandatory"> *</span></nz-form-label>
          <nz-form-control nzErrorTip="Campo obrigatório, por favor digite o seu nome." theme>
            <input type="text" nz-input formControlName="name" placeholder="Nome" maxlength="128" />
            <nz-form-explain *ngIf="form.get('name').dirty && form.get('name').errors">
              <ng-container *ngIf="form.get('name').hasError('required')">
                Por favor digite o nome.
              </ng-container>
            </nz-form-explain>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <nz-form-item>
          <nz-form-label nzFor="socialName" theme
            >Nome para exibição/social<span class="mandatory"> *</span></nz-form-label
          >
          <nz-form-control
            nzErrorTip="Campo obrigatório, por favor digite o seu nome para exibição."
            theme
          >
            <input
              type="text"
              nz-input
              formControlName="socialName"
              placeholder="Nome social"
              maxlength="128"
            />
            <nz-form-explain *ngIf="form.get('socialName').dirty && form.get('socialName').errors">
              <ng-container *ngIf="form.get('socialName').hasError('required')">
                Por favor digite o nome para exibição.
              </ng-container>
            </nz-form-explain>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="col-md-4">
        <nz-form-label nzFor="sexo" theme>Sexo</nz-form-label>
        <nz-select
          id="sexo"
          formControlName="sex"
          nzPlaceHolder="Selecione"
          nzDropdownClassName="sexo-select"
          theme
        >
          <nz-option *ngFor="let sexo of sexos" [nzLabel]="sexo?.label" [nzValue]="sexo?.value">
          </nz-option>
        </nz-select>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6" [formGroup]="form.get('crf')">
        <nz-form-item>
          <nz-form-label nzHasFeedback nzFor="crf" theme
            >CRF<span class="mandatory"> *</span></nz-form-label
          >
          <nz-form-control nzErrorTip="Campo obrigatório, por favor digite o seu CRF.">
            <input type="text" nz-input formControlName="number" placeholder="123456" theme />
            <nz-form-explain *ngIf="form.get('crf.number').dirty && form.get('crf.number').errors">
              <ng-container *ngIf="form.get('crf.number').hasError('required')">
                Por favor digite o número do CFR.
              </ng-container>
            </nz-form-explain>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="col-md-6" [formGroup]="form.get('crf')">
        <nz-form-item>
          <nz-form-label nzFor="uf" theme>UF<span class="mandatory"> *</span></nz-form-label>
          <nz-form-control nzHasFeedback nzErrorTip="Campo obrigatório, por favor selecione o UF do CRM">
            <nz-select
              formControlName="uf"
              nzShowSearch
              nzAllowClear
              nzDropdownClassName="uf-select"
              nzPlaceHolder="Selecione"
              theme
            >
              <nz-option *ngFor="let state of states" [nzLabel]="state?.sigla" [nzValue]="state?.sigla">
              </nz-option>
            </nz-select>
            <nz-form-explain *ngIf="form.get('crf.uf').dirty && form.get('crf.uf').errors">
              <ng-container *ngIf="form.get('crf.uf').hasError('required')">
                Campo obrigatório, por favor selecione o UF.
              </ng-container>
            </nz-form-explain>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
  <div class="d-flex align-items-center justify-content-end mt-3">
    <button
      class="btn btn-icon btn-ok text-capitalize btn-save"
      type="button"
      (click)="submitForm()"
      theme
      [disabled]="form.invalid && !loading"
    >
      <span class="btn-inner--text">Salvar</span>
    </button>
  </div>
</div>
