<div class="float-button">
  <button class="round-btn medical-record-btn" *ngIf="!visible" (click)="setVisible()">
    <img src="assets/icons/file-medical-solid.svg" alt="" />
  </button>

  <form [formGroup]="patientRecordForm" *ngIf="visible" class="patient-record">
    <div class="icons">
      <img
        src="assets/icons/window-minimize-regular.svg"
        class="icon minimize"
        title="Miniminar"
        (click)="setVisible()"
      />
      <img
        *ngIf="patient"
        src="assets/icons/backspace-solid.svg"
        class="icon"
        title="Buscar outro paciente"
        (click)="resetPatient()"
      />
    </div>

    <div [ngClass]="{ 'max-lenght max-width': patient, 'min-width min-width padding': !patient }">
      <span class="title" *ngIf="!patient">PRONTUÁRIO</span>
      <nz-input-group [nzSuffix]="suffixIconSearch" *ngIf="!patient">
        <nz-form-control>
          <input
            nz-input
            [nzAutocomplete]="autoName"
            appPatientSearch
            placeholder="Pesquisar"
            class="search-ipt"
            formControlName="name"
          />
          <nz-autocomplete #autoName nzBackfill>
            <nz-auto-option
              (click)="onChangePatient(option)"
              *ngFor="let option of patients"
              [nzLabel]="option.cpf !== '-1' ? option.name : ''"
              [nzValue]="option.cpf !== '-1' ? option.name : ''"
              class="mao"
            >
              {{ option?.name }}
              {{ option?.cpf !== '-1' ? (option?.cpf | formattedCpf) : '' }}
            </nz-auto-option>
          </nz-autocomplete>
        </nz-form-control>
      </nz-input-group>
      <ng-template #suffixIconSearch>
        <i nz-icon nzType="search"></i>
      </ng-template>
      <span *ngIf="!patient" class="select-text">Selecione um paciente para começar</span>

      <!-- details -->
      <div *ngIf="patient">
        <div class="media padding">
          <img
            class="mr-5"
            *ngIf="patient?.img"
            [src]="'assets/img/' + patient?.img"
            alt="Generic placeholder image"
          />
          <div class="media-body">
            <div class="media-header d-flex align-items-center">
              <h5 class="mt-0 title">{{ patient?.name }}</h5>
            </div>
            <p class="document">CPF: {{ patient?.cpf | formattedCpf }}</p>
          </div>
        </div>

        <app-patient-record [patient]="patient" [customStyle]="patientRecordStyle"></app-patient-record>
      </div>
    </div>
  </form>
</div>
