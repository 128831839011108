<app-alternative-navbar title="Pacientes" titleIcon="fa-assignment"></app-alternative-navbar>
<app-sidebar-menu></app-sidebar-menu>
<section class="sub-header" theme>
  <form [formGroup]="searchForm">
    <div class="form-group">
      <div class="input-group input-group-lg">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fa fa-search"></i></span>
        </div>
        <input
          formControlName="query"
          class="form-control form-control-lg"
          placeholder="Pesquisa"
          type="search"
        />
        <div class="input-group-append">
          <button (click)="clearQuery()" class="btn btn-icon" type="button">
            <span class="input-group-text">
              <i class="fa fa-times"></i>
            </span>
          </button>
        </div>
      </div>
    </div>
  </form>
</section>
<section class="d-flex">
  <aside class="filters-menu patient">
    <div class="list">
      <button type="button" class="list-item">
        <div class="list-item-content">
          <i class="fa fa-dashed-user"></i>
          <div class="list-text">
            <h4 class="list-line">Pacientes ({{ totalRecord }})</h4>
          </div>
        </div>
      </button>
      <button type="button" class="list-item" (click)="showModalSearch()">
        <div class="list-item-content">
          <i class="fa fa-plus"></i>
          <div class="list-text">
            <h4 class="list-line">Novo paciente</h4>
          </div>
        </div>
      </button>
    </div>
  </aside>
  <div class="content section patient pt-0 pb-0 bg-secondary flex-grow-1">
    <div>
      <nz-table
        *ngIf="dataSet"
        #rowSelectionTable
        [nzData]="dataSet"
        nzNoResult="Você ainda não possui nenhum paciente!"
        class="custom-table"
        (nzCurrentPageDataChange)="currentPageDataChange($event)"
        nzFrontPagination="false"
        [nzTotal]="totalRecord"
        [nzPageSize]="pageSize"
        (nzPageIndexChange)="requestMorePatients($event)"
        [nzScroll]="scrollConfig"
      >
        <tbody>
          <tr *ngFor="let patient of rowSelectionTable.data">
            <td class="blank-td icon">
              <img
                src="assets/icons/hand-holding-medical.svg"
                *ngIf="patient.responsible"
                width="22px"
              />
            </td>

            <td class="pl-0 patient-name">
              {{ patient.name }}
              <span class="responsible-name" *ngIf="patient.responsible"
                ><br />RESPONSÁVEL: {{ patient.responsible.name }}</span
              >
            </td>
            <td>
              <span *ngIf="patient.user as user">
                {{ user.email }}
                <span *ngIf="user.emailContact && user.emailContact !== user.email">
                  / {{ user.emailContact }}
                </span>
              </span>
            </td>
            <td>{{ patient.cpf | formattedCpf }}</td>
            <td>{{ patient.cellphone | formattedTel }}</td>
            <td>{{ patient.telephone | formattedTel }}</td>
            <td class="actions-btn">
              <a (click)="redirectToNewPrescription(patient)" title="Nova Prescrição">
                <i class="fa fa-file-medical"></i>
              </a>
              <a (click)="redirectToPatientPrescriptions(patient._id)" title="Histórico"
                ><i class="fas fa-history"></i
              ></a>
              <a title="Informações" (click)="togglePatientModalDetails($event, patient)"
                ><i class="fa fa-clipboard-list"></i
              ></a>
              <a title="Desvincular Paciente" (click)="showModalRemovePatient(patient._id)">
                <i class="fa fa-trash-solid"></i>
              </a>
            </td>
            <td class="blank-td"></td>
          </tr>
        </tbody>
      </nz-table>
    </div>

    <app-patient-details #modalPatient></app-patient-details>
  </div>
</section>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
