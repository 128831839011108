<div class="modal-header" theme>
  <h4 class="modal-title pull-left text-white">Atualizar dados cadastrais</h4>
</div>
<div class="modal-body" [ngClass]="{ 'modal-body-width': !viewAddress() }">
  <nz-alert
    class="mb-4 w-100"
    nzType="warning"
    nzMessage="Para continuar acessando o sistema, preencha os campos obrigatórios"
  ></nz-alert>
  <form nz-form [formGroup]="form">
    <fieldset class="w-100 mb-2" *ngIf="!patient.identity || !patient.cellphone">
      <legend theme>Dados Complementares</legend>
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="!patient.identity">
          <nz-form-item>
            <nz-form-label nzFor="identity">Identidade <span class="mandatory">*</span></nz-form-label>
            <nz-form-control nzHasFeedback>
              <input
                formControlName="identity"
                id="identity"
                nz-input
                placeholder="Digite sua identidade (somente números)"
                type="number"
                theme
              />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="!patient.cellphone">
          <nz-form-item *ngIf="form.get('cellphone') as cellphone">
            <nz-form-label nzFor="cellphone">Telefone <span class="mandatory">*</span></nz-form-label>
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                id="cellphone"
                [mask]="cellphone.value.length <= 10 ? '(00) 0000-00009' : '(00) 00000-0000'"
                placeholder="(21) 92222-2222"
                formControlName="cellphone"
                theme
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </fieldset>
    <app-address-data-form
      *ngIf="viewAddress()"
      [form]="form.get('address')"
      [required]="true"
      alertMsg="Para receber Receitas de Controle Especial (listas C1, C5 e adendos das listas A1, B1 e B2), deverá ser preenchido o endereço completo (RDC 344/98)."
    ></app-address-data-form>
  </form>
</div>
<div class="modal-footer">
  <button class="ok-btn btn-salvar" nz-button theme (click)="save()">Salvar <i class="fa"></i></button>
</div>
