import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CrService } from '@app/modules/document/services/cr.service';
import { Patient } from '@app/shared/models';
import { ColorSetting } from '@app/shared/models/color-setting';
import { Observable, ReplaySubject, timer } from 'rxjs';
import { debounceTime, filter, take, takeUntil, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-prescription',
  templateUrl: './prescription.component.html',
  styleUrls: ['./prescription.component.scss']
})
export class PrescriptionComponent implements OnInit, OnDestroy {
  @Input() patient: Patient;
  @Input() editMode: boolean;
  @Input() colorSetting: ColorSetting;
  @Output() formEmitter: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  public adding = true;
  public search = true;
  signed = false;
  public medications$: Observable<any[]>;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  prescriptionForm = this.fb.group({
    code: [''],
    manipulatedprofile: [undefined],
    showPatientCpf: [true],
    showPrescriberCpf: [false],
    medication: [''],
    medicaments: this.fb.array([], Validators.required),
    local: [''],
    version: ['PRODUCTION']
  });

  constructor(private crService: CrService, private fb: FormBuilder) {
    this.addRow();
  }

  ngOnInit() {
    this.formEmitter.emit(this.prescriptionForm);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  @Input()
  set prescription(prescription: any) {
    if (prescription) {
      this.medicamentsFormCtrl.removeAt(0);
      this.signed = prescription.status === 'Assinada';
      this.prescriptionForm.patchValue(prescription);
      prescription.medicaments.forEach(m => {
        this.addPrescription({
          ...m,
          nome: m.name,
          id: m.medicamentId,
          principio: m.principle,
          laboratorio: m.laboratory,
          ean: m.ean,
          apresentacaoCustomizada: m.description,
          quantity: m.prescribedQuantity,
          chooseMedicament: false,
          addedMedication: true,
          manipuled: m.manipuled
        });
      });

      timer(100)
        .pipe(take(1))
        .subscribe(() => (this.adding = false));
    }
  }

  @Input()
  set prescriptionModel(prescriptionModel: any) {
    if (prescriptionModel) {
      this.removeEmptyMedicaments(this.medicamentsFormCtrl);
      prescriptionModel.medicaments.forEach(m => {
        this.addMedicament({
          ...m,
          quantity: m.prescribedQuantity,
          chooseMedicament: false,
          addedMedication: true
        });
      });

      timer(100)
        .pipe(take(1))
        .subscribe(() => (this.adding = false));
    }
  }

  private removeEmptyMedicaments(medicamentsFormCtrl: FormArray): void {
    for (let i = medicamentsFormCtrl.controls.length - 1; i >= 0; i--) {
      const medicament = medicamentsFormCtrl.controls[i].value;
      if (!medicament.name) {
        medicamentsFormCtrl.removeAt(i);
      }
    }
  }

  @Input()
  set manipulatedProfile(manipulatedProfile: any) {
    if (manipulatedProfile) {
      this.prescriptionForm.get('manipulatedprofile').setValue(manipulatedProfile._id);
      this.medicamentsFormCtrl.removeAt(0);
      manipulatedProfile.medicaments.forEach(m => {
        this.addPrescription({
          ...m,
          nome: m.name,
          chooseMedicament: false,
          addedMedication: true,
          manipuled: true
        });
      });
      timer(100)
        .pipe(take(1))
        .subscribe(() => (this.adding = false));
    }
  }

  get showCpfText() {
    return this.patient && !this.patient.cpf
      ? 'MOSTRAR CPF DO RESPONSÁVEL (Menor sem CPF)'
      : 'MOSTRAR CPF DO PACIENTE';
  }

  formMedicaments({
    name,
    medicamentId,
    principle,
    laboratory,
    ean,
    quantity,
    description,
    dosage,
    medication,
    chooseMedicament,
    addedMedication,
    manipuled,
    disabled = false
  }): FormGroup {
    const formGroup = new FormGroup({
      name: new FormControl(name),
      medicamentId: new FormControl(medicamentId),
      prescribedQuantity: new FormControl(quantity),
      dosage: new FormControl(dosage, Validators.required),
      description: new FormControl(description),
      principle: new FormControl(principle),
      laboratory: new FormControl(laboratory),
      ean: new FormControl(ean),
      medication: new FormControl(medication),
      quantity: new FormControl(quantity),
      chooseMedicament: new FormControl(chooseMedicament),
      addedMedication: new FormControl(addedMedication),
      manipuled: new FormControl(manipuled),
      disabled: new FormControl(disabled)
    });
    formGroup
      .get('medication')
      .valueChanges.pipe(
        filter(value => value && value.length >= 3 && this.search),
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.destroyed$)
      )
      .subscribe(text => {
        this.medications$ = this.crService.searchMedicament(text);
      });

    if (disabled) {
      formGroup.disable();
    }

    return formGroup;
  }

  editMedicament(medicament) {
    medicament.get('chooseMedicament').setValue(true);
    medicament.get('medication').setValue(medicament.get('name').value);
    this.search = true;
  }

  selectMedicament(medicament: FormGroup, selectedMedicament: any) {
    this.search = false;
    this.adding = false;
    medicament.get('name').setValue(selectedMedicament.nome);
    medicament.get('principle').setValue(selectedMedicament.principio);
    medicament.get('laboratory').setValue(selectedMedicament.laboratorio);
    medicament.get('ean').setValue(selectedMedicament.ean);
    medicament.get('description').setValue(selectedMedicament.apresentacaoCustomizada);
    medicament.get('prescribedQuantity').setValue(1);
    medicament.get('medication').setValue(null);
    medicament.get('chooseMedicament').setValue(false);
    medicament.get('addedMedication').setValue(true);
    medicament.get('medicamentId').setValue(selectedMedicament.id);
    this.medications$ = undefined;
  }

  get medicamentsFormCtrl(): FormArray {
    return <FormArray>this.prescriptionForm.get('medicaments');
  }

  public addRow() {
    this.addPrescription({
      nome: '',
      id: '',
      principio: '',
      laboratorio: '',
      ean: '',
      apresentacaoCustomizada: '',
      quantity: 0,
      medication: null,
      chooseMedicament: true,
      addedMedication: false,
      manipuled: false
    });
    this.search = true;
  }

  public toggleAdding() {
    this.adding = !this.adding;
  }

  private addPrescription({
    nome,
    id,
    principio,
    laboratorio,
    ean,
    apresentacaoCustomizada,
    dosage = '',
    quantity = 1,
    medication,
    chooseMedicament,
    addedMedication,
    manipuled,
    disabled = false
  }): void {
    this.medicamentsFormCtrl.push(
      this.formMedicaments({
        name: nome,
        medicamentId: id,
        principle: principio,
        laboratory: laboratorio,
        ean: ean,
        description: apresentacaoCustomizada,
        dosage,
        quantity,
        medication,
        chooseMedicament,
        addedMedication,
        manipuled,
        disabled
      })
    );
    // this.handleMedicationAutoBlur();
    this.medications$ = undefined;
    this.search = true;
  }

  private addMedicament(medicament: any): void {
    this.medicamentsFormCtrl.push(this.formMedicaments(medicament));
  }

  public deletePrescription(index: number): void {
    this.medicamentsFormCtrl.removeAt(index);
    if (this.medicamentsFormCtrl.value.length) {
      this.adding = false;
    } else {
      this.addRow();
    }
  }

  public setSearch(search) {
    this.search = search;
  }

  public addManual(medicamentFormGroup: FormGroup) {
    const name = medicamentFormGroup.get('medication').value;
    medicamentFormGroup.get('chooseMedicament').setValue(false);
    medicamentFormGroup.get('addedMedication').setValue(true);
    medicamentFormGroup.get('prescribedQuantity').setValue(1);
    medicamentFormGroup.get('name').setValue(name);
    medicamentFormGroup.get('medication').setValue(null);
    medicamentFormGroup.get('description').setValue(undefined);
    medicamentFormGroup.get('principle').setValue(undefined);
    medicamentFormGroup.get('ean').setValue(undefined);
    medicamentFormGroup.get('medicamentId').setValue(undefined);
    this.search = false;
    this.adding = false;
  }
}
