<app-alternative-navbar titleIcon="fas fa-history"></app-alternative-navbar>
<app-sidebar-menu></app-sidebar-menu>

<div class="container my-4" theme>
  <div class="container-fluid patient-card p-3">
    <h3>PACIENTE: {{ patient.name }}</h3>
    <h3>CPF: {{ patient.cpf | mask: '000.000.000-00' }}</h3>
    <h4 *ngIf="patient.address as address">
      Endereço: {{ address.street }}, {{ address.number }} - {{ address.neighborhood }},
      {{ address.city }}, {{ address.uf }} -
      {{ address.cep | mask: '00.000-000' }}
    </h4>
    <h4 *ngIf="getPatientAge()">Idade: {{ getPatientAge() }} anos</h4>
  </div>

  <div class="container-fluid professional-card p-3">
    <h3 class="label-medico">MÉDICO: {{ healthProfessional.name }}</h3>
    <h4 class="label-medico" *ngIf="healthProfessional.regionalCouncilNumber as rcn">
      {{ rcn.label }}: {{ rcn.number }}/{{ rcn.uf }}
    </h4>
  </div>

  <div class="container-fluid medicaments-card p-3">
    <ng-container *ngIf="hasDispensation">
      <p id="alert">Atenção! Existem vendas registradas para esta receita!</p>
      <p>
        <button *ngIf="showDispensations" class="btn btn-icon p-2" (click)="showDispensations = false">
          <em class="fa fa-eye"></em>&nbsp;Ocultar Dispensações&nbsp;
        </button>
        <button
          *ngIf="!showDispensations"
          class="btn btn-icon btn-show-dispensations p-2"
          (click)="showDispensations = true"
        >
          <em class="fa fa-eye-slash"></em>&nbsp;Mostrar Dispensações&nbsp;
        </button>
      </p>
    </ng-container>

    <p id="instructions">
      Selecione abaixo os medicamentos que deseja dispensar para começar.
    </p>

    <div class="checkbox-group m-2">
      <label
        nz-checkbox
        id="select-all"
        [(ngModel)]="allChecked"
        (nzCheckedChange)="updateAllChecked()"
        [nzIndeterminate]="indeterminate"
        >SELECIONAR TODOS</label
      >
      <div class="checkbox-item" *ngFor="let medicament of medicaments">
        <label nz-checkbox class="medicament-name" [(ngModel)]="medicament.checked">{{
          medicament.name
        }}</label>
        <p class="pl-4">{{ medicament.dosage }}</p>

        <ng-container *ngIf="showDispensations && medicament.dispensations">
          <div class="dispensation pl-5" *ngFor="let dispensation of medicament.dispensations">
            <em class="fas fa-calendar-alt"
              >&nbsp; {{ dispensation.lastUpdated | date: 'dd/MM/yyyy HH:mm' }}</em
            >
            <p>{{ dispensation.name }}</p>
            <p class="mb-4">Lote: {{ dispensation.batch.lotNumber }}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="footer bg-dark d-flex align-items-center fixed-bottom justify-content-end">
    <button type="button" class="btn btn-success" (click)="register()" [disabled]="!someChecked">
      CONTINUAR PARA DISPENSAÇÃO
      <em class="fa fa-arrow-right"></em>
    </button>
  </div>
</div>
