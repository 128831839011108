<p class="bold">Dados bancários para recebimento do valor das consultas.</p>

<p>
  Você pode usar a Teleconsulta Receita Digital para atender seus próprios pacientes e também para
  atender novos pacientes que procuram nossa plataforma em busca de atedimento médico.
</p>

<p>
  Assim, o paciente faz o pagamento online direto no nosso site, e nós encaminhamos o pagamento para
  você! Para isso, precisaremos dos seus dados bancários para encaminhar o valor das consultas pagar
  online.
</p>

<p>
  Fique tranquilo, está informação não será divulgada em lugar nenhum, e servirá exclusivamente para
  encaminhar o valor das suas consultas! Se você usa um CNPJ para receber pelas suas consultas, basta
  escolher a opção "Pessoa Jurídica" no campo PERFIL DA CONTA. Estes serão os dados utilizados para
  emissão da Nota Fiscal das consultas pagas através da nossa plataforma.
</p>

<p>Se tiver alguma dúvida, é só clicar no link abaixo e falar com a gente pelo chat!</p>
<a href="">link</a>

<!-- Tipo de perfil -->
<nz-divider nzText="Perfil da conta" nzOrientation="left"></nz-divider>
<nz-select
  [(ngModel)]="profile"
  (ngModelChange)="onChangeProfile($event)"
  nzPlaceHolder="Selecione o perfil"
  class="col-sm-6"
>
  <nz-option nzValue="pf" nzLabel="PESSOA FÍSICA"></nz-option>
  <nz-option nzValue="pj" nzLabel="PESSOA JURÍDICA"></nz-option>
</nz-select>

<form nz-form [formGroup]="form" *ngIf="profile">
  <div *ngIf="profile === 'pj'">
    <div class="row">
      <nz-divider nzText="Dados da empresa" nzOrientation="left"></nz-divider>
      <nz-form-item class="col-4">
        <nz-form-label>CNPJ<span class="mandatory">*</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input nz-input formControlName="identity" mask="00.000.000/0000-00" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="col-4">
        <nz-form-label>Razão Social<span class="mandatory">*</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input nz-input formControlName="name" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="col-4">
        <nz-form-label>Nome Fantasia</nz-form-label>
        <nz-form-control nzHasFeedback>
          <input nz-input formControlName="commercialName" />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div formGroupName="address">
      <nz-divider nzText="Endereço" nzOrientation="left"></nz-divider>
      <div class="row">
        <nz-form-item class="col-sm-4">
          <nz-form-label>CEP<span class="mandatory">*</span></nz-form-label>
          <nz-form-control nzHasFeedback>
            <input
              nz-input
              (keyup)="consultCep($event.target.value)"
              mask="00000-000"
              formControlName="zipCode"
            />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="col-sm-5">
          <nz-form-label>Logradouro<span class="mandatory">*</span></nz-form-label>
          <nz-form-control nzHasFeedback>
            <input nz-input formControlName="street" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="col-sm-3">
          <nz-form-label>Número<span class="mandatory">*</span></nz-form-label>
          <nz-form-control nzHasFeedback>
            <input nz-input formControlName="number" type="number" #numero />
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="row">
        <nz-form-item class="col-sm-6">
          <nz-form-label>Complemento</nz-form-label>
          <input nz-input formControlName="complement" />
        </nz-form-item>

        <nz-form-item class="col-sm-6">
          <nz-form-label>Bairro<span class="mandatory">*</span></nz-form-label>
          <nz-form-control nzHasFeedback>
            <input nz-input formControlName="district" />
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="row">
        <nz-form-item class="col-sm-8">
          <nz-form-label>Cidade<span class="mandatory">*</span></nz-form-label>
          <nz-form-control nzHasFeedback>
            <input nz-input formControlName="cityName" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="col-sm-4">
          <nz-form-label>UF<span class="mandatory">*</span></nz-form-label>
          <nz-form-control nzHasFeedback>
            <nz-select nzShowSearch nzPlaceHolder="Selecione" formControlName="stateInitials">
              <nz-option
                *ngFor="let state of states"
                [nzLabel]="state?.sigla"
                [nzValue]="state?.sigla"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <nz-divider nzText="Responsável Legal" nzOrientation="left"></nz-divider>
    <div class="row">
      <nz-form-item class="col-4">
        <nz-form-label>Nome<span class="mandatory">*</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input nz-input formControlName="responsibleName" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="col-4">
        <nz-form-label>CPF<span class="mandatory">*</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input nz-input appPatientSearch formControlName="responsibleIdentity" mask="000.000.000-99" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="col-4">
        <nz-form-label>E-mail<span class="mandatory">*</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input nz-input formControlName="email" />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <!-- dados bancarios -->
  <div formGroupName="bankData">
    <nz-divider nzText="Dados bancários" nzOrientation="left"></nz-divider>

    <div class="row">
      <nz-form-item class="col-sm-7" formGroupName="bank">
        <nz-form-label>Banco<span class="mandatory">*</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <nz-select nzShowSearch nzPlaceHolder="Selecione o banco" formControlName="code">
            <nz-option
              *ngFor="let bank of banks"
              [nzLabel]="bank?.code + ' - ' + bank?.name"
              [nzValue]="bank?.code"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="col-sm-5" formGroupName="accountType">
        <nz-form-label>Tipo de Conta<span class="mandatory">*</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <nz-select nzPlaceHolder="Selecione o tipo de conta" formControlName="code">
            <nz-option nzValue="CC" nzLabel="Conta Corrente"></nz-option>
            <nz-option nzValue="PP" nzLabel="Conta Poupança"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="row">
      <nz-form-item class="col-sm-4">
        <nz-form-label>Agência<span class="mandatory">*</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input nz-input formControlName="bankAgency" type="tel" maxlength="5" pattern="\d{1,5}" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="col-sm-2">
        <nz-form-label>Dígito<span class="mandatory">*</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input nz-input formControlName="bankAgencyDigit" type="tel" maxlength="2" pattern="\d{1,2}" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="col-sm-4">
        <nz-form-label>Conta<span class="mandatory">*</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input nz-input formControlName="bankAccount" type="tel" maxlength="12" pattern="\d{1,12}" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="col-sm-2">
        <nz-form-label>Dígito<span class="mandatory">*</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            nz-input
            formControlName="bankAccountDigit"
            type="tel"
            maxlength="2"
            pattern="\d{1,2}"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
