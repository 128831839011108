import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '@app/core/services/validation.service';
import { CepService } from '@app/modules/entry/services/cep.service';
import { FederalRevenueService } from '@app/modules/entry/services/federal-renevue.service';
import { BrazilianStates } from '@app/shared/data/Brazilian-states';
import { BrazilState } from '@app/shared/models';
import { NzNotificationService } from 'ng-zorro-antd';

interface ResponseSearch {
  uf?: string;
  cidade?: string;
  complemento?: string;
  numero?: string;
  endereco?: string;
  bairro?: string;
  cep?: string;
  razao?: string;
  fantasia?: string;
  cnae: {
    fiscal?: string;
    descricao?: string;
  };
  status?: number;
  erroCodigo?: number;
  erro?: string;
}

const onlyNumbers = (valor: string) => {
  return valor.replace(/[^0-9]+/g, '');
};

@Component({
  selector: 'app-pharmacy-form',
  templateUrl: './pharmacy-form.component.html',
  styleUrls: ['./pharmacy-form.component.scss']
})
export class PharmacyFormComponent implements OnInit {
  readonly STATUS_VALID = 'VALID';

  @Output() formChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  states: BrazilState[] = BrazilianStates;

  form: FormGroup = this.fb.group({
    cnpj: ['', [Validators.required, ValidationService.cnpjValidator]],
    businessName: ['', Validators.required],
    fantasyName: ['', Validators.required],
    cnae: this.fb.group({
      code: [''],
      description: ['']
    }),
    cpfResponsible: ['', [ValidationService.cpfValidator]],
    telephone: ['', Validators.required],
    cellphone: [''],
    email: ['', [Validators.required, Validators.email]],
    emailConfirmation: ['', [Validators.required, Validators.email, ValidationService.emailMatch]],
    licenseNumber: [''],
    technicalResponsible: this.fb.group({
      name: ['', Validators.compose([ValidationService.nomeValidator])],
      cpf: ['', [ValidationService.cpfValidator]],
      crf: this.fb.group({
        number: [''],
        uf: ['']
      })
    }),
    address: this.fb.group({
      uf: [null, Validators.required],
      city: ['', Validators.required],
      complement: [''],
      number: ['', Validators.required],
      street: ['', Validators.required],
      neighborhood: ['', Validators.required],
      cep: ['', [Validators.required, ValidationService.cepValidator]]
    }),
    pendency: [null],
    verifiedCnpj: [true]
  });

  isLoading = false;

  constructor(
    private fb: FormBuilder,
    private cepService: CepService,
    private federalRevenueService: FederalRevenueService,
    private notification: NzNotificationService
  ) {}

  ngOnInit() {
    this.form.get('email').valueChanges.subscribe(email => {
      this.form.get('email').setValue(email && email.toLowerCase(), { emitEvent: false });
    });
    this.form.get('emailConfirmation').valueChanges.subscribe(email => {
      this.form.get('emailConfirmation').setValue(email && email.toLowerCase(), { emitEvent: false });
    });
    this.formChange.emit(this.form);

    this.form.get('cnpj').valueChanges.subscribe(() => {
      this.form.get('verifiedCnpj').setValue(false);
    });
  }

  async handleConsultCnpj(valueCnpj: string) {
    this.isLoading = true;
    const cnpj = onlyNumbers(valueCnpj);
    try {
      const response = (await this.federalRevenueService
        .consultCnpj(cnpj)
        .toPromise()) as ResponseSearch;
      if (response.erro) {
        if (response.erroCodigo === 102) {
          this.handleErrorMessageStatusCode102();
          return;
        } else {
          const consult = {
            status: -1,
            cnpj,
            erroCodigo: response.erroCodigo,
            erro: 'A importação de dados do cnpj está com instabilidade'
          };
          this.form.get('pendency').setValue(consult);
          this.notification.warning(
            'Aviso',
            'A importação de dados do CNPJ está com instabilidade, mas você poderá se cadastrar preenchendo os campos manualmente.'
          );
        }
      }

      this.setDataForm(response);
      if (response) {
        this.form.get('businessName').disable();
        this.form.get('cnae').disable();
      }
      this.verifiedCnpj = true;
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  private handleErrorMessageStatusCode102(): void {
    this.notification.warning(
      'Aviso',
      'O CNPJ informado não existe em nossos fornecedores. Por favor, confira o número do CNPJ e tente novamente. ' +
        'Em caso de dúvidas, entre em contato com o nosso suporte'
    );
  }

  get verifiedCnpj() {
    return this.form.get('verifiedCnpj').value;
  }

  set verifiedCnpj(value: boolean) {
    this.form.get('verifiedCnpj').setValue(value);
  }

  private setDataForm(response: ResponseSearch): void {
    const address = {
      uf: response ? response.uf : null,
      city: response ? response.cidade : null,
      complement: response ? response.complemento : null,
      number: response && response.numero !== 'SN' ? response.numero : null,
      street: response ? response.endereco : null,
      neighborhood: response ? response.bairro : null,
      cep: response ? response.cep : null
    };
    const cnae = {
      code: response ? response.cnae.fiscal : null,
      description: response ? response.cnae.descricao : null
    };
    const pharmacyInformation = {
      businessName: response ? response.razao : null,
      fantasyName: response ? response.fantasia : null,
      address: {
        ...address
      },
      cnae: {
        ...cnae
      }
    };

    this.form.patchValue(pharmacyInformation);
  }

  async onKey(event: any, address: FormGroup) {
    if (event.target.value.length === 10) {
      const cep = event.target.value.replace(/[^\d]+/g, '');
      const data = await this.cepService.consult(cep);
      if (!data.erro) {
        address.setValue({
          uf: data.uf,
          street: data.logradouro,
          neighborhood: data.bairro,
          city: data.localidade,
          complement: data.complemento,
          number: null,
          cep: data.cep
        });
      }
    }
  }
}
