import { Injectable } from '@angular/core';
import { Pharmacy, Pharmacist } from '@app/shared/models';

import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { Retention } from '@app/shared/models/retention';

export const PHARMACY_URL = 'pharmacy';
const ADD_PHARMACIST_URL = 'add-pharmacist';
const REMOVE_PHARMACIST_URL = 'remove-pharmacist';

@Injectable({ providedIn: 'root' })
export class PharmacyService {
  constructor(private http: HttpClient) {}

  save(pharmacy: Pharmacy): Observable<Pharmacy> {
    return this.http.post<Pharmacy>(`${environment.apiRoot}${PHARMACY_URL}`, pharmacy);
  }

  update(id: string, data: Pharmacy) {
    return this.http.put<Retention[]>(`${environment.apiRoot}${PHARMACY_URL}/${id}`, data);
  }

  getPharmacy(id: string): Observable<Pharmacy> {
    return this.http.get<Pharmacy>(`${environment.apiRoot}${PHARMACY_URL}/find/${id}`);
  }

  getPharmacies(): Observable<Pharmacy[]> {
    return this.http.get<Pharmacy[]>(`${environment.apiRoot}${PHARMACY_URL}`);
  }

  getPharmaciesByFilter(filter: any): Observable<any> {
    return this.http.get<Pharmacy[]>(`${environment.apiRoot}${PHARMACY_URL}/filter`, { params: filter });
  }

  getPharmacyByUserId(userId: string): Observable<Pharmacy> {
    return this.http.get<Pharmacy>(`${environment.apiRoot}${PHARMACY_URL}/user/${userId}`);
  }

  getRetentionHistory(userId: string) {
    return this.http.get<Retention[]>(`${environment.apiRoot}${PHARMACY_URL}/retentions/${userId}`);
  }

  getRetentionHistoryByFilter(userId: string, filter: any) {
    return this.http.get<any>(`${environment.apiRoot}${PHARMACY_URL}/retentions/filter/${userId}`, {
      params: filter
    });
  }

  resolvePending() {
    return this.http.post(`${environment.apiRoot}${PHARMACY_URL}/pendency`, {});
  }

  addPharmacist(pharmacyId, pharmacistId) {
    return this.http.post<Pharmacy>(
      `${environment.apiRoot}${PHARMACY_URL}/${pharmacyId}/${ADD_PHARMACIST_URL}`,
      { pharmacistId: pharmacistId }
    );
  }

  removePharmacist(pharmaciId: string, pharmacistId: any): Observable<Pharmacist> {
    return this.http.put<Pharmacist>(
      `${environment.apiRoot}${PHARMACY_URL}/${pharmaciId}/${REMOVE_PHARMACIST_URL}`,
      { pharmacistId: pharmacistId }
    );
  }

  importPharmacies(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(`${environment.apiRoot}${PHARMACY_URL}/import`, formData);
  }
}
