import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthService } from '@app/core/services/auth.service';
import { Patient } from '@app/shared/models';
import { NzNotificationService } from 'ng-zorro-antd';
import { PatientRecordService } from '../../services/patient-record.service';

@Component({
  selector: 'app-patient-record',
  templateUrl: './patient-record.component.html',
  styleUrls: ['./patient-record.component.scss']
})
export class PatientRecordComponent implements OnInit {
  @Input() patient: Patient;
  @Input() customStyle: any;

  user: any;
  visible = false;
  patientRecords: any = [];
  recordsToMerge: any[] = [];
  principalTag: any = {};
  distinctTags: any[];
  mergeConfirmation: boolean;

  tags: any = [];
  filteredTags: any = [];
  record = '';
  selectedTag;
  merging = false;
  loading = true;
  mergingRecords;
  page: number;
  total: number;
  limit = 10;

  @ViewChild('recordInput', { static: false }) recordInput: ElementRef;
  @ViewChild(CdkVirtualScrollViewport, { static: false }) virtualScroll: CdkVirtualScrollViewport;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private patientRecordService: PatientRecordService,
    private notification: NzNotificationService
  ) {}

  async ngOnInit() {
    this.user = await this.authService.user();

    this.tags = await this.patientRecordService.getTags().toPromise();
    if (this.patient) {
      const res: any = await this.patientRecordService
        .getRecords(this.patient._id, 0, this.limit)
        .toPromise();
      this.patientRecords = res.docs;
      this.page = 1;
      this.total = res.total;
      this.limit = res.limit;
      this.setLoading(false);
    }
  }

  async onScroll(event: any) {
    if (
      event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight &&
      this.patientRecords.length < this.total
    ) {
      await this.requestPatientRecords(this.page + 1);
    }
  }

  changeSelectedTag(tag) {
    this.selectedTag = this.tags[tag];
  }

  showOptions() {}

  async updateRecordList() {
    this.setLoading(true);
    const res: any = await this.patientRecordService
      .getRecords(this.patient._id, this.page, this.limit)
      .toPromise();

    this.patientRecords = res.docs;
    this.setLoading(false);
  }

  setLoading(value) {
    this.loading = value;
  }

  editRecord(record) {
    this.patientRecordService.updateRecord(record);
  }

  showCheckbox(show) {
    this.merging = show;
  }

  async mergeRecords() {
    this.mergingRecords = true;
    const recordCreated = await this.patientRecordService
      .mergeRecords(this.recordsToMerge, this.principalTag)
      .toPromise();
    this.mergingRecords = false;
    this.mergeConfirmation = false;
    this.showCheckbox(false);
    this.setLoading(true);
    console.log({ recordCreated });
    this.patientRecords.unshift(recordCreated);
    this.setLoading(false);
  }

  addNewRecordToMerge(data) {
    const { record, event } = data;
    if (event) {
      this.recordsToMerge.push(record);
    } else {
      const index = this.recordsToMerge.map(r => r._id).indexOf(record._id);
      this.recordsToMerge = this.recordsToMerge.splice(index, 1);
    }
  }

  changeMergeConfirmation() {
    const tags = this.recordsToMerge.map(r => r.tag).filter(t => !!t);

    const uniqueTags = [];
    if (tags.length > 0) {
      for (let index = 0; index < tags.length; index++) {
        if (uniqueTags.map(t => t._id).indexOf(tags[index]._id) === -1) {
          uniqueTags.push(tags[index]);
        }
      }

      this.distinctTags = uniqueTags;
    }
    this.mergeConfirmation = !this.mergeConfirmation;
  }

  cancelMergeSelection() {
    this.showCheckbox(false);
  }

  setPrincipalTag(tag) {
    this.principalTag = tag;
  }

  cancelMerge() {
    this.mergeConfirmation = !this.mergeConfirmation;
  }

  onChangeTag() {
    let record = this.record;
    if (record.length === 1 && record === '#') {
      this.filteredTags = this.tags;
      return;
    }

    if (record[0] === '#') {
      record = record.slice(1, record.length).toUpperCase();
    } else {
      record = record.toUpperCase();
    }

    if (record === '') {
      this.selectedTag = undefined;
      this.filteredTags = this.tags;
    } else {
      const newChar = record[record.length - 1];

      if (newChar === ':') {
        const tagFound = this.tags.find(t => t.name === record.slice(0, record.length - 1));
        if (!this.selectedTag) {
          if (!tagFound) {
            this.notification.warning('', 'tag invalida');
          } else {
            this.selectedTag = tagFound;
            this.record = `#${tagFound.name}: `;
          }
        }
      } else {
        this.filteredTags = this.tags.filter(
          t => t.name.includes(record) || t.description.toUpperCase().includes(record)
        );
      }
    }
  }

  async submitRecord() {
    this.setLoading(true);
    let recordData;
    if (!this.selectedTag) {
      recordData = this.extractDataFromRecord();
      let tagFound;
      if (recordData.tag) {
        tagFound = this.tags.find(t => t.name === recordData.tag.toUpperCase());

        if (!tagFound) {
          this.notification.warning('Aviso', 'Tag invalida');
          return;
        }
      }

      if (!recordData.record) {
        this.notification.warning('Aviso', 'O registro não pode ser vazio');
        return;
      }
      recordData = {
        record: recordData.record,
        tag: tagFound
      };
    } else {
      let recordWithoutTag = this.record.replace(this.selectedTag.name, '');
      recordWithoutTag = recordWithoutTag.replace('#', '');
      if (recordWithoutTag[0].includes(':')) {
        recordWithoutTag = recordWithoutTag.slice(1, recordWithoutTag.length);
      }
      recordWithoutTag = recordWithoutTag.trim();

      recordData = {
        tag: this.selectedTag,
        record: recordWithoutTag
      };
    }

    const body: any = {
      patientId: this.patient._id,
      content: recordData.record
    };

    if (recordData.tag) {
      body.tag = recordData.tag._id;
    }
    const recordCreated = await this.patientRecordService.submitRecord(body).toPromise();
    this.setLoading(false);
    this.record = '';
    this.selectedTag = undefined;
    this.patientRecords.unshift(recordCreated);
    this.recordInput.nativeElement.value = '';
  }

  extractDataFromRecord() {
    if (!this.record.includes(':')) {
      return { undefined, record: this.record };
    }
  }

  setSelectedTag(tag, recordInput) {
    this.selectedTag = tag;
    this.record = `#${tag.name}: `;
  }

  async childrenSelected(parent, children) {
    const recordCreated = await this.patientRecordService
      .submitRecord({
        patientId: this.patient._id,
        content: '',
        tag: children._id,
        editable: false
      })
      .toPromise();
    this.setLoading(false);
    this.record = '';
    this.selectedTag = undefined;
    this.patientRecords.unshift(recordCreated);
    this.recordInput.nativeElement.value = '';
  }

  async requestPatientRecords(paginationIndex) {
    this.setLoading(true);

    const res: any = await this.patientRecordService
      .getRecords(this.patient._id, (paginationIndex - 1) * this.limit, this.limit)
      .toPromise();

    this.patientRecords = this.patientRecords.concat(res.docs);
    this.page = paginationIndex;
    this.total = res.total;
    this.setLoading(false);
  }
}
