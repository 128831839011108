import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CepService } from '@app/modules/entry/services/cep.service';
import { BrazilianStates } from '@app/shared/data/Brazilian-states';
import { BrazilState } from '@app/shared/models';

@Component({
  selector: 'app-address-data-form',
  templateUrl: './address-data-form.component.html'
})
export class AddressDataFormComponent {
  @Input() form: FormGroup;
  @Input() required = false;
  @Input() viewAlertMsg = true;
  @Input() alertMsg =
    'Ao preencher o endereço do paciente, você será capaz de emitir Receitas de Controle Especial (listas C1, C5 e adendos das listas A1, B1 e B2). Para prescrever este tipo de medicamentos, deverá ser preenchido o endereço completo (RDC 344/98).';
  @Input() isPet: boolean;
  states: BrazilState[] = BrazilianStates;

  constructor(private cepService: CepService) {}

  async onKey(event: any) {
    if (event.target.value.length === 10) {
      const cep = event.target.value.replace(/[^\d]+/g, '');
      const data = await this.cepService.consult(cep);
      if (!data.erro) {
        this.form.patchValue({
          uf: data.uf,
          street: data.logradouro,
          neighborhood: data.bairro,
          city: data.localidade,
          complement: data.complemento,
          number: null,
          cep: data.cep
        });
      }
    }
  }
}
