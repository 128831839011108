<form [formGroup]="form" nz-form theme>
  <div class="row justify-content-between">
    <nz-alert
      class="mb-4"
      nzType="warning"
      nzMessage="Para agilizar seu cadastro, buscaremos dados complementares no site da Receita Federal.
Este é um procedimento seguro e o sigilo dos seus dados é garantido pela nossa plataforma"
    ></nz-alert>
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-10">
          <nz-form-item>
            <nz-form-label nzFor="cnpj">CNPJ<span class="mandatory"> *</span></nz-form-label>
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                id="cnpj"
                mask="00.000.000/0000-00"
                formControlName="cnpj"
                placeholder="00.000.000/0000-00"
              />
              <nz-form-explain *ngIf="form.get('cnpj').dirty && form.get('cnpj').errors">
                <ng-container *ngIf="form.get('cnpj').hasError('required')">
                  Campo obrigatório, por favor digite seu CNPJ.
                </ng-container>
                <ng-container
                  *ngIf="!form.get('cnpj').hasError('required') && form.get('cnpj').hasError('cnpj')"
                >
                  CNPJ inválido, por favor digite um CNPJ válido.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-md-2 btn-consulta-cnpj">
          <button
            nz-button
            class="btn-primary"
            (click)="handleConsultCnpj(form.get('cnpj').value)"
            [disabled]="form.get('cnpj').invalid || isLoading"
          >
            <span *ngIf="!isLoading">OK</span>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              *ngIf="isLoading"
            ></span>
          </button>
        </div>

        <div class="col-md-12" *ngIf="verifiedCnpj">
          <nz-form-item>
            <nz-form-label nzFor="nomeFantasia"
              >Nome Fantasia<span class="mandatory"> *</span></nz-form-label
            >
            <nz-form-control nzHasFeedback>
              <input
                [attr.id]="'nomeFantasia'"
                formControlName="fantasyName"
                nz-input
                placeholder="ex: Farmácia Dona Santa"
                autocomplete="disabled"
              />
              <nz-form-explain *ngIf="form.get('fantasyName').dirty && form.get('fantasyName').errors">
                <ng-container *ngIf="form.get('fantasyName').hasError('required')">
                  Por favor digite o nome empresarial.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-md-12" *ngIf="verifiedCnpj">
          <nz-form-item>
            <nz-form-label nzFor="businessName"
              >Razão Social<span class="mandatory"> *</span></nz-form-label
            >
            <nz-form-control nzHasFeedback>
              <input
                [attr.id]="'businessName'"
                formControlName="businessName"
                nz-input
                placeholder="ex: Nome da Empresa no CNPJ Ltda"
                autocomplete="disabled"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-md-12" *ngIf="verifiedCnpj" [formGroup]="form.get('cnae')">
          <nz-form-item>
            <nz-form-label nzFor="cnaeCode">CNAE<span class="mandatory"> *</span></nz-form-label>
            <nz-form-control nzHasFeedback>
              <input [attr.id]="'cnaeCode'" formControlName="code" nz-input />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label nzFor="cnaeDescription"
              >Descrição CNAE<span class="mandatory"> *</span></nz-form-label
            >
            <nz-form-control nzHasFeedback>
              <input [attr.id]="'cnaeDescription'" formControlName="description" nz-input />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
    <div class="col-md-5" *ngIf="verifiedCnpj">
      <div class="row">
        <div class="col-md-12">
          <nz-form-item>
            <nz-form-label nzFor="cpf">CPF do responsável legal pela empresa </nz-form-label>
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                id="cpf"
                mask="000.000.000-00"
                formControlName="cpfResponsible"
                placeholder="000.000.000-00"
                autocomplete="disabled"
              />
              <nz-form-explain
                *ngIf="form.get('cpfResponsible').dirty && form.get('cpfResponsible').errors"
              >
                <ng-container *ngIf="form.get('cpfResponsible').hasError('required')">
                  Campo obrigatório, por favor digite seu CPF.
                </ng-container>
                <ng-container
                  *ngIf="
                    !form.get('cpfResponsible').hasError('required') &&
                    form.get('cpfResponsible').hasError('cpf')
                  "
                >
                  CPF inválido, por favor digite um CPF válido.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-md-12">
          <nz-form-item>
            <nz-form-label nzFor="licenseNumber">Número da Licença Sanitária</nz-form-label>
            <nz-form-control nzHasFeedback>
              <input
                formControlName="licenseNumber"
                nz-input
                placeholder="Digite o número da licença sanitária da farmácia"
                maxlength="20"
              />
              <nz-form-explain
                *ngIf="form.get('licenseNumber').dirty && form.get('licenseNumber').errors"
              >
                <ng-container *ngIf="form.get('licenseNumber').hasError('required')">
                  Campo obrigatório, por favor digite o número da licença sanitária.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-md-6">
          <nz-form-item *ngIf="form.get('telephone') as telephone">
            <nz-form-label nzFor="telephone">Telefone 1 <span class="mandatory">*</span></nz-form-label>
            <nz-form-control>
              <input
                nz-input
                id="telephone"
                formControlName="telephone"
                placeholder="(21) 2222-2222"
                [mask]="telephone.value.length <= 10 ? '(00) 0000-00009' : '(00) 00000-0000'"
                autocomplete="disabled"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-md-6">
          <nz-form-item *ngIf="form.get('cellphone') as cellphone">
            <nz-form-label nzFor="cellphone">Telefone 2</nz-form-label>
            <nz-form-control>
              <input
                nz-input
                id="cellphone"
                formControlName="cellphone"
                placeholder="(21) 92222-2222"
                [mask]="cellphone.value.length <= 10 ? '(00) 0000-00009' : '(00) 00000-0000'"
                autocomplete="disabled"
              />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-md-12">
          <nz-form-item>
            <nz-form-label nzFor="email">E-mail<span class="mandatory"> *</span></nz-form-label>
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                id="email"
                type="email"
                formControlName="email"
                placeholder="usuario@email.com"
                style="text-transform: lowercase;"
                (keydown.space)="$event.preventDefault()"
                autocomplete="disabled"
              />
              <nz-form-explain
                *ngIf="
                  (form.get('email').hasError('required') && form.get('email').dirty) ||
                  (form.get('email').invalid && form.get('email').value.length > 0)
                "
              >
                <ng-container *ngIf="form.get('email').hasError('required') && form.get('email').dirty">
                  <span class="error">Campo obrigatório, por favor digite seu e-mail.</span><br />
                </ng-container>
                <ng-container *ngIf="form.get('email').invalid && form.get('email').value.length > 0">
                  <span class="error">E-mail inválido, por favor digite um e-mail válido.</span><br />
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-md-12">
          <nz-form-item>
            <nz-form-label nzFor="emailConfirmation"
              >Confirmação de e-mail<span class="mandatory"> *</span>
            </nz-form-label>
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                id="emailConfirmation"
                formControlName="emailConfirmation"
                placeholder="usuário@email.com"
                type="email"
                style="text-transform: lowercase;"
                (keydown.space)="$event.preventDefault()"
                (onpaste)="(false)"
                autocomplete="disabled"
              />
              <nz-form-explain>
                <ng-container
                  *ngIf="
                    form.get('emailConfirmation').hasError('required') &&
                    form.get('emailConfirmation').dirty
                  "
                >
                  <span class="error">Campo obrigatório, por favor digite seu e-mail.</span><br />
                </ng-container>

                <ng-container
                  *ngIf="
                    form.hasError('emailMatch') &&
                    form.get('email').dirty &&
                    form.get('email').value.length > 0 &&
                    form.get('emailConfirmation').dirty &&
                    form.get('emailConfirmation').value.length > 0
                  "
                >
                  <span class="error">Os endereços de e-mail não correspondem</span><br />
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </div>

  <nz-divider
    nzOrientation="left"
    nzText="Dados do Responsável Técnico"
    *ngIf="verifiedCnpj"
  ></nz-divider>
  <div
    class="row justify-content-between"
    [formGroup]="form.get('technicalResponsible')"
    *ngIf="verifiedCnpj"
  >
    <div class="col-md-6">
      <nz-form-item>
        <nz-form-label nzFor="nomeFantasia">Nome</nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            id="nomeFantasia"
            formControlName="name"
            maxlength="128"
            nz-input
            placeholder="ex: XYZ"
            autocomplete="disabled"
          />
          <nz-form-explain
            *ngIf="
              form.get('technicalResponsible.name').dirty && form.get('technicalResponsible.name').errors
            "
          >
            <ng-container *ngIf="form.get('technicalResponsible.name').hasError('required')">
              Por favor digite o nome.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzFor="nomeFantasia">CPF</nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            formControlName="cpf"
            nz-input
            placeholder="000.000.000-00"
            mask="000.000.000-00"
            autocomplete="disabled"
          />
          <nz-form-explain
            *ngIf="
              form.get('technicalResponsible.cpf').dirty && form.get('technicalResponsible.cpf').errors
            "
          >
            <ng-container *ngIf="form.get('technicalResponsible.cpf').hasError('cpf')">
              CPF inválido, por favor digite um CPF válido.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-md-6">
      <div [formGroup]="form.get('technicalResponsible.crf')">
        <nz-form-item>
          <nz-form-label nzFor="nomeFantasia">CRF</nz-form-label>
          <nz-form-control nzHasFeedback>
            <input formControlName="number" nz-input placeholder="Digite o CRF" maxlength="10" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label>UF</nz-form-label>
          <nz-form-control nzHasFeedback>
            <nz-select
              formControlName="uf"
              id="crm-uf"
              nzShowSearch
              nzAllowClear
              nzDropdownClassName="account-select"
              nzPlaceHolder="Selecione"
            >
              <nz-option *ngFor="let state of states" [nzLabel]="state?.sigla" [nzValue]="state?.sigla">
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </div>

  <nz-divider nzOrientation="left" nzText="Endereço" *ngIf="verifiedCnpj"></nz-divider>

  <div class="row" *ngIf="verifiedCnpj && form.get('address') as address" [formGroup]="address">
    <div class="col-md-3">
      <nz-form-item>
        <nz-form-label nzFor="cep">CEP<span class="mandatory"> *</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            nz-input
            id="cep"
            formControlName="cep"
            placeholder="00.000-000"
            mask="00.000-000"
            (keyup)="onKey($event, address)"
            autocomplete="disabled"
          />
          <nz-form-explain *ngIf="address.get('cep').dirty && address.get('cep').errors">
            <ng-container *ngIf="address.get('cep').hasError('required')">
              Campo obrigatório, por favor digite o cep.
            </ng-container>
            <ng-container
              *ngIf="!address.get('cep').hasError('required') && address.get('cep').hasError('cep')"
            >
              CEP inválido.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-md-6">
      <nz-form-item>
        <nz-form-label nzFor="logradouro">Logradouro<span class="mandatory"> *</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            nz-input
            id="logradouro"
            formControlName="street"
            placeholder="Digite o logradouro"
            maxlength="128"
            autocomplete="disabled"
          />
          <nz-form-explain *ngIf="address.get('street').dirty && address.get('street').errors">
            <ng-container *ngIf="address.get('street').hasError('required')">
              Campo obrigatório, por favor digite o logradouro.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-md-3">
      <nz-form-item>
        <nz-form-label nzFor="addressNumber">Número<span class="mandatory"> *</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            formControlName="number"
            id="addressNumber"
            nz-input
            placeholder="Digite o número"
            maxlength="10"
            autocomplete="disabled"
          />
          <nz-form-explain *ngIf="address.get('number').dirty && address.get('number').errors">
            <ng-container *ngIf="address.get('number').hasError('required')">
              Campo obrigatório, por favor digite o número.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-md-3">
      <nz-form-item>
        <nz-form-label nzFor="complement">Complemento</nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            formControlName="complement"
            id="complement"
            nz-input
            placeholder="Digite o complemento"
            maxlength="128"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-md-3">
      <nz-form-item>
        <nz-form-label nzFor="neighborhood">Bairro<span class="mandatory"> *</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            formControlName="neighborhood"
            id="neighborhood"
            nz-input
            placeholder="Digite o bairro"
            maxlength="128"
            autocomplete="disabled"
          />
          <nz-form-explain
            *ngIf="address.get('neighborhood').dirty && address.get('neighborhood').errors"
          >
            <ng-container *ngIf="address.get('neighborhood').hasError('required')">
              Campo obrigatório, por favor digite o bairro.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-md-4">
      <nz-form-item>
        <nz-form-label nzFor="city">Cidade<span class="mandatory"> *</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            formControlName="city"
            id="city"
            nz-input
            placeholder="Digite a cidade"
            maxlength="64"
            autocomplete="disabled"
          />
          <nz-form-explain *ngIf="address.get('city').dirty && address.get('city').errors">
            <ng-container *ngIf="address.get('city').hasError('required')">
              Campo obrigatório, por favor digite a cidade.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-md-2">
      <nz-form-item>
        <nz-form-label nzFor="uf">UF<span class="mandatory"> *</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <nz-select
            formControlName="uf"
            id="uf"
            nzAllowClear
            nzShowSearch
            nzDropdownClassName="account-select"
            nzPlaceHolder="Selecione"
          >
            <nz-option
              *ngFor="let state of states"
              [nzLabel]="state?.sigla"
              [nzValue]="state?.sigla"
            ></nz-option>
          </nz-select>
          <nz-form-explain *ngIf="address.get('uf').dirty && address.get('uf').errors">
            <ng-container *ngIf="address.get('uf').hasError('required')">
              Campo obrigatório, por favor selecione o UF.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
<ngx-loading [show]="isLoading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
