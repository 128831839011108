<section class="section-hero section-header" theme>
  <div class="container">
    <a routerLink="/" class="brand-bg">
      <img
        [ngStyle]="
          isThemeActiveReceitaDigital
            ? {
                position: 'relative',
                top: '-12px'
              }
            : { position: 'relative', top: '-48px' }
        "
        [src]="
          isThemeActiveReceitaDigital ? 'assets/img/logo-01.jpg' : 'assets/img/mrd/logo-com-texto.png'
        "
        class="img-fluid"
        alt="Logo Receita Digital"
      />
    </a>
    <h1 class="mb-5 mt-5">
      Escolha o tipo de conta que deseja criar
    </h1>
  </div>
</section>
<section class="section-form">
  <div class="container pt-5">
    <form nz-form [formGroup]="accountTypeForm" (ngSubmit)="submitForm($event)">
      <div class="row">
        <div class="col-8 col-lg-4">
          <nz-form-item>
            <nz-form-label nzFor="account-select" theme>Tipo de Conta</nz-form-label>
            <nz-form-control nzHasFeedback>
              <nz-select
                id="account-select"
                formControlName="account"
                [nzPlaceHolder]="'Selecione o tipo de conta'"
                nzDropdownClassName="account-select"
                nzAllowClear
                theme
              >
                <nz-option nzValue="doctor" nzLabel="Médico"></nz-option>
                <nz-option nzValue="dentist" nzLabel="Dentista"></nz-option>
                <nz-option nzValue="veterinarian" nzLabel="Veterinário"></nz-option>
                <nz-option
                  nzValue="patient"
                  nzLabel="Paciente"
                  *ngIf="isThemeActiveReceitaDigital"
                ></nz-option>
                <nz-option
                  nzValue="pharmacy"
                  nzLabel="Farmácia"
                  *ngIf="isThemeActiveReceitaDigital"
                ></nz-option>
              </nz-select>
              <nz-form-explain
                *ngIf="accountTypeForm.get('account').dirty && accountTypeForm.get('account').errors"
              >
                <ng-container *ngIf="accountTypeForm.get('account').hasError('required')">
                  Campo Obrigatório, por favor selecione o tipo de conta.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="row justify-content-end">
          <div class="col-4 col-lg-2 d-flex justify-content-end">
            <button class="btn btn-circle btn-success" theme><i class="fa fa-arrow-right"></i></button>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
