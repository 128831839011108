<br />
<div>
  <nz-form-label nzFor="telephone">Especifique o motivo do cancelamento</nz-form-label>
  <textarea
    nz-input
    [(ngModel)]="reason"
    placeholder="digite o motivo do cancelamento"
    rows="4"
    cols="50"
  ></textarea>
</div>
<hr />
<div class="row" theme>
  <div class="col-6">
    <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
    <nz-spin *ngIf="loading" nzSimple [nzIndicator]="indicatorTemplate"> </nz-spin>
  </div>

  <div class="col-3">
    <button
      type="button"
      class="btn btn-icon  btn-secondary btn-add text-capitalize"
      (click)="handleCancel()"
    >
      <span class="btn-inner--text">Cancelar</span>
    </button>
  </div>

  <div class="col-3">
    <button type="button" class="btn btn-icon btn-success  text-capitalize" (click)="handleOk()">
      <span class="btn-inner--text">Salvar</span>
    </button>
  </div>
</div>
