import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { markFormGroup } from '@app/utils/markFormGroup';
import { NzNotificationService } from 'ng-zorro-antd';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HealthProfessional } from './../../../../shared/models/health-professional';

import { ColorSetting } from '@app/shared/models/color-setting';
import { AttestationModelService } from '../../services/attestation-model.service';
import { ExamModelService } from '../../services/exam-model.service';
import { OrientationModelService } from '../../services/orientation-model.service';
import { PrescriptionModelService } from '../../services/prescription-model.service';

@Component({
  selector: 'app-model-add-favorite-prescription',
  templateUrl: './model-add-favorite-document.component.html',
  styleUrls: ['./model-add-favorite-document.component.scss']
})
export class ModelAddFavoriteDocumentComponent implements OnInit {
  readonly HTTP_STATUS_CONFLICT = 409;

  @Input() prescriber: HealthProfessional;
  @Input() documentType = '';
  @Input() documentModel: any;
  @Input() colorSetting: ColorSetting;
  typesOfDocuments = ['Prescrição', 'Exame', 'Atestado', 'Outros'];
  loading: boolean;
  message = '';

  documentModelForm: FormGroup;
  payload: any;
  constructor(
    public modalRef: BsModalRef,
    private prescriptionModelService: PrescriptionModelService,
    private orientationModelService: OrientationModelService,
    private examModelService: ExamModelService,
    private attestationModelService: AttestationModelService,
    private notification: NzNotificationService
  ) {}

  ngOnInit() {
    switch (this.documentType) {
      case this.typesOfDocuments[0]:
        this.buildPrescriptionForm();
        break;
      case this.typesOfDocuments[1]:
        this.buildExamForm();
        break;
      case this.typesOfDocuments[2]:
        this.buildAttestationForm();
        break;

      case this.typesOfDocuments[3]:
        this.buildOrientationForm();
        break;
    }
    this.setDocumentModel();
  }

  setDocumentModel() {
    if (this.documentType === this.typesOfDocuments[0]) {
      this.setPrescritpionModel();
    } else if (this.documentType === this.typesOfDocuments[1]) {
      this.setExamModel();
    } else if (this.documentType === this.typesOfDocuments[2]) {
      this.setAttestationModel();
    } else if (this.documentType === this.typesOfDocuments[3]) {
      this.setOrientationModel();
    }
  }

  setPrescritpionModel() {
    if (this.documentModel) {
      this.documentModelForm.patchValue({ prescriber: this.prescriber, ...this.documentModel });
      this.documentModel.medicaments.forEach(m => {
        this.addPrescription({
          ...m,
          nome: m.name,
          id: m.medicamentId,
          principio: m.principle,
          laboratorio: m.laboratory,
          ean: m.ean,
          apresentacaoCustomizada: m.description,
          quantity: m.prescribedQuantity
        });
      });
    }
  }

  setExamModel() {
    if (this.documentModel) {
      this.documentModelForm.patchValue({ prescriber: this.prescriber, ...this.documentModel });
      const value = this.documentModel.justification.reduce(
        (acc, val) => (acc ? `${acc}\n${val}` : val),
        ''
      );
      this.documentModelForm.get('text').setValue(value);
    }
  }

  setOrientationModel() {
    if (this.documentModel) {
      this.documentModelForm.patchValue({ prescriber: this.prescriber, ...this.documentModel });
      const value = this.documentModel.orientations.reduce(
        (accumulator, currentValue) => accumulator + '\n' + currentValue
      );
      this.documentModelForm.get('text').setValue(value);
    }
  }

  setAttestationModel() {
    if (this.documentModel) {
      this.documentModelForm.patchValue({ prescriber: this.prescriber, ...this.documentModel });
      const value = this.documentModel.description.reduce(
        (accumulator, currentValue) => accumulator + '\n' + currentValue
      );
      this.documentModelForm.get('text').setValue(value);
    }
  }

  private buildPrescriptionForm() {
    this.documentModelForm = new FormGroup({
      title: new FormControl('', Validators.required),
      prescriber: new FormControl(''),
      medication: new FormControl(''),
      medicaments: new FormArray([], Validators.required)
    });
  }

  private buildOrientationForm() {
    this.documentModelForm = new FormGroup({
      title: new FormControl('', Validators.required),
      prescriber: new FormControl(''),
      text: new FormControl(''),
      orientations: new FormControl('', Validators.required)
    });
  }

  private buildAttestationForm() {
    this.documentModelForm = new FormGroup({
      title: new FormControl('', Validators.required),
      prescriber: new FormControl(''),
      text: new FormControl(''),
      description: new FormControl('', Validators.required)
    });
  }

  private buildExamForm() {
    this.documentModelForm = new FormGroup({
      title: new FormControl('', Validators.required),
      text: new FormControl('')
    });
  }

  public addPrescription({
    nome,
    id,
    principio,
    laboratorio,
    ean,
    apresentacaoCustomizada,
    dosage = '',
    quantity = 1
  }): void {
    this.medicamentosFormCtrl.push(
      this.formMedicamentos({
        name: nome,
        medicamentId: id,
        principle: principio,
        laboratory: laboratorio,
        ean: ean,
        description: apresentacaoCustomizada,
        dosage,
        quantity
      })
    );
    this.documentModelForm.get('medication').setValue(null);
  }

  formMedicamentos({
    name,
    medicamentId,
    principle,
    laboratory,
    ean,
    quantity,
    description,
    dosage
  }): FormGroup {
    return new FormGroup({
      name: new FormControl(name),
      medicamentId: new FormControl(medicamentId),
      prescribedQuantity: new FormControl(quantity),
      dosage: new FormControl(dosage),
      description: new FormControl(description),
      principle: new FormControl(principle),
      laboratory: new FormControl(laboratory),
      ean: new FormControl(ean)
    });
  }

  getText(arrayText: any[]) {
    return arrayText.reduce((acc, val) => (acc ? `${acc}\n${val}` : val), '');
  }

  saveDocumentModel = () => {
    try {
      markFormGroup(this.documentModelForm);
      this.loading = true;
      if (this.documentModelForm.valid) {
        switch (this.documentType) {
          case this.typesOfDocuments[0]:
            this.prescriptionModelService
              .create(this.documentModelForm.value, this.prescriber && this.prescriber.userId)
              .then(() => this.onSuccessCreateFavorite())
              .catch(error => this.onFailureCreateFavorite(error));
            break;

          case this.typesOfDocuments[1]:
            this.examModelService
              .create({
                ...this.documentModel,
                ...this.documentModelForm.value
              })
              .then(() => this.onSuccessCreateFavorite())
              .catch(error => this.onFailureCreateFavorite(error));
            break;

          case this.typesOfDocuments[2]:
            this.attestationModelService
              .create(this.documentModelForm.value)
              .then(() => this.onSuccessCreateFavorite())
              .catch(error => this.onFailureCreateFavorite(error));
            break;

          case this.typesOfDocuments[3]:
            this.orientationModelService
              .create(this.documentModelForm.value)
              .then(() => this.onSuccessCreateFavorite())
              .catch(error => this.onFailureCreateFavorite(error));
            break;
        }
      }
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.message = err.error.message;
      console.log(err);
    }
  };

  onSuccessCreateFavorite() {
    this.modalRef.hide();
    this.notification.success('Sucesso', `Modelo ${this.documentType} salvo com sucesso`);
  }

  onFailureCreateFavorite(error: HttpErrorResponse) {
    if (error.status === this.HTTP_STATUS_CONFLICT) {
      this.notification.warning('Atenção', 'Já existe um modelo com este título. Digite outro título');
    } else {
      this.notification.warning('Error', 'Ocorreu um error ao tentar cadastrado modelo.');
    }
  }

  get medicamentosFormCtrl(): FormArray {
    return <FormArray>this.documentModelForm.get('medicaments');
  }
}
