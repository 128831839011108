<section class="section-hero bg-green">
  <div class="container">
    <div class="row justify-content-center">
      <a routerLink="/" class="brand-bg">
        <img src="assets/img/logo-inline-alt.svg" class="img-fluid" alt="Logo Receita Digital" />
      </a>
    </div>
    <div class="row justify-content-center">
      <div class="hero d-flex justify-content-center flex-column align-items-center">
        <h1 class="text-center">
          Seja Bem-vindo <br />
          ao Receita Digital
        </h1>
        <p class="text-primary">Agora você já pode prescrever gratuitamente</p>
        <p class="text-primary">
          Lembre-se de confirmar a sua conta <br />
          através do link enviado por e-mail
        </p>
        <div class="hero-links">
          <a routerLink="/dashboard" class="btn btn-primary">Ir para o meu painel</a>
          <a href="#" class="btn btn-secondary">Começar a prescrever</a>
        </div>
      </div>
    </div>
  </div>
</section>
