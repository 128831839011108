<div class="modal-video-conf">
  <div class="modal-header">
    <div class="title">
      <img src="assets/icons/video-solid.svg" />
      <h4 class="modal-title pull-left text-white">TELECONSULTA {{ title ? ' - ' + title : '' }}</h4>
    </div>
    <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button> -->

    <button
      class="hire-btn"
      type="button"
      *ngIf="!hire && user?.userType === 'healthProfessional'"
      (click)="hire = true"
    >
      <span aria-hidden="true">Contratar</span>
    </button>

    <button class="hire-btn" type="button" *ngIf="!hire && user?.userType === 'patient'">
      <span aria-hidden="true">Começar</span>
    </button>

    <button class="hire-btn" type="button" *ngIf="hire" (click)="closeModal()">
      <div class="btn-cancel">
        <span aria-hidden="true">Cancelar</span>
      </div>
    </button>
  </div>

  <div class="modal-body">
    <app-intro *ngIf="!hire" [userType]="user?.userType"></app-intro>
    <app-form-steps
      *ngIf="hire"
      (title)="title = $event"
      [userType]="user?.userType"
      (cancel)="hire = false"
      (submitForm)="submitForm($event)"
    ></app-form-steps>
  </div>
</div>
