<p class="bold">Contratação do plano de Teleconsulta Receita Digital</p>
<p>
  A partir de agora você está contratando o plano mensal de Teleconsulta da Receita Digital!
</p>
<p>
  Você já viu na tela anterior como funciona, mas pode conferir o contrato clicando nesse link abaixo:
</p>
<a href="">link</a>
<p>
  São 3 passos simples para contratar, e você ainda tem 15 dias grátis para experimentar.
</p>
<p>
  Para começar, precisamos ter certeza de que você é um profissional habilitado para fazer consultas. Por
  isso, precisamos que você clique no botão abaixo e anexe uma foto da sua carteira profissional.
</p>

<form nz-form [formGroup]="form" class="p-3 mb-2">
  <nz-form-label nzFor="documentFile">Upload CRM ou CRO</nz-form-label>
  <app-input-file
    accept="image/*,.pdf"
    formControlName="documentFile"
    name="documentFile"
    textButton="Selecione uma foto"
    transformTo="base64"
    maxSize="3145728"
    [file]="file"
    [(filename)]="filename"
  >
  </app-input-file>
</form>

<p>Agora é só clicar no botão ao lado, para ir para a próxima etapa.</p>
