import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzModalRef, NzNotificationService } from 'ng-zorro-antd';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.scss']
})
export class ModalAlertComponent implements OnInit, OnDestroy {
  medicament: any;

  private destroyed$ = new ReplaySubject<boolean>();

  constructor(
    private fb: FormBuilder,
    private ref: NzModalRef,
    private notification: NzNotificationService
  ) {}

  ngOnInit() {
    console.log(this.medicament);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  cancel() {
    this.ref.close();
  }

  remove(medicament: any) {
    medicament.checked = false;
    this.ref.close();
  }
}
