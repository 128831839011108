import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HealthProfessionalService } from '@app/modules/health-professional/services/health-professional.service';
import { HealthProfessional, Patient, Pharmacy } from '@app/shared/models';
import { Administrator } from '@app/shared/models/admin';
import { Prescription } from '@app/shared/models/prescription';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { PatientsService } from '../patients/services/patients.service';
import { PharmacyService } from '../pharmacy/services/pharmacy.service';
import { LogsService } from './logs/logs.service';

const ADMIN_URL = 'administrator';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private patientService: PatientsService,
    private healthProfessionalService: HealthProfessionalService,
    private pharmacyService: PharmacyService,
    private logsService: LogsService
  ) {}

  save(administrator: Administrator): Observable<Administrator> {
    return this.http.post<Administrator>(`${environment.apiRoot}${ADMIN_URL}`, administrator);
  }

  update(id: string, admin: any): Promise<Administrator> {
    return this.http.put<Administrator>(`${environment.apiRoot}${ADMIN_URL}/${id}`, admin).toPromise();
  }

  getAdmins(filter?: any): Promise<Administrator[]> {
    return this.http
      .get<Administrator[]>(`${environment.apiRoot}${ADMIN_URL}`, {
        params: filter
      })
      .toPromise();
  }

  deleteAdmin(id: string) {
    return this.http.delete(`${environment.apiRoot}${ADMIN_URL}/${id}`).toPromise();
  }

  filterData(
    dataType: string,
    filterForm: any
  ): Observable<HealthProfessional[] | Patient[] | Pharmacy[] | Prescription> {
    switch (dataType) {
      case 'patients':
        return this.patientService.getPatientsByFilter(filterForm);
      case 'doctors':
        return this.healthProfessionalService.getHealthProfessionalsByProfessionalType(
          'doctor',
          filterForm
        );
      case 'dentists':
        return this.healthProfessionalService.getHealthProfessionalsByProfessionalType(
          'dentist',
          filterForm
        );
      case 'pharmacies':
        return this.pharmacyService.getPharmaciesByFilter(filterForm);
      case 'logs':
        return this.logsService.getLogsByFilter(filterForm);
    }
  }

  async exportData(dataType: string, filter: any) {
    const params = {
      dataType,
      ...filter
    };
    const response = await this.http
      .get<any>(`${environment.apiRoot}${ADMIN_URL}/export`, {
        params,
        responseType: 'blob' as 'json'
      })
      .toPromise();
    const a = document.createElement('a');
    a.href = URL.createObjectURL(response);
    a.download = `${dataType}.xls`;
    a.click();
  }

  getClientApps(): Observable<any> {
    return this.http.get(`${environment.apiV1}/clients`);
  }
}
