<div class="exam row">
  <form class="col-md-6 col-12" [formGroup]="examForm">
    <div
      *ngFor="let examList of examListForm['controls']; index as examListIndex"
      formArrayName="examList"
    >
      <div class="form-area" formGroupName="{{ examListIndex }}">
        <label for="material">Material</label>
        <div class="col-md-11 col-12 input-area">
          <nz-select
            *ngIf="
              colorSetting && (colorSetting.inputBackground || colorSetting.inputBorder);
              else selectTheme
            "
            id="specialties"
            nzDropdownClassName="account-select"
            nzPlaceHolder="Selecione o material"
            formControlName="material"
            nzAllowClear
            nzShowSearch
            [ngStyle]="{
              'background-color': colorSetting?.inputBackground,
              'border-color': colorSetting?.inputBorder,
              border: colorSetting?.inputBorder && '1px ' + colorSetting.inputBorder + ' solid',
              'border-radius': colorSetting?.inputBorder && '10px'
            }"
          >
            <nz-option *ngFor="let material of materials" [nzLabel]="material" [nzValue]="material">
            </nz-option>
          </nz-select>
          <ng-template #selectTheme>
            <nz-select
              id="specialties"
              nzDropdownClassName="account-select"
              nzPlaceHolder="Selecione o material"
              formControlName="material"
              nzAllowClear
              nzShowSearch
            >
              <nz-option *ngFor="let material of materials" [nzLabel]="material" [nzValue]="material">
              </nz-option>
            </nz-select>
          </ng-template>

          <a class="remove-icon" (click)="removeMaterialAt(examListIndex)"
            ><i class="fa fa-trash-solid"></i
          ></a>
        </div>

        <div class="exam-type-area ml-5" *ngIf="hasMaterial(examListIndex)" formArrayName="exams">
          <label for="examName">Exame</label>
          <div
            class="exams-area col-md-11 col-12"
            *ngFor="let exam of examList['controls'].exams['controls']; index as examIndex"
          >
            <div class="input-area" formGroupName="{{ examIndex }}">
              <input type="hidden" formControlName="position" name="position" value="{{ examIndex }}" />

              <input
                id="examName"
                formControlName="name"
                (input)="onChangeExamType($event.target.value, examListIndex)"
                [nzAutocomplete]="autoName"
                nz-input
                placeholder="Tipo de exame"
                [ngStyle]="{
                  'background-color': colorSetting?.inputBackground,
                  'border-color': colorSetting?.inputBorder
                }"
              />

              <nz-autocomplete #autoName nzBackfill>
                <nz-auto-option
                  *ngFor="let option of examTypes"
                  [nzLabel]="option.exam"
                  [nzValue]="option.exam"
                  class="mao"
                >
                  {{ option.exam }}
                </nz-auto-option>
              </nz-autocomplete>

              <a class="remove-icon" (click)="removeExamAt(examListIndex, examIndex)"
                ><i class="fa fa-trash-solid"></i
              ></a>
            </div>
          </div>

          <button
            nz-button
            class="btn btn-icon btn-add-outiline text-capitalize btn-exam"
            type="button"
            (click)="addNewExam(examListIndex)"
            [ngStyle]="{
              'background-color': colorSetting?.secondary,
              'border-color': colorSetting?.secondary
            }"
          >
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="add-button btn-material mb-2">
      <button
        nz-button
        class="btn btn-add-outiline text-capitalize"
        type="button"
        (click)="addMaterial()"
        [ngStyle]="{
          'background-color': colorSetting?.secondary,
          'border-color': colorSetting?.secondary
        }"
      >
        <span>Adicionar Material</span>
      </button>
      <button
        *ngIf="!showJustification"
        nz-button
        class="btn btn-icon btn-add-outiline text-capitalize"
        type="button"
        (click)="addJustification()"
        [ngStyle]="{
          'background-color': colorSetting?.secondary,
          'border-color': colorSetting?.secondary
        }"
      >
        <span>Inserir Justificativa</span>
      </button>
      <button
        *ngIf="showJustification"
        nz-button
        class="btn btn-icon btn-add-outiline text-capitalize"
        type="button"
        (click)="removeJustification()"
        [ngStyle]="{
          'background-color': colorSetting?.secondary,
          'border-color': colorSetting?.secondary
        }"
      >
        <span>Remover Justificativa</span>
      </button>
    </div>
    <div *ngIf="showJustification" class="mt-2">
      <label for="material">Justificativa:</label>
      <textarea
        rows="4"
        nz-input
        formControlName="text"
        (input)="onChangeJustification($event.target.value)"
        placeholder="Texto livre"
      ></textarea>
    </div>
  </form>
</div>
