<ng-template #tplContent let-params let-ref="tplModal">
  <div class="container">
    <h4 class="title text-modal" theme>
      {{ title }}
    </h4>
    <p>{{ subtitle }}</p>
    <br />
  </div>
</ng-template>
<ng-template #tplFooter>
  <div class="container modal-footer d-flex align-items-end flex-column" theme>
    <div class="row">
      <button class="btn btn-ok" (click)="destroyTplModal()">
        Ok
      </button>
    </div>
  </div>
</ng-template>
