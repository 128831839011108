<div class="page-content">
  <app-navigation></app-navigation>
  <app-sidebar-menu></app-sidebar-menu>
  <div class="upload-content">
    <img class="download-icon" src="assets/icons/download-solid.svg" />
    <span>O download da sua receita foi realizado com sucesso.</span>
    <span>
      Agora você precisa assinar digitalmente o PDF da receita e depois salvá-la dentro da plataforma.
    </span>
    <br /><br />
    <span
      >Obs.: Caso tenha duvidas,
      <a target="_blank" href="/assets/file/tutorial_sign.pdf">clique aqui</a> para verificar o passo a
      passo de como assinar a Receita</span
    >
    <br />
    <span>Salvar o PDF da Receita Assinado</span>
    <br />
    <input
      class="btn btn-primary text-white mt-50"
      type="file"
      (change)="uploadFile($event)"
      placeholder="Upload file"
      accept=".pdf"
    />
  </div>
</div>

<app-unsigned-prescription-modal></app-unsigned-prescription-modal>
