import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Attestation } from '@app/shared/models/attestation';
import { Patient } from '@app/shared/models/patient';
import { ColorSetting } from '@app/shared/models/color-setting';

@Component({
  selector: 'app-attestation',
  templateUrl: './attestation.component.html',
  styleUrls: ['./attestation.component.scss']
})
export class AttestationComponent implements OnInit {
  @Input() patient: Patient;
  @Input() colorSetting: ColorSetting;
  @Output() formEmitter: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  attestationForm: FormGroup = this.fb.group({
    text: this.fb.control('', Validators.required),
    description: this.fb.control('')
  });

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.onChangeValue();
    this.formEmitter.emit(this.attestationForm);
  }

  @Input()
  set attestation(attestation: Attestation) {
    if (attestation) {
      const value = attestation.description.reduce(
        (accumulator, currentValue) => accumulator + '\n' + currentValue
      );
      this.attestationForm.get('text').setValue(value);
      this.attestationForm.get('description').setValue(attestation.description);
    }

    this.onChangeValue();
  }

  @Input()
  set attestationModel(attestationModel: any) {
    if (attestationModel) {
      this.attestationForm.addControl('title', this.fb.control('', Validators.required));

      const value = attestationModel.description.reduce(
        (accumulator, currentValue) => accumulator + '\n' + currentValue
      );
      this.attestationForm.get('title').setValue(attestationModel.title);
      this.attestationForm.get('text').setValue(value);
      this.attestationForm.get('description').setValue(attestationModel.description);
    }

    this.onChangeValue();
  }

  onChangeValue() {
    this.attestationForm.get('text').valueChanges.subscribe(val => {
      if (val) {
        const value = val.split('\n');
        this.attestationForm.get('description').setValue(value);
      }
    });
    this.formEmitter.emit(this.attestationForm);
  }
}
