<div class="attestation" [formGroup]="attestationForm">
  <label for="material" theme>Digite aqui o conteúdo do atestado</label>
  <textarea
    rows="6"
    nz-input
    formControlName="text"
    [ngStyle]="{
      'background-color': colorSetting?.inputBackground,
      'border-color': colorSetting?.inputBorder
    }"
  ></textarea>
</div>
