<div theme>
  <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h1 class="titulo">Dispensar Medicamento</h1>

  <div class="modal-body pt-3 pb-3">
    <p class="title1">{{ medicament.name }}</p>
    <!-- <p class="title2">{{ medicament.dosage }}</p> -->
    <p class="title3">{{ medicament.dosage }}</p>

    <nz-collapse>
      <nz-collapse-panel
        *ngFor="let item of medicamentItems; let i = index"
        [nzHeader]="item.nome"
        [nzActive]="item.show"
        [nzExtra]="extraTpl"
        [id]="'item-' + i"
      >
        <p class="title3 mb-1">{{ item.nome }} - {{ item.apresentacaoCustomizada }}</p>
        <p class="title4 mb-1">{{ item.principio }}</p>
        <p class="title3 mb-1">{{ item.laboratorio }}</p>
        <p class="title3 mt-2">
          Intercambialidade:
          <nz-switch
            [(ngModel)]="item.intercambialidade"
            nzCheckedChildren="Sim"
            nzUnCheckedChildren="Não"
          ></nz-switch>
        </p>
        <p class="title5" *ngIf="item.caixas?.length">
          <span class="badge-caixa">{{ item.caixas.length }}</span> Quantas unidades desse produto
        </p>

        <div class="row m-0">
          <div class="col-12 m-0" *ngFor="let caixa of item.caixas">
            <span class="title3">
              <label class="title6">Número de lote caixa:</label> {{ caixa.nome }}
            </span>
            <a class="remove-icon" (click)="deleteBox(item, caixa.nome)">
              <i class="fa fa-trash-solid" style="font-size: 16px;"></i>
            </a>
            <hr class="m-0" />
          </div>

          <div class="col-10 pl-0 mt-2">
            <form nz-form [formGroup]="batchForm">
              <nz-form-item class="m-0">
                <nz-form-control>
                  <input
                    nz-input
                    formControlName="number"
                    placeholder="Digite aqui o número do lote da caixa"
                    maxlength="20"
                  />
                </nz-form-control>
              </nz-form-item>
            </form>
          </div>

          <div class="col-2 align-self-center pl-0 mt-2">
            <a class="add-icon" (click)="addBox(item)">
              <i class="fa fa-plus" style="font-size: 20px;"></i>
            </a>
          </div>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #extraTpl>
      <i nz-icon nzType="delete" (click)="deleteMedicament($event)"></i>
    </ng-template>

    <div class="row mt-2 mb-2" *ngIf="isNewItem">
      <div class="col-12" [formGroup]="medicationForm">
        <nz-input-group [nzSuffix]="suffixEan">
          <input
            nz-input
            formControlName="ean"
            [nzAutocomplete]="autoMedication"
            placeholder="Digite aqui o nome do medicamento ou código de barras para buscar"
            maxlength="250"
          />
          <nz-autocomplete #autoMedication nzBackfill>
            <nz-auto-option
              (click)="selectMedicament(option)"
              *ngFor="let option of medications"
              class="mao"
            >
              <div>{{ option?.nome }}, &nbsp;</div>
              <div>{{ option.apresentacaoCustomizada }},&nbsp;</div>
              <div></div>
              <div>{{ option?.tipo }}</div>
            </nz-auto-option>
          </nz-autocomplete>
        </nz-input-group>
        <ng-template #suffixEan>
          <i nz-icon nzType="loading" *ngIf="loading" class="pr-2"></i>
          <i nz-icon nzType="barcode" nzTheme="outline" style="font-size: 20px;"></i>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-3 d-flex justify-content-start">
      <button
        type="button"
        class="btn btn-icon btn-cancel btn-add text-capitalize btn-save"
        (click)="cancel()"
      >
        <span class="btn-inner--text">CANCELAR</span>
      </button>
    </div>

    <div class="col-4 d-flex justify-content-center">
      <button
        *ngIf="!invalidForm"
        type="button"
        class="btn btn-icon btn-cancel btn-add text-capitalize btn-save"
        (click)="newItem()"
      >
        <span class="btn-inner--text">NOVO ITEM</span>
      </button>
    </div>

    <div class="col-5 d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-icon btn-add text-capitalize btn-save"
        [ngClass]="invalidForm ? 'btn-cancel' : 'btn-info'"
        (click)="finish()"
        [disabled]="invalidForm"
      >
        <span class="btn-inner--text">CONCLUIR MEDICAMENTO</span>
      </button>
    </div>
  </div>
</div>
