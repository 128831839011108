import { Comercial } from './comercial';
import { SocialNetwork } from './social-network';
import { Address } from './address';
import { Specialty } from './specialty';

export class PreWriter {
  _id: string;
  userId: string;
  professionalType: string;
  name: string;
  cpf: string;
  type: string;
  regionalCouncilNumber: { number: Number; uf: string; label: string };
  specialties: Specialty[];
  telephone: string;
  email: string;
  password: string;
  otherPhones?: string[];
  otherEmails?: string[];
  personalAddress?: Address;
  dateOfBirth?: string;
  sex?: string;
  schooling?: string;
  photo?: string;
  socialNetworks?: SocialNetwork[];
  commercialData?: Comercial[];

  constructor(preWriter: any) {
    this._id = preWriter._id;
    this.userId = preWriter.userId;
    this.professionalType = preWriter.professionalType;
    this.name = preWriter.name;
    this.cpf = preWriter.cpf;
    this.regionalCouncilNumber = preWriter.regionalCouncilNumber;
    this.specialties = preWriter.specialties;
    this.telephone = preWriter.telephone;
    this.email = preWriter.email;
    this.password = preWriter.password;
    this.otherPhones = preWriter.otherPhones;
    this.otherEmails = preWriter.otherEmails;
    this.personalAddress = preWriter.personalAddress;
    this.dateOfBirth = preWriter.dateOfBirth;
    this.sex = preWriter.sex;
    this.schooling = preWriter.schooling;
    this.photo = preWriter.photo;
    this.socialNetworks = preWriter.socialNetworks;
    this.commercialData = preWriter.commercialData;
  }
}
