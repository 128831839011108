<app-alternative-navbar title="Meu Painel" titleIcon="fa-view-compact"></app-alternative-navbar>
<app-sidebar-menu></app-sidebar-menu>
<app-float-button *ngIf="hasPatientRecordFeature"></app-float-button>

<ng-container *ngIf="hasCipFeature; then templateNew; else templateOld"></ng-container>

<ng-template #templateNew>
  <div class="container">
    <div class="row justify-content-around pt-4">
      <div class="col-1"></div>
      <div class="col-md-4">
        <p class="card-title">RECEITA DIGITAL</p>
        <nz-card class="card search-card">
          <form (submit)="search(param.value)">
            <p>
              Digite abaixo o CPF do paciente (somente números) ou o código da receita para validar e
              registrar a dispensação.
            </p>

            <nz-input-group [nzSuffix]="suffixIconSearch">
              <input nz-input #param />
            </nz-input-group>
            <ng-template #suffixIconSearch>
              <i nz-icon nzType="search" (click)="search(param.value)"></i>
            </ng-template>
          </form>
        </nz-card>
      </div>

      <div class="col-md-4">
        <p class="card-title">OUTRAS RECEITAS</p>
        <nz-card class="card dispensation-card">
          <p>
            Faça upload do PDF de receitas que não foram feitas na Receita Digital para validar e
            registar a dispensação
          </p>

          <app-input-file
            [(ngModel)]="pdf"
            accept=".pdf"
            textButton="Selecione uma receita"
            placeholder=""
            maxSize="3145728"
            name="pdf"
            [(filename)]="filename"
          ></app-input-file>
          <button *ngIf="pdf" nz-button class="btn-send mt-2" nzType="primary" (click)="sendPdf()">
            Enviar
          </button>
        </nz-card>
      </div>
      <div class="col-1"></div>
    </div>
  </div>
</ng-template>

<!-- ************************************************************************************************************** -->
<ng-template #templateOld>
  <div *ngIf="completeUser" class="container-dashboard">
    <div class="container">
      <section class="section-profile">
        <div class="avatar-container" *ngIf="avatarB64" title="Meus Dados">
          <a routerLink="/profile"><img class="mr-3 avatar" [src]="avatarB64"/></a>
        </div>

        <img
          class="mr-3 avatar"
          src="assets/img/default-avatar.png"
          alt="Generic placeholder image"
          *ngIf="!avatarB64 && completeUser.sex !== 'F'"
        />

        <img
          class="mr-3 avatar"
          src="assets/img/default-avatar.F.png"
          alt="Generic placeholder image"
          *ngIf="!avatarB64 && completeUser.sex === 'F'"
        />

        <div>
          <ng-container [ngSwitch]="user.userType">
            <h5 class="mt-0 user-name" *ngSwitchCase="'healthProfessional'">Olá, {{ doctorName }}</h5>
            <h5 class="mt-0 user-name" *ngSwitchCase="'patient'">Olá, {{ completeUser?.name }}</h5>
            <h5 class="mt-0 user-name" *ngSwitchCase="'pharmacist'">Olá, {{ completeUser?.name }}</h5>
            <h5 class="mt-0 user-name" *ngSwitchCase="'pharmacy'">
              Olá, {{ completeUser?.fantasyName }}
            </h5>
          </ng-container>
          <p class="date">{{ today | date: 'fullDate' }} | {{ today | date: 'HH:mm' }}</p>
          <p class="crm" *ngIf="user.userType === 'healthProfessional'">
            {{ completeUser?.regionalCouncilNumber.label }}:
            {{ completeUser?.regionalCouncilNumber?.number }}/{{
              completeUser?.regionalCouncilNumber?.uf
            }}
          </p>
        </div>
      </section>
      <section
        [ngSwitch]="user.userType"
        [ngStyle]="user.userType !== 'healthProfessional' ? { 'margin-top': '30px' } : {}"
      >
        <ng-container *ngSwitchCase="'healthProfessional'">
          <div class="row flex-wrap justify-content-md-center">
            <div class="col-md-3 tile-container">
              <a
                routerLink="/prescription/new"
                class="tiles card-nova-prescricao d-flex align-items-center flex-column justify-content-center"
              >
                Nova Prescrição
                <i class="fa fa-plus"></i>
              </a>
            </div>
            <div class="col-md-3 tile-container">
              <a
                routerLink="/favorites"
                class="tiles card-modelo-favorito d-flex align-items-center flex-column justify-content-center"
              >
                Modelos Favoritos
                <i class="fa fa-heart"></i>
              </a>
            </div>
            <div class="col-md-3 tile-container">
              <a
                routerLink="/historic"
                class="tiles card-historico-prescricao d-flex align-items-center flex-column justify-content-center pointer"
              >
                <span class="option-title">
                  Minhas Prescrições
                </span>
                <i class="fas fa-history"></i>
              </a>
            </div>
            <div class="col-md-3 tile-container">
              <a
                routerLink="/patients"
                class="tiles card-pacientes d-flex align-items-center flex-column justify-content-center pointer"
              >
                <span>
                  Pacientes
                </span>
                <i class="fa-assignment"></i>
              </a>
            </div>
            <div class="col-md-3 tile-container">
              <a
                routerLink="/document/new"
                class="tiles card-new-prescription d-flex align-items-center flex-column justify-content-center pointer"
              >
                <div class="ribbon ribbon-top-left"><span>BETA</span></div>
                <span class="w-75">
                  Experimente um novo jeito de prescrever!
                </span>
                <i class="fa-solid fa-pills"></i>
              </a>
            </div>
            <div class="col-md-3 tile-container" *ngIf="!hasAccessToManipulatedFunctionality()">
              <a
                routerLink="/profile"
                class="tiles card-meus-dados d-flex align-items-center flex-column justify-content-center"
              >
                Meus Dados
                <i class="fa fa-account-box"></i>
              </a>
            </div>
          </div>
          <div
            class="row flex-wrap justify-content-md-center"
            *ngIf="hasAccessToManipulatedFunctionality()"
          >
            <div class="col-md-3 tile-container">
              <a
                routerLink="/profile"
                class="tiles card-meus-dados d-flex align-items-center flex-column justify-content-center"
              >
                Meus Dados
                <i class="fa fa-account-box"></i>
              </a>
            </div>

            <div class="col-md-3 tile-container" *ngIf="hasAccessToManipulatedFunctionality()">
              <a
                routerLink="/personalized-medications"
                class="tiles dark  d-flex align-items-center flex-column justify-content-center"
              >
                <span class="option-title">
                  Personalizar Tratamento
                </span>
                <i nz-icon nzType="profile" nzTheme="outline"></i>
              </a>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'patient'">
          <div class="row flex-wrap justify-content-md-center">
            <div class="col-md-3 tile-container">
              <a
                routerLink="/historic"
                class="tiles card-historico-prescricao d-flex align-items-center flex-column justify-content-center pointer"
              >
                Minhas Prescrições
                <i class="fa fa-account-box"></i>
              </a>
            </div>

            <div class="col-md-3 tile-container">
              <a
                routerLink="/profile"
                class="tiles card-meus-dados d-flex align-items-center flex-column justify-content-center"
              >
                <span class="option-title">
                  Meus Dados
                </span>
                <i class="fas fa-history"></i>
              </a>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'pharmacy'">
          <div class="row flex-wrap justify-content-md-center" style="text-align: center;">
            <div class="col-md-3 tile-container">
              <a>
                <div
                  (click)="open()"
                  class="tiles card-nova-prescricao d-flex align-items-center flex-column justify-content-center"
                >
                  Buscar Receitas
                  <i class="fas fa-search"></i>
                </div>
              </a>
            </div>
            <div class="col-md-3 tile-container">
              <a
                routerLink="/pharmacy/recipe"
                class="tiles card-pacientes d-flex align-items-center flex-column justify-content-center pointer"
              >
                <span>
                  Receita de Terceiros
                </span>
                <i class="fas fa-clipboard-list"></i>
              </a>
            </div>

            <div class="col-md-3 tile-container">
              <a
                routerLink="/pharmacist"
                class="tiles card-meus-dados d-flex align-items-center flex-column justify-content-center"
              >
                Farmacêuticos
                <i nz-icon nzType="team" nzTheme="outline"></i>
              </a>
            </div>
          </div>

          <div class="row flex-wrap justify-content-md-center">
            <div class="col-md-3 tile-container">
              <a
                routerLink="/historic"
                class="tiles card-historico-prescricao d-flex align-items-center flex-column justify-content-center pointer"
              >
                <span>
                  Histórico de Retenções
                </span>
                <i class="fas fa-history"></i>
              </a>
            </div>
            <div class="col-md-3 tile-container">
              <a
                routerLink="/profile"
                class="tiles card-meus-dados d-flex align-items-center flex-column justify-content-center"
              >
                Meus dados
                <i class="fa fa-account-box"></i>
              </a>
            </div>
            <div class="col-md-3 tile-container" *ngIf="hasAccessToManipulatedFunctionality()">
              <a
                routerLink="/personalized-medications"
                class="tiles card-nova-prescricao d-flex align-items-center flex-column justify-content-center"
              >
                Manipulados
                <i nz-icon nzType="medicine-box" nzTheme="outline"></i>
              </a>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'pharmacist'">
          <div class="row flex-wrap justify-content-md-center">
            <div class="col-md-3 tile-container">
              <div
                (click)="open()"
                class="tiles card-nova-prescricao d-flex align-items-center flex-column justify-content-center"
              >
                Buscar Receitas
                <i class="fas fa-search"></i>
              </div>
            </div>
            <div class="col-md-3 tile-container">
              <a
                routerLink="/pharmacy/recipe"
                class="tiles card-pacientes d-flex align-items-center flex-column justify-content-center pointer"
              >
                Receita de Terceiros
                <i class="fas fa-clipboard-list"></i>
              </a>
            </div>
            <div class="col-md-3 tile-container">
              <a
                routerLink="/profile"
                class="tiles card-meus-dados d-flex align-items-center flex-column justify-content-center"
              >
                Meus Dados
                <i class="fa fa-account-box"></i>
              </a>
            </div>
            <div class="col-md-3 tile-container" *ngIf="hasAccessToManipulatedFunctionality()">
              <a
                routerLink="/personalized-medications"
                class="tiles card-pacientes d-flex align-items-center flex-column justify-content-center pointer"
              >
                Manipulados
                <i nz-icon nzType="medicine-box" nzTheme="outline"></i>
              </a>
            </div>
          </div>
        </ng-container>
      </section>
    </div>
  </div>
</ng-template>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<app-modal-cip-acceptance
  [(isVisible)]="isModalCipVisible"
  [code]="this.cipFeature?.code"
></app-modal-cip-acceptance>
<app-modal-rqe-warning
  *ngIf="completeUser"
  [(isVisible)]="isModalRqeVisible"
  [healthProfessional]="completeUser"
>
</app-modal-rqe-warning>
<app-modal-womens-month *ngIf="completeUser" [(isVisible)]="isModalWomanVisible">
</app-modal-womens-month>
