import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExamType } from '@app/shared/models';
import { ColorSetting } from '@app/shared/models/color-setting';
import { ExamItem, ExamList, Exam } from '@app/shared/models/exam';
import { NzNotificationService } from 'ng-zorro-antd';
import { ExamService } from '../../services/exam.service';

@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExamComponent implements OnInit {
  @Input() colorSetting: ColorSetting;
  @Output() formEmitter: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  position: number;
  adding: boolean;
  materialSelected: string;
  materials: string[];
  examTypes: ExamType[];
  hasForm = false;
  showJustification = false;

  examForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private examService: ExamService,
    private notification: NzNotificationService
  ) {}

  ngOnInit() {
    this.position = 0;
    this.buildForm();
    this.examService.getMaterials().subscribe(materials => {
      this.materials = materials;
    });
  }

  private buildForm(exam?: Exam) {
    if (!this.examForm) {
      this.examForm = this.fb.group({
        examList: this.fb.array([], Validators.required),
        justification: this.fb.control([]),
        text: this.fb.control('')
      });
      this.examListForm.push(this.formExamList());
    }

    if (exam && exam.document === 'exam') {
      if (exam.title) {
        this.examForm.addControl('title', this.fb.control('', Validators.required));
        this.examForm.get('title').setValue(exam.title);
      }

      this.examListForm.clear();
      exam.examList.forEach(ex => this.addMaterial(ex));
      this.examForm.get('justification').setValue(exam.justification);
      if (exam.justification) {
        const value = exam.justification.reduce((acc, val) => `${acc}\n${val}`, '');
        this.examForm.get('text').setValue(value);
        this.showJustification = !!value;
      }
    }
    this.formEmitter.emit(this.examForm);
  }

  @Input()
  set exam(exam: Exam) {
    if (exam) {
      this.buildForm(exam);
    }
  }

  @Input()
  set examModel(examModel: Exam) {
    if (examModel) {
      this.buildForm(examModel);
    }
  }

  private formExamList(examList: ExamList = null) {
    const group = this.fb.group({
      material: this.fb.control('', Validators.required),
      exams: this.fb.array([])
    });

    const examsForm = <FormArray>group.get('exams');
    if (examList) {
      const materialForm = group.get('material');

      const { material, exams } = examList;
      materialForm.setValue(material);
      exams.forEach(exam => examsForm.push(this.formExamItem(exam)));
    } else {
      examsForm.push(this.formExamItem());
    }

    return group;
  }

  private formExamItem(exam: ExamItem = null) {
    return this.fb.group({
      name: this.fb.control(exam ? exam.name : '', Validators.required),
      tuss: this.fb.control(exam ? exam.tuss : ''),
      position: this.fb.control(this.position++)
    });
  }

  get examListForm(): FormArray {
    return <FormArray>this.examForm.get('examList');
  }

  private getExam(examListIndex: number) {
    return this.examListForm.at(examListIndex);
  }

  onChangeExamType(examType: string, examListIndex: number) {
    this.adding = true;
    const examList: ExamList = this.getExam(examListIndex).value;
    const filterExists = examList.exams.map(itemExists => itemExists.name);
    this.examService.getExamTypes(examList.material, examType).subscribe(res => {
      const resFiltered = res.filter(itemRes => !filterExists.includes(itemRes.exam));
      this.examTypes = resFiltered;
      this.adding = false;
    });
  }

  onChangeJustification(text: string) {
    const value = text.split('\n');
    this.examForm.get('justification').setValue(value);
  }

  selectExamType(exam: ExamType, index: number) {
    this.examListForm.value[index] = exam._id;
  }

  addNewExam(examListIndex: number): void {
    const exam: FormArray = <FormArray>this.getExam(examListIndex).get('exams');
    if (exam.valid) {
      exam.push(this.formExamItem());
    } else {
      this.notification.warning('Aviso', 'Você precisa selecionar um exame.');
    }
  }

  addMaterial(examList: ExamList = null): void {
    const list = this.examListForm.value;
    const filterExists = list.map(itemExists => itemExists.material);

    this.examService.getMaterials().subscribe(materials => {
      const resFiltered = materials.filter(itemRes => !filterExists.includes(itemRes));
      this.materials = resFiltered;
    });
    if (list.length === 0 || list[list.length - 1].material) {
      this.examListForm.push(this.formExamList(examList));
    }
  }

  addJustification(): void {
    if (this.examListForm.value.length) {
      this.showJustification = true;
    }
  }

  removeJustification(): void {
    this.onChangeJustification('');
    this.showJustification = false;
  }

  removeMaterialAt(index: number): void {
    if (this.examListForm.length > 1) {
      this.examListForm.removeAt(index);
    } else if (this.examListForm.length === 1) {
      this.examListForm.at(index).reset();
    }
  }

  removeExamAt(examListIndex: number, examIndex: number): void {
    const exams: FormArray = <FormArray>this.examListForm.at(examListIndex).get('exams');
    if (exams.length > 1) {
      exams.removeAt(examIndex);
    } else if (exams.length === 1) {
      exams.at(examIndex).reset();
    }
  }

  hasMaterial(examListIndex: number) {
    return !!(<FormArray>this.examListForm.at(examListIndex).value.material);
  }
}
