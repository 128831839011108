<div class="protocols-modal" theme>
  <div
    class="modal-header"
    [ngStyle]="{
      'background-color': colorSetting?.secondary,
      'border-color': colorSetting?.secondary
    }"
  >
    <h4 class="modal-title pull-left text-white">Sugestão de Protocolos</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="body protocols">
    <nz-list nzBordered="false">
      <nz-list-item *ngFor="let group of groups" class="flex-column pl-2 pr-2">
        <div
          class="row group align-items-center"
          [ngStyle]="{
            'background-color': colorSetting?.primary,
            'border-color': colorSetting?.primary
          }"
        >
          <div class="col-1 pb-1" (click)="activeChange(group)">
            <i nz-icon nzType="right" *ngIf="!group.active" nzTheme="outline"></i>
            <i nz-icon nzType="down" *ngIf="group.active" nzTheme="outline"></i>
          </div>

          <div class="col-7">
            <h6 class="m-0">{{ group.name }}</h6>
          </div>

          <div *ngIf="group.active" class="col-12 protocols pl-0 pr-0">
            <nz-list nzBordered="false">
              <nz-list-item *ngFor="let protocol of group.protocols" class="flex-column pl-0 pr-0">
                <div class="row subgroup align-items-center">
                  <div class="col-1 pb-1" (click)="activeChange(protocol)">
                    <i nz-icon nzType="right" *ngIf="!protocol.active" nzTheme="outline"></i>
                    <i nz-icon nzType="down" *ngIf="protocol.active" nzTheme="outline"></i>
                  </div>

                  <div class="col">
                    <h6 class="m-0">
                      <span *ngIf="protocol.code">{{ protocol.code }} - </span>{{ protocol.name }}
                    </h6>
                  </div>

                  <div class="col p-2" style="text-align: end;">
                    <button
                      class="ok-btn btn-select"
                      nz-button
                      (click)="selectProtocol(protocol)"
                      [ngStyle]="{
                        'background-color': colorSetting?.primary,
                        'border-color': colorSetting?.primary
                      }"
                    >
                      Adicionar
                    </button>
                  </div>
                </div>

                <div class="row elements" *ngIf="protocol.active">
                  <div *ngFor="let phase of protocol.phases" class="col-12 mt-3">
                    <p class="bold">
                      <span *ngIf="phase.name">{{ phase.name }} - </span>{{ phase.category }}
                    </p>
                    <div *ngFor="let element of phase.elements" class="pl-4 mt-2">
                      <p><span class="bold">Composição: </span>{{ element.name }}</p>
                      <p><span class="bold">Quantidade: </span>{{ element.amount }}</p>
                      <p><span class="bold">Medida: </span>{{ element.measurement }}</p>
                    </div>
                  </div>
                </div>
              </nz-list-item>
            </nz-list>
          </div>
        </div>
      </nz-list-item>
    </nz-list>
    <br />
  </div>
</div>
