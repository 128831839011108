import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthService } from '@app/core/services/auth.service';
import { HealthProfessionalService } from '@app/modules/health-professional/services/health-professional.service';
import { PatientsService } from '@app/modules/patients/services/patients.service';
import { User } from '@app/shared/models/decodedLoginToken';
import { BigboostService } from '@app/shared/services/bigboost.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-underage-data-form',
  templateUrl: './underage-data-form.component.html'
})
export class UnderageDataFormComponent implements OnInit {
  @Input() isResponsible = false;
  @Input() form: FormGroup;

  @Output() patchFormEvent = new EventEmitter<any>();
  @Output() changeConsultResponsibleEvent = new EventEmitter<boolean>();

  loading: boolean = false;
  consulted: boolean = false;

  constructor(
    private authService: AuthService,
    private patientService: PatientsService,
    private healthProfessionalService: HealthProfessionalService,
    private bigboostService: BigboostService,
    private notification: NzNotificationService
  ) {}

  ngOnInit() {}

  get cpf() {
    return this.form.get('responsible.cpf');
  }

  get user(): User {
    return this.authService.user();
  }

  async searchCpf() {
    try {
      const cpf = this.cpf.value;

      this.loading = true;
      const patient = await this.getPatient(cpf);

      if (patient) {
        this.consulted = true;
        this.patchFormEvent.emit({
          patientExists: true,
          data: patient
        });
      } else {
        const consult = await this.consultCpf(cpf);
        if (consult.status === 1) {
          this.consulted = true;
          this.patchFormEvent.emit({
            patientExists: false,
            data: consult
          });
        } else {
          this.patchFormEvent.emit(null);
          this.notification.warning(
            'Responsável não encontrado.',
            'CPF informado não foi encontrado em nossas bases de consulta'
          );
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  private async getPatient(cpf: string) {
    try {
      const hasPatient = await this.hasPatient(cpf);
      if (hasPatient) {
        return hasPatient;
      }
      return await this.patientService.getPatientByCpf(cpf).toPromise();
    } catch (err) {
      return null;
    }
  }

  private async hasPatient(cpf: string) {
    const result = await this.healthProfessionalService
      .getPatientsByUserId(cpf, this.user._id)
      .toPromise();
    return result.length ? result[0] : null;
  }

  private async consultCpf(cpf: string) {
    try {
      return await this.bigboostService.consultCpf(cpf).toPromise();
    } catch (err) {
      console.error(err);
      const consult = {
        status: -1,
        cpf,
        erroCodigo: err.status,
        erro: 'A importação de dados do cpf está com instabilidade'
      };
      return consult;
    }
  }

  changedNewRepnsible() {
    this.consulted = false;
    this.changeConsultResponsibleEvent.emit(false);
    this.cpf.reset();
    this.cpf.enable();
  }
}
