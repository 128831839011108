import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '@app/core/services/validation.service';
import { CepService } from '@app/modules/entry/services/cep.service';
import { HealthProfessionalService } from '@app/modules/health-professional/services/health-professional.service';
import { PatientsService } from '@app/modules/patients/services/patients.service';
import { PharmacistService } from '@app/modules/pharmacist/services/pharmacist.service';
import { PharmacyService } from '@app/modules/pharmacy/services/pharmacy.service';
import { BrazilianStates } from '@app/shared/data/Brazilian-states';
import { BrazilState } from '@app/shared/models';
import { User } from '@app/shared/models/decodedLoginToken';
import { markFormGroup } from '@app/utils/markFormGroup';
import { NzModalRef } from 'ng-zorro-antd';

@Component({
  selector: 'app-modal-edit-address-data',
  templateUrl: './modal-edit-address-data.component.html',
  styleUrls: ['./modal-edit-address-data.component.scss']
})
export class ModalEditAddressDataComponent implements OnInit {
  user: User;
  completeUser: any;
  isNew: boolean;
  userType: string;
  commercialData: any;
  commercialDataIndex: number;
  loading = false;
  form: FormGroup;

  states: BrazilState[] = BrazilianStates;
  @Output() updateUserEvent = new EventEmitter<User>();

  constructor(
    private fb: FormBuilder,
    private cepService: CepService,
    private patientService: PatientsService,
    private healthProfessionalService: HealthProfessionalService,
    private pharmacyService: PharmacyService,
    private pharmacistService: PharmacistService,
    private nzModalRef: NzModalRef
  ) {}

  ngOnInit() {
    if (this.userType === 'healthProfessional') {
      this.buildCommercialAddressForm();
    } else {
      this.buildAddressForm();
    }
  }

  private buildCommercialAddressForm() {
    this.form = this.fb.group({
      email: ['', Validators.email],
      name: ['', Validators.required],
      telephone: ['', Validators.required],
      cellphone: [''],
      address: this.fb.group({
        uf: ['', Validators.required],
        city: ['', Validators.required],
        complement: [''],
        number: ['', Validators.required],
        street: ['', Validators.required],
        neighborhood: ['', Validators.required],
        cep: ['', [Validators.required, ValidationService.cepValidator]]
      })
    });

    if (!this.isNew) {
      this.form.patchValue(this.commercialData);
    }
  }

  private buildAddressForm() {
    this.form = this.fb.group({
      address: this.fb.group({
        uf: ['', Validators.required],
        city: ['', Validators.required],
        complement: [''],
        number: ['', Validators.required],
        street: ['', Validators.required],
        neighborhood: ['', Validators.required],
        cep: ['', [Validators.required, ValidationService.cepValidator]]
      })
    });
    this.form.controls.address.patchValue(this.completeUser.address);
  }

  async submitForm() {
    markFormGroup(this.form);
    try {
      if (this.form.valid) {
        this.loading = true;
        this.form.disable();

        switch (this.userType) {
          case 'healthProfessional':
            if (this.isNew) {
              this.completeUser.commercialData.push(this.form.value);
            } else {
              this.completeUser.commercialData[this.commercialDataIndex] = this.form.value;
            }
            this.completeUser = await this.healthProfessionalService
              .update(this.completeUser.userId, this.completeUser)
              .toPromise();
            break;

          case 'pharmacy':
            this.completeUser.address = this.form.value.address;
            this.completeUser = await this.pharmacyService
              .update(this.completeUser._id, this.completeUser)
              .toPromise();
            break;
          case 'pharmacist':
            this.completeUser.address = this.form.value.address;
            this.completeUser = await this.pharmacistService
              .update(this.completeUser._id, this.completeUser)
              .toPromise();
            break;

          default:
            this.completeUser.address = this.form.value.address;
            this.completeUser = await this.patientService
              .update(this.completeUser._id, this.completeUser)
              .toPromise();
        }

        this.form.enable();
        this.updateUserEvent.emit(this.completeUser);
        this.loading = false;
        this.nzModalRef.destroy();
      }
    } catch (err) {
      this.loading = false;
    }
  }

  async onKey(event: any) {
    if (event.target.value.length === 10) {
      const cep = event.target.value.replace(/[^\d]+/g, '');
      const data = await this.cepService.consult(cep);
      if (!data.erro) {
        this.form.controls['address'].setValue({
          uf: data.uf,
          street: data.logradouro,
          neighborhood: data.bairro,
          city: data.localidade,
          complement: data.complemento,
          number: null,
          cep: data.cep
        });
      }
    }
  }
}
