import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { HistoricComponent } from '@app/modules/historic/historic.component';
import { AuthGuard } from '@app/shared/guards/auth.guard';
import { environment } from '@env/environment';
import { MyDataComponent } from './modules/dashboard/my-data/my-data.component';
import { RecipeComponent } from './modules/pharmacy/recipe/recipe.component';
import { SystemUpdateComponent } from './modules/system-update/system-update.component';
import { EntryGuard } from './shared/guards/entry.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: '@app/modules/home/home.module#HomeModule'
  },
  {
    path: 'maintenance',
    component: SystemUpdateComponent
  },
  {
    path: 'p/:code',
    loadChildren: '@app/modules/public-document/public-document.module#PublicDocumentModule'
  },
  {
    path: 'entry',
    canActivate: [EntryGuard],
    loadChildren: '@app/modules/entry/entry.module#EntryModule'
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: '@app/modules/dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'patients',
    canActivate: [AuthGuard],
    loadChildren: '@app/modules/patients/patients.module#PatientsModule'
  },
  // {
  //   path: 'personalized-medications',
  //   canActivate: [AuthGuard],
  //   loadChildren:
  //     '@app/modules/personalized-medications/personalized-medications.module#PersonalizedMedicationsModule'
  // },
  {
    path: 'doctor',
    canActivate: [AuthGuard],
    loadChildren: '@app/modules/health-professional/health-professional.module#HealthProfessionalModule'
  },
  {
    path: 'dentist',
    canActivate: [AuthGuard],
    loadChildren: '@app/modules/health-professional/health-professional.module#HealthProfessionalModule'
  },
  {
    path: 'prescriber',
    canActivate: [AuthGuard],
    loadChildren: '@app/modules/health-professional/health-professional.module#HealthProfessionalModule'
  },
  {
    path: 'prescription',
    canActivate: [AuthGuard],
    loadChildren: '@app/modules/document/document.module#DocumentModule'
  },
  {
    path: 'document',
    canActivate: [AuthGuard],
    loadChildren: '@app/modules/document-v2/document-v2.module#DocumentV2Module'
  },
  {
    path: 'recipe',
    loadChildren:
      '@app/modules/external-platform-document/external-platform-document.module#ExternalPlatformDocumentModule'
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    component: MyDataComponent
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: '@app/modules/admin/admin.module#AdminModule'
  },
  {
    path: 'historic',
    canActivate: [AuthGuard],
    component: HistoricComponent
  },
  {
    path: 'dispensation',
    canActivate: [AuthGuard],
    loadChildren: '@app/modules/dispensation/dispensation.module#DispensationModule'
  },
  {
    path: 'pharmacy/recipe',
    canActivate: [AuthGuard],
    component: RecipeComponent
  },
  {
    path: 'pharmacist',
    canActivate: [AuthGuard],
    loadChildren: '@app/modules/pharmacist/pharmacist.module#PharmacistModule'
  },
  {
    path: 'favorites',
    canActivate: [AuthGuard],
    loadChildren:
      '@app/modules/favorite-prescription/favorite-prescription.module#FavoritePrescriptionModule'
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router) {
    this.router.config.forEach(route => {
      if (environment.maintenance && route.path !== 'maintenance') {
        route.redirectTo = 'maintenance';
      }
    });
  }
}
