<div theme>
  <header>
    <span class="title">
      DADOS DO COMPRADOR
    </span>
  </header>
  <div class="modal-body">
    <h3 class="subtitle">
      Insira os dados do comprador para finalizar a retenção da receita.
    </h3>

    <form nz-form [formGroup]="form">
      <div class="row">
        <div class="col-md-3 d-flex align-items-center">
          <nz-form-item>
            <label
              nz-checkbox
              (nzCheckedChange)="setDataPatient($event)"
              formControlName="isPatientBuyer"
              class="custom-check mr-1 align-self-start"
              >O comprador é o paciente</label
            >
          </nz-form-item>
        </div>

        <div class="col-md">
          <nz-form-item *ngIf="form.get('cpf') as cpf">
            <nz-form-label nzFor="cpf">CPF do comprador<span class="mandatory"> *</span></nz-form-label>
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                id="cpf"
                name="cpf"
                formControlName="cpf"
                mask="000.000.000-00"
                (keyup)="onKeyFindBuyer($event)"
                placeholder="Digite o CPF do comprador"
              />
              <nz-form-explain *ngIf="cpf.errors">
                <ng-container *ngIf="cpf.dirty && cpf.hasError('required')">
                  Campo obrigatório, por favor digite seu CPF.
                </ng-container>
                <ng-container *ngIf="!cpf.hasError('required') && cpf.hasError('cpf')">
                  CPF inválido.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-md">
          <nz-form-item *ngIf="form.get('identity') as identity">
            <nz-form-label nzFor="identity">Identidade<span class="mandatory"> *</span></nz-form-label>
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                formControlName="identity"
                placeholder="Digite a identidade do comprador"
                name="identity"
                id="identity"
                maxlength="12"
              />
              <nz-form-explain *ngIf="identity.dirty && identity.errors">
                <ng-container *ngIf="identity.hasError('required')">
                  Campo obrigatório, por favor digite a identidade.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-md-5">
          <nz-form-item *ngIf="form.get('name') as name">
            <nz-form-label nzFor="name"
              >Nome do comprador<span class="mandatory"> *</span></nz-form-label
            >
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                id="name"
                formControlName="name"
                placeholder="Digite o nome"
                name="name"
                maxlength="128"
              />
              <nz-form-explain *ngIf="name.dirty && name.errors">
                <ng-container *ngIf="name.hasError('required')">
                  Campo obrigatório, por favor digite o nome e sobrenome do comprador.
                </ng-container>
                <ng-container *ngIf="!name.hasError('required') && name.hasError('nome')">
                  Por favor digite o nome e sobrenome do comprador.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-md">
          <nz-form-item *ngIf="form.get('cellphone') as cellphone">
            <nz-form-label nzFor="cellphone">Telefone 1 <span class="mandatory">*</span></nz-form-label>
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                id="cellphone"
                name="cellphone"
                formControlName="cellphone"
                [placeholder]="cellphone.value ? 'Digite o Telefone' : ''"
                [mask]="cellphone.value?.length <= 10 ? '(00) 0000-00009' : '(00) 00000-0000'"
              />
              <nz-form-explain *ngIf="cellphone.dirty && cellphone.errors">
                <ng-container *ngIf="cellphone.hasError('required')">
                  Campo obrigatório, por favor digite o telefone do comprador.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-md">
          <nz-form-item *ngIf="form.get('telephone') as telephone">
            <nz-form-label nzFor="telephone">Telefone 2</nz-form-label>
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                id="telephone"
                name="telephone"
                formControlName="telephone"
                [placeholder]="telephone.value === null ? 'Digite o Telefone' : ''"
                [mask]="telephone.value?.length <= 10 ? '(00) 0000-00009' : '(00) 00000-0000'"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="row" [formGroup]="form.get('address')">
        <div class="col-md-3">
          <nz-form-item>
            <nz-form-label nzFor="cep">CEP<span class="mandatory"> *</span></nz-form-label>
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                type="text"
                mask="00.000-000"
                id="cep"
                formControlName="cep"
                placeholder="ex: 27.244-333"
                name="cep"
                (keyup)="onKeyFindCep($event)"
              />
              <nz-form-explain *ngIf="form.get('address.cep').dirty && form.get('address.cep').errors">
                <ng-container *ngIf="form.get('address.cep').hasError('required')">
                  Campo obrigatório, por favor digite seu CEP.
                </ng-container>
                <ng-container
                  *ngIf="
                    !form.get('address.cep').hasError('required') &&
                    form.get('address.cep').hasError('address.cep')
                  "
                >
                  CEP inválido, por favor digite um CEP válido.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-md">
          <nz-form-item>
            <nz-form-label nzFor="street">Logradouro<span class="mandatory"> *</span></nz-form-label>
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                placeholder="Digite o logradouro"
                formControlName="street"
                name="street"
                maxlength="128"
                id="street"
              />
              <nz-form-explain
                *ngIf="form.get('address.street').dirty && form.get('address.street').errors"
              >
                <ng-container *ngIf="form.get('address.street').hasError('required')">
                  Campo obrigatório, por favor digite o logradouro.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-md-3">
          <nz-form-item>
            <nz-form-label nzFor="number">Número<span class="mandatory"> *</span></nz-form-label>
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                formControlName="number"
                placeholder="Digite o número"
                name="number"
                id="number"
                maxlength="10"
              />
              <nz-form-explain
                *ngIf="form.get('address.number').dirty && form.get('address.number').errors"
              >
                <ng-container *ngIf="form.get('address.number').hasError('required')">
                  Campo obrigatório, por favor digite o número.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="row" [formGroup]="form.get('address')">
        <div class="col-md">
          <nz-form-item>
            <nz-form-label nzFor="complement">Complemento</nz-form-label>
            <nz-form-control nzHasFeedback>
              <input
                formControlName="complement"
                id="complement"
                nz-input
                placeholder="Digite o complemento"
                maxlength="128"
              />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-md">
          <nz-form-item>
            <nz-form-label nzFor="neighborhood">Bairro<span class="mandatory"> *</span></nz-form-label>
            <nz-form-control nzHasFeedback>
              <input
                formControlName="neighborhood"
                id="neighborhood"
                nz-input
                placeholder="Digite o bairro"
                maxlength="128"
              />
              <nz-form-explain
                *ngIf="form.get('address.neighborhood').dirty && form.get('address.neighborhood').errors"
              >
                <ng-container *ngIf="form.get('address.neighborhood').hasError('required')">
                  Campo obrigatório, por favor digite o bairro.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="row" [formGroup]="form.get('address')">
        <div class="col-md">
          <nz-form-item>
            <nz-form-label nzFor="city">Cidade<span class="mandatory"> *</span></nz-form-label>
            <nz-form-control nzHasFeedback>
              <input
                formControlName="city"
                id="city"
                name="city"
                nz-input
                placeholder="Digite a cidade"
                maxlength="64"
              />
              <nz-form-explain *ngIf="form.get('address.city').dirty && form.get('address.city').errors">
                <ng-container *ngIf="form.get('address.city').hasError('required')">
                  Campo obrigatório, por favor digite a cidade.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-md">
          <nz-form-item>
            <nz-form-label nzFor="uf">UF<span class="mandatory"> *</span></nz-form-label>
            <nz-form-control nzHasFeedback>
              <nz-select
                formControlName="uf"
                id="uf"
                name="uf"
                nzAllowClear
                nzShowSearch
                nzDropdownClassName="account-select"
                nzPlaceHolder="Selecione"
              >
                <nz-option
                  *ngFor="let state of states"
                  [nzLabel]="state?.sigla"
                  [nzValue]="state?.sigla"
                >
                </nz-option>
              </nz-select>
              <nz-form-explain *ngIf="form.get('address.uf').dirty && form.get('address.uf').errors">
                <ng-container *ngIf="form.get('address.uf').hasError('required')">
                  Campo obrigatório, por favor selecione o UF.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
  </div>
  <nz-divider nzOrientation="left"></nz-divider>
  <div class="container row">
    <div class="col-md-6">
      <div *ngIf="loading">
        <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
        <nz-spin nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
      </div>
    </div>
    <div class="col-md-6 d-flex justify-content-end" theme>
      <button nz-button class="btn-cancel mr-3" (click)="close()">Cancelar</button>
      <button nz-button class="btn-ok" (click)="save()">{{ okText }}</button>
    </div>
  </div>
</div>
