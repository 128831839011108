<div class="page-container" theme>
  <div class="content-wrap">
    <app-navbar *ngIf="route?.includes('home') || route?.includes('update')"></app-navbar>
    <main class="container-fluid" [class.p-0]="route !== 'home'" [class.pr-0]="route === 'home'">
      <main>
        <router-outlet></router-outlet>
      </main>
    </main>
  </div>
  <app-footer
    *ngIf="
      !route?.includes('entry/register/pharmacy') &&
      !route?.includes('entry/register/patient') &&
      !route?.includes('entry/register/doctor') &&
      !route?.includes('entry/register/dentist') &&
      !route?.includes('new-prescription') &&
      !route?.includes('prescription/') &&
      !route?.includes('recipe/') &&
      !route?.includes('pharmacist/new') &&
      !route?.includes('add-commercial-data') &&
      !route?.includes('dispensation') &&
      !route?.includes('document') &&
      !route?.includes('prescription-new')
    "
  ></app-footer>
</div>
