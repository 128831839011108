<ng-container *ngIf="hasCipFeature; then templateNew; else templateOld"></ng-container>
<ng-template #templateNew>
  <nav id="siderbar-menu" class="sidebar" theme>
    <div class="inner-menu">
      <ul class="menu list-unstyled">
        <li>
          <a routerLink="/"> <i class="fas fa-plus-square"></i> Dispensar </a>
        </li>

        <li *ngIf="user?.userType === 'pharmacy'">
          <a routerLink="/historic"> <i class="fa fa-history"></i> Histórico de Retenções </a>
        </li>

        <li *ngIf="user?.userType === 'pharmacy'">
          <a routerLink="/pharmacist/"> <i class="fa fa-user-friends"></i> Farmacêuticos </a>
        </li>

        <li *ngIf="hasAccessToManipulatedFunctionality()">
          <a routerLink="/personalized-medications">
            <i class="fa fa-account-box"></i>
            Manipulados
          </a>
        </li>

        <li>
          <a routerLink="/profile">
            <i class="fa fa-account-box"></i>
            Meus dados
          </a>
        </li>
      </ul>
    </div>
  </nav>
</ng-template>
<!-- ************************************************************************************************************** -->
<ng-template #templateOld>
  <nav id="siderbar-menu" class="sidebar" theme>
    <div class="inner-menu">
      <ul class="menu list-unstyled" [ngSwitch]="user?.userType">
        <ng-container *ngSwitchCase="'healthProfessional'">
          <li>
            <a routerLink="/dashboard">
              <i class="fa fa-view-compact"></i>
              Meu Painel
            </a>
          </li>
          <li>
            <a routerLink="/prescription/new">
              <i class="fa fa-plus"></i>
              Nova Prescrição
            </a>
          </li>
          <li>
            <a routerLink="/favorites">
              <i class="fa fa-heart"></i>
              Modelos Favoritos
            </a>
          </li>
          <li>
            <a routerLink="/historic">
              <i class="fa fa-history"></i>
              Histórico de Documentos
            </a>
          </li>
          <li>
            <a routerLink="/patients" class="submenu">
              <i class="fa fa-assignment"></i>
              Meus Pacientes
            </a>
          </li>
          <li *ngIf="hasAccessToManipulatedFunctionality()">
            <a routerLink="/personalized-medications" class="submenu">
              <i class="fa fa-assignment"></i>
              Personalizar Tratamento
            </a>
          </li>
          <li>
            <a routerLink="/profile">
              <i class="fa fa-account-box"></i>
              Meus dados
            </a>
          </li>
        </ng-container>

        <ng-container *ngSwitchCase="'patient'">
          <li>
            <a routerLink="/dashboard">
              <i class="fa fa-view-compact"></i>
              Meu Painel
            </a>
          </li>
          <a routerLink="/historic">
            <i class="fa fa-business"></i>
            Minhas Prescrições
          </a>
          <li>
            <a routerLink="/profile">
              <i class="fa fa-account-box"></i>
              Meus Dados
            </a>
          </li>
        </ng-container>

        <ng-container *ngSwitchCase="'pharmacy'">
          <li>
            <a routerLink="/dashboard">
              <i class="fa fa-view-compact"></i>
              Meu painel
            </a>
          </li>
          <li>
            <a (click)="openSearch()" class="submenu">
              <i class="fa fa-search"></i>
              Buscar Receitas
            </a>
          </li>
          <li>
            <a routerLink="/pharmacy/recipe">
              <i class="fas fa-clipboard-list"></i> Receitas de Terceiros
            </a>
          </li>
          <li>
            <a routerLink="/pharmacist/"> <i class="fa fa-user-friends"></i>Farmacêuticos</a>
          </li>
          <!-- <li>
            <a routerLink="/historic"> <i class="fa fa-history"></i>Histórico de Retenções</a>
          </li> -->
          <li *ngIf="hasAccessToManipulatedFunctionality()">
            <a routerLink="/personalized-medications">
              <i class="fa fa-account-box"></i>
              Manipulados
            </a>
          </li>
          <li>
            <a routerLink="/profile">
              <i class="fa fa-account-box"></i>
              Meus Dados
            </a>
          </li>
        </ng-container>

        <ng-container *ngSwitchCase="'pharmacist'">
          <li>
            <a routerLink="/dashboard">
              <i class="fa fa-view-compact"></i>
              Meu painel
            </a>
          </li>
          <li>
            <a (click)="openSearch()" class="submenu">
              <i class="fa fa-search"></i>
              Buscar Receitas
            </a>
          </li>
          <li>
            <a routerLink="/pharmacy/recipe">
              <i class="fas fa-clipboard-list"></i>
              Receita de Terceiros
            </a>
          </li>
          <li>
            <a routerLink="/profile">
              <i class="fa fa-account-box"></i>
              Meus dados
            </a>
          </li>
          <li *ngIf="hasAccessToManipulatedFunctionality()">
            <a routerLink="/personalized-medications">
              <i class="fa fa-account-box"></i>
              Manipulados
            </a>
          </li>
        </ng-container>

        <ng-container *ngSwitchCase="'admin'">
          <li>
            <a (click)="changeRoute('')">
              <i class="fa fa-view-compact"></i>
              Visão geral
            </a>
          </li>

          <li *ngIf="user?.permission === Permission.MASTER">
            <a (click)="changeRoute('register')">
              <i class="fa fa-business"></i>
              Cadastrar Administrador
            </a>
          </li>

          <li>
            <a (click)="changeRoute('doctors')">
              <i class="fa fa-user"></i>
              Médicos
            </a>
            <ul>
              <li class="subitem" (click)="changeRoute('dentists')"><a>Dentistas</a></li>
              <li class="subitem" (click)="changeRoute('patients')"><a>Pacientes</a></li>
              <li class="subitem" (click)="changeRoute('pharmacies')"><a>Farmácias</a></li>
              <li class="subitem" (click)="changeRoute('pharmacists')"><a>Farmacêuticos</a></li>
            </ul>
          </li>

          <li>
            <a (click)="changeRoute('prescribers')">
              <i class="fas fa-user"></i>
              Atividade dos Prescritores
            </a>
          </li>

          <li>
            <a (click)="changeRoute('prescriptions')">
              <i class="fa fa-file-medical"></i>
              Prescrições
            </a>
            <ul>
              <li class="subitem" (click)="changeRoute('attestations')"><a>Atestados</a></li>
              <li class="subitem" (click)="changeRoute('exams')"><a>Exames</a></li>
              <li class="subitem" (click)="changeRoute('orientations')"><a>Outros documentos</a></li>
              <li class="subitem" (click)="changeRoute('retentions')"><a>Retenções</a></li>
            </ul>
          </li>

          <li>
            <a (click)="changeRoute('specialties')">
              <i class="fas fa-file-alt"></i>
              Especialidades
            </a>
            <ul>
              <li class="subitem" (click)="changeRoute('medicaments')"><a>Medicamentos</a></li>
              <li class="subitem" (click)="changeRoute('additional-features')">
                <a>Funcionalidades adicionais</a>
              </li>
            </ul>
          </li>

          <li>
            <a (click)="changeRoute('logs')">
              <i class="fa fa-business"></i>
              QR Code Logs
            </a>
            <ul>
              <li class="subitem" (click)="changeRoute('logs')"><a>Todos</a></li>
              <li class="subitem" (click)="changeRoute('logs', { groupBy: 'date' })"><a>Por dia</a></li>
              <li class="subitem" (click)="changeRoute('logs', { groupBy: 'month' })"><a>Por mês</a></li>
              <li class="subitem" (click)="changeRoute('logs', { groupBy: 'prescription' })">
                <a>Por prescrição</a>
              </li>
            </ul>
          </li>

          <li>
            <a (click)="changeRoute('unsubscribe-user')">
              <i class="ml-1 mr-3" nz-icon nzType="user-delete" nzTheme="outline"></i>
              Descadastrar usuário
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
  </nav>
</ng-template>

<div id="siderbar-menu-blur"></div>
