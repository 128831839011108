<nz-tabset>
  <nz-tab [nzTitle]="tab01">
    <ng-template #tab01>
      <img src="assets/icons/door-open-solid.svg" />
    </ng-template>
    <ng-container *ngIf="userType == 'healthProfessional'">
      <p class="bold">Bem vindo à Teleconsulta Receita Digital</p>
      <p>
        Esta é uma das ferramentas que fazem parte do plano pago da plataforma Receita Digital.
      </p>
      <p>
        Para saber como funciona, basta clicar nos botões acima e conhecer todos os detalhes desta nova
        ferramenta.
      </p>
      <p>
        Gostou do que viu? Clique no botão CONTRATAR acima.
      </p>
    </ng-container>

    <ng-container *ngIf="userType == 'patient'">
      <p class="bold">Bem vindo à Teleconsulta Receita Digital</p>
      <p>
        Aqui você encontra diversos médicos que atendem online, via teleconsulta e estão prontos para
        fazer sua Receita Digital.
      </p>
      <p>
        Para saber como funciona, navegue pelos botões acima e tire suas principais dúvidas sobre a
        consulta, formas de pagamento, como receber sua receita, políticas de cancelamento, e mais!
      </p>
    </ng-container>
  </nz-tab>

  <nz-tab [nzTitle]="tab02">
    <ng-template #tab02>
      <img
        src="{{
          userType == 'healthProfessional'
            ? 'assets/icons/dollar-sign-solid.svg'
            : 'assets/icons/ambulance-solid.svg'
        }}"
      />
    </ng-template>
    <ng-container *ngIf="userType == 'healthProfessional'">
      <p class="bold">Quanto custa?</p>
      <p>
        Esta é uma das ferramentas que fazem parte do plano pago da plataforma Receita Digital.
      </p>
      <p>
        Pagando apenas R$139,90 por mês você usa nossa plataforma para fazer quantas consultas quiser.
      </p>
      <p>
        Você pode usar a Teleconsulta Receita Digital para atender seus próprios pacientes e também para
        atender novos pacientes que procuram nossa plataforma em busca de atendimento médico.
      </p>
      <p>
        Cancele quando quiser, a fidelidade que acreditamos é aquela em que o cliente gosta do produto,
        pode isso decide ficar!
      </p>
    </ng-container>

    <ng-container *ngIf="userType == 'patient'">
      <p class="bold">É uma emergência?</p>

      <p>Se você está se sentindo mal, considere procurar a emergência mais próxima!</p>

      <p>Até mesmo as consultas do plantão podem demorar mais do que é seguro esperar!</p>
    </ng-container>
  </nz-tab>

  <nz-tab [nzTitle]="tab03">
    <ng-template #tab03>
      <img
        src="{{
          userType == 'healthProfessional'
            ? 'assets/icons/tools-solid.svg'
            : 'assets/icons/video-solid.svg'
        }}"
      />
      <!-- <img src="assets/icons/video-solid.svg" *ngIf="userType == 'patient'" /> -->
    </ng-template>
    <ng-container *ngIf="userType == 'healthProfessional'">
      <p class="bold">Quais as ferramentas terei acesso?</p>
      <p>
        Ao contratar a Teleconsulta Receita Digital você terá acesso a todas as ferramentas da plataforma
        que você já usa e mais:
      </p>
      <br />
      <p>
        Agenda para marcar as consultas dos seus pacientes e receber agendamento de novos pacientes
      </p>
      <p>
        Ferramenta de videoconferência com criptografia ponta a ponta, e segurança adequada para
        atendimentos médicos.
      </p>
      <p>
        Ferramenta para anotações durante as consultas, que já ficam salvas no perfil do seu paciente.
      </p>
    </ng-container>

    <ng-container *ngIf="userType == 'patient'">
      <p class="bold">Às vezes você precisa mais do que uma teleconsulta...</p>
      <p>
        A teleconsulta possui limitações pela distância médico-paciente, portanto nem sempre é possível
        fazer um diagnóstico.
      </p>
      <p>
        Dê prefereência a consultas com médicos próximos de você, caso seja necessária uma consulta
        complementar presencial.
      </p>
      <p>Está em dúvida? Converse com um dos nossos atendentes!</p>
    </ng-container>
  </nz-tab>

  <nz-tab [nzTitle]="tab04">
    <ng-template #tab04>
      <img
        src="{{
          userType == 'healthProfessional'
            ? 'assets/icons/video-solid.svg'
            : 'assets/icons/stethoscope-solid.svg'
        }}"
      />
      <!-- <img src="assets/icons/stethoscope-solid.svg" *ngIf="userType == 'patient'" /> -->
    </ng-template>
    <ng-container *ngIf="userType == 'healthProfessional'">
      <p class="bold">Como funciona?</p>
      <p>
        Esta é uma das ferramentas que fazem parte do plano pago da plataforma Receita Digital. Veja como
        funciona:
      </p>
    </ng-container>

    <ng-container *ngIf="userType == 'patient'">
      <p class="bold">Não interferimos na conduta do profissional!</p>
      <p>O médico é soberano para decidir qual a melhor conduta a se adotar;</p>
      <p>
        Se você não gostar do desfecho da consulta, você pode buscar uma segunda opinião, mas não será
        possível solicitar reembolso da consulta.
      </p>
    </ng-container>
  </nz-tab>

  <nz-tab [nzTitle]="tab05">
    <ng-template #tab05>
      <img
        src="{{
          userType == 'healthProfessional'
            ? 'assets/icons/calendar-alt-solid.svg'
            : 'assets/icons/dot-circle-regular.svg'
        }}"
      />
      <!-- <img src="assets/icons/dot-circle-regular.svg" *ngIf="userType == 'patient'" /> -->
    </ng-template>

    <ng-container *ngIf="userType == 'healthProfessional'">
      <p class="bold">Agendamento de consulta</p>
      <p>
        Esta é uma das ferramentas que fazem parte do plano pago da plataforma Receita Digital. Veja como
        funciona:
      </p>
    </ng-container>

    <ng-container *ngIf="userType == 'patient'">
      <p class="bold">É proibido gravar!</p>
      <p>Médicos e pacientes são proibidos de gravar a consulta sem o consentimento do outro!</p>
      <p>Também é proibido "printar" a tela sem autorização!</p>
      <p>
        Se por algum motivo você acha que precisa gravar a consulta ou "printar" a tela, informe o
        profissional que está lhe atendendo e solicite sua autorização!
      </p>
    </ng-container>
  </nz-tab>

  <nz-tab [nzTitle]="tab06">
    <ng-template #tab06>
      <img
        class="white"
        src="{{
          userType == 'healthProfessional'
            ? 'assets/icons/credit-card-solid.svg'
            : 'assets/icons/capsules-solid.svg'
        }}"
      />
      <!-- <img
        class="white"
        src=""
        *ngIf="userType == 'patient'"
      /> -->
    </ng-template>
    <ng-container *ngIf="userType == 'healthProfessional'">
      <p>Receba pelas consultas com pagamento online</p>
      <p>
        Esta é uma das ferramentas que fazem parte do plano pago da plataforma Receita Digital. Veja como
        funciona:
      </p>
      <p>Usamos a ferramenta de pagamento Safe2Pay, da Safeweb, segurança...</p>
    </ng-container>

    <ng-container *ngIf="userType == 'patient'">
      <p class="bold">Pagar por uma consulta não lhe garante uma receita médica!</p>
      <p>
        Nem toda consulta gera uma receita médica e o médico não é obrigado a prescrever um medicamento,
        um pedido de exames ou atestado médico, mesmo que você tenha solicitado.
      </p>
      <p>Assim como acontece nas consultas presenciais.</p>
    </ng-container>
  </nz-tab>

  <nz-tab [nzTitle]="tab07">
    <ng-template #tab07>
      <img
        class="white"
        src="{{
          userType == 'healthProfessional'
            ? 'assets/icons/users-solid.svg'
            : 'assets/icons/dollar-sign-solid.svg'
        }}"
      />
      <!-- <img
        class="white"
        src="assets/icons/dollar-sign-solid.svg"
        *ngIf="userType == 'patient'"
      /> -->
    </ng-template>
    <ng-container *ngIf="userType == 'healthProfessional'">
      <p class="bold">Atenda seus pacientes e novos pacientes</p>
      <p>
        Esta é uma das ferramentas que fazem parte do plano pago da plataforma Receita Digital. Veja como
        funciona:
      </p>
    </ng-container>

    <ng-container *ngIf="userType == 'patient'">
      <p class="bold">Quanto vou pagar? Como vou pagar?</p>
      <p>
        Cada médico decide quanto cobrar pela consulta. Você poderá consultar este valor nas informações
        dos médicos disponíveis. Também será possível definir um filtro com o valor máximo que você
        deseja pagar.
      </p>
      <p>
        Para consultas agendadas com 48h de antecedência, você pode pagar com cartão de crédito, débito e
        boleto bancário. Para as consultas de emergências, com menos de 48h de antecedência, somente com
        cartões de crédito e débito.
      </p>
    </ng-container>
  </nz-tab>

  <nz-tab [nzTitle]="tab08">
    <ng-template #tab08>
      <!-- assets/icons/notes-medical-solid.svg -->
      <img
        class="white"
        src="{{
          userType == 'healthProfessional'
            ? 'assets/icons/notes-medical-solid.svg'
            : 'assets/icons/times-circle-solid.svg'
        }}"
      />
      <!-- <img
        class="white"
        src=""
        *ngIf="userType == 'patient'"
      /> -->
    </ng-template>
    <ng-container *ngIf="userType == 'healthProfessional'">
      <p class="bold">Atenda seus pacientes e novos pacientes</p>
      <p>
        Esta é uma das ferramentas que fazem parte do plano pago da plataforma Receita Digital. Veja como
        funciona:
      </p>
    </ng-container>

    <ng-container *ngIf="userType == 'patient'">
      <p class="bold">E se precisar cancelar ou reagendar uma consulta?</p>
      <p>
        Você poderá solicitar a alteração da consulta previamente marcada, escolhendo um novo horário com
        o mesmo profissional ou usando o valor pago como crédito para escolher outro profissional.
      </p>
      <p>
        Se desejar cancelar a consulta e solicitar o reembolso do valor pago, será cobrado o valor de 5%
        para cobrir as despesas da operadora financeira.
      </p>
      <p>
        Caso o profissional não compareça no horário marcado, você poderá solicitar o reembolso integral
        do valor pago, e nós nos responsabilizamos pelo pagamento das despesas da operadora.
      </p>
    </ng-container>
  </nz-tab>
</nz-tabset>
