<div>
  <div class="modal-header">
    <img src="assets/icons/Covid-19 arte.png" class="gfg" />
    <h4 class="modal-title pull-left text-white">MOVIMENTO COVID-19 : Teste Rápido</h4>
    <button type="button" class="close pull-right" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="container">
    <form form class="row justify-content-center" [formGroup]="covidForm">
      <p>
        Com o objetivo de ajudar no combate ao Covid-19, estamos mapeando as farmácias e drogarias que
        oferecem o teste rápido em suas lojas.
      </p>
      <h5>Sua farmácia realiza o Teste COVID-19?</h5>
      <div>
        <div class="form-input">
          <nz-form-label nzFor="covid" theme></nz-form-label>
          <nz-radio-group id="covid" formControlName="covid" theme>
            <h4>
              <label nz-radio nzValue="1" (click)="save(1)">Sim, realizo</label>
              <label nz-radio nzValue="2" (click)="save(2)">Não realizo</label>
              <label nz-radio nzValue="3" (click)="save(3)">Não realizo, mas gostaria de ter</label>
            </h4>
          </nz-radio-group>
        </div>
      </div>
    </form>
  </div>
</div>
