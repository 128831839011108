import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/core/services/auth.service';
import { CepService } from '@app/modules/entry/services/cep.service';
import { HealthProfessionalService } from '@app/modules/health-professional/services/health-professional.service';
import { BrazilianBanks } from '@app/shared/data/Brazilian-banks';
import { BrazilianStates } from '@app/shared/data/Brazilian-states';
import { BrazilState, HealthProfessional } from '@app/shared/models';
import { BrazilBanks } from '@app/shared/models/brazil-banks';
import { User } from '@app/shared/models/decodedLoginToken';
import { markFormGroup } from '@app/utils/markFormGroup';

@Component({
  selector: 'app-subaccount-step',
  templateUrl: './subaccount-step.component.html',
  styleUrls: ['./subaccount-step.component.scss', '../../modal-telemedicine-contract.component.scss']
})
export class SubaccountStepComponent implements OnInit {
  @Input() profile: string;
  @Output() profileChange = new EventEmitter<string>();
  @Output() formChange = new EventEmitter<any>();

  @ViewChild('numero', { static: false })
  numeroRef: ElementRef;

  states: BrazilState[] = BrazilianStates;
  banks: BrazilBanks[] = BrazilianBanks;

  private _form: FormGroup;
  private lastCep: string;
  private healthProfessional: HealthProfessional;
  private personalForm: any;
  private companyForm: any;

  constructor(
    private fb: FormBuilder,
    private cepService: CepService,
    private authService: AuthService,
    private healthProfessionalService: HealthProfessionalService
  ) {
    this._form = this.fb.group({
      name: this.fb.control('', Validators.required),
      commercialName: this.fb.control(null),
      identity: this.fb.control('', [Validators.required, Validators.minLength(11)]),
      responsibleName: this.fb.control(null),
      responsibleIdentity: this.fb.control(null),
      email: this.fb.control('', Validators.compose([Validators.required, Validators.email])),
      techName: this.fb.control(null),
      techIdentity: this.fb.control(null),
      techEmail: this.fb.control(null),
      bankData: this.fb.group({
        bank: this.fb.group({
          code: this.fb.control('', Validators.required)
        }),
        accountType: this.fb.group({
          code: this.fb.control('', Validators.required)
        }),
        bankAgency: this.fb.control('', Validators.required),
        bankAgencyDigit: this.fb.control('', Validators.required),
        bankAccount: this.fb.control('', Validators.required),
        bankAccountDigit: this.fb.control('', Validators.required)
      }),
      address: this.fb.group({
        zipCode: this.fb.control('', Validators.required),
        street: this.fb.control('', Validators.required),
        number: this.fb.control('', Validators.required),
        complement: this.fb.control(''),
        district: this.fb.control('', Validators.required),
        cityName: this.fb.control('', Validators.required),
        stateInitials: this.fb.control('', Validators.required),
        countryName: this.fb.control('', Validators.required)
      })
    });
  }

  ngOnInit() {
    this._form.valueChanges.subscribe(value => {
      this.formChange.emit(value);
      if (value.name) {
        if (this.profile === 'pf') {
          this.personalForm = value;
        } else if (this.profile === 'pj') {
          this.companyForm = value;
        }
      }
    });
  }

  get user(): User {
    return this.authService.user();
  }

  @Input()
  set form(form: any) {
    if (form) {
      this._form.setValue(form);
    }
  }

  get form() {
    return this._form;
  }

  async onChangeProfile(profile: string) {
    this.profileChange.emit(profile);
    this._form.reset();

    if (profile === 'pf') {
      this._form.get('commercialName').setValidators([]);
      this._form.get('responsibleName').setValidators([]);
      this._form.get('responsibleIdentity').setValidators([]);

      if (!this.healthProfessional) {
        const healthProfessional = await this.healthProfessionalService
          .getHealthProfessional(this.user._id)
          .toPromise();
        this.healthProfessional = healthProfessional;
      }

      if (this.personalForm) {
        this._form.setValue(this.personalForm);
      } else {
        this._form.patchValue({
          identity: this.healthProfessional.cpf,
          name: this.healthProfessional.name,
          email: this.user.email
        });

        const { commercialData } = this.healthProfessional;
        const { address } = commercialData && commercialData[0];
        if (address) {
          this._form.get('address').patchValue({
            ...address,
            zipCode: address.cep,
            district: address.neighborhood,
            cityName: address.city,
            stateInitials: address.uf,
            countryName: 'Brasil'
          });
        }
      }
    } else {
      this._form.get('responsibleName').setValidators([Validators.required]);
      this._form.get('responsibleIdentity').setValidators([Validators.required]);

      if (this.companyForm) {
        this._form.setValue(this.companyForm);
      }
    }
  }

  async consultCep(value: string) {
    const cep = value.replace(/[^\d]+/g, '');
    if (cep.length === 8 && this.lastCep !== cep) {
      this.lastCep = cep;
      const data = await this.cepService.consult(cep);
      if (!data.erro) {
        this._form.get('address').setValue({
          street: data.logradouro,
          number: null,
          district: data.bairro,
          zipCode: data.cep,
          cityName: data.localidade,
          stateInitials: data.uf,
          countryName: 'Brasil',
          complement: data.complemento
        });
        if (data.logradouro) {
          this.numeroRef.nativeElement.focus();
        }
      }
    }
  }

  get invalid() {
    markFormGroup(this._form);
    return this._form.invalid;
  }

  get errorMessage() {
    return 'Você precisa preencher todos os campos obrigatórios';
  }
}
