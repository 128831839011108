import { Component, OnInit, ViewChild } from '@angular/core';
import { PrescriptionService } from '../../services/prescription.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HealthProfessional } from '@app/shared/models';
import { Prescription } from '@app/shared/models/prescription';
import { AuthService } from '@app/core/services/auth.service';
import { NzNotificationService, NzModalService } from 'ng-zorro-antd';
import { Retention } from '@app/shared/models/retention';
import { PharmacyService } from '@app/modules/pharmacy/services/pharmacy.service';
import { ModalBuyerDataComponent } from '@app/modules/document/pages/modal-buyer-data/modal-buyer-data.component';
import { User } from '@app/shared/models/decodedLoginToken';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  quantidade = 1;
  allChecked = false;
  indeterminate = false;
  prescription: Prescription = new Prescription();
  prescriptionCode;
  medications;
  quantities = [];
  checkedNumber: number;
  loading = false;
  completeUser: any;
  pharmacyId: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private prescriptionService: PrescriptionService,
    private notification: NzNotificationService,
    private pharmacyService: PharmacyService,
    private modalService: NzModalService
  ) {
    const state = this.router.getCurrentNavigation().extras.state;
    if (state) {
      this.prescription = state.prescription;
      this.prescriptionCode = this.prescription.code;
    }
  }

  async ngOnInit() {
    this.loading = true;

    this.route.params.subscribe(async params => {
      this.prescriptionCode = params['param'];
    });
    await this.getCompleteUser();

    this.prescription = await this.prescriptionService
      .getPrescriptionbyCode(this.prescriptionCode)
      .toPromise();
    this.quantities = this.prescription.medicaments.map(m => m.availableQuantity);
    this.loading = false;
  }

  get user(): User {
    return this.authService.user();
  }

  refreshStatus(): void {
    const allChecked = this.prescription.medicaments.every(value => value.valid === true);
    const allUnChecked = this.prescription.medicaments.every(value => !value.valid);
    this.allChecked = allChecked;
    this.indeterminate = !allChecked && !allUnChecked;
    this.checkedNumber = this.prescription.medicaments.filter(value => value.valid).length;
  }

  private async getCompleteUser() {
    try {
      this.loading = true;
      const userType = this.user.userType;

      switch (userType) {
        case 'pharmacy':
          this.completeUser = await this.pharmacyService.getPharmacyByUserId(this.user._id).toPromise();
          this.pharmacyId = this.completeUser._id;
          break;
        default:
          const extraData = JSON.parse(localStorage.getItem('extra_data'));
          this.pharmacyId = extraData.pharmacyId;
      }

      this.loading = false;
    } catch (error) {
      this.loading = false;
      console.log(error);
    }
  }

  checkAll(value: boolean): void {
    this.prescription.medicaments.forEach(data => {
      if (data.availableQuantity > 0) {
        data.valid = value;
      }
    });
    this.refreshStatus();
  }

  getSpecialties() {
    return this.prescription.healthProfessional.specialties.map(s => s.title).join(', ');
  }

  openModalBuyerData() {
    const medicinesRetained = this.prescription.medicaments.filter(m => m.valid);
    if (medicinesRetained.length === 0) {
      this.notification.warning('Aviso', 'Selecione um medicamento');
    } else {
      const modal = this.modalService.create({
        nzContent: ModalBuyerDataComponent,
        nzComponentParams: {
          patient: this.prescription.patient
        },
        nzFooter: null,
        nzWidth: 800
      });

      modal.afterOpen.subscribe(_ => {
        modal.getContentComponent().saveRetention.subscribe(cpf => {
          this.saveRetentionFeedback(modal, cpf, medicinesRetained);
        });
      });
    }
  }

  async saveRetentionFeedback(modal: any, cpfBuyer: string, medicinesRetained: any[]) {
    const cpf = cpfBuyer.replace(/[^\d]+/g, '');
    await this.confirm(cpf, medicinesRetained);
    modal.close();
  }

  async confirm(cpfBuyer: string, medicinesRetained: any[]) {
    const retention = {
      cpfBuyer,
      medicinesRetained
    };

    this.prescriptionService
      .confirm(retention, this.pharmacyId, this.prescriptionCode)
      .then((res: Retention) => {
        this.router.navigate([`prescription/confirmation/successful/${res.dispensationCode}`]);
      });
  }

  changeRoute() {
    this.router.navigate([`/dashboard`]);
  }

  downloadPrescriptionPdf(prescription) {
    this.prescriptionService.downloadPrescriptionPdf(prescription.code);
  }

  getProfessionalTypeName() {
    return HealthProfessional.getProfessionalTypeName(
      this.prescription.healthProfessional.professionalType
    );
  }
}
