<footer class="footer" *ngIf="!isMobile; else templateMobile">
  <div class="versions">
    <span class="m-2">F: {{ frontendVersion }}</span>
    <span class="m-2">B: {{ backendVersion$ | async }}</span>
  </div>
  <div class="box-marca-and-social-medias">
    <div class="box-marca">
      <span *ngIf="isThemeActiveReceitaDigital()"
        >© Receita Digital {{ actualDate.getFullYear() }}
      </span>
      <span *ngIf="!isThemeActiveReceitaDigital()">Powered by Receita Digital ®</span>
      <span class="m-2 ml-2">•</span>
      <span>Grupo Consulta Remédios</span>
    </div>
    <ul class="list-unstyled list-inline social-icons mb-0 ml-2">
      <ng-container *ngIf="isThemeActiveReceitaDigital(); else mrd">
        <li class="list-inline-item">
          <a href="https://www.instagram.com/receitadigital" target="_blank"
            ><i class="fa fa-instagram"></i
          ></a>
        </li>
        <li class="list-inline-item">
          <a href="https://www.facebook.com/ReceitaDigital/" target="_blank"
            ><i class="fa fa-facebook"></i
          ></a>
        </li>
        <li class="list-inline-item">
          <a href="https://www.linkedin.com/company/receitadigital/" target="_blank"
            ><i class="fa fa-linkedin"></i
          ></a>
        </li>
      </ng-container>
      <ng-template #mrd>
        <li class="list-inline-item">
          <a href="https://www.instagram.com/meureceituariodigital/" target="_blank"
            ><i class="fa fa-instagram"></i
          ></a>
        </li>
        <li class="list-inline-item">
          <a href="https://www.facebook.com/meureceituariodigital/" target="_blank"
            ><i class="fa fa-facebook"></i
          ></a>
        </li>
        <li class="list-inline-item">
          <a href="https://www.linkedin.com/company/meu-receituário-digital" target="_blank"
            ><i class="fa fa-linkedin"></i
          ></a>
        </li>
      </ng-template>
    </ul>
  </div>
</footer>
<ng-template #templateMobile>
  <app-footer-mobile
    [frontendVersion]="frontendVersion"
    [backendVersion]="backendVersion$ | async"
  ></app-footer-mobile>
</ng-template>
