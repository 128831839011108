<div class="orientation" [formGroup]="orientationForm">
  <nz-form-item>
    <label nz-checkbox formControlName="showPatientCpf" *ngIf="!editMode">
      <span> {{ showCpfText }} </span>
    </label>
  </nz-form-item>
  <label for="material">Título</label>
  <input
    id="title"
    class="input-name"
    formControlName="title"
    nz-input
    placeholder="Digite o título do documento"
    [ngStyle]="{
      'background-color': colorSetting?.inputBackground,
      'border-color': colorSetting?.inputBorder
    }"
  />
  <label for="material">Escreva o conteúdo do documento:</label>
  <textarea
    rows="4"
    nz-input
    formControlName="text"
    [ngStyle]="{
      'background-color': colorSetting?.inputBackground,
      'border-color': colorSetting?.inputBorder
    }"
  ></textarea>
</div>
