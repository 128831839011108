<div theme>
  <header>
    <span class="title">
      DADOS EMPRESARIAIS
    </span>
  </header>

  <div class="modal-body">
    <form nz-form [formGroup]="form" (ngSubmit)="submitForm()">
      <ng-container *ngIf="userType === 'healthProfessional'">
        <div>
          <nz-form-item>
            <nz-form-label nzFor="name">Nome <span class="mandatory">*</span></nz-form-label>
            <nz-form-control>
              <nz-input-group>
                <input
                  type="text"
                  nz-input
                  formControlName="name"
                  placeholder="Nome"
                  maxlength="128"
                  autocomplete="disabled"
                />
              </nz-input-group>
              <nz-form-explain *ngIf="form.get('name').dirty && form.get('name').errors">
                <ng-container *ngIf="form.get('name').hasError('required')">
                  Campo obrigatório, por favor digite o seu nome e sobrenome.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>

          <div class="row">
            <div class="col-md-6">
              <nz-form-item *ngIf="form.get('telephone') as telephone">
                <nz-form-label nzFor="telephone"
                  >Telefone 1 <span class="mandatory">*</span></nz-form-label
                >
                <nz-form-control>
                  <nz-input-group>
                    <input
                      nz-input
                      type="text"
                      formControlName="telephone"
                      placeholder="(21) 92222-2222"
                      [mask]="telephone.value.length <= 10 ? '(00) 0000-00009' : '(00) 00000-0000'"
                      autocomplete="disabled"
                    />
                  </nz-input-group>
                  <nz-form-explain *ngIf="telephone.dirty && telephone.errors">
                    <ng-container *ngIf="telephone.hasError('required')">
                      Campo obrigatório, por favor digite seu telefone para contato.
                    </ng-container>
                  </nz-form-explain>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-md-6">
              <nz-form-item *ngIf="form.get('cellphone') as cellphone">
                <nz-form-label nzFor="cellphone">Telefone 2</nz-form-label>
                <nz-form-control>
                  <nz-input-group>
                    <input
                      nz-input
                      type="text"
                      formControlName="cellphone"
                      placeholder="(21) 92222-2222"
                      [mask]="cellphone.value.length <= 10 ? '(00) 0000-00009' : '(00) 00000-0000'"
                      autocomplete="disabled"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <nz-form-item>
            <nz-form-label nzFor="email">E-mail</nz-form-label>
            <nz-form-control nzErrorTip="E-mail inválido, por favor digite um endereço e-mail correto">
              <nz-input-group>
                <input
                  type="text"
                  nz-input
                  formControlName="email"
                  placeholder="usuário@email.com"
                  autocomplete="disabled"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <hr />
        </div>
      </ng-container>

      <div *ngIf="form.get('address') as addressGroup" [formGroup]="addressGroup">
        <nz-form-item>
          <nz-form-label nzFor="cep">CEP <span class="mandatory">*</span></nz-form-label>
          <nz-form-control nzErrorTip="Campo obrigatório, por favor digite o seu CEP.">
            <nz-input-group>
              <input
                (keyup)="onKey($event)"
                type="text"
                nz-input
                formControlName="cep"
                placeholder="CEP"
                mask="00.000-000"
                autocomplete="disabled"
              />
            </nz-input-group>
            <nz-form-explain *ngIf="addressGroup.get('cep').dirty && addressGroup.get('cep').errors">
              <ng-container *ngIf="addressGroup.get('cep').hasError('required')">
                Campo obrigatório, por favor digite o cep.
              </ng-container>
              <ng-container
                *ngIf="
                  !addressGroup.get('cep').hasError('required') &&
                  addressGroup.get('cep').hasError('cep')
                "
              >
                Cep inválido.
              </ng-container>
            </nz-form-explain>
          </nz-form-control>
        </nz-form-item>

        <div class="row">
          <div class="col-7">
            <nz-form-item>
              <nz-form-label nzFor="street">Logradouro <span class="mandatory">*</span></nz-form-label>
              <nz-form-control nzErrorTip="Campo obrigatório, por favor digite o seu Logradouro.">
                <nz-input-group>
                  <input
                    type="text"
                    nz-input
                    formControlName="street"
                    placeholder="Logradouro"
                    maxlength="128"
                    autocomplete="disabled"
                  />
                </nz-input-group>
                <nz-form-explain
                  *ngIf="addressGroup.get('street').dirty && addressGroup.get('street').errors"
                >
                  <ng-container *ngIf="addressGroup.get('street').hasError('required')">
                    Campo obrigatório, por favor digite o logradouro.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-5">
            <nz-form-item>
              <nz-form-label nzFor="number">Número <span class="mandatory">*</span></nz-form-label>
              <nz-form-control nzErrorTip="Campo obrigatório, por favor digite o seu número.">
                <nz-input-group>
                  <input
                    nz-input
                    formControlName="number"
                    placeholder="Número"
                    maxlength="10"
                    autocomplete="disabled"
                  />
                </nz-input-group>
                <nz-form-explain
                  *ngIf="addressGroup.get('number').dirty && addressGroup.get('number').errors"
                >
                  <ng-container *ngIf="addressGroup.get('number').hasError('required')">
                    Campo obrigatório, por favor digite o número.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <nz-form-item>
          <nz-form-label nzFor="complement">Complemento</nz-form-label>
          <nz-form-control>
            <nz-input-group>
              <input
                type="text"
                nz-input
                formControlName="complement"
                placeholder="Complemento"
                maxlength="128"
                autocomplete="disabled"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="neighborhood">Bairro <span class="mandatory">*</span></nz-form-label>
          <nz-form-control nzErrorTip="Campo obrigatório, por favor digite o seu Bairro.">
            <nz-input-group>
              <input
                type="text"
                nz-input
                formControlName="neighborhood"
                placeholder="Bairro"
                maxlength="128"
                autocomplete="disabled"
              />
            </nz-input-group>
            <nz-form-explain
              *ngIf="addressGroup.get('neighborhood').dirty && addressGroup.get('neighborhood').errors"
            >
              <ng-container *ngIf="addressGroup.get('neighborhood').hasError('required')">
                Campo obrigatório, por favor digite o bairro.
              </ng-container>
            </nz-form-explain>
          </nz-form-control>
        </nz-form-item>

        <div class="row">
          <div class="col-7">
            <nz-form-item>
              <nz-form-label nzFor="city">Cidade <span class="mandatory">*</span></nz-form-label>
              <nz-form-control nzErrorTip="Campo obrigatório, por favor digite o seu Cidade.">
                <nz-input-group>
                  <input
                    type="text"
                    nz-input
                    formControlName="city"
                    placeholder="Cidade"
                    maxlength="64"
                    autocomplete="disabled"
                  />
                </nz-input-group>
                <nz-form-explain
                  *ngIf="addressGroup.get('city').dirty && addressGroup.get('city').errors"
                >
                  <ng-container *ngIf="addressGroup.get('city').hasError('required')">
                    Campo obrigatório, por favor digite a cidade.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-5">
            <nz-form-item>
              <nz-form-label nzFor="uf">UF <span class="mandatory">*</span></nz-form-label>
              <nz-form-control
                nzHasFeedback
                nzErrorTip="Campo obrigatório, por favor selecione o UF do CRM"
              >
                <nz-select
                  formControlName="uf"
                  id="uf"
                  nzShowSearch
                  nzAllowClear
                  nzDropdownClassName="account-select"
                  nzPlaceHolder="Selecione"
                >
                  <nz-option
                    *ngFor="let state of states"
                    [nzLabel]="state?.sigla"
                    [nzValue]="state?.sigla"
                  >
                  </nz-option>
                </nz-select>
                <nz-form-explain *ngIf="addressGroup.get('uf').dirty && addressGroup.get('uf').errors">
                  <ng-container *ngIf="addressGroup.get('uf').hasError('required')">
                    Campo obrigatório, por favor selecione o UF.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>
    </form>

    <div *ngIf="loading">
      <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
      <nz-spin nzSimple [nzIndicator]="indicatorTemplate"> </nz-spin>
    </div>

    <button class="btn btn-icon btn-ok btn-save" type="button" (click)="submitForm()">
      <span class="btn-inner--text">SALVAR</span>
    </button>
  </div>
</div>
